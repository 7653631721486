import { gql } from "@apollo/client";

export const CREATE_PURCHASE = gql`
  mutation CreatePurchaseMutation(
    $amount: Float
    $createdAt: AWSDateTime
    $experienceID: ID
    $purchased_by: ID
    $slots: Int
    $purchaser_role: String
    $sessionID: ID
    $status: String
  ) {
    createPurchases(
      input: {
        amount: $amount
        createdAt: $createdAt
        experienceID: $experienceID
        purchased_by: $purchased_by
        slots: $slots
        purchaser_role: $purchaser_role
        sessionID: $sessionID
        status: $status
      }
    ) {
      id
      purchased_by
    }
  }
`;


export const DELETE_PURCHASE = gql`
  mutation DeletePurchaseMutation(
    $id: ID!
  ) {
    deletePurchases(
      input: {
        id: $id
      }
    ) {
      id
      purchased_by
    }
  }
`;

