

const stopProp = (e) => {
  e.stopPropagation();
};


function ApplicationWithdrawn({ removeOverlay, header, text, shown, position }) {
  return ( 
    <div
      className={`overlay_background ${shown}`}
      onClick={(e) => removeOverlay()}
    >
      <div className="overlay_card" onClick={(e) => stopProp(e)}>
        <div className={`popup_inner ${position} text-left`}>
          <div className="close-line" onClick={(e) => removeOverlay()}></div>
          <div className="withdrawn-app">
            <h3 className="popup_header">
              { header }
            </h3>
            <p>
              { text }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationWithdrawn;