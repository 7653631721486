import { useEffect, useState } from "react";
import InitialWrapper from "../../../Components/Common/InitialWrapper";
import { formatDate, getRandomColor } from "../../../utils/helpers";


function InvitedFriends({ friends }) {

  return ( 
    <div className="invited-friends-table">
      <div className="table-head border-bottom">
        <div>NAME</div>
        <div>SIGN UP DATE</div>
      </div>
      <div className="table-content">
        {friends.map((elt, index) =>
        <div className={`item mt-2 ${index !== friends.length - 1 ? "pb-2 border-bottom": ""}`} key={String(index)}>
          <div className="details">
            <InitialWrapper  
              name={elt.name}
              size="lg"
              cls=""
              randomColor={getRandomColor()}
            />
            <span>{elt.name}</span>
          </div>
          <div className="date-signup">{formatDate(elt.createdAt)}</div>
        </div>
        )}
      </div>
    </div>
  );
}

export default InvitedFriends;