/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import makeAnimated from 'react-select/animated'
import { useMutation } from "@apollo/client";
import NavTabs from "../Nav";
import {
  convertFirstLetterToUpper, getCities,
  getNameType,
  // returnDateType,
} from "../../../../utils/helpers";
// import { MONTHSHORTS, DAYS } from "../../../../utils/data";
import { UPDATE_STUDENT } from "../../../../Services/mutation/student";
import { setUserDataAction } from "../../../../Redux/actions/userActions";
import Verified from "../../../../Components/Popus/Verified";

const animatedComponents = makeAnimated();

const Personal = () => {
  const modifiedStates = getCities({
    keys: ["label", "value"],
    requiresAll: false,
    returnObj: true,
    sort: true
  });

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const [location, setLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState();
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [verified, setVerified] = useState(false);

  const genderJsx = [];
  const genders = ["male", "female", "other"];
  // const yearsJsx = [];
  // const daysJsx = [];
  // const monthJsx = [];
  //
  // const maxYear = new Date().getFullYear()-13
  // const minYear = new Date().getFullYear()-20
  //
  // let dateRange = []
  //
  // for(let i = minYear; i <= maxYear; i++){
  //   dateRange.push(i)
  // }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const save = async() => {
    setLoading(true)
    try {
      const inputData = {
        state: location?.label || user?.state,
        id: user?.id,
        ...state,
      };
  
      const data = await updateStudent({
        variables: { ...inputData, is_verified: true },
      })
      dispatch(setUserDataAction(data?.data?.updateStudent));
      setVerified(true);
      setTimeout(() => {
        setVerified(false);
      }, 3000);
      setLoading(false)
      
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  };
  

  return (
    <>
      <NavTabs />
      <div className={verified ? "overlay_shown" : "overlay_hidden"}>
        <Verified
            removeOverlay={() => setVerified(false)}
            shown={verified ? 'shown': 'hidden'}
            position={verified ? 'slide-up' : 'slide-down'}
            header="Success!"
            text="Updated Successfully!"
        />
      </div>
      <div className="auth-sec pb-6">
        <div className="des">
          Your personal information helps resources and mentors learn more about
          you.
        </div>
        <form>
          <div className="form-auth">
            <label>First Name</label>
            <input
              type="text"
              placeholder=""
              name="fname"
              onChange={user?.is_verified && handleChange}
              value={state?.fname || getNameType(user.name, "fname")}
              className={!user?.is_verified && 'inactive'}
            />
          </div>
          <div className="form-auth">
            <label>Last Name</label>
            <input
              type="text"
              placeholder=""
              name="lname"
              onChange={user?.is_verified && handleChange}
              value={state?.lname || getNameType(user.name, "lname")}
              className={!user?.is_verified && 'inactive'}
            />
          </div>
          {/*<div className="form-auth">*/}
          {/*  <label>When were you born?</label>*/}
          {/*  <div className="custom-select-options">*/}
          {/*    <select disabled>*/}
          {/*      <option>{MONTHSHORTS[returnDateType(user?.dob, "month")]}</option>*/}
          {/*      {MONTHSHORTS.forEach((elt) => {*/}
          {/*        monthJsx.push(*/}
          {/*          <option value={MONTHSHORTS[returnDateType(user?.dob, "month")]}>*/}
          {/*            {elt}*/}
          {/*          </option>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*      {monthJsx}*/}
          {/*    </select>*/}
          {/*    <select disabled>*/}
          {/*      <option>{DAYS[returnDateType(user?.dob, "day") - 1]}</option>*/}
          {/*      {DAYS.forEach((elt) => {*/}
          {/*        daysJsx.push(<option value={elt}>{elt}</option>);*/}
          {/*      })}*/}
          {/*      {daysJsx}*/}
          {/*    </select>*/}
          {/*    <select disabled>*/}
          {/*      <option>{returnDateType(user?.dob, "year")}</option>*/}
          {/*      {dateRange.forEach((elt) => {*/}
          {/*        yearsJsx.push(<option value={elt} key={`year-${elt}`}>{elt}</option>)*/}
          {/*      })}*/}
          {/*      {yearsJsx}*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="form-auth">
            <label>How do you identify yourself?</label>
            <div className="custom-text-radio">
              {genders?.forEach((elt) => {
                genderJsx.push(
                  elt === user?.gender ? (
                    <>
                      <input
                        type="radio"
                        id={convertFirstLetterToUpper(elt)}
                        name="gender"
                        value={elt}
                        disabled
                        defaultChecked
                      />
                      <label htmlFor={elt}>
                        {convertFirstLetterToUpper(elt)}
                      </label>
                    </>
                  ) : (
                    <>
                      <input
                        type="radio"
                        id={convertFirstLetterToUpper(elt)}
                        name="gender"
                        value={elt}
                        disabled
                      />
                      <label htmlFor={convertFirstLetterToUpper(elt)}>
                        {convertFirstLetterToUpper(elt)}
                      </label>
                    </>
                  )
                );
              })}
              {genderJsx}
            </div>
          </div>
          <div className="form-auth">
            <label>Street Address</label>
            <input
              type="text"
              placeholder="Street Address"
              name="address"
              value={state?.address || user?.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-auth">
            <label>Apartment or Suite (optional)</label>
            <input
              type="text"
              placeholder="Apt #12"
              name="apartment"
              value={state?.apartment || user?.apartment}
              onChange={handleChange}
            />
          </div>
          <div className="form-auth">
            <label>Town or State</label>
            <Select
              closeMenuOnSelect={false}
              className="grayed"
              components={animatedComponents}
              options={modifiedStates}
              isClearable={true}
              placeholder="Start typing..."
              defaultInputValue={location?.label || user?.state}
              name="state"
              onChange={setLocation}
            />
          </div>
        </form>
        <div className={`select-btn`}>
          <button 
            type="type" 
            onClick={save}
            disabled={loading}
            style={{opacity: loading ? 0.6 : 1}}>
            {loading ? 'Loading...' : 'Save'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Personal;
