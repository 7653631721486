import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetUserDataAction } from '../../Redux/actions/userActions'
import { resetCognitoUserDataAction } from '../../Redux/actions/cognitoUserActions'
import { resetLoadersDataAction } from '../../Redux/actions/loadersAction'
import { useDispatch } from 'react-redux'
import Close from '../../Assets/images/x.svg'
import { Mail } from 'react-feather'
import './style.scss'
import { resetHomeOpportunityDataAction } from '../../Redux/actions/homePageOpportunities'


const VerifyEmail = () => {
  const search = new URLSearchParams(useLocation().search);
  const [countDown, setCountDown] = useState(5)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = search.get('status');

  const logout = async () => {
    dispatch(resetUserDataAction());
    dispatch(resetCognitoUserDataAction());
    dispatch(resetLoadersDataAction());
    dispatch(resetHomeOpportunityDataAction());
    navigate('/login');

    // const signoutUri = awsmobile.oauth.redirectSignOut;
    // window.location.href = 'https://' + awsmobile.oauth.domain + '/logout?client_id=' + awsmobile.aws_user_pools_web_client_id + '&logout_uri=' + signoutUri;
  }

  useEffect(() => {
    if(status && status === 'new'){
      if (countDown){
        setTimeout(()=>{
          setCountDown(prev => countDown -1)
        }, 1000)
      }
      else {
        logout()
      }
    }
  }, [countDown])

  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          {status && status === 'new'
          ? 
          null
          : 
            <img src={Close} alt="icon" onClick={() => navigate(-1)}/>
          }
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="verify-email mt-5">
        <div className="circle">
          <Mail size="75" />
        </div>
        <div className="title">Check your Email</div>
        <div className="des">
          We just sent you an email with a link to confirm your { status || '' } email address.
        </div>
        <div className="bottom">
          <p>Did not receive the email?</p>
          <p>
            Check your spam folder, or <strong>request a new email.</strong>
          </p>
        </div>
      </div>
      {status && status === 'new'
      ? 
      <div className="countdown my-3 text-center">
        <h4>Logging out in <b>{countDown}s</b></h4>
      </div>
      : 
      null
      }
    </>
  )
}

export default VerifyEmail
