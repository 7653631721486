import { gql } from "@apollo/client";

export const CREATE_STUDENT_GUARDIAN = gql`
  mutation CreateNewStudentGuardian(
    $name: String!
    $phone: AWSPhone
    $email: AWSEmail
    $createdAt: AWSDateTime
  ) {
    createStudentGuardian(
      input: {
        name: $name
        phone: $phone
        email: $email
        createdAt: $createdAt
      }
    ) {
      createdAt
      email
      id
      name
      phone
    }
  }
`;


export const DELETE_STUDENT_GUARDIAN = gql`
  mutation DeleteStudentGuardian(
    $id: ID!
  ) {
    deleteStudentGuardian(
      input: {
        id: $id
      }
    ) {
      createdAt
      email
      id
      name
      phone
    }
  }
`;