import { gql, useQuery } from "@apollo/client";

export const GET_JUNITY_INFLUENCERS = gql`
  query JunityInfluencersQuery {
    listJunityInfluencers (limit: 1000) {
      items {
        role
        role_ID
        id
        createdAt
        is_active
      }
    }
}
`

export function AllInfluencers() {
  const resp = useQuery(GET_JUNITY_INFLUENCERS);
  if (resp?.data) {
    const data = resp?.data?.listJunityInfluencers?.items;
    return data
  }
  return [];
}


export const GET_JUNITY_INFLUENCER = gql`
  query SingleInfluencer($id: ID!) {
    getJunityInfluencer(id: $id) {
      role
      role_ID
      id
      createdAt
      is_active
    }
  }
`;


export function GetJunityInfluencer(id) {
  const resp = useQuery(GET_JUNITY_INFLUENCER, {
    variables: {
      id
    },
  });

  if (resp?.data) {
    return {
      data: resp?.data?.getJunityInfluencer,
      refetch: resp?.refetch
    }
  }

  return {};
}
