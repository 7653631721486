import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Clock from '../../../Assets/images/stopwatch.svg';

const InProgressVerifivation = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="auth-sec">
        <div className="back-head">
          <Link to="/home">
            <i className="fas fa-times"></i>
          </Link>
        </div>
        <form>
          <div className="checking-mail">
            <div className="mail-icon">
              <img src={Clock} alt="icon" />
            </div>
            <div className="title">Verification in Progress.</div>
            <div className="des">
              You should get an update on your verfication status within 48
              hours.
            </div>
            <div className="submit-btn-v">
              <button onClick={() => navigate('/home')}>Continue Exploring Junity</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default InProgressVerifivation
