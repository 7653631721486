/* eslint-disable no-unused-expressions */
import { Auth } from 'aws-amplify';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT } from '../../../Services/mutation/student'
import { setUserDataAction } from '../../../Redux/actions/userActions';
import { setCognitoUserDataAction } from '../../../Redux/actions/cognitoUserActions';
import Verified from '../../../Components/Popus/Verified';
import { GetStudentByPhone } from '../../../Services/query/students';
import { isMobilePhone } from "validator";
import AlertToast from '../../../Components/Common/Alerts/ToastAlert';
import {isRequiredFieldsPassed} from "../../../utils/helpers";


const ChangePhone = () => {
  const [verifiedOverlay, setVerifiedOverlay] = useState(false);
  const [state, setState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const [phoneCls, setPhoneCls] = useState("");
  const user = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const handleStudentUpdate = async (number) => {
    updateStudent({
      variables: {id: user?.id, phone: number}
    }).then(async (data) => {
      const studentObj = { ...data?.data?.updateStudent };
      dispatch(setUserDataAction(studentObj));
      setLoading(false);
      setVerifiedOverlay(true);
      setTimeout(() => {
        setVerifiedOverlay(false);
      }, 3000);
      await new Promise(r => setTimeout(r, 3000));
      navigate('/account-settings');
    }).catch(err => {
      console.error(err);
      setLoading(false);
    });
  }

  const validatePhone = (phone) => {
    const codeWithPhone = '+1' + phone;
    return isMobilePhone(codeWithPhone, 'en-US', {
      strictMode: true,
    });
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value});
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const loggedInUser = await Auth.signIn(user.email, state.password);
      setCognitoUserDataAction(loggedInUser);
      const student = GetStudentByPhone(state.phone);
      if (Object.keys(student).length === 0) {
        const phoneWithCode = "+1" + state.phone;
        await Auth.updateUserAttributes(loggedInUser, { phone_number: phoneWithCode });
        handleStudentUpdate(phoneWithCode);
      } else {
        setActiveToast(true);
        setToast("Phone number already used");
      }
    } catch (err) {
      console.error(err);
      setActiveToast(true);
      setToast('An error occurred');
      setLoading(false);
    }
  }

  useEffect(() => {
    setIsValid(isRequiredFieldsPassed(state, 2, 'eq')
        && state?.phone?.length === 10
        && validatePhone(state?.phone)
    );
    if (state?.phone?.length === 10) {
      const validPhone = validatePhone(state?.phone);
      setPhoneCls(validPhone ? 'border-success' : 'border-danger');
    } else {
      if (phoneCls !== '') setPhoneCls('');
    }
  }, [state]);


  return (
    <>
      <Verified
        removeOverlay={() => setVerifiedOverlay(false)} 
        shown={verifiedOverlay ? 'shown': 'hidden'}
        position={verifiedOverlay ? 'slide-up' : 'slide-down'}
        header="Success!"
        text="Updated Successfully!"
      />
      <AlertToast
        variant={'success'} 
        icon={'check'} 
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to="/account-settings">
            <span>{'<'}</span>
          </Link>
        </div>
      </div>
      <div className="auth-sec mt-3">
        <div className="heading">Change Phone Number</div>
        <div className="sub-heading">
          Change to a phone number you have access to as a verification code
          will be sent to ensure it is your phone.
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-auth">
            <label>New Phone Number </label>
            <div className={`phone-input ${phoneCls}`}>
              <span>+1</span>
              <input 
                type="tel" 
                placeholder="xxxxxxxxx"
                name='phone'
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-auth">
            <label>Password</label>
            <input 
              type="password" 
              placeholder="Type your password" 
              name='password'
              onChange={handleChange}
            />
          </div>
          <div className="submit-login">
            <button 
              type="submit" 
              disabled={!isValid}
              style={{ opacity: isValid ? 1 : 0.6 }}
            >
              { loading ? "Loading..." : "Change Phone Number" }
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePhone
