/* eslint-disable no-unused-expressions */
import React from 'react'
import ImgFour from '../../Assets/images/scene-4.webp'
import Error from '../../Assets/images/error-occurred.svg'
import './style.scss'

const stopProp = (e) => {
  e.stopPropagation()
}

const Verified = ({ removeOverlay, header, text, shown, position, error }) => {

  return (
    <>
      <div className={`overlay_background ${shown}`} onClick={(e) => removeOverlay()}>
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="text-center mt-4">
              <img 
                src={error ? Error : ImgFour}
                width={100}
                height={100}
                alt=""
                style={{ObjectFit: 'cover'}}
              />
            </div>
            <div className='code_sent_head_text'>
              <h5>{header}</h5>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Verified
