import React from 'react';
import Skeleton from 'react-loading-skeleton'
import './style.scss';

function HomeSkeletonLoader() {
  return ( 
    <>
      <div className="main-skeleton">
        <div className="header-h">
          <div className="title">
            <Skeleton height={20}/>
          </div>
          <div className="menu">
            <div className="noti">
              <Skeleton width={50} height={20}/>
            </div>
            <div className="user-p">
              <Skeleton width={50} height={20}/>
            </div>
          </div>
        </div>

        <div className="verify-btn">
          <Skeleton width={120} height={20}/>
          <Skeleton width={120} className='ml-1' height={20}/>
        </div>
        <div className='draw_lines'>
          <Skeleton height={20} width={"80%"}/>
          <Skeleton height={20} width={"100%"}/>
          <Skeleton height={20} width={"100%"}/>
          <Skeleton height={20} width={"30%"}/>
          <Skeleton height={20} width={"80%"}/>
        </div>
      </div>
      <div style={{overflow: "hidden"}}>
        <div className="popular-items-skeleton">
          <div>
            <Skeleton className="item"/>
            <div className=''>
              <Skeleton height={15} containerClassName="opp_lines" width={"100%"}/>
              <Skeleton height={10}  containerClassName="opp_lines1" width={"100%"}/>
              <Skeleton height={5}  containerClassName="opp_lines2" width={"100%"}/>
            </div>
          </div>
          <div>
            <Skeleton className="item"/>
            <div className=''>
              <Skeleton height={15} containerClassName="opp_lines" width={"100%"}/>
              <Skeleton height={10}  containerClassName="opp_lines1" width={"100%"}/>
              <Skeleton height={5}  containerClassName="opp_lines2" width={"100%"}/>
            </div>      
          </div>
        </div>
        <div className='draw_lines pl-3 pr-3'>
          <Skeleton height={20} width={"80%"}/>
          <Skeleton height={20} width={"100%"}/>
        </div>
        <div className="popular-items-skeleton">
          <div>
            <Skeleton className="item"/>
            <div className=''>
              <Skeleton height={15} containerClassName="opp_lines" width={"100%"}/>
              <Skeleton height={10}  containerClassName="opp_lines1" width={"100%"}/>
              <Skeleton height={5}  containerClassName="opp_lines2" width={"100%"}/>
            </div>
          </div>
          <div>
            <Skeleton className="item"/>
            <div className=''>
              <Skeleton height={15} containerClassName="opp_lines" width={"100%"}/>
              <Skeleton height={10}  containerClassName="opp_lines1" width={"100%"}/>
              <Skeleton height={5}  containerClassName="opp_lines2" width={"100%"}/>
            </div>      
          </div>
        </div>
        <div className='draw_lines pl-3 pr-3'>
          <Skeleton height={20} width={"80%"}/>
          <Skeleton height={20} width={"100%"}/>
        </div>
      </div>
    </>
  );
}

export default HomeSkeletonLoader;