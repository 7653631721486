import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style.scss";
import DeleteAccount from "../../Components/Popus/DeleteAccount";
import HoldBinToDeleteAccount from "../../Components/Popus/HoldBinToDelete";

const AccountSettings = () => {
  const user = useSelector((store) => store.user);
  const [overlay, setOverlay] = useState(false);
  const [deleteAcc, setDeleteAcc] = useState(false);
  const [longPressDeleteOverlay, setLongPressDeleteOverlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteAcc) {
      setOverlay(false);
      setLongPressDeleteOverlay(true);
    }
  }, [deleteAcc]);
  
  return (
    <>
      <div className={overlay ? "overlay_shown" : "overlay_hidden"}>
        <DeleteAccount
          removeOverlay={() => setOverlay(false)}
          setDeleteAcc={setDeleteAcc}
          shown={overlay ? "shown" : "hidden"}
          position={overlay ? "slide-up" : "slide-down"}
        />
      </div>
      <div className={longPressDeleteOverlay ? "overlay_shown" : "overlay_hidden"}>
        <HoldBinToDeleteAccount
          removeOverlay={() => setLongPressDeleteOverlay(false)}
          shown={longPressDeleteOverlay ? "shown" : "hidden"}
          position={longPressDeleteOverlay ? "slide-up" : "slide-down"}
        />
      </div>
      <div className="noti-head">
        <div className="icon">
          <Link to="/accounts">
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Account Settings</div>
        <div className="icon"></div>
      </div>
      <div className="acount-info mt-5">
        <div className="title">Account Information</div>
        <div className="item-list">
          <div className="item">
            <div className="par">Name:</div>
            <div className="chi">{user?.name}</div>
          </div>
          <div className="item">
            <div className="par">Mobile Number:</div>
            <div className="chi">{user?.phone}</div>
          </div>
          <div className="item">
            <div className="par">Email Address:</div>
            <div className="chi">{user?.email}</div>
          </div>
        </div>
      </div>
      <div className="security-sec">
        <div className="title">Security</div>
        <div className="item-list">
          <div className="item" onClick={() => navigate("/change-password")}>
            <div className="label">
              Change Password <span>{">"}</span>
            </div>
          </div>
          <div className="item" onClick={() => navigate("/change-number")}>
            <div className="label">
              Change Phone Number <span>{">"}</span>
            </div>
          </div>
          <div className="item" onClick={() => navigate("/change-email")}>
            <div className="label">
              Change Email Address<span>{">"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="del-acc">
        <div className="item" onClick={() => setOverlay(true)}>
          <div className="label">
            Delete my Account <span>{">"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
