import {
  SET_LOADERS,
  RESET_LOADERS,
} from "../constants/loadersConstants";

export const setLoadersDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADERS,
    payload: data,
  });
};

export const resetLoadersDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_LOADERS,
  });
};
