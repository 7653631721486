/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import JICon from '../../Assets/images/jCoin.svg'
import { AllExperienceSessions } from '../../Services/query/experienceSessions'
import { calculateTimeWithPmOrAm, formatDateForSessions } from '../../utils/helpers'
import './style.scss'

const stopProp = (e) => {
  e.stopPropagation()
}

const ChooseSessionPopup = ({ removeOverlay, experience, shown, position }) => {
  const navigate = useNavigate();
  const [experienceSessions, setExperienceSession] = useState([]);

  const getSessions = () => {
    const sessions = [];
    experience?.sessionsIDs?.forEach(sessionID => {
      const session = experienceSessions?.filter(
        (elt) => elt?.id === sessionID
      )[0]

      sessions.push(session);
    });

    return sessions;
  }

  const changeSession = (sessionID) => {
    navigate(`/store/checkout?experience=${experience?.id}&session=${sessionID}`);
    removeOverlay();
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const experienceSessions = await AllExperienceSessions()
      setExperienceSession(experienceSessions);
    }
    handleSetDependencies();
  }, [])
  

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="single-item">
              <div className="choose-session store-sec">
                <div className="sec-title">Choose a Session</div>
                <div className="horizontal-items">
                  {getSessions().map((elt, index) => 
                  <div className="session" key={index}>
                    <div className="date">
                      {formatDateForSessions(elt?.date)}
                    </div>
                    <div className="time">
                      {calculateTimeWithPmOrAm(elt?.start_time)} - 
                      {calculateTimeWithPmOrAm(elt?.end_time)}
                      (ET)
                    </div>
                    <div className="slots" type="down">
                      {elt?.slots} Slots Left
                    </div>
                    <div className="persons">
                      <img src={JICon} alt="icon" />
                      <span>20 </span>
                      <small>/ person</small>
                    </div>
                    <div className="act">
                      <button 
                        type='button'
                        onClick={() => changeSession(elt?.id)}
                      >
                        Choose
                      </button>                    
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseSessionPopup
