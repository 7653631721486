/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { ChevronLeft, Heart } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import JICon from '../../../Assets/images/jCoin.svg'
import { setUserDataAction } from '../../../Redux/actions/userActions'
import { GetExperienceLikedByStudent } from '../../../Services/query/experiences'
import { GetSingleStudent } from '../../../Services/query/students'
import { AddExperienceLike } from '../util'
import { ReactComponent as EmptyState } from '../../../Assets/images/no-opportunities.svg';
import './style.scss'

const LikedJunityItems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [student, setStudent] = useState([]);
  const [selectItem, setSelectItem] = useState({});
  const [likedByStudent, setLikedByStudent] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);

  const handleSelect = (e, id, price) => {
    if (Object.keys(selectItem).includes(id)) {
      const itemsSelected = { ...selectItem };
      delete itemsSelected[id];
      setSelectItem(itemsSelected);
    } else {
      setSelectItem({
        ...selectItem,
        [id]: price
      });
    }
  };

  const getTotal = () => {
    let sum = 0;
    Object.keys(selectItem)?.forEach(elt => {
      sum += parseInt(selectItem[elt]);
    });

    return sum;
  };

  const updateOpps = (id, newLikes, oldLikedBy) => {
    
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = []
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id)
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    } 
    
    setOppsLikedBy(updatedLikedBy);
  }

  const addLike = async (experience) => {
    const addExperienceLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      experience,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    }

    AddExperienceLike(addExperienceLikeProps);
  };

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user?.id);
      dispatch(setUserDataAction(student));
      setStudent(student);
      const likedByStudent = await GetExperienceLikedByStudent(user?.id);
      setLikedByStudent(likedByStudent);
    }

    studentVerified();
  }, [state]);

  useEffect(() => {
    setCheckoutEnabled(Object.keys(selectItem).length > 0);
  }, [selectItem]);
  
  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon" onClick={() => navigate('/store')}>
          <ChevronLeft />
        </div>
        <div className="text">Liked Items</div>
        <div className="icon"></div>
      </div>
      <div className="store-sec mb-150" style={{marginTop: 80}}>
        {likedByStudent?.length
            ? (
                <div className="Vertical-items">
                  <div className="row">
                    {likedByStudent?.map((elt, index) =>
                        <div className={likedByStudent?.length > 1 ? "col-6" : "col-12"} key={index}>
                          <div className="item">
                            <div className="item-img">
                              <img src={elt.image} alt="store-img"/>
                              <div className="round">
                                <input
                                    type="checkbox"
                                    id="c1"
                                    value={Object.keys(selectItem).includes(elt.id)}
                                    onChange={(e) => handleSelect(e, elt.id, elt.price_per_person)}
                                />
                                <label htmlFor="c1"></label>
                              </div>
                            </div>
                            <div className="item-info">
                              <div className="name">{elt?.title}</div>
                              <div className="heart" onClick={() => addLike(elt)}>
                                {(state[elt?.id] || elt?.liked_by?.includes(user?.id)) ? (
                                    <img
                                        src={require("../../../Assets/images/heart-2.png")}
                                        alt=""
                                    />
                                ) : (
                                    <Heart />
                                )}
                              </div>
                            </div>
                            <div
                                className="des"
                                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                            >
                              {Object.keys(likes)?.includes(elt?.id)
                                  ? (elt?.liked_by?.length || 0)
                                  : (elt?.likes || 0)
                              } {(elt?.liked_by?.length || 0) === 1 ? 'person likes' : 'people like'} this
                            </div>
                            <div
                                className="j-icon"
                                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                            >
                              <img src={JICon} alt="icon" />
                              {elt?.price_per_person}
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
            ) : (
                <div className='d-flex justify-content-center align-items-center w-100 mt-4' style={{
                  height: "80vh"
                }}>
                  <div className="d-flex flex-column">
                    <EmptyState />
                    <p>No Liked Items</p>
                  </div>
                </div>
            )

        }

      </div>
      {checkoutEnabled 
      ?
      <div className="bottom-checkout">
        <div className="total">
          <strong>Total:</strong>
          <img src={JICon} alt="icon" />
          <span>{getTotal()}</span>
        </div>
        <div className="des">
          You need {(getTotal() - student?.balance) || 0} 
          {" "}more Junity coins to get all selected items.
        </div>
        <button>Checkout</button>
      </div>: ''}
    </>
  )
}

export default LikedJunityItems
