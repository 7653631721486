/* eslint-disable no-unused-expressions */
import { days, months } from "./data";
import { useState, useEffect } from "react";
import { GetStudentInterestByStudentID } from "../Services/query/studentInterests";
import { GetSingleInterest } from "../Services/query/interests";
import { City } from "country-state-city";
import { isMobilePhone } from "validator";
import axios from "axios";
import { tasksClient } from "../libs/axiosClient";

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(" ")[1];
    return monthStr + " " + newDate.getDate() + ", " + newDate.getFullYear();
  }

  return "Not specified";
};

export const formatDateMonthYear = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(" ")[1];
    return monthStr + ", " + newDate.getFullYear();
  }

  return "Not specified";
};

export const getSchoolState = (address) => {
  if (address) {
    const addressObj = JSON.parse(address);
    const locality = addressObj.address_components.find(
      (elt) => elt.types[0] === "locality"
    );
    const state = addressObj.address_components.find(
      (elt) => elt.types[0] === "administrative_area_level_1"
    );
    return locality.long_name + ", " + state.long_name;
  }

  return "";
};

export const isRequiredFieldsPassed = (obj, fields, type) => {
  if (type === "eq") {
    return (
      Object.keys(obj).length === fields &&
      Object.values(obj).every((elt) => elt !== "")
    );
  }

  return false;
};

export const calculateTime = (date) => {
  if (date) {
    const now = new Date();
    const previous = new Date(date);
    if (now.getFullYear() === previous.getFullYear()) {
      if (now.getMonth() === previous.getMonth()) {
        const res = now.getDate() - previous.getDate();
        let message = "";

        if (res === 0) message = "Today";
        else if (res === 1) message = "Yesterday";
        else message = "days ago";
        return `${res > 1 ? res : ""} ${message}`;
      } else {
        const res = now.getMonth() - previous.getMonth();
        return `${res} ${res === 1 ? "month" : "months"} ago`;
      }
    } else {
      const monthInterval = 12 - previous.getMonth() + now.getMonth();
      if (monthInterval < 12) {
        return `${monthInterval} ${
          monthInterval === 1 ? "month" : "months"
        } ago`;
      }
      const res = now.getFullYear() - previous.getFullYear();
      return `${res} ${res === 1 ? "year" : "years"} ago`;
    }
  }
};

export const timePassed = (fromDate) => {
  const givenDate = new Date(fromDate);
  const timeDiff = Date.now() - givenDate.getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44);

  if (months > 0) {
    return months + (months === 1 ? " mon" : " mons");
  } else if (weeks > 0) {
    return weeks + (weeks === 1 ? " wk" : " wks");
  } else if (days > 0) {
    return days + (days === 1 ? " day" : " days");
  } else if (hours > 0) {
    return hours + (hours === 1 ? " hr" : " hrs");
  } else {
    return minutes + (minutes === 1 ? " min" : " mins");
  }
};

export const calculateAge = (date) => {
  date = new Date(date);
  const currentDate = new Date();
  try {
    return currentDate.getFullYear() - date.getFullYear();
  } catch (err) {
    return -1;
  }
};

export const getNameType = (name, type) => {
  if (type === "fname") {
    return name?.split(" ")[0];
  } else {
    const otherNames = name?.split(" ")?.slice(1);
    let namesReturned = "";
    otherNames?.forEach((elt) => {
      namesReturned += elt + " ";
    });
    return namesReturned;
  }
};

export const returnDateType = (date, sect) => {
  if (date) {
    const currentDate = new Date(date);
    if (sect === "year") return currentDate?.getFullYear();
    else if (sect === "month") return currentDate?.getMonth();
    else return currentDate?.getDate();
  }
};

export const convertFirstLetterToUpper = (word) => {
  if (word) {
    return word[0]?.toUpperCase() + word.slice(1);
  }
};

export const getInitial = (name) => {
  if (name) {
    let initials = "";
    const splittedName = name?.split(" ") || [];
    splittedName.forEach((elt) => {
      initials += elt[0]?.toUpperCase() || "";
    });
    return initials;
  }

  return "";
};

export const capitalizeFirstLetter = (title) => {
  if (title) return title[0]?.toUpperCase() + title.slice(1)?.toLowerCase();
  else return null;
};

export const titleWords = (sentence) => {
  if (sentence) {
    const words = sentence?.split(" ");
    const newWords = [];
    words?.forEach((word) => {
      newWords.push(capitalizeFirstLetter(word));
    });
    return newWords.join(" ");
  }

  return "";
};

export const calculateTimeWithPmOrAm = (time) => {
  if (time) {
    let hour = time?.split(":")[0];

    const ampm = parseInt(hour) >= 12 ? "pm" : "am";
    hour %= 12;
    hour = hour || 12;

    return `${hour}:${time?.split(":")[1]} ${ampm}`;
  }
};

export const formatDateForSessions = (date) => {
  let dateObj = new Date(date);
  if (dateObj) {
    return `${days[dateObj.getDay()]},  
      ${dateObj.getDate()}
      ${months[dateObj.getMonth()]}`;
  }

  return "";
};

export const LikeOpportunity = async (likeOpp, like, id) => {
  return await likeOpp({
    variables: {
      id,
      likes: like + 1,
    },
  });
};

export const errorKeys = (code) => {
  if (code === "UsernameExistsException") {
    return "User already exists";
  }

  return "An Error has occurred, try again later";
};

export const getSpecificStringDate = (time) => {
  const currentDate = new Date();
  if (time === "today") {
    return {
      firstDay: currentDate,
      lastDay: currentDate,
    };
  } else if (time === "week") {
    let first = currentDate.getDate() - currentDate.getDay();
    let last = first + 6;

    let firstDay = new Date(currentDate.setDate(first));
    let lastDay = new Date(currentDate.setDate(last));

    return {
      firstDay,
      lastDay,
    };
  } else if (time === "month") {
    let firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    let lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    return {
      firstDay,
      lastDay,
    };
  }
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const removeCommas = (str = "") => {
  if (str) {
    if (str.startsWith(",") && str.endsWith(",")) {
      return str.slice(1, -1);
    }

    if (str.startsWith(",")) {
      return str.slice(1);
    }

    if (str.endsWith(",")) {
      return str.slice(0, -1);
    }
  }

  return str;
};

export const oppsFilterBySameState = (user, opps) => {
  const sameStateOpps = [];
  opps?.forEach((opp) => {
    const userState = user.state;
    if (opp?.state?.some((state) => state === userState)) {
      sameStateOpps.push(opp);
    }
  });

  return sameStateOpps;
};

export const oppsFilterBySameInterests = (opps, studentInterestNames) => {
  const sameInterestOpps = [];
  opps?.forEach((opp) => {
    const matchInterest = (interest) =>
      studentInterestNames?.includes(interest);
    if (opp?.interests?.some(matchInterest)) {
      sameInterestOpps.push(opp);
    }
  });

  return sameInterestOpps;
};

export const sameInterestNames = async (user) => {
  const studentInterest = await GetStudentInterestByStudentID(user?.id);
  const interestIDs = studentInterest?.interestIDs;

  return interestIDs?.reduce(async (previousPromise, interestID) => {
    const interest1 = GetSingleInterest(interestID);
    const [interest, studentInterestName] = await Promise.all([
      interest1,
      previousPromise,
    ]);
    studentInterestName.push(interest.name);
    return studentInterestName;
  }, Promise.resolve([]));
};

const sortOppsByType = (opps, type, order) => {
  const oppsToSort = [...opps];
  oppsToSort.sort((a, b) => {
    const itemA = a[type];
    const itemB = b[type];
    if (itemA < itemB) {
      return order === "reverse" ? 1 : -1;
    }
    if (itemA > itemB) {
      return order === "reverse" ? -1 : 1;
    }
    return 0;
  });
  return oppsToSort;
};

export const PopularInYourArea = async (user, opps, type) => {
  const usCities = getCities({
    keys: [],
    requiresAll: false,
    returnObj: false,
    sort: false
  });
  const userStateData = usCities.find(
    (elt) => elt.name === user.state.split(",")[0].trim()
  );
  const userStateCoord = {
    lat1: userStateData.latitude,
    lon1: userStateData.longitude,
  };
  const modOpp = [];

  opps?.forEach((opp) => {
    const oppObj = { ...opp };
    const stateDistances = opp?.state?.reduce((acc, state) => {
      const res = [...acc];
      const stateCoord = usCities.find(
        (elt) => elt.name === state.split(",")[0].trim()
      );
      const stateDistance = distance(userStateCoord, {
        lat2: stateCoord.latitude,
        lon2: stateCoord.longitude,
      });
      res.push(stateDistance);
      return res;
    }, []);

    oppObj.minDistance = Math.min(...stateDistances);
    modOpp.push(oppObj);
  });

  const sortedProximityOpps = sortOppsByType(modOpp, "minDistance");

  const interestNames = await sameInterestNames(user);
  const sameInterestOpps = oppsFilterBySameInterests(
    sortedProximityOpps,
    interestNames
  );
  return type !== "likes"
    ? sameInterestOpps
    : sortOppsByType(sameInterestOpps, "likes", "reverse");
};

// Function to calculate the distance between two points given their latitude and longitude coordinates
export function distance(userCoordinates, stateCoordinates) {
  const { lat1, lon1 } = userCoordinates;
  const { lat2, lon2 } = stateCoordinates;

  const earthRadius = 6371; // radius of the earth in km
  const dLat = radians(lat2 - lat1);
  const dLon = radians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radians(lat1)) *
      Math.cos(radians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
}

function radians(degrees) {
  return (degrees * Math.PI) / 180;
}

function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

function get_file_name_from_url(url) {
  const urlSplit = url.split("/");
  return urlSplit[urlSplit.length - 1];
}

export const createFile = async (url) => {
  const fileType = get_url_extension(url);
  const fileName = get_file_name_from_url(url);
  let response = await axios.get(url, { responseType: "blob" });
  let data = await response.data;
  let metadata = { type: `image/${fileType}` };
  return new File([data], fileName, metadata);
};

// Function to generate a secure and unique referral code
export function generateReferralCode(students, length) {
  const characters = "23456789abcdefghjklmnpqrstuvwxyz"; // characters to choose from, avoiding ambiguous characters
  const codeLength = length || 10; // length of the referral code, default is 10
  const codeAttempts = 10; // number of attempts to generate a unique code
  let referralCode = "";

  // Helper function to check if a referral code already exists
  function isReferralCodeExists(code) {
    // Logic to check if referral code exists in the database or storage
    // ... (e.g., query a database or check an array of existing codes)
    // Return true if exists, false otherwise
    return students.filter((elt) => elt.referral_code === code).length > 0; // Placeholder for simplicity
  }

  // Generate a unique referral code
  for (let i = 0; i < codeAttempts; i++) {
    referralCode = "";
    for (let j = 0; j < codeLength; j++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      referralCode += characters[randomIndex];
    }
    if (!isReferralCodeExists(referralCode)) {
      // If generated code is unique, break the loop
      break;
    }
  }

  return referralCode;
}

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const cleanMessageDate = (timestamp) => {
  const splittedDate = timestamp?.split(" ");
  if (splittedDate?.length > 1) {
    const secondSplit = splittedDate[1]?.split("+");
    return splittedDate[0] + "T" + secondSplit[0] + "Z";
  }

  return splittedDate[0];
};

export const formatMessageTime = (timestamp) => {
  if (timestamp) {
    const messageDate = new Date(cleanMessageDate(timestamp));
    const currentDate = new Date();

    // Check if the message was sent today
    if (
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear()
    ) {
      const hours = messageDate.getHours();
      const minutes = messageDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      // Check if the message was sent yesterday
      const yesterday = new Date();
      yesterday.setDate(currentDate.getDate() - 1);
      if (
        messageDate.getDate() === yesterday.getDate() &&
        messageDate.getMonth() === yesterday.getMonth() &&
        messageDate.getFullYear() === yesterday.getFullYear()
      ) {
        return "Yesterday";
      } else {
        // Format the message date in a long format
        return messageDate.toLocaleDateString();
      }
    }
  }

  return timestamp;
};

export const messageCreatedDate = (time) => {
  if (/^\d{1,2}:\d{2}\s(AM|PM)$/.test(time)) {
    return "Today";
  } else {
    return time;
  }
};

export const calculateResponseTimeRate = (responseTimes) => {
  const sum = responseTimes.reduce((acc, curr) => acc + curr, 0);
  const average = sum / responseTimes.length;

  let formattedAverage = "";

  switch (true) {
    case average < 60000:
      formattedAverage = Math.round(average / 1000) + " seconds";
      break;
    case average < 3600000:
      formattedAverage = Math.round(average / 60000) + " minutes";
      break;
    case average < 86400000:
      formattedAverage = Math.round(average / 3600000) + " hours";
      break;
    case average < 604800000:
      formattedAverage = Math.round(average / 86400000) + " days";
      break;
    case average < 2592000000:
      formattedAverage = Math.round(average / 604800000) + " weeks";
      break;
    case average < 31536000000:
      formattedAverage = Math.round(average / 2592000000) + " months";
      break;
    default:
      formattedAverage = Math.round(average / 31536000000) + " years";
      break;
  }

  return formattedAverage;
};

export const responseTimeRates = (messages, studentID) => {
  const messageTypeMessage = messages.filter(
    (message) => JSON.parse(message?.custom_json || "{}")?.type === "message"
  );
  const receiverMessages = messageTypeMessage.filter(
    (message) =>
      JSON.parse(message?.custom_json || "{}")?.sender_id !== studentID
  );

  const responseTimes = [];

  for (const element of receiverMessages) {
    const currentMessage = element;
    const messageIndex = messageTypeMessage.findIndex(
      (elt) => elt.id === currentMessage.id
    );
    const previousSenderMessage = messageTypeMessage
      .slice(0, messageIndex)
      .reverse()
      .find(
        (message) =>
          JSON.parse(message?.custom_json || "{}")?.sender_id === studentID &&
          JSON.parse(message?.custom_json || "{}")?.type === "message"
      );

    if (previousSenderMessage) {
      const responseTime =
        new Date(cleanMessageDate(currentMessage.created)).getTime() -
        new Date(cleanMessageDate(previousSenderMessage.created)).getTime();
      responseTimes.push(responseTime);
    }
  }

  return responseTimes;
};

export const formatTimestamp = (date, time) => {
  const dateTime = new Date(`${date}T${time}`);
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getDate().toString().padStart(2, "0");
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");

  return `${year}${month}${day}T${hours}${minutes}00`;
};

export const validatePhone = (phone) => {
  const codeWithPhone = "+1" + phone;
  return isMobilePhone(codeWithPhone, "en-US", {
    strictMode: true,
  });
};

export const arrayContainsElement = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      return true;
    }
  }
  return false;
};

export const daysToExpiration = (dateString) => {
  const expirationDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = expirationDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
};

export const daysPassed = (date) => {
  const now = new Date();
  const givenDate = new Date(date);

  const oneDayInMs = 1000 * 60 * 60 * 24;

  const timeDifference = now.getTime() - givenDate.getTime();

  return Math.round(timeDifference / oneDayInMs);
};

export const generateDeviceID = () => {
  const { userAgent, hardwareConcurrency, language } = navigator;
  const { width, height } = window.screen;
  return `${userAgent}-${hardwareConcurrency}-${language}-${width}-${height}`;
};

export const hoursPassedSince = (date) => {
  const currentDate = new Date();
  const timeDifference = currentDate - date;
  return timeDifference / (1000 * 60 * 60);
};

export const sendEmailNotification = async (
  email,
  template,
  subject,
  context
) => {
  return await tasksClient.post("/email", {
    template,
    subject,
    email,
    context,
  });
};

export const sendAppNotification = async (
  userId,
  text,
  title = "success",
  link = "",
  action = "",
  module = "youth",
  type = "inapp",
  icon = "compass",
  read = false
) => {
  return await tasksClient.post("/notification", {
    userId,
    title,
    text,
    link,
    action,
    module,
    type,
    icon,
    read,
  });
};


export const getCities = (filters) => {
  const { keys, requiresAll, returnObj, sort } = filters;
  const cities = process.env.REACT_APP_CITIES_LIST;
  let modifiedStates = cities.split(",")?.reduce((acc, city) => {
    if (returnObj) {
      const usStates = City.getCitiesOfState('US', city)?.map((elt) => {
        return { [keys[0]]: `${elt.name}, ${city}`, [keys[1]]: `${elt.name}, ${city}` };
      });
      return [...acc, ...usStates];
    } else {
      return [...acc, ...City.getCitiesOfState('US', city)]
    }
  }, []);

  if (sort) {
    modifiedStates = sortOppsByType(modifiedStates, keys[0], "");
  }

  if (requiresAll) {
    modifiedStates = [{ [keys[0]]: 'All', [keys[1]]: 'All' }, ...modifiedStates];
  }

  return modifiedStates;
}