
import { gql } from "@apollo/client";

export const CREATE_JUNITY_INFLUENCER = gql`
  mutation CreateJunityInfluencerMutation(
    $role: String!
	  $role_ID: ID!
    $createdAt: AWSDateTime!
    $is_active: Boolean
  ) {
    createJunityInfluencer(
      input: {
        role: $role
	      role_ID: $role_ID
        createdAt: $createdAt
        is_active: $is_active
      }
    ) {
      id
      role_ID
    }
  }
`;


export const UPDATE_JUNITY_INFLUENCER = gql`
  mutation UpdateJunityInfluencerMutation(
    $id: ID!
    $is_active: Boolean
  ) {
    updateJunityInfluencer(
      input: {
	      id: $id
        is_active: $is_active
      }
    ) {
      id
      role_ID
    }
  }
`;
