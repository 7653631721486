import React, { useState, useEffect } from "react";
import { User, UserPlus, FileText, Briefcase, LogOut } from "react-feather";
import { useNavigate } from "react-router-dom";
import { GetSingleStudent } from "../../Services/query/students";
import { useAuth } from "../Auth";
import { daysToExpiration, sendAppNotification } from "../../utils/helpers";
import { AllOpportunitiesAPI } from "../../Services/query/opportunities";
import { useLocalStorage, useSessionStorage } from "../../utils/storage";
import { GetExperienceLikedByStudent } from "../../Services/query/experiences";
import { GetPurchasesByStudentID } from "../../Services/query/purchases";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const MyAccount = ({ removeOverlay, shown, position }) => {
  const navigate = useNavigate();
  const [student, setStudent] = useState({});
  const { user, logout, addNotification } = useAuth();
  const [oppsNotified, setOppsNotified] = useSessionStorage('opps_notified', false)
  // const [unreadMsgs, setUnreadMsgs] = useSessionStorage('unread_msgs', false)
  // const [biWeekly, setBiWeekly] = useLocalStorage('bi-weekly-notifications', 0)
  const [sessNoti, setSessNoti] = useSessionStorage('session_notification', 0)
  const [soldOutNoti, setSoldOutNoti] = useSessionStorage('soldout_notification', false)
  // const [bwNotified, setBwNotified] = useLocalStorage('bi-weekly-notified', false)
  const DAYS = [7, 2, 1]

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user.id);
      setStudent(student);
    };
    studentVerified();
  }, []);

  useEffect(() => {
    const getLikedOpps = async () => {
        try {
            const opps = await AllOpportunitiesAPI();
            const likedOpps = opps?.filter(
                (elt) => elt?.liked_by?.includes(user?.id)
            );
            let notifyExpiration = false

            likedOpps?.forEach((opp)=>{
                if ([7, 2, 1].includes(daysToExpiration(opp?.expiry_date))){
                  notifyExpiration = true
                }
            })
            if (notifyExpiration && !oppsNotified){
              await sendAppNotification(
                user?.id,
                `You have one or more liked opportunities closing soon.`,
                'success',
                `/opportunities`,
                'Click here to view',
                'youth',
                'inapp',
                'compassSuccess'
              )
              setOppsNotified(true)
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    // const getMessages = async () => {
    //     try {
    //         const chats = await chatClient.get('chats/')
    //         const userChats = chats.data?.filter((elt) => JSON.parse(elt?.custom_json)?.creatorID === user?.id);
    //         let hasUnreadMessages = false
    //         userChats.forEach(async(chat) => {
    //             const messages = await chatClient.get(`chats/${chat?.id}/messages/`);
    //             const lastMessage = messages.data[messages.data.length - 1]
    //             const lastMessageSender = lastMessage?.custom_json ? JSON.parse(lastMessage?.custom_json) : {}
                
    //             if (lastMessageSender?.sender_id !== user?.id){
    //                 hasUnreadMessages = true
    //             }

    //             if (hasUnreadMessages && !unreadMsgs){
    //                 addNotification({
    //                     status: 'log',
    //                     text: `You have one or more unread messages.`,
    //                     linkUrl: `/chats`,
    //                     linkTitle: 'Click here to view',
    //                     icon: <MessageCircleYellow/>,
    //                     type: 'both',
    //                     time: new Date().toISOString(),
    //                     shown: false
    //                 })
    //                 setUnreadMsgs(true)
    //             }
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // };

    const getExperiences = async()=>{
      try {
      const experiences = await GetExperienceLikedByStudent(user?.id)
        experiences.forEach(experience => {
          const sessions = experience?.sessions
          if ((sessions instanceof Array) && sessions?.length){
            sessions.forEach(async(session) => {
              let sessionDate = `${session?.date}T${session?.end_time}:00Z`
              if ((daysToExpiration(sessionDate) <= 1) && (session?.slots <= 5) && !soldOutNoti){
                await sendAppNotification(
                  user?.id,
                  `You have one or more liked experiences that are almost sold out.`,
                  'success',
                  `/store/liked-items`,
                  'Click here to view',
                  'youth',
                  'inapp',
                  'cube'
                )
                setSoldOutNoti(true)
              }
            })
            
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    const getPurchases = async()=>{
      try {
      const purchases = await GetPurchasesByStudentID(user?.id)

        purchases.forEach(async(purchase) => {
          const session = purchase?.experience?.sessions?.filter(sess => sess?.id === purchase?.sessionID)

          let sessionDate = `${session?.date}T${session?.end_time}:00Z`
            if (DAYS.includes(daysToExpiration(sessionDate)) && (!sessNoti || DAYS.includes(sessNoti))){
              await sendAppNotification(
                user?.id,
                `You have an upcoming event.`,
                'log',
                `/store/single-item?id=${purchase?.experienceID}`,
                'Click here to view',
                'youth',
                'inapp',
                'cubeBlack'
              )
              setSessNoti(daysToExpiration(sessionDate))
            }
        })
      } catch (error) {
        console.log(error)
      }
    }

    // const getBiWeeklyNotifications = async()=>{
    //   if (user?.is_verified && (((!biWeekly || !daysPassed(biWeekly)) && !bwNotified) || daysPassed(biWeekly) === 13)){
    //     setBwNotified(true)
    //     await sendAppNotification(
    //       user?.id,
    //       `Share your profile with organizations offering opportunities.`,
    //       'success',
    //       `/profile-sharing`,
    //       'Click here to view',
    //       'youth',
    //       'inapp',
    //       'user'
    //     )
    //     if (daysPassed(biWeekly) === 14) {
    //       setBwNotified(false)
    //       setBiWeekly(new Date().toISOString())
    //     }
    //   }
    // }

    // getBiWeeklyNotifications()
    // getMessages()
    getLikedOpps()
    getExperiences()
    getPurchases()
  }, [])

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={() => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="my-account">
              <div className="list">
                <div
                  className={`item ${
                    student?.verification_status === "verified"
                      ? ""
                      : "inactive"
                  }`}
                  onClick={() => navigate("/accounts")}
                >
                  <User />
                  <span>My Account</span>
                </div>
                <div
                  className={`item ${
                    student?.verification_status === "verified"
                      ? ""
                      : "inactive"
                  }`}
                  onClick={() => navigate("/submitted-applications")}
                >
                  <FileText />
                  <span>Submitted Applications</span>
                </div>
                <div
                  className={`item ${
                    student?.verification_status === "verified"
                      ? ""
                      : "inactive"
                  }`}
                  onClick={() => navigate("/recommendations")}
                >
                  <Briefcase />
                  <span>Recommended Opportunities</span>
                </div>
                <div
                  className={`item`}
                  onClick={() => navigate("/invite-friends")}
                >
                  <UserPlus />
                  <span>Invite Friends</span>
                </div>
                <div className="item" onClick={logout}>
                  <LogOut />
                  <span>Log out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
