import { gql, useQuery } from "@apollo/client";
import { API } from 'aws-amplify';
import { GetEducatorsBySchoolID } from "./educators";

export const GET_SCHOOLS = gql`
  query AllSchools {
    listSchools (limit: 1000) {
      items {
        contact_email
        contact_phone
        createdAt
        id
        website
        type
        name
        state
        updatedAt
        accepting_connection
        banner
        zipcode
        image
        full_address
        connections
        counselors_dont_show_again
        about
        connection_dates
        dont_show_again
      }

      nextToken
    }
  }
`;


export const GET_SCHOOLS_WITH_TOKEN = gql`
  query SchoolsQuery ($limit: Int, $nextToken: String) {
    listSchools (limit: $limit, nextToken: $nextToken) {
      items {
        contact_email
        contact_phone
        createdAt
        website
        id
        name
        state
        updatedAt
        image
        type
        banner
        full_address
        accepting_connection
        zipcode
        connections
        about
        connection_dates
        counselors_dont_show_again
        dont_show_again
      }
      nextToken
    }
}
`

export async function AllSchools() {
  const resp = await API.graphql({ 
    query: GET_SCHOOLS,
  });

  const data = resp?.data?.listSchools
  let nextToken = data?.nextToken
  let schools = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_SCHOOLS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listSchools

    const previousData = [...schools, ...respData?.items]
    schools = [...previousData];

    nextToken = respData?.nextToken
  }

  return await schools?.reduce(async (previousPromise, school) => {
    const educators = await GetEducatorsBySchoolID(school.id);
    let schoolsData = await previousPromise;
    const obj = { ...school, educators };
    schoolsData.push(obj);
    return schoolsData;
  }, Promise.resolve([]));
}


export function GetSchool() {
  const resp = useQuery(GET_SCHOOLS);
  if (resp?.data) {
    const schoolData = resp?.data?.listSchools?.items;
    return schoolData
  }
  return [];
}

const matchState = (schoolAddress, userState) => {
  const schoolAddressObj = JSON.parse(schoolAddress);
  const schoolMatched = schoolAddressObj.address_components.find(
    (address) => address.short_name === userState?.split(',')[1]?.trim()
  );
  return Object.keys(schoolMatched || {}).length > 0;
}

export async function GetSchoolsByState(state) {
  const schools = await AllSchools();
  const sameStateSchools = schools?.filter(
    (elt) => matchState(elt.full_address, state) === true && (elt?.type !== "resource")
  );
  return sameStateSchools || []
}

export function GetSchoolObj() {
  const resp = useQuery(GET_SCHOOLS);
  if (resp?.data) {
    return {
      data: resp?.data?.listSchools?.items,
      refetch: resp?.refetch
    }
  }
  return {};
}

export const GET_SCHOOL = gql`
  query SingleSchool($id: ID!) {
    getSchool(id: $id) {
      contact_email
      contact_phone
      createdAt
      id
      website
      image
      name
      banner
      state
      type
      full_address
      updatedAt
      zipcode
      accepting_connection
      connections
      about
      connection_dates
      counselors_dont_show_again
      dont_show_again
    }
  }
`;

export async function GetSingleSchool(id) {
  try {
    const respSchool = await API.graphql({
      query: GET_SCHOOL,
      variables: { id },
    });
    const school = respSchool?.data?.getSchool;
    return school;
  } catch (err) {
    return {};
  }
}