import { gql } from "@apollo/client";

export const CREATE_WITHDRAWN_APP = gql`
  mutation CreateWithdrawApp(
    $studentID: ID!
    $reason: String!
    $opportunityName: String!
    $createdAt: AWSDateTime
  ) {
    createWithdrawnOpportunityApplications(
      input: {
        studentID: $studentID
        reason: $reason
        opportunityName: $opportunityName
        createdAt: $createdAt
      }
    ) {
      id
      reason
    }
  }
`;
