/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Flag from '../../../Assets/images/flag.svg'
import Upload from '../../../Assets/images/upload.svg'
import HeartC from '../../../Assets/images/heart.svg'
import { Heart, Calendar, Clock, MessageCircle, X } from 'react-feather'
import WithdrawApplication from '../../../Components/Popus/WithdrawApplication'
import { AllOpportunitiesAPI, GetSingleOpportunity } from '../../../Services/query/opportunities';
import { calculateTime, PopularInYourArea } from '../../../utils/helpers'
import ReviewStarFill from '../../../Widgets/ReviewStars/ReviewStarFill'
import { GetStudentOpportunityApplication } from '../../../Services/query/studentOpportunityApplications'
import { GetSingleStudent } from '../../../Services/query/students';
import { setUserDataAction } from '../../../Redux/actions/userActions';
import { AddLike } from '../../../utils/common';
import {setRoutingStateDataAction} from "../../../Redux/actions/routingStateAction";

const SingleApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((store) => store.user)
  const [state, setState] = useState({});
  const [toastLoading, setToastLoading] = useState(false);
  const [overlay, setOverlay] = useState(false)
  const [expandDes, setExpandDes] = useState(false)
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [dependencies, setDependencies] = useState({});

  const search = new URLSearchParams(useLocation().search);
  const appId = search.get("id");

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };

  useEffect(() => {
    if (!appId) {
      navigate("/submitted-applications")
    }
  }, [appId, navigate])

  const calculateReview = () => {
    let sum = 0;
    // eslint-disable-next-line no-unused-expressions
    dependencies?.reviews?.forEach((elt) => {
      sum += parseInt(elt.stars);
    });
    const overallStars = parseFloat(sum / dependencies?.reviews?.length);
    return Math.round(overallStars * 10) / 10;
  };

  const routeToDetailedOpportunity = (elt) => {
    dispatch(setRoutingStateDataAction({
      singleOpportunityPreviousRoute: location.pathname + location.search,
    }));
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const student = await GetSingleStudent(user?.id);
      if (student !== undefined && Object.keys(student || {}).length) {
        dispatch(setUserDataAction(student));
        const app = await GetStudentOpportunityApplication(appId);
        const opps = await AllOpportunitiesAPI();
        const userOpps = await PopularInYourArea(user, opps, "interests");
        const opp = await GetSingleOpportunity(app?.opportunityID);
        const otherOpps = userOpps?.filter((elt) => elt.id !== opp.id);

        setDependencies({ app, opp, otherOpps });
      }
    }

    handleSetDependencies();

  }, []);
  

  return (
    <>
      <WithdrawApplication 
        removeOverlay={() => setOverlay(false)} 
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
      />
      <div className="noti-head">
        <div
          className="icon"
          onClick={() => navigate('/submitted-applications')}
        >
          <X />
        </div>
        <div className="text"></div>
        <div className="icon mr-3">
          <Link to="">
            <img src={Upload} alt="icon" />
          </Link>
        </div>
        <div className="icon">
          <Link to="">
            <img src={Flag} alt="icon" />
          </Link>
        </div>
      </div>
      <div className="sub-app mt-5">
        <div className="head">
          <div className="title">{dependencies?.opp?.name}</div>
          <div 
            className="like" 
            onClick={() => addLike(dependencies?.opp)}
          >
            <div className="circle">
            {state[dependencies?.opp?.id] || dependencies?.opp?.liked_by?.includes(user?.id)
              ? <i className="fas fa-heart"></i>
              : (
                <Heart size={20} />
              )
            }
            </div>
            <div className="likes">
              {Object.keys(likes)?.includes(dependencies?.opp?.id)
              ? likes[dependencies?.opp?.id]
              : dependencies?.opp?.likes || 0}{" "}
            </div>
          </div>
        </div>
        <div className="value">
          <div className="day">
            <Calendar size={16} />
            <span>{calculateTime(dependencies?.opp?.createdAt)}</span>
          </div>
          <div className="status">
            <Clock size={16} />
            <span>Closed</span>
          </div>
        </div>
        <div className="agence">
          <div className="info">
            <div className="logo">
              <img src={dependencies?.opp?.resource?.image} alt="icon" />
            </div>
            <div className="label">
              <div className="name">
                {dependencies?.opp?.resource?.name}
              </div>
              <div className="des">
                {dependencies?.opp?.state[0] || ""}
              </div>
            </div>
          </div>
          <div className="rating">
            <div className="stars">
              <ReviewStarFill starNo={calculateReview(dependencies?.opp) || 0}/>
            </div>
            <div className="reviews">
              {calculateReview(dependencies?.opp) || 0} 
              <span>({dependencies?.opp?.Reviews?.items?.length} reviews)</span>
            </div>
          </div>
        </div>
        <div className="info">
          <div className={`des ${expandDes ? "" : "break-detail"}`}>
            {dependencies?.opp?.description}
          </div>
          <div 
            className="view-more" 
            onClick={() => setExpandDes(!expandDes)}
          >
            {expandDes ? "View less" : "View more"} 
            <span>{'>'}</span>
          </div>
        </div>
        <div className="app-status" type={dependencies?.app?.status?.toLowerCase()}>
          <div className="title">
            Your application is {dependencies?.app?.status?.toLowerCase()}
            <span className="withdraw" onClick={() => setOverlay(true)}>
              Withdraw
            </span>
          </div>
          <div className="des">Submitted {calculateTime(dependencies?.app?.createdAt)}</div>
        </div>
        <div className="send-msg">
          <button>
            <MessageCircle />
            Send a message
          </button>
        </div>
      </div>
      <div className="sug-opp">
        <div className="main">
          <div className="title">You Might Also Be Interested In...</div>
          <div className="popular-items pl-0">
            {dependencies?.otherOpps?.map((elt, index) => 
            <div className="item" key={index}>
              <div className="item-head">
                <div className="p-logo">
                  <img
                      src={elt?.school?.image}
                      className="circle" alt="c-logo"
                      onClick={() => routeToDetailedOpportunity(elt)}
                  />

                  <div
                    className="heart"
                    onClick={() => addLike(elt)}
                  >
                    {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                      <i className="fas fa-heart"></i>
                    ) : (
                      <img src={HeartC} alt="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div 
                className="info"
                onClick={() => routeToDetailedOpportunity(elt)}
              >
                <div className="title">{elt?.name}</div>
                <div className="des break-overview">
                  {elt?.description}
                </div>
                <div className="date-likes">
                  <div className="date">{calculateTime(elt?.createdAt)}</div>
                  <div className="dot"></div>
                  <div className="likes">
                    {Object.keys(likes)?.includes(elt?.id)
                      ? likes[elt?.id]
                      : elt?.likes || 0}{" "}
                    likes
                  </div>
                </div>
              </div>
              <div 
                className="p-type mr-1" 
                type={elt?.category} 
                key={index}
              >
                {elt?.category}
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleApplication
