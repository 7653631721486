import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Verified from "../../../../Components/Popus/Verified";
import FieldChecked from '../../../../Assets/images/field-checked.svg';
import { setUserDataAction } from "../../../../Redux/actions/userActions";
import { isEmail } from "../../../AccountSettings/utils";
import AlertToast from "../../../../Components/Common/Alerts/ToastAlert";
import { axiosClient } from "../../../../libs/axiosClient";

const LastStep = () => {
  useEffect(() => {
    document.body.style.background =
      "linear-gradient(0deg, rgba(218, 248, 238, 0.2), rgba(218, 248, 238, 0.2)), #FFFFFF";
  });

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [codeSentOverlay, setCodeSentOverlay] = useState(false);
  const [state, setState] = useState({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const [valid, setValid] = useState(false);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setValid(
      Object.keys(state).length === 2 &&
        Object.values(state).every((elt) => elt.trim() !== "")
    );
  }, [state]);

  const handleChange = (e) => {
    const newState = { ...state };

    newState[e.target.name] = e.target.value;

    if (e.target.name === "password") {
      const validPassword = e.target.value.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^'\\"_-]).{8,}/
      );
      setIsPasswordValid(validPassword !== null);
      if (!(validPassword !== null)) {
        delete newState[e.target.name];
      }
    }
    setState({ ...newState });
  };


  const proceed = async () => {
    user.email = state.email;
    try {
      setLoading(true);
      const resp = await axiosClient.post('/create-student/', {
        email: state.email,
        password: state.password,
        user
      });

      // save user data including token
      dispatch(setUserDataAction(resp.data.data));

      setVerified(true);
      setTimeout(() => { setVerified(false); }, 2000);
      navigate('/get-start');  
    } catch (err) {
      setLoading(false);
      setActiveToast(true);
      setToast(err.response.data.message);
    }
  };
  
  useEffect(() => {
    if (Object.keys(state).length > 0 && state?.email) {
      setIsEmailValid(isEmail(state?.email));
    }
  }, [state])
  

  return (
    <>
      <AlertToast 
        variant={'error'} 
        icon={'delete'} 
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <Verified
        removeOverlay={() => setCodeSentOverlay(false)}
        header="Authentication code sent!"
        text="Check your phone for the new code."
        shown={codeSentOverlay ? 'shown': 'hidden'}
        position={codeSentOverlay ? 'slide-up' : 'slide-down'}
      />
      <div className={verified ? "overlay_shown" : "overlay_hidden"}>
        <Verified
          removeOverlay={() => setVerified(false)}
          header='Verified'
          text='Your mobile number has been verified.'
        />
      </div>
        <div className="noti-head p-0 back-head border-0">
          <div className="icon">
            <Link to="/second-step">
              <i className="fas fa-angle-left"></i>
            </Link>
          </div>
          <div className='text progress-bars'>
            <div className='previous'></div>
            <div className='previous'></div>
            <div className='active'></div>
          </div>
        </div>
      <div className="auth-sec mt-5">
        <div className="heading">Almost done. Set up your Account.</div>
        <form>
          <div className="form-auth">
            <label>Email Address</label>
            <div className="checked-field-wrapper">
              <input
                type="text"
                placeholder="Enter your email address"
                name="email"
                onChange={handleChange}
              />
              {isEmailValid
                ? <img src={FieldChecked} alt="" />
                : ""
              }
            </div>
            <p>
              Please enter a valid email address. You’ll use
              this to log in to your account.
            </p>
          </div>
          <div className="form-auth">
            <label>Password</label>
            <div className="checked-field-wrapper">
              <input
                className={isPasswordValid ? "" : "border-danger"}
                type="password"
                placeholder="Choose a strong password"
                name="password"
                onInput={handleChange}
              />
              {isPasswordValid
                ? <img src={FieldChecked} alt="" />
                : ""
              }
            </div>
            <p style={{ color: isPasswordValid ? undefined : "red" }}>
              Password must be alpha-numeric with both lowercase and uppercase
              and at least 8 characters.
            </p>
          </div>
        </form>
      </div>
      <div className="center-btn last">
        <div className="submit-btn">
          <button
            className="cus-btn"
            disabled={!valid}
            onClick={proceed}
            style={{ opacity: valid ? 1 : 0.3 }}
          >
            { loading ? "Loading..." : "Sign up" }
          </button>
          <div className="signup_footnote">
            By signing up, you agree to Junity’s Terms
            and Conditions and Privacy Policy.
          </div>
        </div>   
      </div>
    </>
  );
};

export default LastStep;
