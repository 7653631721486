import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function OpportunitiesLoader() {
  return ( 
    <>
      <div className="item">
        <div className='d-flex justify-content-between align-items-center my-2'>
          <Skeleton width={50} height={50}/>
          <Skeleton width={20} height={20}/>
        </div>
        <Skeleton height={25}/>
        <Skeleton height={50}/>
        <div className='d-flex'>
          <Skeleton height={15} width={80}/>
          <Skeleton height={15} width={40} className="mx-2"/>
        </div>
        <Skeleton height={20} width={100}/>
      </div>
    </>
  );
}

export default OpportunitiesLoader;