import React, { useState } from "react";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const ApplyApplication = ({ removeOverlay, setInnerOverlay, shown, position }) => {
  const [checkbox, setCheckbox] = useState(false);

  const handlePop = () => {
    setInnerOverlay(true);
    removeOverlay();
  };
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="connect-counselor text-left">
              <div className="title">Submit your Application</div>
              <div className="des">
                You are about to submit an application to become a Junity
                Influencer.
              </div>
              <div className="des mt-3">
                After applying, our team will consider your application and see
                if you’re a match.
              </div>
              <div className="cus-check">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customControlAutosizing"
                    value={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customControlAutosizing"
                  >
                    I have read and agree to the{" "}
                    <span>Terms and Conditions</span> of the Junity Influencer
                    Program.
                  </label>
                </div>
              </div>
              <div className="connect">
                <button
                  type="button"
                  className={checkbox ? "active" : ""}
                  disabled={!checkbox}
                  style={{ opacity: checkbox ? 1 : 0.6 }}
                  onClick={handlePop}
                >
                  Submit Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyApplication;
