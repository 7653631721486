import {
  SET_REFERRED_BY_DATA,
  RESET_REFERRED_BY_DATA,
} from "../constants/referredByConstants";

const initialLoginState = {
  userID: ""
};

const referredByReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_REFERRED_BY_DATA:
      state = { userID: action.payload };
      return state;
    case RESET_REFERRED_BY_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default referredByReducer;
