import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { chatClient } from "../../libs/axiosClient";
import BottomNav from "../../Components/BottomNav";
import SearchIcon from "../../Assets/images/chat-search.svg";
import MuteIcon from "../../Assets/images/volume-off.svg";
import Person from "../../Assets/images/counselor-img.jpg";
import ResourcesChat from "./All/Resources";
import CounselorsChat from "./All/Counselors";

import "./style.scss";

const CustomApp = (props) => {
  const user = useSelector((store) => store.user);
  // const [userChats, setUserChats] = useState([]);
  const [resourceChats, setResourceChats] = useState([]);
  const [counselorChats, setCounselorChats] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filteredResourceChats, setFilteredResourceChats] = useState([]);
  const [filteredCounselorChats, setFilteredCounselorChats] = useState([]);

  const getUserChats = async () => {
    const chats = await chatClient.get("/chats/");
    const userChats = chats.data?.filter(
      (elt) => JSON.parse(elt?.custom_json)?.creatorID === user?.id
    );
    const userChatsFilter = userChats.filter(
      (elt) => JSON.parse(elt?.custom_json)?.destinationType === "resource"
    );
    setResourceChats(userChatsFilter);
    setFilteredResourceChats(userChatsFilter);
    const counselorChatsFilter = userChats.filter(
      (elt) => JSON.parse(elt?.custom_json)?.destinationType === "educator"
    );
    setCounselorChats(counselorChatsFilter);
    setFilteredCounselorChats(counselorChatsFilter);
  };

  useLayoutEffect(() => {
    if (searchKey.trim() !== "") {
      const re = new RegExp(searchKey.toLowerCase(), "g");
      const counselorMatches = counselorChats?.filter(
        (elt) => elt.title.toLowerCase().match(re) !== null
      );
      const resourceMatches = resourceChats?.filter(
        (elt) => elt.title.toLowerCase().match(re) !== null
      );
      setFilteredCounselorChats(counselorMatches);
      setFilteredResourceChats(resourceMatches);
    }
    // else {
    //   setFilteredCounselorChats(counselorChats);
    //   setFilteredResourceChats(resourceChats);
    // }
  }, [searchKey]);

  useEffect(() => {
    getUserChats();
    let socket = new WebSocket(
      `wss://api.chatengine.io/person/?publicKey=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&username=${user?.id}&secret=${user?.id}`
    );
    socket.onopen = (event) => console.log("open", event);
    socket.onmessage = () => getUserChats();
    socket.onerror = (error) => console.log("error", error);
  }, []);

  return (
    <>
      <div className="chats">
        <div className="head">
          <div className="title">
            Chat{" "}
            <div className="msg-count">
              {resourceChats.length + counselorChats.length}
            </div>
          </div>
          <div className="search" onClick={() => setShowSearch(!showSearch)}>
            <img src={SearchIcon} alt="icon" />
          </div>
        </div>
        {showSearch && (
          <div className={`chat-search`}>
            <input
              type="text"
              placeholder="Search for a chat..."
              name="searchKey"
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <i className="fas fa-search"></i>
          </div>
        )}
        <div className="chat-tabs">
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="All-tab"
                data-toggle="tab"
                data-target="#All"
                type="button"
                role="tab"
                aria-controls="All"
                aria-selected="true"
              >
                All
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Counselors-tab"
                data-toggle="tab"
                data-target="#Counselors"
                type="button"
                role="tab"
                aria-controls="Counselors"
                aria-selected="false"
              >
                Counselors
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Resources-tab"
                data-toggle="tab"
                data-target="#Resources"
                type="button"
                role="tab"
                aria-controls="Resources"
                aria-selected="false"
              >
                Resources
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="All"
            role="tabpanel"
            aria-labelledby="All-tab"
          >
            {filteredCounselorChats.length > 0 && (
              <div className="persons">
                <div className="title">Counselors</div>
                {filteredCounselorChats.slice(0, 4)?.map((elt) => (
                  <CounselorsChat item={elt} key={elt?.id} />
                ))}
              </div>
            )}
            {filteredResourceChats.length > 0 && (
              <div className="persons">
                <div className="title">Resources</div>
                {filteredResourceChats.slice(0, 4)?.map((elt) => (
                  <ResourcesChat item={elt} key={elt?.id} />
                ))}
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="Counselors"
            role="tabpanel"
            aria-labelledby="Counselors-tab"
          >
            <div className="persons">
              {filteredCounselorChats?.map((elt) => (
                <CounselorsChat item={elt} key={elt?.id} />
              ))}
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="Resources"
            role="tabpanel"
            aria-labelledby="Resources-tab"
          >
            <div className="persons">
              {filteredResourceChats?.map((elt) => (
                <ResourcesChat item={elt} key={elt?.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
};

export default CustomApp;
