import axios from 'axios';

const hubspotURL = process.env.REACT_APP_HUBSPOT_URL;

export const createHubspotContact = async (
  name,
  email,
  phone,
  user_type,
  company = null,
  website = null
) => {
  let splitname = name?.split(' ');
  let firstname = splitname[0];
  let lastname = splitname[splitname?.length - 1];
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${hubspotURL}/contacts`, {
        firstname,
        lastname,
        email,
        phone,
        user_type,
        company,
        website
      });
      const result = response?.data;
      const { status, message, hubspotID } = result;

      status === 'success' ? resolve(hubspotID) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};

export const createHubspotTicket = async (subject, content, category, hubspotID, accountType='youth') => {
  const categories_to_ids = {
    support: 0,
    feedback: 32094776
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${hubspotURL}/tickets`, {
        subject,
        content,
        category: categories_to_ids[category],
        hubspotID,
        accountType
      });

      const result = response?.data;
      const { status, message, ticket } = result;
      
      status === 'success' ? resolve(ticket) : reject(new Error(message));
    } catch (error) {
      console.log(error);
      reject(error?.message);
    }
  });
};
