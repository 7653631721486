import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Setting from "../../Assets/images/settings.svg";
import Close from "../../Assets/images/x.svg";
import EmptyState from "../../Assets/images/snoozing-img.png";
import NotificationsLoader from "../../Components/Common/NotificationsLoader";
import { useAuth } from "../../Components/Auth";
import MessageItem from "./message";
import { getUserNotifications } from "../../Services/query/notifications";
import "./style.scss";
import { API } from "aws-amplify";
import { deleteAppNotification } from "../../graphql/mutations";

const Notifications = () => {
  const [loaded, setLoaded] = useState(false);
  const { user } = useAuth();
  const [userNotifications, setUserNotifications] = useState([]);

  const clearNotifications = async () => {
    const promises = userNotifications.map(async (notification) => {
      return await API.graphql({
        query: deleteAppNotification,
        variables: { input: { id: notification.id } },
      });
    });
    Promise.all(promises);
    setUserNotifications([]);

    await retrieveNotifications();
  };

  const retrieveNotifications = async () => {
    const results = await getUserNotifications(user?.id);
    setUserNotifications(
      results?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    );
  };

  useEffect(() => {
    retrieveNotifications();
    setLoaded(true);
  }, []);

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to="/home">
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text">Notifications</div>
        <div className="d-flex align-items-center gap-2">
          <i
            className="fas fa-trash-alt mr-2"
            style={{ fontSize: "1.4rem", cursor: "pointer" }}
            onClick={() => {
              clearNotifications();
            }}
          ></i>
          <Link to="/notification-settings">
            <img src={Setting} alt="icon" />
          </Link>
        </div>
      </div>
      {loaded ? (
        <div className="notifications">
          {userNotifications?.length ? (
            <>
              {userNotifications.reverse().map((notification, index) => (
                <MessageItem
                  key={index}
                  id={notification?.id}
                  content={notification?.text}
                  link={notification?.link}
                  action={notification?.action}
                  receivedTime={new Date(notification?.createdAt)}
                />
              ))}
            </>
          ) : (
            <div className="empty-container">
              <img src={EmptyState} alt="snoozing" />
            </div>
          )}
        </div>
      ) : (
        <NotificationsLoader />
      )}
    </>
  );
};

export default Notifications;
