import React, { useState } from 'react'
import JICon from '../../../Assets/images/jCoin.svg'
import { User, ChevronLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import ChooseSessionPopup from '../../../Components/Popus/ChooseSessionPopup'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetExperience } from '../../../Services/query/experiences'
import { GetExperienceSession } from '../../../Services/query/experienceSessions'
import { calculateTimeWithPmOrAm, formatDateForSessions } from '../../../utils/helpers'
import { useMutation } from '@apollo/client';
import { CREATE_PURCHASE } from '../../../Services/mutation/purchase';
import AlertToast from '../../../Components/Common/Alerts/ToastAlert';

import './style.scss'
import { axiosClient } from '../../../libs/axiosClient';
import { UPDATE_STUDENT } from '../../../Services/mutation/student';

const CheckoutJunityItem = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const [overlay, setOverlay] = useState(false);
  const [personNumber, setPersonNumber] = useState(1);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');

  const [createPurchase] = useMutation(CREATE_PURCHASE);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const search = new URLSearchParams(useLocation().search);
  const experienceID = search.get("experience");
  const sessionID = search.get("session");

  const experience = GetExperience(experienceID)?.data;
  const session = GetExperienceSession(sessionID)?.data;

  const experienceType = {
    'online': 'Online Experience',
    'inperson': 'In Person Experience',
  }

  const getExperienceType = (type) => {
    if (type && Object.keys(experience)?.includes(type)) {
      return experienceType[type];
    }

    return ""
  }

  const changePhoneNumberPage = ()=>{
    navigate('/change-number')
  }

  const getSessionDuration = () => {
    if (session) {
      const dateTimeStart = new Date(`${session?.date}T${session?.start_time}:00`);
      const dateTimeEnd = new Date(`${session?.date}T${session?.end_time}:00`);
      const hourDiff = dateTimeEnd.getHours() - dateTimeStart.getHours();
      if (hourDiff === 0) {
        return `${dateTimeEnd.getMinutes() - dateTimeStart.getMinutes()} mins`;
      }
      return `${hourDiff} hr`;
    }
    return 0
  }

  const checkout = async () => {
    const amount = parseInt(experience?.price_per_person) * personNumber;
    if (amount > ((user?.balance || 0).toFixed(2))) {
      setActiveToast(true);
      setToast(`You need ${(amount - ((user?.balance || 0).toFixed(2))).toFixed(2)} more JC to complete the purchase`);
    } else {
      const inputData = {
        amount,
        createdAt: new Date(),
        experienceID: experience?.id,
        slots: personNumber,
        sessionID: session?.id,
        status: "Confirmed",
        purchaser_role: "Student",
        purchased_by: user?.id,
      }
      try {
        await createPurchase({ variables: {...inputData} });
        await updateStudent({ variables: { id: user?.id, balance: (user?.balance || 0) - amount } });
        await axiosClient.post('/update-purchase-multiplier', { studentID: user?.id, multiplier: user?.multiplier || 0 });
        navigate(`/store/purchased?id=${experience?.id}`)
      } catch (e) {
        console.error(e);
      }
    }
  }


  return (
    <>
      <ChooseSessionPopup 
        removeOverlay={() => setOverlay(false)} 
        experience={experience}
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
      />
      <AlertToast 
        variant={'error'} 
        icon={'delete'} 
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="noti-head border-bottom-0">
        <div className="icon" onClick={() => navigate(`/store/single-item?id=${experienceID}`)}>
          <ChevronLeft />
        </div>
        <div className="text">Checkout</div>
        <div className="icon"></div>
      </div>
      <div className="checkout-sec" style={{marginTop: 80}}>
        <div className="purchase-item">
          <div className="title">Your Purchase</div>
          <div className="item">
            <div className="item-img">
              <img src={experience?.image} alt="item-img" />
            </div>
            <div className="info">
              <div className="item-title">{experience?.title}</div>
              <div className="des">
                {getExperienceType(experience?.type)}
              </div>
              <div className="time">{getSessionDuration()}</div>
            </div>
          </div>
        </div>
        <div className="change-session">
          <div className="title">Session</div>
          <div className="item">
            <div className="sess">
              {formatDateForSessions(session?.date)}<br/>
              {calculateTimeWithPmOrAm(session?.start_time)} - 
              {calculateTimeWithPmOrAm(session?.end_time)}
              (ET)
            </div>
            <div className="act">
              <button onClick={() => setOverlay(true)}>Change</button>
            </div>
          </div>
        </div>
        <div className="slots">
          {/*<div className="title">Slots</div>*/}
          <div className="item">
            <div className="left">
              <User />
              <span>{personNumber} Entry</span>
            </div>
            {/*<div className="right">*/}
            {/*  <button */}
            {/*    type='button' */}
            {/*    className="count"*/}
            {/*    disabled={personNumber === 1}*/}
            {/*    onClick={() => setPersonNumber(personNumber - 1)}*/}
            {/*  >*/}
            {/*    <Minus />*/}
            {/*  </button>*/}
            {/*  <button */}
            {/*    type='button' */}
            {/*    className="count"*/}
            {/*    disabled={personNumber === session?.slots}*/}
            {/*    onClick={() => setPersonNumber(personNumber + 1)}*/}
            {/*  >*/}
            {/*    <Plus />*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="price">
          <div className="title">Price</div>
          <div className="item">
            <div className="left">
              <strong>Total: </strong>
              <img src={JICon} alt="icon" />
              <span>{parseInt(experience?.price_per_person) * personNumber}</span>
            </div>
            <div className="right">
              {'( '} <img src={JICon} alt="icon" /> 
              {experience?.price_per_person} X {personNumber} Person {' )'}
            </div>
          </div>
        </div>
        <div className="change-session">
          <div className="title">Your Contact Information</div>
          <div className="item">
            <div className="sess">
              <div className="label">Mobile Phone Number</div>
              {user?.phone}
            </div>
            <div className="act">
              <button onClick={changePhoneNumberPage}>Change</button>
            </div>
          </div>
        </div>
        <div className="acti">
          <p>
            By tapping the button below, you agree to{' '}
            <strong>Junity’s Terms and Conditions</strong>,{' '}
            <strong>Privacy Policy</strong>, and{' '}
            <strong>Cancellation and Refund Policy</strong>.
          </p>
          <button onClick={checkout}>Confirm Purchase</button>
        </div>
      </div>
    </>
  )
}

export default CheckoutJunityItem
