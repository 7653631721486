import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectInterestDetail from "../../Components/Common/SelectInterests";
import { axiosClient } from "../../libs/axiosClient";
import "./style.scss";

const SelectInterest = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const referredBy = useSelector((store) => store.referredBy);
  const [loading, setLoading] = useState(false);

  const updateReferrerCredit = async () => {
    if (referredBy.userID) {
      const resp = await axiosClient.post("/get-student-by-id", {
        id: referredBy.userID,
      }, {
        headers: { authorization: `Bearer ${user?.token}` },
      });
      const student = resp.data.data;
      await axiosClient.post("/update-by-invite", {
        studentID: student.id,
        multiplier: student?.multiplier || 0,
        balance: student?.balance || 0,
      });
    }
  };

  const proceed = async (interests, categories) => {
    setLoading(true);
    try {
      const resp = await axiosClient.post("/create-student-interest", {
        interests, categories, studentID: user?.id
      });
      updateReferrerCredit();
      if (resp.status === 200) navigate("/setting-up");
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <SelectInterestDetail proceed={proceed} loading={loading} title={true} />
  );
};

export default SelectInterest;
