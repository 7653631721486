import { gql } from "@apollo/client";

export const CREATE_REVOKE_PROFILE_SHARING = gql`
  mutation CreateRevokeProfileSharingMutation(
    $studentID: String!
    $school: String!
    $createdAt: AWSDateTime
    $reason: String
  ) {
    createRevokeProfileSharing(
      input: {
        studentID: $studentID
        school: $school
        createdAt: $createdAt
        reason: $reason
      }
    ) {
      id
      school
    }
  }
`;
