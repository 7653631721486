/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Heart from '../../../Assets/images/heart.svg'
import { AllOpportunitiesAPI,  } from '../../../Services/query/opportunities'
import { calculateTime } from '../../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setUserDataAction } from '../../../Redux/actions/userActions'
import { Toaster } from 'react-hot-toast'
import EmptyState from '../../../Assets/images/empty-data.png';
import { AddLike } from '../../../utils/common'

const LikedItems = () => {
  const user = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [allLikedOpps, setAllLikedOpps] = useState([]);
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);
  const [student, setStudent] = useState([]);
  
  const search = new URLSearchParams(useLocation().search);
  const interest = search.get("interest");

  const updateOpps = (id, newLikes, oldLikedBy) => {

    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = []
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id)
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    } 
    
    setOppsLikedBy(updatedLikedBy);
  }

  const addLike = (elt) => {
    const addLikeProps = {
      state, 
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading
    }

    AddLike(addLikeProps);
  };

  useEffect(() => {
    const studentVerified = async () => {
      if (student !== undefined && Object.keys(student || {})) {
        dispatch(setUserDataAction(student));
        setStudent(student);
        const opps = await AllOpportunitiesAPI();
        const likedOpps = opps?.filter(
          (elt) => elt?.liked_by?.includes(user.id)
        );        
        const interestFilteredLikedOpps = likedOpps?.filter(
          (elt) => elt?.interests?.includes(interest)
        );
        const allLikedOpps = interest
        ? interestFilteredLikedOpps
        : likedOpps;

        setAllLikedOpps(allLikedOpps);
      }
    }

    studentVerified();
  }, []);
  

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <div onClick={() => navigate('/accounts')}>
            <span>{'<'}</span>
          </div>
        </div>
        <div className="text">Liked Opportunities </div>
        <div className="icon"></div>
      </div>
      {allLikedOpps?.length
          ?
        <>
          <div className="liked-items mt-5">
            <div className="title">All Liked Opportunities</div>
          </div>
          <div className="oppertunities">
            <div className="opper-list">
              {allLikedOpps?.map((elt, index) =>
                  <div className="item" key={index}>
                    <div className="item-head">
                      <div className="p-logo">
                        <img src={elt?.school?.image} className="circle" alt="c-logo" />

                        <div
                            className="heart"
                            onClick={() => addLike(elt)}
                        >
                          {(state[elt?.id] || elt?.liked_by?.includes(user?.id)) ? (
                              <i className="fas fa-heart"></i>
                          ) : (
                              <img src={Heart} alt="icon" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="title">{elt?.name}</div>
                      <div className="des break-overview">
                        {elt?.description}
                      </div>
                      <div className="date-likes">
                        <div className="date">
                          {calculateTime(elt?.createdAt)}
                        </div>
                        <div className="dot"></div>
                        <div className="likes">
                          {elt?.likes || 0}
                          {elt.likes === 1 ? ' like' : ' likes'}
                        </div>
                      </div>
                    </div>
                    <div
                        className="p-type mr-1"
                        type={elt?.category}
                        key={index}
                    >
                      {elt?.category}
                    </div>
                  </div>
              )}
            </div>
          </div>
        </>
          : (
              <div className="empty-state-height d-flex justify-content-center align-items-center">
                <img src={EmptyState} alt="empty state" />
              </div>
          )
      }
      <Toaster />
    </>
  )
}

export default LikedItems
