import React, { useState } from "react";

import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const AddVolunteer = (props) => {
  const { 
    removeOverlay, 
    data, 
    setData, 
    selectedItem,
    shown,
    position
  } = { ...props };
  const [work, setWork] = useState({});

  const handleChange = (e) => {
    setWork({
      ...work,
      [e.target.name]: e.target.value,
    });
  };

  const addWork = () => {
    work.id = selectedItem.id;
    work.organization = work.organization || selectedItem.organization;
    work.hours_spent = work.hours_spent || selectedItem.hours_spent;
    setData([...data, work]);
    setWork({});
    removeOverlay();
  };

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={() => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={() => removeOverlay()}></div>
            <div className="add-volunteer text-left">
              <div className="title">Add Volunteer Work</div>
              <div className="form-auth">
                <label>Organization Name</label>
                <input
                  type="text"
                  placeholder="Organization Name"
                  name="organization"
                  defaultValue={selectedItem.organization}
                  onChange={handleChange}
                />
              </div>
              <div className="form-auth">
                <label>Hours Spent</label>
                <input
                  type="text"
                  placeholder="Hours Spent"
                  name="hours_spent"
                  defaultValue={selectedItem.hours_spent}
                  onChange={handleChange}
                />
              </div>
              <div className="buttons">
                <button className="con" onClick={addWork}>
                  Add Volunteer Work
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVolunteer;
