import { gql } from "@apollo/client";
import { API } from 'aws-amplify'

export const GET_GROUPS = gql`
  query GroupsQuery {
    listMyGroups (limit: 1000) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }

      nextToken
    }
}
`

export const GET_GROUPS_WITH_TOKEN = gql`
  query IGroupsQuery ($limit: Int, $nextToken: String) {
    listMyGroups (limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentIDs
        createdAt
        description
        name
        roleID
        updatedAt
      }
      nextToken
    }
}
`

export async function AllGroups() {
  const resp = await API.graphql({ 
    query: GET_GROUPS,
  });

  const data = resp?.data?.listMyGroups
  let nextToken = data?.nextToken
  let groups = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_GROUPS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listMyGroups

    const previousData = [...groups, ...respData?.items]
    groups = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return groups || [];
}

export const GET_GROUP = gql`
  query SingleGroup($id: ID!) {
    getMyGroup(id: $id) {
      id
      studentIDs
      createdAt
      description
      name
      roleID
      updatedAt
    }
  }
`;


export async function GetGroup(id) {
  if (!id){
    return {}
  }
  try {
    const respInterest = await API.graphql({
      query: GET_GROUP,
      variables: { id },
    });
    return respInterest?.data?.getMyGroup;
  } catch (err) {
    console.error(err);
    return {};
  }
}

