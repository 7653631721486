/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Auth } from "aws-amplify";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCognitoUserDataAction } from '../../Redux/actions/cognitoUserActions';
import { setUserDataAction } from '../../Redux/actions/userActions';
import { GetStudentByEmail } from '../../Services/query/students';

function RedirectPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function getUserDataFromGoogle() {
      const googleUser = await Auth.currentAuthenticatedUser();
      if (googleUser) {
        dispatch(setCognitoUserDataAction(googleUser));
        const name = googleUser?.attributes?.name;
        const email = googleUser?.attributes?.email;
        dispatch(setUserDataAction({ name, email }));
        const student = await GetStudentByEmail(email);
        if (Object.keys(student).length > 0) {
          dispatch(setUserDataAction(student));
          navigate('/home');
        } else {
          dispatch(setUserDataAction({ name, email, email_verified: 'true' }));
          navigate('/complete-details');
        }

      } else { navigate('/login') }
    }

    getUserDataFromGoogle();
  }, []);
  

  return ( 
    <div className="empty-state-full">
      <div className="center-content">
        <h4>Redirecting...</h4>
      </div>
    </div>

  );
}

export default RedirectPage;