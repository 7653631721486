import { getInitial } from '../../../utils/helpers';
import './style.scss';

function InitialWrapper({ name, size, cls, randomColor }) {
  return ( 
    <div 
      className={`initials-wrapper ${cls} ${size}`}
      style={{ backgroundColor: randomColor, color: randomColor.slice(0, 4) !== "#FFF" ? "#FFF" : "#000" }}
    > 
    {getInitial(name)}
    </div>
  );
}

export default InitialWrapper;