import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { DELETE_STUDENT_OPPORTUNITY_APPLICATION } from "../../Services/mutation/studentOpportunityApplication";
import { CREATE_WITHDRAWN_APP } from "../../Services/mutation/withdrawnOpportunityApps";
import { GetApplicationByOpportunityIDAndStudentID } from "../../Services/query/studentOpportunityApplications";
import { titleWords } from "../../utils/helpers";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const WithdrawOpportunityApplication = (props) => {
  const {
    removeOverlay,
    resourceName,
    opp,
    studentID,
    setSuccessfulWithdrawn,
    shown,
    position,
  } = { ...props };

  const [reason, setReason] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = useState("");
  const [createWithdrawnApp] = useMutation(CREATE_WITHDRAWN_APP);
  const [deleteWithdrawnApp] = useMutation(
    DELETE_STUDENT_OPPORTUNITY_APPLICATION
  );

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const withdraw = async () => {
    removeOverlay();
    setSuccessfulWithdrawn(true);
    const studentApp = await GetApplicationByOpportunityIDAndStudentID(
      opp?.id,
      studentID
    );
    deleteWithdrawnApp({
      variables: { id: studentApp.id },
    })
      .then(() => {
        createWithdrawnApp({
          variables: {
            studentID,
            opportunityName: opp?.name,
            createdAt: new Date(),
            reason: reason === "other" ? state : reason,
          },
        })
          .then(() => {
            removeOverlay();
            setSuccessfulWithdrawn(true);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (reason.trim() !== "") {
      if (reason === "other" && state.trim() !== "") {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      if (reason !== "other") setIsValid(true);
    }
  }, [reason, state]);

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="connect-counselor text-left">
              <div className="title">
                Are you sure you want to withdraw this application?
              </div>
              <div className="des mt-2">
                {titleWords(resourceName)} already received your application. If
                you withdraw your application now, you would no longer be
                considered for this opportunity.
              </div>
              <div className="des-red mt-2">
                You would have to send a new application if you change your
                mind.
              </div>
              <div className="checklist">
                <div className="title">
                  Why do you want to withdraw your application?
                </div>
                <div className="item">
                  <div className="label">I am no longer interested</div>
                  <div className="cus-check">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        value="notInterested"
                        name="reason"
                        onChange={handleChange}
                        id="v1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="v1"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="label">I found some other opportunity</div>
                  <div className="cus-check">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="reason"
                        value="foundOther"
                        onChange={handleChange}
                        id="v2"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="v2"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="label">
                    The application process is taking too long
                  </div>
                  <div className="cus-check">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="reason"
                        value="longLoad"
                        onChange={handleChange}
                        id="v3"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="v3"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="label">Some other reason</div>
                  <div className="cus-check">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="reason"
                        value="other"
                        onChange={handleChange}
                        id="v4"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="v4"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className={`item ${reason === "other" ? "" : "d-none"}`}>
                  <textarea
                    className="other_reason_input"
                    placeholder="Please state your reason"
                    onChange={(e) => setState(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="prim">
                <button
                  className="primary"
                  type="button"
                  onClick={removeOverlay}
                >
                  No. Keep my application
                </button>
                <button
                  className="danger"
                  type="button"
                  disabled={!isValid}
                  style={{ opacity: isValid ? 1 : 0.6 }}
                  onClick={withdraw}
                >
                  Yes, Withdraw Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawOpportunityApplication;
