/* eslint-disable no-unused-expressions */
import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSingleCategory } from "./categories";

export const GET_INTERESTS = gql`
  query InterestsQuery {
    listInterests (limit: 1000) {
      items {
        id
        name
        updatedAt
        description
        createdAt
        categoryID
      }
      nextToken
    }
}
`

export const GET_INTERESTS_WITH_TOKEN = gql`
  query InterestsQuery ($limit: Int, $nextToken: String) {
    listInterests (limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        updatedAt
        description
        createdAt
        categoryID
      }
      nextToken
    }
}
`

export async function AllInterests() {
  const resp = await API.graphql({ 
    query: GET_INTERESTS,
  });

  const data = resp?.data?.listInterests
  let nextToken = data?.nextToken
  let interests = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_INTERESTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listInterests

    const previousData = [...interests, ...respData?.items]
    interests = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return interests || [];
}

export const GET_INTEREST = gql`
  query SingleInterest($id: ID!) {
    getInterest(id: $id) {
      id
      name
      updatedAt
      description
      createdAt
      categoryID
    }
  }
`;

export async function GetSingleInterest(id) {
  try {
    const respInterest = await API.graphql({
      query: GET_INTEREST,
      variables: { id },
    });
    const interest = respInterest?.data?.getInterest;
    return interest;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_INTEREST_BY_CATEGORY_ID = gql`
  query SingleInterest($categoryID: ID!) {
    listInterests(limit: 1000, filter: {categoryID: {eq: $categoryID}}) {
      items {
        id
        name
        updatedAt
        description
        createdAt
        categoryID
      }
    }
  }
`;

export async function GetSingleInterestByCategory(id) {
  const interests = await AllInterests();
  const interest = interests?.find(i => i.categoryID === id);
  return interest || {} 
}

export async function GetMultipleInterestByCategory(id) {
  const interests = await AllInterests();
  const interest = interests?.filter(i => i.categoryID === id);
  return interest || [] 
}


export const GET_INTEREST_BY_NAME = gql`
  query SingleInterestByName($name: String) {
    listInterests(limit: 1000, filter: {name: {eq: $name}}) {
      items {
        id
        name
        updatedAt
        description
        createdAt
        categoryID
      }
    }
  }
`;

export async function GetSingleInterestByName(name) {
  const interests = await AllInterests();
  const interest = interests?.find(i => i.name === name)
  return interest || {} 
}

export async function GetMultipleInterestByName(name) {
  const interests = await AllInterests();
  const interest = interests?.filter(i => i.name === name)
  return interest || []
}

export async function GetInterestByNameAndCategoryID(name, id) {
  const interests = await AllInterests();
  const interest = interests?.find(
    (elt) => elt.name === name && elt.categoryID === id
  )
  return interest || {}
}

export async function GetInterestWithCategory() {
  const interests = await AllInterests();
  return await interests.reduce(async (previousPromise, interest) => {
    const category = await GetSingleCategory(interest.categoryID);
    const interestData = await previousPromise;
    const obj = { ...interest, categoryName: category?.name }
    interestData.push(obj);
    return interestData;
  }, Promise.all([]));
}
