import {
    SET_ROUTING_STATE_DATA,
    RESET_ROUTING_STATE_DATA,
} from "../constants/routingStateConstant";

const initialLoginState = {
    previousRoute: "",
    singleOpportunityPreviousRoute: "",
};

const routingStateReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case SET_ROUTING_STATE_DATA:
            state = { ...state, ...action.payload };
            return state;
        case RESET_ROUTING_STATE_DATA:
            state = initialLoginState;
            return state;
        default:
            return state;
    }
};

export default routingStateReducer;
