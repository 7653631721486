import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

function WelcomePreloader() {
  return (
    <>
    <div className="main-skeleton">
      <div className="d-flex justify-content-center align-items-center mt-5 flex-column my-2">
        <Skeleton width={250} height={300} className="img" />
        <div className="d-flex justify-content-center mt-5 flex-column">
          <Skeleton width={350} height={25} className="mb-2"/>
          <Skeleton width={150} height={25} />

          <Skeleton width={350} height={20} className="mt-4 mb-1"/>
          <Skeleton width={150} height={20} />
        </div>
      </div>
      <div className="btns position-fixed d-flex justify-content-between">
        <Skeleton width={70} height={25}/>
        <Skeleton width={70} height={25} className="mr-2" />
      </div>
    </div>
    </>
  );
}

export default WelcomePreloader;
