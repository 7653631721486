import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { UPDATE_STUDENT } from "../../../Services/mutation/student";
import { CREATE_CHAT_HANDLER } from "../../../Services/mutation/chatHandler";
import { GetSingleOpportunity } from "../../../Services/query/opportunities";
import { X } from "react-feather";
import { chatClient } from "../../../libs/axiosClient";
import { AllResources } from "../../../Services/query/resources";
import Confetti from "../../../Assets/images/Confetti-svg.svg";

import "./style.scss";

function ApplicationSubmitted() {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const appId = search.get("appId");

  const [opp, setOpp] = useState({});
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [createChatHandler] = useMutation(CREATE_CHAT_HANDLER);

  const createUser = async () => {
    const firstName = user?.name?.split(" ")[0];
    const lastName = user?.name?.split(" ").slice(1).join(" ");
    await chatClient.post("/users/", {
      username: user?.id,
      first_name: firstName,
      last_name: lastName,
      secret: user?.id,
    });

    updateStudent({
      variables: {
        id: user?.id,
        chat_username: user?.id,
      },
    });
  };

  const createChat = async (users) => {
    if (opp) {
      setLoading(true);
      setIsActive(false);
      const resources = await AllResources();
      const sameSchoolResources = resources?.filter(
        (elt) => elt.organizationID === opp?.resource?.organizationID
      );

      const usernamesCreated = await sameSchoolResources?.reduce(
        async (acc, resource) => {
          if (users.findIndex((elt) => elt.username === resource?.id) === -1) {
            await chatClient.post("/users/", {
              username: resource?.id,
              first_name: resource?.name?.split(" ")[0],
              last_name: resource?.name?.split(" ").splice(1).join(""),
              secret: resource?.id,
            });
          }

          const resp = await acc;
          return [...resp, resource?.id];
        },
        Promise.resolve([])
      );

      const data = {
        usernames: [...usernamesCreated, user?.id],
        title: opp?.school?.name,
        is_direct_chat: false,
        custom_json: JSON.stringify({
          orgID: opp?.school?.id,
          creatorID: user?.id,
          creatorType: "youth",
          destinationType: "resource",
        }),
      };

      const chat = await chatClient.put("/chats/", data);
      createChatHandler({
        variables: {
          chat_id: chat.data.id,
          access_key: chat.data.access_key,
          createdAt: new Date(),
        },
      });
      setLoading(false);
      setIsActive(true);
      sendAppText(chat.data.id, "submitted an application");
      sendMessageOpp(chat.data.id, opp?.name);
      navigate(`/chat-person?id=${chat.data.id}`);
    }
  };

  const sendAppText = async (id, text) => {
    const messageData = {
      text,
      custom_json: JSON.stringify({
        type: "app-sub",
        appId,
      }),
    };
    await chatClient.post(`chats/${id}/messages/`, messageData);
  };

  const sendMessageOpp = async (id, text) => {
    const messageData = {
      text,
      custom_json: JSON.stringify({
        type: "app-message",
        appId,
      }),
    };
    await chatClient.post(`chats/${id}/messages/`, messageData);
  };

  const getAllChats = async () => {
    const chats = await chatClient.get("/chats/");
    return chats.data?.find(
      (elt) =>
        JSON.parse(elt.custom_json)?.orgID === opp?.resource?.organizationID &&
        JSON.parse(elt.custom_json)?.creatorID === user?.id
    );
  };

  const handleProceedToChat = async () => {
    const users = await chatClient.get("/users");
    try {
      if (users.data.findIndex((elt) => elt.username === user?.id) === -1) {
        createUser();
      }
      const chatAlreadyCreated = await getAllChats();
      if (Object.keys(chatAlreadyCreated || {}).length === 0) {
        createChat(users.data);
      } else {
        sendAppText(chatAlreadyCreated.id, "submitted an application");
        sendMessageOpp(chatAlreadyCreated.id, opp?.name);
        navigate(`/chat-person?id=${chatAlreadyCreated.id}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setIsActive(Object.keys(opp).length > 0);
  }, [opp]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const opp = await GetSingleOpportunity(id);
      setOpp(opp);
    };

    handleSetDependencies();
  }, [id]);

  return (
    <div className="app_submitted">
      <div
        className="icon"
        onClick={() => navigate(`/apply-for-opportunity?id=${id}`)}
      >
        <X />
      </div>
      <div className="main_sect">
        <img src={Confetti} alt="" />
        <div className="confetti_text">
          <h5>
            Great job, {user?.name}! <br /> Your application has been submitted.
          </h5>
          <p>
            Send a message to <b>{opp?.school?.name}</b> to make your
            application even more personal.
          </p>
        </div>
        <div className="select-btn">
          <button
            type="button"
            disabled={!isActive}
            style={{ opacity: isActive ? 1 : 0.6 }}
            onClick={handleProceedToChat}
          >
            {loading ? "Loading..." : "Go to Chat"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApplicationSubmitted;
