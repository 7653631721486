import { API } from "aws-amplify";
import { UPDATE_EXPERIENCE } from "../../../Services/mutation/experience";


export const AddExperienceLike = async (props) => {
  const {
    state,
    setState,
    oppsLikedBy,
    user,
    experience,
    likes,
    updateOpps,
    toastLoading,
    setToastLoading,
  } = { ...props };

  let { id, liked_by } = { ...experience };
  const likedBy = oppsLikedBy?.includes(user?.id) ? oppsLikedBy: (liked_by || [])
  state[id]
  ? setState({ ...state, [id]: !state[id] })
  : setState({ ...state, [id]: !(likedBy?.includes(user?.id))});

  if (!toastLoading) {
    setToastLoading(true);
    let newLikes = 0
    const inputData = {
      id,
      liked_by
    };

    const userLikes = likes[id] || experience?.likes

    if (liked_by?.includes(user?.id)) {
      inputData.likes = userLikes < 1 ? 0 : userLikes - 1
      const othersLiked = liked_by?.filter((elt) => elt !== user?.id);
      inputData.liked_by = othersLiked;
    } else {
      inputData.likes = userLikes + 1;
      if (likedBy.length > 0) {
        inputData.liked_by = [...likedBy, user?.id];
      } else {
        inputData.liked_by = [user?.id];
      }
    }

    liked_by = inputData.liked_by

    // Remove duplicate user ids
    liked_by = new Set(liked_by)
    liked_by = Array.from(liked_by)
    inputData.liked_by = liked_by
    inputData.likes = inputData?.liked_by?.length
    
    newLikes = inputData.likes
    API.graphql({
      query: UPDATE_EXPERIENCE,
      variables: {...inputData}
    }).then(async () => {
      updateOpps(id, newLikes, likedBy);
      setToastLoading(false);
    }).catch(async (err) => {
      setToastLoading(false);
    })
  }
}