import { gql } from "@apollo/client";

export const CREATE_REVIEW = gql`
  mutation CreateReviewMutation(
    $review: String!
    $description: String
    $stars: Int
    $opportunityID: ID
    $resourceID: ID
    $studentID: ID
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    createReview(
      input: {
        review: $review
        description: $description
        stars: $stars
        opportunityID: $opportunityID
        resourceID: $resourceID
        studentID: $studentID
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      stars
    }
  }
`;


export const UPDATE_REVIEW = gql`
  mutation UpdateReviewMutation(
    $id: ID!
    $review: String!
    $description: String
    $stars: Int
    $opportunityID: ID
    $resourceID: ID
    $updatedAt: AWSDateTime
  ) {
    updateReview(
      input: {
        id: $id
        review: $review
        description: $description
        stars: $stars
        opportunityID: $opportunityID
        resourceID: $resourceID
        updatedAt: $updatedAt
      }
    ) {
      id
      stars
    }
  }
`;



export const DELETE_REVIEW = gql`
  mutation DeleteReviewMutation(
    $id: ID!
  ) {
    deleteReview(
      input: {
        id: $id
      }
    ) {
      id
      stars
    }
  }
`;
