/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { X, Share, MoreHorizontal, Heart, CheckCircle } from "react-feather";
import JICon from "../../../Assets/images/jCoin.svg";
import "./style.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateTimeWithPmOrAm,
  formatDateForSessions,
  titleWords,
} from "../../../utils/helpers";

import { AddExperienceLike } from "../util";
import {axiosClient} from "../../../libs/axiosClient";

const SingleItem = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const [toastLoading, setToastLoading] = useState(false);
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);  
  const [state, setState] = useState({});
  const [resources, ] = useState([]);
  const [experience, setExperience] = useState({});
  const [otherExperiences, setOtherExperiences] = useState([]);

  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");

  const experienceType = {
    online: "Online Experience",
    inperson: "In Person Experience",
  };

  const getExperienceType = (type) => {
    if (type && Object.keys(experience)?.includes(type)) {
      return experienceType[type];
    }

    return "";
  };

  const getExperiencePartners = (partners) => {
    const resourceNames = [];
    partners?.forEach((partnerID) => {
      const resource = resources?.filter((elt) => elt?.id === partnerID)[0];
      resourceNames.push(titleWords(resource?.name));
    });
    return resourceNames.join(", ");
  };

  const updateOpps = (id, newLikes, oldLikedBy) => {

    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = []
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id)
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    } 
    
    setOppsLikedBy(updatedLikedBy);
  }

  const addLike = async (experience) => {
    const addExperienceLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      experience,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    }

    await AddExperienceLike(addExperienceLikeProps);
  };

  const addExperienceViewer = async () => {
    try {
      await axiosClient.post('/experience/viewing', {
        id,
        viewing: experience?.viewing
      });
    } catch (err) {
      console.error(err);
    }
  }

  const removeExperienceViewer = async () => {
    try {
      await axiosClient.patch('/experience/viewing', {
        id,
        viewing: experience?.viewing
      });
    } catch (err) {
      console.error(err);
    }
  }

  const getOtherExperiences = async () => {
    try {
      const resp = await axiosClient.post('/experiences/filter', {
        filter: {id: {ne: id}},
        limit: 3,
        fields: ["id", "title", "likes", "liked_by", "image", "is_featured", "price_per_person"]
      });

      setOtherExperiences(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getSingleExperience = async () => {
      try {
        const resp = await axiosClient.get(`experience/${id}`);
        setExperience(resp.data.data);
      } catch (err) {
        console.error(err)
      }
  }

  useEffect(() => {
    const studentVerified = async () => {
        // const resources = await AllResources();
        // setResources(resources);
        await getSingleExperience()
        await getOtherExperiences();
    }

    studentVerified();

  }, []);

  useEffect(() => {
    addExperienceViewer()

    return () => {
      removeExperienceViewer()
    }
  }, []);

  return (
    <>
      <div className="single-item">
        <div className="head">
          <div className="back" onClick={() => navigate("/store")}>
            <X />
          </div>
          <div className="right">
            <Share />
            <MoreHorizontal />
          </div>
        </div>
        <div className="banner">
          <img src={experience?.image} alt="user-img" />
        </div>
        <div className="item-info">
          <div className="name">
            <div className="title">{experience?.title}</div>
            <div className="des">{getExperienceType(experience?.type)}</div>
            <div className="people-v">
              {(experience?.viewing !== 0) && (
                  <>
                    <i className="fas fa-fire"></i>
                    <span>{experience?.viewing} people are viewing right now</span>
                  </>
              )}
            </div>
          </div>
          <div className="likes" onClick={() => addLike(experience)}>
            <div className="circle">
              {(state[experience?.id] || experience?.liked_by?.includes(user?.id)) ? (
                <img
                  src={require("../../../Assets/images/heart-2.png")}
                  alt=""
                />
              ) : (
                <Heart />
              )}
            </div>
            <span>                
              {Object.keys(likes)?.includes(experience?.id) 
                ? likes[experience?.id] 
                : (experience?.likes || 0)
              }
            </span>
          </div>
        </div>
        <div className="des-sec">
          <div className="title">What You’ll Do</div>
          <div className="des">{experience?.description}</div>
        </div>
        <div className="des-sec border-top-0">
          <div className="title">What You’ll Need</div>
          {experience?.requirements?.map((elt, index) => (
            <div className="item" key={index}>
              <CheckCircle size={18} />
              <span>{elt}</span>
            </div>
          ))}
        </div>
        <div className="choose-session store-sec">
          <div className="sec-title" id="choose_session">
            Choose a Session
          </div>
          <div className="horizontal-items">
            {experience?.sessions?.map((elt, index) => (
              <div className="session" key={index}>
                <div className="date">{formatDateForSessions(elt?.date)}</div>
                <div className="time">
                  {calculateTimeWithPmOrAm(elt?.start_time)} -
                  {calculateTimeWithPmOrAm(elt?.end_time)}
                  (ET)
                </div>
                <div className="slots" type="down">
                  {elt?.slots} Slots Left
                </div>
                <div className="persons">
                  <img src={JICon} alt="icon" />
                  <span>{experience?.price_per_person} </span>
                  <small>/ person</small>
                </div>
                <div className="act">
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `/store/checkout?experience=${id}&session=${elt?.id}`
                      )
                    }
                  >
                    Choose
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="des-sec">
          <div className="title">Got Questions?</div>
          <div className="des">
            This experience has been organized by Junity in partnership with{" "}
            {getExperiencePartners(experience?.partners)}. If you have any
            questions about this experience, please reach Junity’s support team.
          </div>
          <div className="action-btn">
            <button>Get Help with this Experience</button>
          </div>
        </div>
        <div className="store-sec mb-5">
          <div className="sec-title">Similar Experiences</div>
          <div className="horizontal-items">
            {otherExperiences?.map((elt, index) => (
              <div className="item" key={index}>
                <div 
                  className="item-img"
                  onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                >
                  <img src={elt?.image} alt="store-img" />
                </div>
                <div className="item-info">
                  <div className="name">{elt?.title}</div>
                  <div
                    className="heart"
                    onClick={() => addLike(elt)}
                  >
                    {(state[elt?.id] || elt?.liked_by?.includes(user?.id)) ? (
                      <img
                        src={require("../../../Assets/images/heart-2.png")}
                        alt=""
                      />
                    ) : (
                      <Heart />
                    )}
                  </div>
                </div>
                <div
                  className="des"
                  onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                >
                  {Object.keys(likes)?.includes(elt?.id) 
                  ? (elt?.liked_by?.length || 0) 
                  : (elt?.likes || 0)
                  } {(elt?.liked_by?.length || 0) === 1 ? 'person likes' : 'people like'} this
                </div>
                <div
                  className="j-icon"
                  onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                >
                  <img src={JICon} alt="icon" />
                  {elt?.price_per_person}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="choose-session-fix">
          <div className="left">
            <img src={JICon} alt="icon" />
            <span>{experience?.price_per_person} </span>
            <small>/ person</small>
          </div>
          <div className="right">
            <button>
              <a href="#choose_session">Choose Session</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
