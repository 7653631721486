import React from 'react'
import { Link } from 'react-router-dom'

const ReportIssue = () => {
  return (
    <>
      <div className='change-s'>
        <div className="auth-sec">
          <div className="back-head">
            <Link to="/chat-person">
              <i className="fas fa-angle-left"></i>
            </Link>
          </div>
          <div className="heading">Report an Issue</div>
          <div className="sub-heading">
            We’re sorry you experienced a problem. Please let us know what went
            wrong so we can take proper action.
          </div>
          <form>
            <div className="form-auth">
              <label>What kind of issue?</label>
              <select>
                <option>Choose a category</option>
              </select>
            </div>
            <div className="form-auth">
              <label>Who is involved?</label>
              <input type="text" placeholder="Start typing a name..." />
              <p>Your report is anonymous.</p>
            </div>
            <div className="form-auth">
              <label>Describe the issue</label>
              <textarea
                rows={4}
                placeholder="Explain what happened and why you are reporting this incident. (It’s a good idea to mention who was involved.)"
              />
            </div>
            <div className="submit-login">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ReportIssue
