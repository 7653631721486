import React from 'react'
import { Link } from 'react-router-dom'
import GroupImg from '../../../Assets/images/org-img.jpg'
import { MessageSquare, AlertTriangle, Flag, VolumeX } from 'react-feather'
import './style.scss'

const AboutChatGroup = () => {
  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to="/chat-person">
            <span>{'<'}</span>
          </Link>
        </div>
        <div className="text text-left pl-3">About Connecticut JIs</div>
        <div className="icon"></div>
      </div>
      <div className="about-chat-group mt-5">
        <div className="bg-img">
          <img src={GroupImg} alt="group-img" />
        </div>
        <div className="sec-title">Group Description</div>
        <div className="sec-des">
          Welcome to the official Junity group chat for Junity Influencers in
          Connecticut. Connect with other JIs in Connecticut. Remember to make
          sure all your communication here conforms to our Code of Conduct and
          JIP Terms of Use to which you agreed or you may...
        </div>
        <div className="read-more">Read More</div>
        <div className="item green">
          <div className="icon">
            <MessageSquare size={18} />
          </div>
          <div className="info">
            <strong>Junity Influencers are co-operative. </strong>
            Contribute to group chats to help organize events and promote
            Junity’s mission.
          </div>
        </div>
        <div className="item yellow">
          <div className="icon">
            <AlertTriangle size={18} />
          </div>
          <div className="info">
            <strong>
              Junity Influencers are kind and respectful to others.
            </strong>{' '}
            Avoid inappropriate messages and hate speech.
          </div>
        </div>
      </div>
      <div className="members-group">
        <div className="title">In this Group (14)</div>
        <div className="item">
          <div className="placeholder active">JB</div>
          <div className="info">
            <div className="name">
              Jerome Bell
              <div className="label">Admin</div>
            </div>
            <div className="des">Hartford, CT</div>
          </div>
        </div>
        <div className="item">
          <div className="placeholder active">JB</div>
          <div className="info">
            <div className="name">
              Jerome Bell
              <div className="label">You</div>
            </div>
            <div className="des">Hartford, CT</div>
          </div>
        </div>
        <div className="item">
          <div className="placeholder active">JB</div>
          <div className="info">
            <div className="name">Jerome Bell</div>
            <div className="des">Hartford, CT</div>
          </div>
        </div>
        <div className="item">
          <div className="placeholder active">JB</div>
          <div className="info">
            <div className="name">Jerome Bell</div>
            <div className="des">Hartford, CT</div>
          </div>
        </div>
        <div className="item">
          <div className="placeholder active">JB</div>
          <div className="info">
            <div className="name">Jerome Bell</div>
            <div className="des">Hartford, CT</div>
          </div>
        </div>
      </div>
      <div className="action">
        <button>
          <VolumeX />
          Mute Chat Notifications
        </button>
        <button>
          <Flag />
          Report an Issue
        </button>
      </div>
    </>
  )
}

export default AboutChatGroup
