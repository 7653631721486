/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import NavTabs from '../Nav'
import RevokePop from '../../../../Components/Popus/RevokePopup'
import { GetSingleStudent } from '../../../../Services/query/students'
import { useSelector } from 'react-redux'
import { GetSingleSchool } from '../../../../Services/query/school'
import { GetStudentOpportunityApplicationBySchoolID } from '../../../../Services/query/studentOpportunityApplications'
import ApplicationWithdrawn from '../../../../Components/Popus/ApplicationWithdrawn'
import { ReactComponent as EmptyState } from '../../../../Assets/images/no-submissions.svg';



const ProfileSharing = () => {
  const [overlay, setOverlay] = useState(false);
  const user = useSelector((store) => store.user);
  const [school, setSchool] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [successfulWithdrawn, setSuccessfulWithdrawn] = useState(false);

  const revoke = (selected) => {
    setSchool(selected);
    setOverlay(true);
  }

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user.id);
      const schoolsMatched = await student?.profile_sharing?.reduce(async (previousPromise, schoolID) => {
        const school = await GetSingleSchool(schoolID);
        const studentApps = await GetStudentOpportunityApplicationBySchoolID(school.id);
        let schools = await previousPromise;
        const schoolAndApps = { ...school, studentApps }
        schools.push(schoolAndApps);
        return schools;
      }, Promise.resolve([]));
      setFilteredSchools(schoolsMatched);
    }
    studentVerified();
  }, [overlay]);


  return (
    <>
      <RevokePop 
        removeOverlay={() => setOverlay(false)} 
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
        school={school}
        user={user}
        setFilteredSchools={setFilteredSchools}
      />
      <ApplicationWithdrawn 
        removeOverlay={() => setSuccessfulWithdrawn(false)} 
        shown={successfulWithdrawn ? 'shown': 'hidden'}
        position={successfulWithdrawn ? 'slide-up' : 'slide-down'}
        header="Your Application has been withdrawn."
        text={`${school?.name} will no longer see your 
        application or have access to your profile.`}
      />
      <NavTabs />
      {filteredSchools?.length
          ? (
              <div className="auth-sec pb-6">
                <div className="des">
                  You are sharing your profile with the following organizations.
                </div>
                <form>
                  <div className="profile-sharng">
                    {filteredSchools?.map((elt) =>
                        <div className="item" key={elt?.id}>
                          <div className="logo">
                            <img src={elt?.image} alt="logo" />
                          </div>
                          <div className="info">
                            <div className="title">{elt?.name}</div>
                            <div className="des">
                              {elt.studentApps.length > 0 ? elt.studentApps.length : "No"} {" "}
                              application{elt.studentApps.length > 1 ? 's': ''} in progress
                            </div>
                          </div>
                          <div className="revoke" onClick={() => revoke(elt)}>
                            Revoke
                          </div>
                        </div>
                    )}
                  </div>
                </form>
              </div>

          ) : (
              <div className='empty-state-height d-flex justify-content-center align-items-center'>
                <div className="d-flex flex-column align-items-center">
                  <EmptyState />
                  <p className="text-center">You are not sharing your profile with any organizations.</p>
                </div>
              </div>
          )

      }
    </>
  )
}

export default ProfileSharing
