import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function FindCouncellorLoader() {
  return ( 
    <>
        <div className="items">
            <div className="row">
                <div className="col-6">
                    <div className='item'>
                        <div className="ins-logo border-0">
                            <Skeleton width={45} height={45} className='rounded-circle'/>
                        </div>
                        <div className='title'>
                            <Skeleton width={120} height={20}/>
                        </div>
                        <div className='label'>
                            <Skeleton width={100} height={17}/>
                        </div>
                        <div className="no-cons d-flex">
                            <Skeleton width={15} height={15}/>
                            <Skeleton width={80} height={15} className='ml-1'/>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='item'>
                        <div className="ins-logo border-0">
                            <Skeleton width={45} height={45} className='rounded-circle'/>
                        </div>
                        <div className='title'>
                            <Skeleton width={120} height={20}/>
                        </div>
                        <div className='label'>
                            <Skeleton width={100} height={17}/>
                        </div>
                        <div className="no-cons d-flex">
                            <Skeleton width={15} height={15}/>
                            <Skeleton width={80} height={15} className='ml-1'/>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='item'>
                        <div className="ins-logo border-0">
                            <Skeleton width={45} height={45} className='rounded-circle'/>
                        </div>
                        <div className='title'>
                            <Skeleton width={120} height={20}/>
                        </div>
                        <div className='label'>
                            <Skeleton width={100} height={17}/>
                        </div>
                        <div className="no-cons d-flex">
                            <Skeleton width={15} height={15}/>
                            <Skeleton width={80} height={15} className='ml-1'/>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='item'>
                        <div className="ins-logo border-0">
                            <Skeleton width={45} height={45} className='rounded-circle'/>
                        </div>
                        <div className='title'>
                            <Skeleton width={120} height={20}/>
                        </div>
                        <div className='label'>
                            <Skeleton width={100} height={17}/>
                        </div>
                        <div className="no-cons d-flex">
                            <Skeleton width={15} height={15}/>
                            <Skeleton width={80} height={15} className='ml-1'/>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}

export default FindCouncellorLoader;