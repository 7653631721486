import browser from "browser-detect";
import LaunchLogo from "../../Assets/images/launch-logo.svg";
import "./style.scss";
import ShareIcon from "../../Assets/images/share-icon.svg";
import { useState, useEffect } from "react";
import Verified from "../../Components/Popus/Verified";

function InstallApp() {
  const userBrowser = browser();
  const [success, setSuccess] = useState(false);
  const [promptEvent, setPromptEvent] = useState(null);

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PUBLIC_URL}/install`
    );
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const addToHomeScreen = () => {
    if (promptEvent !== null) {
      promptEvent.prompt();
    }
  };

  const criosSystem = () => {
    return (
      <>
        <div className="junity-text">
          For ease of use, this site can only be viewed in Safari. Tap the “Copy
          Link” button below to copy the URL then open Safari and paste it in
          the address bar.
        </div>
        <div className="center-btn">
          <div className="submit-btn">
            <button type="button" className="cus-btn" onClick={copyLink}>
              Copy Link
              <img src={require("../../Assets/images/link-icon.png")} alt="" />
            </button>
          </div>
        </div>
      </>
    );
  };

  const safariSystem = () => {
    return (
      <div className="share-text">
        Tap the
        <img src={ShareIcon} alt="Share" id="share-btn" />
        icon at the bottom of your screen, scroll down the pop-up menu until you
        see “Add to home screen” and hit add.
      </div>
    );
  };

  const androidSystems = () => {
    return (
      <>
        <div className="junity-text">
          For a better experience, add Junity to Home Screen.
        </div>
        <div className="center-btn">
          <div className="submit-btn">
            <button type="button" className="cus-btn" onClick={addToHomeScreen}>
              Add To Home Screen
            </button>
          </div>
        </div>
      </>
    );
  };

  const desktopSystems = () => {
    window.location.href = process.env.REACT_APP_DESKTOP_SYSTEM_LOGIN;
  };

  const returnSystemContent = (browserData) => {
    if (browserData.mobile) {
      switch (browserData.name) {
        case "crios":
          return criosSystem();
        case "safari":
          return safariSystem();
        default:
          return androidSystems();
      }
    } else {
      return desktopSystems();
    }
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setPromptEvent(e);
    });
  }, []);

  return (
    <>
      <div className={success ? "overlay_shown" : "overlay_hidden"}>
        <Verified
          removeOverlay={() => setSuccess(false)}
          header="Success!"
          text="Link Copied!"
        />
      </div>
      <div className="launch-screen mobile">
        <div className="logo-centered">
          <img src={LaunchLogo} alt="logo" />
        </div>
        {returnSystemContent(userBrowser)}
      </div>
    </>
  );
}

export default InstallApp;
