import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Person from "../../../Assets/images/counselor-img.jpg";
import JunityLogo from "../../../Assets/images/logo.png";
// import ComImg from "../../../Assets/images/org-img.jpg";
import Menu from "../../../Assets/images/dot-3.svg";
import Face from "../../../Assets/images/face-emoji.svg";
import Attach from "../../../Assets/images/attach-icon.svg";
import Clock from "../../../Assets/images/clock.svg";
import PersonInfo from "../../../Components/Popus/PersonInfo";
import ChatMenu from "../../../Components/Popus/ChatMenu";
import EmojiPicker from "emoji-picker-react";
import AttachmentModal from "./Components/AttachmentModal";
import {useDispatch, useSelector} from "react-redux";
import { Send } from "react-feather";
import { chatClient } from "../../../libs/axiosClient";
import { GetSingleSchool } from "../../../Services/query/school";
import {
  calculateTime,
  formatDate,
  formatMessageTime,
  getInitial,
  getRandomColor,
  messageCreatedDate,
  titleWords,
} from "../../../utils/helpers";
import { GetSingleChatHandler } from "../../../Services/query/chatHandler";
import { AllStudentOpportunityApplications } from "../../../Services/query/studentOpportunityApplications";
import { GetEducator } from "../../../Services/query/educators";
import {setRoutingStateDataAction} from "../../../Redux/actions/routingStateAction";
import "./style.scss";

const appDetails = (app) => {
  return (
    <div className="your-msg">
      <div className="post">
        <div className="com-img">
          <img
            src={app?.opportunity?.school?.banner || JunityLogo}
            alt="com-img"
          />
        </div>
        <div className="title">
          {app?.opportunity?.name}
          <div className="time">
            <img src={Clock} alt="icon" /> Closes {formatDate(app?.createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

const receiverMessage = (elt, index) => {
  return (
      <>
        <div className="user-msg" key={index}>
          <div className="bg">
            <div className="name">
              {JSON.parse(elt?.custom_json)["sender_name"]}
            </div>
            <div className="text">{elt.text}</div>
            <div className="time">{formatMessageTime(elt?.created)}</div>
          </div>
        </div>
        <div className="images-sects mb-2">
          {elt.attachments?.map((item, index) => (
              <img src={item?.file} alt="" key={index} width={100} height={100} />
          ))}
        </div>
      </>
  );
};

const senderMessage = (elt, index) => {
  return (
    <div className="your-msg" key={index}>
      <div className="images-sects mb-2">
        {elt.attachments?.map((item, index) => (
          <img src={item?.file} alt="" key={index} width={100} height={100} />
        ))}
      </div>
      <div className="bg">
        <div className="text">{elt.text}</div>
        <div className="time">{formatMessageTime(elt?.created)}</div>
      </div>
    </div>
  );
};

const SingleChat = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const [apps, setApps] = useState([]);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const type = search.get("type");

  useEffect(() => {
    document.body.style.background = "#FCFCFD";
  });

  const [overlay, setOverlay] = useState(false);
  const [overlay1, setOverlay1] = useState(false);
  const [chatDetails, setChatDetails] = useState({});
  const [school, setSchool] = useState({});
  const [state, setState] = useState("");
  const [messages, setMessages] = useState([]);
  const [toggleEmoji, setToggleEmoji] = useState(false);
  const [chatDateStarted, setChatDateStarted] = useState("");
  const [submittedAppDrop, setSubmittedAppDrop] = useState({});
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [imagesSelected, setImagesSelected] = useState([]);
  const [imagesSelectedUrls, setImagesSelectedUrls] = useState([]);
  const [counselor, setCounselor] = useState({});
  const chatDates = [];
  const colorPicked = useRef("");
  const bottomRef = useRef(null);
  const attachInputRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const getMessages = async () => {
    const messages = await chatClient.get(`chats/${id}/messages/`);
    setMessages(messages.data);
    scrollToBottom();
    // if (id) {
    //   if (messages.data.length > 0) {
    //     await chatClient.patch(`chats/${id}/people/`, {
    //       last_read: messages.data[messages.data.length - 1]?.id
    //     });
    //   }
    // }
  };

  function scrollToBottom() {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const appSub = (text, index, app) => {
    return (
      <>
        <div
          className="application-sub"
          onClick={() =>
            setSubmittedAppDrop({
              ...submittedAppDrop,
              [index]: !submittedAppDrop[index],
            })
          }
        >
          You {text} <label>{">"}</label>
        </div>
        {submittedAppDrop[index] && appDetailsLarger(app)}
      </>
    );
  };

  const messageSectionDateLabel = (elt) => {
    let chatDayJsx = null;
    if (
      !chatDates.includes(messageCreatedDate(formatMessageTime(elt?.created)))
    ) {
      chatDayJsx = (
        <div className="chat-day">
          {messageCreatedDate(formatMessageTime(elt?.created))}
        </div>
      );
      chatDates.push(messageCreatedDate(formatMessageTime(elt?.created)));
    } else {
      chatDayJsx = <></>;
    }
    return chatDayJsx;
  };

  const appDetailsLarger = (app) => {
    return (
      <div className="app-larger">
        <div className="post">
          <div className="com-img">
            <img
              src={app?.opportunity?.school?.banner || JunityLogo}
              alt="com-img"
            />
          </div>
          <div className="app-details">
            <div className="title">{app?.opportunity?.name}</div>
            <div className="app-status border-bottom mt-2 pb-2">
              <span className={`${app?.status}`}>
                {titleWords(app?.status)}
              </span>{" "}
              -<span className="time">{calculateTime(app?.createdAt)}</span>
            </div>
            <div className="org-details mt-1">
              <span className="logo-wrapper">
                <img src={app?.opportunity?.school?.image} alt="" />
              </span>
              <span className="org-name">{app?.opportunity?.school?.name}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const returnMessageLayout = (elt, userId, index) => {
    let messageJsx = null;
    const customJsonObj = JSON.parse(elt?.custom_json || "{}");
    if (Object.keys(customJsonObj)?.includes("type")) {
      if (customJsonObj.type === "app-sub") {
        const app = apps.find((elt) => elt.id === customJsonObj.appId);
        messageJsx = appSub(elt?.text, index, app);
      } else if (customJsonObj.type === "app-message") {
        const app = apps.find((elt) => elt.id === customJsonObj.appId);
        messageJsx = appDetails(app);
      } else {
        if (customJsonObj["sender_id"] === userId) {
          messageJsx = senderMessage(elt, index);
        } else {
          messageJsx = receiverMessage(elt, index);
        }
      }
    }

    return messageJsx === null ? <></> : messageJsx;
  };

  const sendMessage = async () => {
    try {
      if (state.trim() !== "") {
        const messageData = {
          text: state.trim(),
          custom_json: JSON.stringify({
            sender_id: user?.id,
            sender_name: user?.name,
            type: "message",
          }),
        };
        await chatClient.post(`chats/${id}/messages/`, messageData);
        setState("");
        getMessages();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const chatTitle = (chatDetails) => {
    if (type === "educator") {
      return JSON.parse(chatDetails.custom_json || "{}")?.destinationName;
    } else {
      return chatDetails?.title;
    }
  };

  const senderLogo = () => {
    let jsx = null;
    const bg =
      colorPicked.current !== "" ? colorPicked.current : getRandomColor();
    if (type === "educator") {
      const customObj = JSON.parse(chatDetails.custom_json || "{}");
      jsx = counselor?.image ? (
        <img src={counselor?.image} alt="user-img" />
      ) : (
        <div
          style={{
            backgroundColor: bg,
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          {getInitial(customObj?.destinationName)}
        </div>
      );
    } else {
      jsx = school?.image && <img src={school?.image} alt="user-img" />;
    }

    if (colorPicked.current === "") {
      colorPicked.current = bg;
    }

    return jsx;
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setImagesSelected(files);
    const imgUrls = Array.from(files).map((elt) => URL.createObjectURL(elt));
    setImagesSelectedUrls(imgUrls);
    setShowAttachmentModal(true);
  };

  const handleEmojiClick = (item) => {
    setState(state + item.emoji);
    setToggleEmoji(false);
  };

  const handleProfileClick = () => {
    if (type === "educator") {
      setOverlay(true);
    } else {
      dispatch(setRoutingStateDataAction({
        previousRoute: location.pathname + location.search,
      }));
      navigate(
        `/resource-profile?id=${
          JSON.parse(chatDetails.custom_json || "{}")?.orgID
        }`
      );
    }
  };

  useEffect(() => {
    chatClient.get(`chats/${id}/`).then((resp) => {
      const chatDetails = { ...resp.data };
      chatDetails.org_online = resp?.data?.people?.some(
        (elt) =>
          elt?.person?.username !== user?.id && elt?.person?.is_online === true
      );
      setChatDetails(chatDetails);

      GetEducator(
        JSON.parse(chatDetails?.custom_json || "{}")?.destinationID
      ).then((data) => setCounselor(data));

      GetSingleSchool(JSON.parse(resp.data.custom_json).orgID).then((school) =>
        setSchool(school)
      );
    });

    getMessages();

    GetSingleChatHandler(id).then((data) => {
      let socket = new WebSocket(
        `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${id}&accessKey=${data?.access_key}`
      );
      socket.onmessage = () => getMessages();
      setChatDateStarted(formatDate(data?.createdAt));
    });

    AllStudentOpportunityApplications()
      .then((apps) => setApps(apps))
      .catch((err) => console.error(err));
  }, [id]);

  useEffect(() => {
    let socket = new WebSocket(
      `wss://api.chatengine.io/person/?publicKey=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&username=${user?.id}&secret=${user?.id}`
    );
    socket.onopen = (event) => console.log('open', event);
    socket.onerror = (error) => console.log('error', error);
  }, []);

  return (
    <div>
      <PersonInfo
        info={counselor}
        removeOverlay={() => setOverlay(false)}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <ChatMenu
        type={type}
        chatId={chatDetails?.id}
        chatHandlerId={id}
        url={
          type === "educator"
            ? `/counselor-institute?id=${counselor?.schoolID}`
            : `/resource-profile?id=${
                JSON.parse(chatDetails.custom_json || "{}")?.orgID
              }`
        }
        removeOverlay={() => setOverlay1(false)}
        shown={overlay1 ? "shown" : "hidden"}
        position={overlay1 ? "slide-up" : "slide-down"}
      />
      <div className="position-fixed" style={{ bottom: 0, left: 0, right: 0 }}>
        <AttachmentModal
          chatId={id}
          user={user}
          images={imagesSelected}
          sendMessage={sendMessage}
          getMessages={getMessages}
          show={showAttachmentModal}
          setImages={setImagesSelected}
          imageUrls={imagesSelectedUrls}
          setImageUrls={setImagesSelectedUrls}
          onHide={() => setShowAttachmentModal(false)}
        />
      </div>
      <div className="single-chat">
        <div className="head">
          <div className="back" onClick={() => navigate("/chats")}>
            {"<"}
          </div>
          <div className="user-info" onClick={handleProfileClick}>
            <div className="user-img">
              {senderLogo()}
              <div className={chatDetails?.org_online && "active"}></div>
            </div>
            <div className="info">
              <div className="name">{chatTitle(chatDetails)}</div>
              <div className="status">
                {chatDetails?.org_online && "Online"}
              </div>
            </div>
          </div>
          <div className="menu" onClick={() => setOverlay1(true)}>
            <img src={Menu} alt="icon" />
          </div>
        </div>
        <div className="chat-body pt-5">
          <div className="first-alert mb-3">Chat started {chatDateStarted}</div>
          {/* <div className="unread-noti">
            1 unread message <label>{">"}</label>
          </div> */}

          {messages?.map(
            (elt, index) => (
              <>
                {messageSectionDateLabel(elt)}
                {returnMessageLayout(elt, user?.id, index)}
              </>
            )

            // <div className="new-join">
            //   <img src={Person} alt="i" />
            //   <span>Ronald Richards joined the chat</span>
            // </div>
          )}
          <div ref={bottomRef} />
        </div>
        <div className="chat-footer">
          <div className="icon" onClick={() => setToggleEmoji((prev) => !prev)}>
            <img src={Face} alt="icon" />
          </div>
          <div className="input-text">
            <input
              type="text"
              placeholder="Write a message..."
              value={state}
              onChange={(e) => setState(e.target.value)}
              onKeyDown={(e) => {
                if(e.key === 'Enter') {
                  sendMessage();
                }
              }}
            />
            <div className="icon">
              <img
                src={Attach}
                alt="icon"
                onClick={() => attachInputRef.current.click()}
              />
              <input
                type="file"
                name="attach"
                accept="image/*"
                ref={attachInputRef}
                onChange={handleAttachmentChange}
                style={{ display: "none" }}
                multiple
              />
            </div>
          </div>
          <div
            className="icon"
            onClick={sendMessage}
            style={{ pointerEvents: state.trim() === "" ? "none" : "auto" }}
          >
            <Send stroke={state.trim() === "" ? "#969CA5" : "#10408C"} />
          </div>
        </div>
      </div>
      {toggleEmoji && (
        <div>
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}
    </div>
  );
};

export default SingleChat;
