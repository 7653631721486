import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetStudentByEmail } from '../../../Services/query/students'
import isEmail from 'validator/lib/isEmail';


const RecoverEmail = () => {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSend = async () => {
    setLoading(true);
    try {
      const student = await GetStudentByEmail(email);
      if (Object.keys(student).length > 0) {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_URL}/account-recovery`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            name: student.name,
            phone: student.phone,
            email,
          })
        });
  
        if (resp.status === 200) {
          setLoading(false);
          navigate('/resend-link');
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setValid(isEmail(email))
  }, [email])

  return (
    <>
      <div className="auth-sec">
        <div className="back-head">
          <Link to="/login">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className="heading">Recover Your Account</div>
        <div className="sub-heading">
          Enter the email address associated with your Junity account and we’ll
          send account recovery instructions if we find your account.
        </div>
        <form>
          <div className="form-auth">
            <label>Email Address</label>
            <input 
              type="email" 
              placeholder="Enter your email address" 
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="submit-login" >
            <button 
              type="button"
              disabled={!valid || loading}
              style={{ opacity: valid ? 1 : 0.6 }}
              onClick={handleSend}>
              { loading ? "Loading..." : "Send Recovery Instructions" }
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default RecoverEmail
