import React from 'react'
import BottomNav from '../../../Components/BottomNav'
import Heart from '../../../Assets/images/heart.svg'
import PLogo from '../../../Assets/images/c-logo.png'
import './style.scss'

const SearchOpportunities = () => {
  return (
    <>
      <div className="oppertunities search-opper">
        <div className="search-field">
          <div className="search">
            <input type="text" placeholder="Search for an institution" defaultValue="Sum" />
            <i className="fas fa-search"></i>
          </div>
          <div className="search-text">Cancle</div>
        </div>
        <div className="select-interest pb-0 pt-3">
          <div className="item mb-0">
            <div className="selector m-0">
              <div className="selecotr-item">
                <input
                  type="radio"
                  id="All"
                  value="All"
                  name="a1"
                  className="selector-item_radio"
                  defaultChecked
                />
                <label htmlFor="All" className="selector-item_label">
                  All
                </label>
              </div>
              <div className="selecotr-item">
                <input
                  type="radio"
                  id="Education"
                  value="Education"
                  name="a1"
                  className="selector-item_radio"
                />
                <label htmlFor="Education" className="selector-item_label">
                  Education
                </label>
              </div>
              <div className="selecotr-item">
                <input
                  type="radio"
                  id="Employment"
                  value="Employment"
                  name="a1"
                  className="selector-item_radio"
                />
                <label htmlFor="Employment" className="selector-item_label">
                  Employment
                </label>
              </div>
              <div className="selecotr-item">
                <input
                  type="radio"
                  id="Mentorship"
                  value="Mentorship"
                  name="a1"
                  className="selector-item_radio"
                />
                <label htmlFor="Mentorship" className="selector-item_label">
                  Mentorship
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="search-suggestion">
          <div className="listing">
            <div className="item">
              <i className='fas fa-search'></i>
              <span>Summer</span>
            </div>
            <div className="item">
              <i className='fas fa-search'></i>
              <span>Summer School</span>
            </div>
            <div className="item">
              <i className='fas fa-search'></i>
              <span>Summit</span>
            </div>
          </div>
        </div>
        <div className="opper-list">
          <div className="head">
            <div className="label">Recently Searched</div>
            <div className="clear-all">Clear All</div>
          </div>
          <div className="item">
            <div className="item-head">
              <div className="p-logo">
                <div className="circle">
                  <img src={PLogo} alt="c-logo" />
                </div>
                <div className="heart">
                  <img src={Heart} alt="icon" />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="title">Summer School Mentorship</div>
              <div className="des">
                Lorem ipsum dolor sit amet, conset non adipiscing elit, sed do
                eiusmo ...
              </div>
              <div className="date-likes">
                <div className="date">3 days ago</div>
                <div className="dot"></div>
                <div className="likes">87 likes</div>
              </div>
            </div>
            <div className="p-type" type="Mentorship">
              Mentorship
            </div>
          </div>
          <div className="item">
            <div className="item-head">
              <div className="p-logo">
                <div className="circle">
                  <img src={PLogo} alt="c-logo" />
                </div>
                <div className="heart">
                  <img src={Heart} alt="icon" />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="title">Summer School Mentorship</div>
              <div className="des">
                Lorem ipsum dolor sit amet, conset non adipiscing elit, sed do
                eiusmo ...
              </div>
              <div className="date-likes">
                <div className="date">3 days ago</div>
                <div className="dot"></div>
                <div className="likes">87 likes</div>
              </div>
            </div>
            <div className="p-type" type="Mentorship">
              Mentorship
            </div>
          </div>
          <div className="item">
            <div className="item-head">
              <div className="p-logo">
                <div className="circle">
                  <img src={PLogo} alt="c-logo" />
                </div>
                <div className="heart">
                  <img src={Heart} alt="icon" />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="title">Summer School Mentorship</div>
              <div className="des">
                Lorem ipsum dolor sit amet, conset non adipiscing elit, sed do
                eiusmo ...
              </div>
              <div className="date-likes">
                <div className="date">3 days ago</div>
                <div className="dot"></div>
                <div className="likes">87 likes</div>
              </div>
            </div>
            <div className="p-type" type="Mentorship">
              Mentorship
            </div>
          </div>
          <div className="item">
            <div className="item-head">
              <div className="p-logo">
                <div className="circle">
                  <img src={PLogo} alt="c-logo" />
                </div>
                <div className="heart">
                  <img src={Heart} alt="icon" />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="title">Summer School Mentorship</div>
              <div className="des">
                Lorem ipsum dolor sit amet, conset non adipiscing elit, sed do
                eiusmo ...
              </div>
              <div className="date-likes">
                <div className="date">3 days ago</div>
                <div className="dot"></div>
                <div className="likes">87 likes</div>
              </div>
            </div>
            <div className="p-type" type="Mentorship">
              Mentorship
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  )
}

export default SearchOpportunities
