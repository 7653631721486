import React from "react";
import { useNavigate } from "react-router-dom";
import NoImg from "../../Assets/images/no-img.jpg";
import Person from "../../Assets/images/counselor-img.jpg";
import InsLogo from "../../Assets/images/ins-logo.png";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const PersonInfo = ({ removeOverlay, shown, position, info }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="person-info">
              <div className="person-img">
                <img src={info?.image || NoImg} alt="user-img" />
              </div>
              <div className="name">{info?.name}</div>
              <div className="label">
                <span>Counselor at</span>
                <img src={info?.school?.image} alt="ins-logo" />
                <span>{info?.school?.name}</span>
              </div>
              <div className="des">
                Chat with me about career advice, portfolio review and bi-weekly
                recommendations.
              </div>
              <div className="view-ins">
                <button onClick={() => navigate(`/counselor-institute?id=${info?.schoolID}`)}>
                  View Institution Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonInfo;
