import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function SelectInterestsLoader() {
  return ( 
    <>
      <div className="sub-app mt-5">
        <div className='d-flex justify-content-between my-2'>
          <Skeleton width={400} height={25}/>
        </div>
        <div className='d-flex mb-2'>
          <div className='d-flex'>
            <Skeleton width={80} height={20}/>
          </div>
        </div>
        <div className='d-flex'>
          <Skeleton width={400} height={15}/>
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
        <div>
          <div className='d-flex mt-4 mb-2'>
            <Skeleton width={200} height={20}/>
          </div>
          <div className='d-flex justify-content-between mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
          </div>
          <div className='d-flex justify-content-start mb-1 pb-2'>
              <Skeleton height={30} width={120} style={{borderRadius: 32}}/>
              <Skeleton height={30} width={120} style={{borderRadius: 32, marginLeft: "20px"}}/>
          </div>       
        </div>
      </div>
    </>
  );
}

export default SelectInterestsLoader;