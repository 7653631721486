import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AllResources  } from '../../Services/query/resources'
import { AllOpportunitiesAPI } from '../../Services/query/opportunities'
import { GetStudentOpportunityApplicationByStudentID } from '../../Services/query/studentOpportunityApplications'
import { calculateTime, titleWords } from '../../utils/helpers'
import { ReactComponent as EmptyState } from '../../Assets/images/no-submissions.svg';
import './style.scss'
import HomeSkeletonLoader from '../../Components/Common/HomeLoader/HomeLoader'


const SubmittedApplications = () => {
  const user = useSelector((store) => store.user);
  const navigate = useNavigate()
  const [opps, setOpps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  const filterOpps = (app, field) => {
    const filteredOpp = opps?.filter(elt => elt?.id === app?.opportunityID)[0];
    const resource = resources?.filter((elt) => elt?.id === filteredOpp?.resourceID)[0];
    if (field === 'resourceName') {
      return resource ? resource?.name : ""
    }
    return filteredOpp ? filteredOpp[field] : ""
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const opps = await AllOpportunitiesAPI();
      setOpps(opps);
      const resources = await AllResources();
      setResources(resources);
      const filteredApps = await GetStudentOpportunityApplicationByStudentID(user?.id);
      setFilteredApps(filteredApps);
      setLoading(false);
    }

    handleSetDependencies();

  }, [user])
  

  return (
    <>
      {loading
      ? <HomeSkeletonLoader/>
      : (<>
        <div className="noti-head">
          <div className="icon">
            <Link to="/accounts">
              <span>{'<'}</span>
            </Link>
          </div>
          <div className="text">Submitted Applications</div>
          <div className="icon"></div>
        </div>
        {filteredApps?.length
            ? (<div className="sub-applications mt-5">
          {filteredApps.map((elt, index) => 
          <div
            className="item"
            onClick={() => navigate(`/apply-for-opportunity?id=${elt.opportunityID}`)}
            key={index}
          >
            <div className="logo">
              <img src={elt?.opportunity?.school?.image} alt="icon" />
            </div>
            <div className="name">
              {filterOpps(elt, "name")}
            </div>
            <div className="label">
              {filterOpps(elt, "resourceName")}
            </div>
            <div className="status">
              <div className="status-s" type={elt?.status}>
                {titleWords(elt?.status)}
              </div>
              <div className="dot"></div>
              <div className="sub-day">{calculateTime(elt?.createdAt)}</div>
            </div>
          </div>
          )}
        </div>)
        : (
            <div className='empty-state-height d-flex justify-content-center align-items-center'>
              <div className="d-flex flex-column">
                <EmptyState />
                <p>No Submitted Applications</p>
              </div>
            </div>
            )}
      </>)}
    </>
  )
}

export default SubmittedApplications
