import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImgTwo from '../../../Assets/images/scene-3.webp'

import './style.scss'
import WelcomePreloader from '../../../Components/Common/WelcomePreloaders'

const SplitThree = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.background= "linear-gradient(0deg, #daf8ee33, #daf8ee33), #ffffff"
  })


  return (
    <>
    {loading && <WelcomePreloader />}
    <div 
      className="onbording-screen bg-three"
      style={{ display: loading ? "none" : "block" }}
    >
      <div className="img my-0">
        <img 
          src={ImgTwo} 
          alt="classroom img" 
          onLoad={() => setLoading(false)}
        />
      </div>
      <div className="text">
        <div className="heading">Equipping you to explore</div>
        <div className="sub-heading">
          Providing you with the tools to find your passion.
        </div>
      </div>
      <div className="indicators">
        <div className="item"></div>
        <div className="item"></div>
        <div className="item active"></div>
      </div>
      <div className="create-account">
        <Link to="/sign-up" className="button">
          Create Account
        </Link>
        <p>
          <Link to="/login">Already have an account? Log In</Link>
        </p>
      </div>
    </div>
    </>
  )
}

export default SplitThree
