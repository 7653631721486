import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MuiltiFiles from "../../Assets/images/multiple-files.svg";
import { AllOpportunitiesAPI } from "../../Services/query/opportunities";
import "./style.scss";
import { useAuth } from "../../Components/Auth";

const LikedOpportunities = () => {
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const [likedOppsBreakDown, setLikedOppsBreakDown] = useState({});
  const [likedOpps, setLikedOpps] = useState([]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const opps = await AllOpportunitiesAPI();
      const likedOpps = opps?.filter((elt) => elt?.liked_by?.includes(user.id));
      setLikedOpps(likedOpps);

      const likedOppsBreakDown = {};
      likedOpps?.forEach((opps) => {
        opps?.interests?.forEach((interest) => {
          if (Object.keys(likedOppsBreakDown).includes(interest)) {
            likedOppsBreakDown[interest] += 1;
          } else {
            likedOppsBreakDown[interest] = 1;
          }
        });
      });

      setLikedOppsBreakDown(likedOppsBreakDown);
    };

    handleSetDependencies();
  }, []);

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Liked Opportunities </div>
        <div className="icon"></div>
      </div>
      <div className="like-opp-grid mt-5">
        <div className="row">
          {/* <div className="col-6" onClick={() => navigate("/liked-items")}>
            <div
              className="img-bg"
              style={{
                backgroundImage: `url(${require("../../Assets/images/org-img.jpg")})`,
              }}
            >
              <div className="count">
                <img src={MuiltiFiles} alt="icon" />
                <span>{likedOpps?.length || 0}</span>
              </div>
            </div>
            <div className="label">All Opportunities</div>
          </div> */}
          <div className="col-6" onClick={() => navigate(`/liked-items`)}>
            <div className="liked-img-bg">
              <div className="count-icon">
                <img src={MuiltiFiles} alt="icon" />
                <span>{likedOpps?.length || 0}</span>
              </div>
              <div className="liked-opp-img-container">
                <img
                  src={require(`../../Assets/images/opportunities/junity-logo.png`)}
                  alt="All Opportunities"
                  className="liked-opp-all-img"
                />
              </div>
            </div>
            <div className="label">All Opportunities</div>
          </div>
          {Object.keys(likedOppsBreakDown)?.map((elt, index) => (
            <div
              className="col-6"
              onClick={() => navigate(`/liked-items?interest=${elt}`)}
              key={index}
            >
              <div className="liked-img-bg">
                <div className="count-icon">
                  <img src={MuiltiFiles} alt="icon" />
                  <span>{likedOppsBreakDown[elt]}</span>
                </div>
                <div className="liked-opp-img-container">
                  <img
                    src={require(`../../Assets/images/opportunities/${elt
                      .toLowerCase()
                      .split(" ")
                      .join("-")}.png`)}
                    alt={elt}
                    className="liked-opp-img"
                  />
                </div>
              </div>
              <div className="label">{elt}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LikedOpportunities;
