import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosClient } from "../../libs/axiosClient";
import { useSelector } from "react-redux";
import "./style.scss";
import { useAuth } from "../../Components/Auth";

const NotificationSettings = () => {
  const user = useSelector((store) => store.user);
  const [state, setState] = useState({});
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const createOrUpdateNotificationSettings = async (payload) => {
    try {
      await axiosClient.put("/notification-settings", payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserNotificationSettings = async () => {
    try {
      const res = await axiosClient.post("/notification-settings", {
        youthId: user?.id,
      });
      setState(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = async (e) => {
    const payload = { ...state };
    payload[e.target.name] = !payload[e.target.name];
    await createOrUpdateNotificationSettings(payload);
    setState(payload);
  };

  useEffect(() => {
    getUserNotificationSettings();
  }, []);

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Notification Settings</div>
        <div className="icon"></div>
      </div>
      <div className="noti-settings">
        <div className="des">
          You should keep notifications on so you don’t miss important updates.
        </div>
        <div className="c-des">
          But, we would understand if you had to turn some OFF.
        </div>
        <div className="item-list">
          <div className="title">Push Notifications</div>
          <div className="item">
            <div className="label">Expiring Opportunities</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c1"
                  name="push_expiring_opportunities"
                  onClick={handleChange}
                  checked={state?.push_expiring_opportunities === true}
                />
                <label className="custom-control-label" htmlFor="c1"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">Updates on Your Applications</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c2"
                  name="push_update_application"
                  onClick={handleChange}
                  checked={state?.push_update_application === true}
                />
                <label className="custom-control-label" htmlFor="c2"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">New Chat Messages</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c3"
                  name="push_new_chat"
                  onClick={handleChange}
                  checked={state?.push_new_chat === true}
                />
                <label className="custom-control-label" htmlFor="c3"></label>
              </div>
            </div>
          </div>
        </div>
        <div className="item-list">
          <div className="title">Email Notifications</div>
          <div className="item">
            <div className="label">Expiring Opportunities</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c4"
                  name="email_expiring_opportunities"
                  onClick={handleChange}
                  checked={state?.email_expiring_opportunities === true}
                />
                <label className="custom-control-label" htmlFor="c4"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">Updates on Your Applications</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c5"
                  name="email_update_application"
                  onClick={handleChange}
                  checked={state?.email_update_application === true}
                />
                <label className="custom-control-label" htmlFor="c5"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">New Chat Messages</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c6"
                  name="email_new_chat"
                  onClick={handleChange}
                  checked={state?.email_new_chat === true}
                />
                <label className="custom-control-label" htmlFor="c6"></label>
              </div>
            </div>
          </div>
        </div>
        <div className="item-list">
          <div className="title">SMS Notifications</div>
          <div className="item">
            <div className="label">Expiring Opportunities</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c7"
                  name="sms_expiring_opportunities"
                  onClick={handleChange}
                  checked={state?.sms_expiring_opportunities === true}
                />
                <label className="custom-control-label" htmlFor="c7"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">Updates on Your Applications</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c8"
                  name="sms_update_application"
                  onClick={handleChange}
                  checked={state?.sms_update_application === true}
                />
                <label className="custom-control-label" htmlFor="c8"></label>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">New Chat Messages</div>
            <div className="custom-control custom-switch">
              <div className="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="c9"
                  name="sms_new_chat"
                  onClick={handleChange}
                  checked={state?.sms_new_chat === true}
                />
                <label className="custom-control-label" htmlFor="c9"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSettings;
