/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import BottomNav from "../../Components/BottomNav";
import Filter from "../../Assets/images/filter.svg";
import Heart from "../../Assets/images/heart.svg";
import "./style.scss";
import FilterItems from "../../Components/Popus/FilterItems";
import { AllOpportunitiesAPI } from "../../Services/query/opportunities";
import {
  calculateTime,
  PopularInYourArea,
} from "../../utils/helpers";
import { setUserDataAction } from "../../Redux/actions/userActions";
import { AddLike } from "../../utils/common";
import { HandleFilter } from "./utils";
import HomeSkeletonLoader from "../../Components/Common/HomeLoader/HomeLoader";
import OpportunitiesLoader from "../../Components/Common/OpportunitiesLoader";
import { setLoadersDataAction } from "../../Redux/actions/loadersAction";
import { useLayoutEffect } from "react";
import { setHomeOpportunityDataAction } from "../../Redux/actions/homePageOpportunities";
import { API } from "aws-amplify";
import { onCreateOpportunity, onUpdateOpportunity, onUpdateSchool } from "../../graphql/subscriptions";
import { ReactComponent as EmptyState } from '../../Assets/images/no-opportunities.svg';
import {setRoutingStateDataAction} from "../../Redux/actions/routingStateAction";

const Opportunities = () => {
  const user = useSelector((store) => store.user);
  const loaders = useSelector((store) => store.loaders);
  const homeOpps = useSelector((store) => store.homeOpps);
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [userLikedOpps, setUserLikedOpps] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [toastLoading, setToastLoading] = useState(false);
  const [opps, setOpps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const [filteredSearch, setFilteredSearch] = useState(homeOpps.mainOpps);
  const [headerTextState, setHeaderTextState] = useState("");

  const [sameInterestOpps, setSameInterestOpps] = useState(homeOpps.mainOpps);

  const handleSelect = (value) => {
    if (value === "All") {
      setFilteredSearch(sameInterestOpps);
    } else {
      setFilteredSearch(sameInterestOpps.filter((elt) => elt.category === value));
    }
    setActiveTab(value);
  };

  const updateOpps = (id, newLikes, oldLikedBy) => {

    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = []
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id)
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    } 
    
    setOppsLikedBy(updatedLikedBy);
  }

  const addLike = (elt) => {
    const addLikeProps = {
      state, 
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading
    }

    AddLike(addLikeProps);
  };

  const handleFilter = (filterBy, filterParams, reset) => {
    const handleFilterProps = {
      setFilteredSearch,
      setHeaderTextState,
      reset,
      filterBy,
      filterParams,
      setOverlay,
      sameInterestOpps
    }

    HandleFilter(handleFilterProps);
  };

  const updateDependencies = async (loaded) => {
    setLoading(loaded);
    const opps = await AllOpportunitiesAPI();
    const userLikedOpps = opps?.filter((elt) => elt?.liked_by?.includes(user?.id));
    const nonExpiredOpps = opps?.filter((elt) => new Date(elt?.expiry_date) > new Date());
    setUserLikedOpps(userLikedOpps?.length);
    dispatch(setUserDataAction(user));
    dispatch(setLoadersDataAction({ opportunities: true }));
    setOpps(nonExpiredOpps);
    const sameInterestOpps = await PopularInYourArea(user, nonExpiredOpps, "interests");
    setSameInterestOpps(sameInterestOpps);
    setFilteredSearch(sameInterestOpps);
    dispatch(setHomeOpportunityDataAction({ 
      mainOpps: sameInterestOpps,
      expiredOpps: opps?.filter((elt) => new Date(elt?.expiry_date) < new Date())
    }));
    setLoading(false);
    setRefetch(false);
  }

  const routeToResourceProfile = (elt) => {
    dispatch(setRoutingStateDataAction({
      previousRoute: location.pathname + location.search
    }));
    navigate(`/resource-profile?id=${elt?.school?.id}`)
  }

  const routeToOpportunityDetail = (elt) => {
    dispatch(setRoutingStateDataAction({
      singleOpportunityPreviousRoute: location.pathname + location.search
    }));
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  }

  const returnOpportunitiesList = () => {
    if (!loading) {
      if (filteredSearch?.length) {
        return filteredSearch?.map((elt, index) =>
            <div className={`item`} key={index}>
              <div className="item-head">
                <div className="p-logo">
                  <img
                      src={elt?.school?.image}
                      className="circle"
                      alt="c-logo"
                      onClick={() => routeToResourceProfile(elt)}
                  />
                  <div
                      className={`heart ${
                          user?.verification_status === "verified"
                              ? ""
                              : "inactive"
                      }`}
                      onClick={() => addLike(elt)}
                  >
                    {(state[elt?.id] || elt?.liked_by?.includes(user?.id)) ? (
                        <i className="fas fa-heart"></i>
                    ) : (
                        <img src={Heart} alt="icon"/>
                    )}
                  </div>
                </div>
              </div>
              <div
                  className="info"
                  onClick={() => routeToOpportunityDetail(elt)}
              >
                <div className="title">{elt?.name}</div>
                <div className="des break-overview">{elt?.description}</div>
                <div className="date-likes">
                  <div className="date">{calculateTime(elt?.createdAt)}</div>
                  <div className="dot"></div>
                  <div className="likes">
                    {Object.keys(likes)?.includes(elt?.id)
                        ? likes[elt?.id]
                        : (elt?.likes || 0)
                    } {likes[elt?.id] === 1 ? ' like' : ' likes'}
                  </div>
                </div>
              </div>
              <div className="p-type mr-1" type={elt?.category} key={index}>
                {elt?.category}
              </div>
            </div>
        )
      } else {
        return (
          <div className='d-flex justify-content-center align-items-center w-100 mt-4' style={{
            height: "40vh"
          }}>
            <div className="d-flex flex-column">
              <EmptyState />
              <p>No Opportunities</p>
            </div>
          </div>
        )
      }
    }
  }

  useEffect(() => {
    updateDependencies(false);
  }, [refetch]);
  

  useEffect(() => {
    let subscription1;
    let subscription2;
    let subscription3;
  
    if (Object.keys(homeOpps?.mainOpps || {}).length === 0) {
      updateDependencies(true);
    }
  
    setTimeout(() => setLoading(false), 2000);
    dispatch(setLoadersDataAction({ opportunities: true }));
    setLoading(false);
  
    // Subscription 1: onCreateOpportunity
    subscription1 = API.graphql({
      query: onCreateOpportunity,
    }).subscribe({
      next: () => {
        setRefetch(true);
      },
    });
  
    // Subscription 2: onUpdateSchool
    subscription2 = API.graphql({
      query: onUpdateSchool,
    }).subscribe({
      next: () => {
        setRefetch(true);
      },
    });
  
    // Subscription 3: onUpdateOpportunity
    subscription3 = API.graphql({
      query: onUpdateOpportunity,
    }).subscribe({
      next: () => {
        setRefetch(true);
      },
    });
  
    // Return cleanup functions to unsubscribe from subscriptions
    return () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, []);
  
  useLayoutEffect(() => {
    if (searchKey.trim() !== "") {
      const re = new RegExp(searchKey.toLowerCase(), "g");
      const matches = opps?.filter(
        (elt) => elt.name.toLowerCase().match(re) !== null
      );
      setFilteredSearch(matches);
    } else {
      setFilteredSearch(sameInterestOpps);
    }

  }, [searchKey]);

  return (
    <>
      {loaders?.opportunities !== true
      ? <HomeSkeletonLoader/>
      : <> 
          <FilterItems 
            removeOverlay={handleFilter} 
            shown={overlay ? 'shown': 'hidden'}
            position={overlay ? 'slide-up' : 'slide-down'}
          />
          <div className="oppertunities">
            <div className="head">
              <div className="title">Opportunities</div>
              <div
                className={`like ${
                  user?.verification_status === "verified" ? "" : "inactive"
                }`}
                onClick={() => navigate("/liked-opportunities")}
              >
                <i className="fas fa-heart"></i>
                <span>{userLikedOpps}</span>
              </div>
            </div>
            <div className={`search`}>
              <input
                type="text"
                placeholder="Search for an opportunity..."
                name="searchKey"
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <i className="fas fa-search"></i>
            </div>
            <div className="select-interest pb-0 pt-3">
              <div className={`item mb-0`}>
                <div className="selector m-0">
                  <div className="selecotr-item">
                    <input
                      type="radio"
                      id="All"
                      value="All"
                      name="a1"
                      className="selector-item_radio"
                      onChange={() => handleSelect("All")}
                      defaultChecked
                    />
                    <label htmlFor="All" className="selector-item_label">
                      All
                    </label>
                  </div>
                  <div className="selecotr-item">
                    <input
                      type="radio"
                      id="Education"
                      value="Education"
                      name="a1"
                      className="selector-item_radio"
                      onChange={() => handleSelect("Education")}
                    />
                    <label htmlFor="Education" className="selector-item_label">
                      Education
                    </label>
                  </div>
                  <div className="selecotr-item">
                    <input
                      type="radio"
                      id="Employment"
                      value="Employment"
                      name="a1"
                      className="selector-item_radio"
                      onChange={() => handleSelect("Employment")}
                    />
                    <label htmlFor="Employment" className="selector-item_label">
                      Employment
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="opper-list">
              <div className="head">
                <div className="label">
                  Showing{" "}
                  {filteredSearch?.length}{" "}
                  {headerTextState ||
                    (filteredSearch?.length > 1 ? "opportunities" : "opportunity")}
                </div>
                <div className={`filter`} onClick={() => setOverlay(true)}>
                  <img src={Filter} alt="icon" />
                  Filter
                </div>
              </div>
              {loading
              ? <OpportunitiesLoader/>
              : null}
              {returnOpportunitiesList()}
            </div>
          </div>
          <BottomNav />
        </>
      }
    </>
  );
};

export default Opportunities;
