/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Heart, MoreHorizontal, Search } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JICon from "../../Assets/images/jCoin.svg";
import StoreOption from "../../Components/Popus/StoreOptions";
import { GetSingleStudent } from "../../Services/query/students";
import "./style.scss";
import { setUserDataAction } from "../../Redux/actions/userActions";
import { AddExperienceLike } from "./util";
import { axiosClient } from "../../libs/axiosClient";
import {
  GetExperienceLikedByStudent,
  GetFeaturedExperiences,
} from "../../Services/query/experiences";
import { useAuth } from "../../Components/Auth";

const JunityStoreHome = () => {
  const user = useSelector((store) => store.user);
  const [toastLoading, setToastLoading] = useState(false);
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [student, setStudent] = useState({});
  const [state, setState] = useState({});
  const [overlay, setOverlay] = useState(false);
  const [featuredExperiences, setFeaturedExperiences] = useState([]);
  const [notFeaturedExperiences, setNotFeaturedExperiences] = useState([]);
  const [likedByExperiences, setLikedByExperiences] = useState([]);
  const [storeSearch, setStoreSearch] = useState("");
  const [searchedFeatured, setSearchedFeatured] = useState([]);
  const [searchedNotFeatured, setSearchedNotFeatured] = useState([]);

  const { navigateToPreviousRoute } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = async (experience) => {
    const addExperienceLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      experience,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddExperienceLike(addExperienceLikeProps);
  };

  const getAllExperiences = async () => {
    try {
      const resp = await axiosClient.post("/experiences", {
        fields: [
          "id",
          "image",
          "title",
          "likes",
          "liked_by",
          "is_featured",
          "price_per_person",
        ],
      });
      const experienceData = resp.data.data;
      setNotFeaturedExperiences(experienceData);
      setSearchedNotFeatured(experienceData);
      const featuredExperiences = await GetFeaturedExperiences();
      setFeaturedExperiences(featuredExperiences);
      setSearchedFeatured(featuredExperiences);
      const likedByStudent = GetExperienceLikedByStudent(user?.id);
      setLikedByExperiences(likedByStudent);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user?.id);
      if (student !== undefined && Object.keys(student || {}).length) {
        dispatch(setUserDataAction(student));
        setStudent(student);
      }

      getAllExperiences();
    };

    studentVerified();
  }, [state]);

  useEffect(() => {
    if (storeSearch.trim() !== "") {
      const re = new RegExp(storeSearch.toLowerCase(), "g");
      const featuredMatches = featuredExperiences?.filter(
        (elt) => elt.title.toLowerCase().match(re) !== null
      );
      const notFeaturedMatches = notFeaturedExperiences?.filter(
        (elt) => elt.title.toLowerCase().match(re) !== null
      );
      setSearchedFeatured(featuredMatches);
      setSearchedNotFeatured(notFeaturedMatches);
    } else {
      setSearchedFeatured(featuredExperiences);
      setSearchedNotFeatured(notFeaturedExperiences);
    }
  }, [storeSearch]);

  return (
    <>
      <StoreOption
        removeOverlay={() => setOverlay(false)}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <div className="store-sec">
        <div className="store-head">
          <div className="back" onClick={navigateToPreviousRoute}>
            {"<"}
          </div>
          <div className="title">Store</div>
          <div className="action-r">
            <div className="j-icon">
              <img src={JICon} alt="icon" />
              <span>{(student?.balance || 0).toFixed(2)}</span>
            </div>
            <div
              className="likes"
              onClick={() => navigate("/store/liked-items")}
            >
              <Heart size={16} />
              <span>{likedByExperiences?.length}</span>
            </div>
            <div className="menu" onClick={() => setOverlay(true)}>
              <MoreHorizontal />
            </div>
          </div>
        </div>
        <div
          className={`store-search ${
            student?.verification_status === "verified" ? "" : "inactive"
          }`}
        >
          <div className="search">
            <Search size={18} />
            <input
              type="text"
              placeholder="Search"
              name="storeSearch"
              onChange={(e) => setStoreSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="sec-title">Featured Experiences</div>
        <div className="horizontal-items">
          {searchedFeatured?.map((elt, index) => (
            <div
              className={`item ${
                student?.verification_status === "verified" ? "" : "inactive"
              }`}
              key={index}
            >
              <div
                className="item-img"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                <img src={elt?.image} alt="store-img" />
              </div>
              <div className="item-info">
                <div
                  className="name"
                  onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                >
                  {elt?.title}
                </div>
                <div className="heart" onClick={() => addLike(elt)}>
                  {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                    <img
                      src={require("../../Assets/images/heart-2.png")}
                      width="20px"
                      height="20px"
                      alt=""
                    />
                  ) : (
                    <Heart />
                  )}
                </div>
              </div>
              <div
                className="des"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                {Object.keys(likes)?.includes(elt?.id)
                  ? elt?.liked_by?.length || 0
                  : elt?.likes || 0}{" "}
                {elt?.liked_by?.length === 1 ? "person likes" : "people like"}{" "}
                this
              </div>
              <div
                className="j-icon"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                <img src={JICon} alt="icon" />
                {elt?.price_per_person}
              </div>
            </div>
          ))}
        </div>
        <div className="sec-title">All Experiences</div>
        <div className="horizontal-items">
          {searchedNotFeatured?.map((elt, index) => (
            <div
              className={`item ${
                student?.verification_status === "verified" ? "" : "inactive"
              }`}
              key={index}
            >
              <div
                className="item-img"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                <img src={elt?.image} alt="store-img" />
              </div>
              <div className="item-info">
                <div
                  className="name"
                  onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
                >
                  {elt?.title}
                </div>
                <div className="heart" onClick={() => addLike(elt)}>
                  {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                    <img
                      src={require("../../Assets/images/heart-2.png")}
                      width="20px"
                      height="20px"
                      alt=""
                    />
                  ) : (
                    <Heart />
                  )}
                </div>
              </div>
              <div
                className="des"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                {Object.keys(likes)?.includes(elt?.id)
                  ? elt?.liked_by?.length || 0
                  : elt?.likes || 0}{" "}
                {elt?.liked_by?.length === 1 ? "person likes" : "people like"}{" "}
                this
              </div>
              <div
                className="j-icon"
                onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
              >
                <img src={JICon} alt="icon" />
                {elt?.price_per_person}
              </div>
            </div>
          ))}
        </div>
        <div className="end">You’ve reached the end.</div>
      </div>
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
      />
    </>
  );
};

export default JunityStoreHome;
