import { gql } from "@apollo/client";

export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity(
    $id: ID!
    $likes: Int
    $liked_by: [ID!]
    $views: [ID]
  ) {
    updateOpportunity(
      input: {
        id: $id
        likes: $likes
        liked_by: $liked_by
        views: $views
      }
    ) {
      id
      name
      description
      tag
      expiry_date
      facilitatorIDs
      facilitators
      createdAt
      questions
      duration
      resourceID
      automatic_message
      state
      liked_by
      remote
      likes
      views
      category
      interests
    }
  }
`;

