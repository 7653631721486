/* eslint-disable import/no-webpack-loader-syntax */
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

import InProgressVerification from "./Screens/AccountVerification/InProgress";
import SendVerification from "./Screens/AccountVerification/SendVerification";
import RecoverEmail from "./Screens/Auth/RecoverEmail";
import ResendRecoverEmail from "./Screens/Auth/ResendRecovery";
import ResetPassword from "./Screens/Auth/ResetPassword";
import Login from "./Screens/Auth/SignIn/Login";
import NoLogin from "./Screens/Auth/SignIn/NoLogin";
import SignUp from "./Screens/Auth/SignUp";
import LastStep from "./Screens/Auth/SignUp/LastStep";
import SecondStep from "./Screens/Auth/SignUp/SecondStep";
import SocialSignUp from "./Screens/Auth/SocialSignUp";
import CounselorIns from "./Screens/CounselerInstitute";
import FindCounselor from "./Screens/FindCounselor";
import GettingStart from "./Screens/GettingStart";
import Home from "./Screens/Home";
import LogoutPage from "./Screens/Auth/LogoutPage";
import Notifications from "./Screens/Notifications";
import SelectInterest from "./Screens/SelectInterest";
import LaunchScreen from "./Screens/SplitScreen/LaunchScreen";
import SplitOne from "./Screens/SplitScreen/ScreenOne";
import SplitThree from "./Screens/SplitScreen/ScreenThree";
import SplitTwo from "./Screens/SplitScreen/ScreenTwo";
import ThingsToDO from "./Screens/ThingsToDo";
import Opportunities from "./Screens/Opportunities";
import SearchOpportunities from "./Screens/Opportunities/SearchOppertunity";
import AccountScreen from "./Screens/Account";
import ChatScreen from "./Screens/Chats";
import Personal from "./Screens/Account/Tabs/Personal";
import InterestTab from "./Screens/Account/Tabs/Interest";
import EducationTab from "./Screens/Account/Tabs/Education";
import CareerTab from "./Screens/Account/Tabs/Career";
import ProfileSharing from "./Screens/Account/Tabs/ProfileSharing";
import SingleChat from "./Screens/Chats/SingleChat";
import AccountSettings from "./Screens/AccountSettings";
import ChangePassword from "./Screens/AccountSettings/ChangePassword";
import ChangePhone from "./Screens/AccountSettings/ChangePhone";
import ChangeEmail from "./Screens/AccountSettings/ChangeEmail";
import NotificationSettings from "./Screens/NotificationSettings";
import SubmittedApplications from "./Screens/SubmittedApplication";
import SingleApplication from "./Screens/NotificationSettings/single";
import LikedOpportunities from "./Screens/LikedOpportunities";
import LikedItems from "./Screens/LikedOpportunities/LikedItems";
import RecommendedItems from "./Screens/RecommendedOpper";
import ApplyOnOpportunity from "./Screens/ApplyOpportunity";
import SendProfile from "./Screens/ApplyOpportunity/SendProfile";
import CompleteProfile from "./Screens/ApplyOpportunity/CompleteProfile";
import AdditionalInfo from "./Screens/ApplyOpportunity/AdditionalInfo";
import InfluencerProgram from "./Screens/InfluencerProgram";
import VerifyEmail from "./Components/EmailVerification";
import ConnectingCounelor from "./Screens/ConnectingConselor";
import ChooseCounselor from "./Screens/ChooseCounselor";
import ResourceProfile from "./Screens/ResourceProfile";
import ReportIssue from "./Screens/Chats/ReportIssue";
import AboutChatGroup from "./Screens/Chats/AboutGroup";
import JunityStoreHome from "./Screens/JunityStore";
import SingleItem from "./Screens/JunityStore/SingleItem";
import CheckoutJunityItem from "./Screens/JunityStore/Checkout";
import ItemPurchased from "./Screens/JunityStore/ItemPurchased";
import LikedJunityItems from "./Screens/JunityStore/LikedItems";
import PurchaseItems from "./Screens/JunityStore/Purchases";
import PurchasesDetail from "./Screens/JunityStore/PurchasesDetail";
import InviteFriends from "./Screens/InviteFriends";
import RedirectPage from "./Screens/Auth/redirectPage";
import LaunchingApp from "./Screens/LaunchingApp";
import ApplicationSubmitted from "./Screens/ApplyOpportunity/ApplicationSubmitted";
import ResourceOpportunities from "./Screens/ResourceProfile/Opportunites";
import CompleteAuthDetails from "./Screens/Auth/SocialSignUp/SocialSignUpRedirect";
import SocialSecondStep from "./Screens/Auth/SocialSignUp/SocialStepTwoDetails";
import InstallApp from "./Screens/InstallApp";
import EmailVerificationRedirect from "./Screens/EmailVerificationRedirect";
import { AuthProvider } from "./Components/Auth";

const App = () => {
  return (
    <div className="main-structure">
      <div className="desktop-view">
        <Router>
          <Routes>
            <Route
              path="/verify-email/redirect"
              exact
              element={<EmailVerificationRedirect />}
            />
          </Routes>
        </Router>
      </div>
      <div className="mobile-view">
        <Router>
          <Routes>
            <Route path="/" exact element={<LaunchScreen />} />
            <Route path="/reset-password" exact element={<ResetPassword />} />
            <Route path="/login" exact element={<NoLogin />} />
            <Route path="/logout" exact element={<LogoutPage />} />
            <Route path="/redirect" exact element={<RedirectPage />} />
            <Route path="/login-2" exact element={<Login />} />
            <Route path="/resend-link" exact element={<ResendRecoverEmail />} />
            <Route path="/forgot-password" exact element={<RecoverEmail />} />
            <Route
              path="/complete-details"
              exact
              element={<CompleteAuthDetails />}
            />
            <Route path="/verify-email" exact element={<VerifyEmail />} />
            <Route path="/split-one" exact element={<SplitOne />} />
            <Route path="/split-two" exact element={<SplitTwo />} />
            <Route path="/split-three" exact element={<SplitThree />} />
            <Route path="/sign-up" exact element={<SignUp />} />
            <Route path="/social-sign-up" exact element={<SocialSignUp />} />
            <Route path="/second-step" exact element={<SecondStep />} />
            <Route
              path="/social-second-step"
              exact
              element={<SocialSecondStep />}
            />
            <Route path="/last-step" exact element={<LastStep />} />
            <Route path="/get-start" exact element={<GettingStart />} />
            <Route path="/install" exact element={<InstallApp />} />

            <Route path="/select-interest" exact element={<SelectInterest />} />
            <Route path="/setting-up" exact element={<LaunchingApp />} />
          </Routes>
          <AuthProvider>
            <Routes>
              <Route
                path="/app-submitted"
                exact
                element={<ApplicationSubmitted />}
              />
              <Route path="/home" exact element={<Home />} />
              <Route path="/notifications" exact element={<Notifications />} />
              <Route
                path="/account-verification"
                exact
                element={<SendVerification />}
              />
              <Route
                path="/verification-inprogress"
                exact
                element={<InProgressVerification />}
              />
              <Route path="/things-to-do" exact element={<ThingsToDO />} />
              <Route path="/find-counselor" exact element={<FindCounselor />} />
              <Route
                path="/counselor-institute"
                exact
                element={<CounselorIns />}
              />
              <Route
                path="/connecting-counselor"
                exact
                element={<ConnectingCounelor />}
              />
              <Route
                path="/choose-counselor"
                exact
                element={<ChooseCounselor />}
              />
              <Route
                path="/resource-profile"
                exact
                element={<ResourceProfile />}
              />
              <Route
                path="/resource-opportunities"
                exact
                element={<ResourceOpportunities />}
              />
              <Route path="/opportunities" exact element={<Opportunities />} />
              <Route
                path="/search-opportunities"
                exact
                element={<SearchOpportunities />}
              />
              <Route path="/accounts" exact element={<AccountScreen />} />
              <Route path="/chats" exact element={<ChatScreen />} />
              <Route path="/report-issue" exact element={<ReportIssue />} />
              <Route path="/personal" exact element={<Personal />} />
              <Route path="/add-interest" exact element={<InterestTab />} />
              <Route path="/education" exact element={<EducationTab />} />
              <Route path="/career" exact element={<CareerTab />} />
              <Route
                path="/profile-sharing"
                exact
                element={<ProfileSharing />}
              />
              <Route path="/chat-person" exact element={<SingleChat />} />
              <Route path="/about-group" exact element={<AboutChatGroup />} />
              <Route
                path="/notification-settings"
                exact
                element={<NotificationSettings />}
              />
              <Route
                path="/account-settings"
                exact
                element={<AccountSettings />}
              />
              <Route
                path="/change-password"
                exact
                element={<ChangePassword />}
              />
              <Route path="/change-number" exact element={<ChangePhone />} />
              <Route path="/change-email" exact element={<ChangeEmail />} />
              <Route
                path="/submitted-applications"
                exact
                element={<SubmittedApplications />}
              />
              <Route
                path="/submitted-application"
                exact
                element={<SingleApplication />}
              />
              <Route
                path="/liked-opportunities"
                exact
                element={<LikedOpportunities />}
              />
              <Route path="/liked-items" exact element={<LikedItems />} />
              <Route
                path="/recommendations"
                exact
                element={<RecommendedItems />}
              />
              <Route
                path="/apply-for-opportunity"
                exact
                element={<ApplyOnOpportunity />}
              />
              <Route path="/send-profile" exact element={<SendProfile />} />
              <Route
                path="/complete-profile"
                exact
                element={<CompleteProfile />}
              />
              <Route
                path="/additional-info"
                exact
                element={<AdditionalInfo />}
              />
              <Route
                path="/influencer-program"
                exact
                element={<InfluencerProgram />}
              />
              <Route path="/store" exact element={<JunityStoreHome />} />
              <Route path="/store/single-item" exact element={<SingleItem />} />
              <Route
                path="/store/checkout"
                exact
                element={<CheckoutJunityItem />}
              />
              <Route
                path="/store/purchased"
                exact
                element={<ItemPurchased />}
              />
              <Route
                path="/store/liked-items"
                exact
                element={<LikedJunityItems />}
              />
              <Route
                path="/store/purchase-items"
                exact
                element={<PurchaseItems />}
              />
              <Route
                path="/store/purchase-items/detail"
                exact
                element={<PurchasesDetail />}
              />
              <Route path="/invite-friends" exact element={<InviteFriends />} />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </div>
  );
};

export default App;
