import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Copy, ChevronDown, ChevronUp } from "react-feather";
import { useSelector } from "react-redux";
import {
  AllStudents,
  AllStudentsByInvitedBy,
  GetSingleStudent,
} from "../../Services/query/students";
import { API } from "aws-amplify";
import { UPDATE_STUDENT } from "../../Services/mutation/student";
import { ReactComponent as ReferralIcon } from "../../Assets/images/referral-icon.svg";

import ImgThirteen from "../../Assets/images/scene-13.webp";
import AlertToast from "../../Components/Common/Alerts/ToastAlert";

import "./style.scss";
import { generateReferralCode } from "../../utils/helpers";
import InvitedFriends from "./Components/InvitedFriends";
import { useAuth } from "../../Components/Auth";

const InviteFriends = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();
  const [friends, setFriends] = useState([]);
  const [student, setStudent] = useState({});
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [accordionActive, setAccordionActive] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PUBLIC_URL}/${
        referralCode || student?.referral_code
      }`
    );
    setTimeout(() => {
      setActiveToast(true);
      setToast("Link Copied!");
    }, 1000);
  };

  const sendInvite = async () => {
    const data = {
      title: "Invite a friend",
      text: `${process.env.REACT_APP_PUBLIC_URL}/${
        referralCode || student?.referral_code
      }`,
      url: `${process.env.REACT_APP_PUBLIC_URL}/${
        referralCode || student?.referral_code
      }`,
    };

    if (navigator.canShare(data)) {
      try {
        await navigator.share(data);
      } catch (err) {
        console.error(`Error: ${err}`);
      }
    }
  };

  const updateStudentReferralCode = (id, code) => {
    API.graphql({
      query: UPDATE_STUDENT,
      variables: { id, referral_code: code },
    });
  };

  const handleSetReferralCode = async (id) => {
    const students = await AllStudents();
    const studentExcludeUser = students.filter((student) => student.id !== id);
    const code = generateReferralCode(studentExcludeUser, 10);
    setReferralCode(code);
    updateStudentReferralCode(id, code);
  };

  useEffect(() => {
    if (!user?.id) {
      navigate("/login");
    }
  });

  useEffect(() => {
    GetSingleStudent(user?.id).then((student) => {
      setStudent(student);
      if (!student?.referral_code) {
        handleSetReferralCode(user?.id);
      }
    });

    AllStudentsByInvitedBy(user?.id).then((students) => setFriends(students));
  }, []);

  return (
    <>
      <AlertToast
        variant={"success"}
        icon={"check"}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="noti-head border-bottom-0">
        <div className="icon" onClick={navigateToPreviousRoute}>
          <ChevronLeft />
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="invite-friends pt-0 mt-5">
        <div className="img">
          <img src={ImgThirteen} className="img-fluid" alt="placeholder" />
        </div>
        <div className="title mt-0">Invite Friends to Junity</div>
        <div className="des">
          Invite your friends and fellow students to explore their passions and
          connect to opportunities.
        </div>
        <div
          className={`action ${
            student?.verification_status === "verified" ? "" : "inactive"
          }`}
        >
          <button onClick={sendInvite}>Send Invite</button>
          <p>OR COPY YOUR INVITE LINK</p>
        </div>
        <div
          onClick={copyLink}
          className={`copy ${
            student?.verification_status === "verified" ? "" : "inactive"
          }`}
        >
          <div className="text">
            {process.env.REACT_APP_PUBLIC_URL}/
            {referralCode || student?.referral_code}
          </div>
          <div className="icon">
            <Copy />
          </div>
        </div>
        <div className="border-bottom mt-3"></div>
        <div className="referral-card">
          <div className="referral-text-details">
            <div className="referral-icon">
              <ReferralIcon />
            </div>
            <div className="referral-text">
              <h6>Referrals</h6>
              <p>No one has signed up with your referral link yet. 😞</p>
              {/* <p>2 new referrals this week  🙌</p> */}
            </div>
          </div>
          <div className="referrals-value">{friends.length}</div>
        </div>
        <div className="youth-referred">
          <div className="accordion">
            <div
              className="head"
              onClick={() => setAccordionActive((prev) => !prev)}
            >
              {!accordionActive ? (
                <>
                  <span>Show Referrals</span>
                  <span>
                    <ChevronDown />
                  </span>
                </>
              ) : (
                <>
                  <span>Hide Referrals</span>
                  <span>
                    <ChevronUp />
                  </span>
                </>
              )}
            </div>
            {accordionActive && (
              <div className="content">
                <InvitedFriends friends={friends} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteFriends;
