import {
  SET_APPLY_OPPORTUNITY_DATA,
  RESET_APPLY_OPPORTUNITY_DATA,
} from "../constants/applyOpportunityConstants";

export const setApplyOpportunityDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_APPLY_OPPORTUNITY_DATA,
    payload: data,
  });
};

export const resetApplyOpportunityDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_APPLY_OPPORTUNITY_DATA,
  });
};
