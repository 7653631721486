import React from "react";
import { CheckCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const VerifyAccount = ({ removeOverlay, shown, position }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="apply-opper-popup text-left">
              <div className="title">Verify Your Account</div>
              <div className="des">
                We want to keep Junity safe for everyone; including you. You
                might want to verify your account to:
              </div>
              <div className="list">
                <div className="item">
                  <CheckCircle />
                  <span>Start applying to opportunities.</span>
                </div>
                <div className="item">
                  <CheckCircle />
                  <span>Connect to resources near you.</span>
                </div>
                <div className="item">
                  <CheckCircle />
                  <span>Get help and guidance from counselors.</span>
                </div>
                <div className="item">
                  <CheckCircle />
                  <span>
                    Earn credits to purchase experiences on the store.
                  </span>
                </div>
              </div>
              <div className="lets-btn">
                <div className="l-des">
                  To verify your identity,
                  <strong>
                    you will provide the contact information of a parent or
                    guardian.
                  </strong>{" "}
                  Ensure that your profile information is accurate at all times,
                  to avoid account restrictions.
                </div>
                <button onClick={() => navigate("/account-verification")}>
                  Get Started
                </button>
                <p onClick={(e) => removeOverlay()}>I’ll do this later</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
