import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function CounselorInstituteLoader() {
  return ( 
    <>
      <div className="institute-couns">
        <div className="ins-head">
            <div>
                <Skeleton height={90} className='rounded-0'/>
            </div>
            <div className="logo">
                <Skeleton width={80} height={80} className="rounded-circle"/>
            </div>

            <div className="info">
                <div className="title">
                    <Skeleton width={180} height={25}/>
                </div>
                <div className="des">
                    <Skeleton height={25} width={300}/>
                </div>
                <div className="connections">
                    <div className="text">
                        <Skeleton height={20} width={130}/>
                    </div>
                    <div className="dot"></div>
                    <div className="text">
                        <Skeleton height={20} width={100}/>
                    </div>
                </div>
                <div className="connect-btn text-center">
                    <Skeleton height={50} style={{borderRadius: 32}}/>
                    <Skeleton height={12} width={250} className='my-2'/>
                </div>
            </div>
        </div>
        <div className="conunselors">
          <div className="title">
            <Skeleton height={20} width={150}/>
          </div>
        </div>
        <div className="information">
          <div className="title">
            <Skeleton height={20} width={80}/>
          </div>
          <div className={`des break-detail`}>
            <Skeleton height={15}/>
            <Skeleton height={15}/>
            <Skeleton height={15}/>
            <Skeleton height={15}/>
          </div>
          <div className="social-links">
            <label><Skeleton height={15} width={80}/></label>
            <div className="link">
                <Skeleton height={15} width={250}/>
            </div>
          </div>
          <div className="social-links">
            <label><Skeleton height={15} width={80}/></label>
            <div className="link">
                <Skeleton height={15} width={250}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CounselorInstituteLoader;