import {
  SET_COGNITO_USER_DATA,
  RESET_COGNITO_USER_DATA,
} from "../constants/cognitoUserConstants";

export const setCognitoUserDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_COGNITO_USER_DATA,
    payload: data,
  });
};

export const resetCognitoUserDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_COGNITO_USER_DATA,
  });
};
