import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateTime } from "../../../utils/helpers";
import Search from "../../../Assets/images/search.svg";
import ImgElevenA from "../../../Assets/images/scene-11a.webp";
import Heart from "../../../Assets/images/heart.svg";
import { GetGroup } from "../../../Services/query/groups";
import { GetSingleOpportunity } from "../../../Services/query/opportunities";
import { AllRecommendedOpportunities } from "../../../Services/query/recommendedOpportunities";
import { useDispatch, useSelector } from "react-redux";
import { setHomeOpportunityDataAction } from "../../../Redux/actions/homePageOpportunities";
import { API } from "aws-amplify";
import {
  onCreateRecommendation,
  onUpdateMyGroup,
  onUpdateOpportunity,
} from "../../../graphql/subscriptions";
import { setRoutingStateDataAction } from "../../../Redux/actions/routingStateAction";

function Recommendations(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [refetch, setRefetch] = useState(false);
  const homeOpps = useSelector((store) => store.homeOpps);
  const [recommendations, setRecommendations] = useState(
    props.recommendations || homeOpps.recommendations
  );
  const { student, addLike, state, user } = { ...props };

  useEffect(() => {
    if (refetch) handleDependencies();
  }, [refetch]);

  const handleDependencies = async () => {
    const allRecommendedOpps = await AllRecommendedOpportunities();
    const studentRecommendations = await allRecommendedOpps?.reduce(
      async (acc, elt) => {
        const previousData = await acc;
        let resp = [...previousData];
        if (elt?.groupID) {
          const group = await GetGroup(elt?.groupID);
          if (group?.studentIDs?.includes(user?.id)) {
            const opportunity = await GetSingleOpportunity(elt?.opportunityID);
            resp = [...resp, opportunity];
          }
        }

        return resp;
      },
      Promise.resolve([])
    );

    setRecommendations(studentRecommendations.slice(0, 5));
    dispatch(
      setHomeOpportunityDataAction({ recommendations: studentRecommendations })
    );
    setRefetch(false);
  };

  const routeToDetailedOpportunityPage = (elt) => {
    dispatch(
      setRoutingStateDataAction({
        singleOpportunityPreviousRoute: location.pathname + location.search,
      })
    );
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  };

  const routeToResourceProfile = (elt) => {
    dispatch(
      setRoutingStateDataAction({
        previousRoute: location.pathname + location.search,
      })
    );
    navigate(`/resource-profile?id=${elt?.school?.id}`);
  };

  useEffect(() => {
    if (homeOpps.recommendations == null) handleDependencies();

    const recommendationCreation = API.graphql({
      query: onCreateRecommendation,
    }).subscribe({
      next: () => setRefetch(true),
    });

    const groupUpdate = API.graphql({
      query: onUpdateMyGroup,
    }).subscribe({
      next: () => setRefetch(true),
    });

    const opportunityUpdate = API.graphql({
      query: onUpdateOpportunity,
    }).subscribe({
      next: () => setRefetch(true),
    });

    return () => {
      recommendationCreation.unsubscribe();
      groupUpdate.unsubscribe();
      opportunityUpdate.unsubscribe();
    };
  }, []);

  const EmptyState = () => {
    return (
      <div className="sec">
        <div className="icon" style={{ width: "100px", height: "100px" }}>
          <img src={ImgElevenA} alt="icon" className="img-fluid" />
        </div>
        <div className="des">
          You have no recommended opportunities yet. Connect to a counselor to
          start receiving recommendations.
        </div>
        <div
          className={`search ${
            student?.verification_status === "verified" ? "" : "inactive"
          }`}
          onClick={() => navigate("/find-counselor")}
        >
          <img src={Search} alt="icon" />
          Find a Counselor
        </div>
      </div>
    );
  };

  const AvailableState = () => {
    return (
      <div className="popular-items">
        {recommendations?.map((elt, index) => (
          <div
            className={`item ${
              student?.verification_status === "verified" ? "" : "inactive"
            }`}
            key={index}
          >
            <div className="item-head">
              <div className="p-logo">
                <img
                  src={elt?.school?.image}
                  className="circle"
                  alt="c-logo"
                  onClick={() => routeToResourceProfile(elt)}
                />
                <div className="heart" onClick={() => addLike(elt)}>
                  {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                    <i className="fas fa-heart"></i>
                  ) : (
                    <img src={Heart} alt="icon" />
                  )}
                </div>
              </div>
            </div>
            <div
              className="info"
              onClick={() => routeToDetailedOpportunityPage(elt)}
            >
              <div className="title">{elt?.name}</div>
              <div className="des break-overview">{elt?.description}</div>
              <div className="date-likes">
                <div className="date">{calculateTime(elt?.createdAt)}</div>
                <div className="dot"></div>
                <div className="likes">
                  {elt?.likes || 0}
                  {elt?.likes === 1 ? " like" : " likes"}
                </div>
              </div>
            </div>
            <div className="p-type mr-1" type={elt?.category} key={index}>
              {elt?.category}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>{recommendations?.length === 0 ? <EmptyState /> : <AvailableState />}</>
  );
}

export default Recommendations;
