import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import awsExports from "./aws-exports";
import { Amplify, API } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
import { client } from "./Services/client";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

Amplify.configure(awsExports);
API.configure({
  cache: {
    dataStore: 'memory', 
    cachePolicy: {
      maxAge: 10 * 60 * 1000,
      caching: 'cache'
    }
  }
});

const root = createRoot(document.getElementById("root"))
root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ApolloProvider>,
);


