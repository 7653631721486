import React, { useEffect, useState } from 'react'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import Close from '../../Assets/images/x.svg'
import Clogo from '../../Assets/images/ins-logo.png'
import { GetSingleSchool } from '../../Services/query/school'
import { getInitial } from '../../utils/helpers'

import './style.scss'

const ConnectingCounselor = () => {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const user = useSelector((store) => store.user);

  const [school, setSchool] = useState({});

  useEffect(() => {
    GetSingleSchool(id).then((school) => setSchool(school));
  }, []);
  
  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={`/counselor-institute?id=${id}`}>
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="connecting-c mt-5">
        <div className="logos">
          <div className="com">
            <img src={Clogo} alt="c-img" />
          </div>
          <div className="plus">{'+'}</div>
          <div className="user">{getInitial(user.name)}</div>
        </div>
        <div className="title">You’re now connected to {school.name}</div>
        <div className="des">
          Start your first chat with a <b>{school.name}</b> counselor to begin
          receiving tailored career advice and recommendations.
        </div>
        <div className="action">
          <button onClick={() =>navigate(`/choose-counselor?id=${id}`)}>Start a Chat</button>
          <p onClick={() => navigate(`/counselor-institute?id=${id}`)}>I’ll do this later</p>
        </div>
      </div>
    </>
  )
}

export default ConnectingCounselor
