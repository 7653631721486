import React, { useState, useEffect } from 'react';
import InfoHelp from "../../Popus/InfoHelp";
import QuestionMark from '../../../Assets/images/question-mark.svg'
import { GetCategoryWithInterests } from '../../../Services/query/categories';
import SelectInterestsLoader from '../SelectInterestLoader';
import {useLocation} from "react-router-dom";


function SelectInterestDetail({ proceed, loading, userInterests, title, status }) {
  const [overlay, setOverlay] = useState(false);
  const [state, setState] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [interests, setInterests] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [interestLoading, setInterestLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState({});

  const location = useLocation();

  const showInfo = (category) => {
    setSelectedCategory(category);
    setOverlay(true);
  }

  const handleChange = (e, name) => {
    if (state.includes(e.target.value)) {
      const filterInterests = state.filter((elt) => elt !== e.target.value);
      const filterCategories = categories.filter((elt) => elt !== name);
      setState(filterInterests);
      setCategories(filterCategories);
    } else {
      setState([ ...state, e.target.value ]);
      setCategories([ ...categories, name ]);
    }
  };

  const handleProceed = () => {
    proceed(state, categories);
  }

  const getButtonText = () => {
    return location.pathname === "/add-interest" ? "Save": "Done";
  }

  useEffect(() => {
    setIsValid(state.length === 3);
    setDisabled(state.length === 3);
  }, [state]);

  useEffect(() => {
    setInterestLoading(interests.length === 0);
  }, [interests]);

  useEffect(() => {
    if (userInterests) setState(userInterests);
  }, [userInterests?.length > 0]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const interests = await GetCategoryWithInterests();
      setInterests(interests);
    }
    handleSetDependencies();
  }, [])


  return ( 
    <>
      <InfoHelp 
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
        removeOverlay={() => setOverlay(false)} 
        category={selectedCategory}
      />
      {interestLoading
       ? <SelectInterestsLoader />
       : (
       <div className="select-interest">
        {title && <div className="title">Choose up to 3 fields of interests</div> }
        <div className="des">
          {title
            ? "We'll use your interests to recommend you opportunities."
            : "Choose up to 3 interests to get personalized opportunity recommendations."
          }
        </div>
        {interests.map((elt) => 
        <div className="item" key={elt.id}>
          <div className="label" onClick={() => showInfo(elt, elt.interests)}>
            {elt.name}
            <img 
              src={QuestionMark} 
              alt="icon" 
            />
          </div>
          <div className="selector">
            {elt.interests.map((innerElt) => 
            <div className="selecotr-item" key={innerElt.id}>
              <input
                type="checkbox"
                disabled={status}
                id={innerElt.name}
                value={innerElt.id}
                name={elt.name}
                checked={(state || userInterests)?.includes(innerElt.id)}
                className={`selector-item_radio${(!((state || userInterests).includes(innerElt.id)) && disabled) ? ' disabled' : '' }`}
                onClick={(e) => handleChange(e, elt.name)}
              />
              <label htmlFor={innerElt.name} className="selector-item_label">
                {innerElt.name}
              </label>
            </div>
            )}
          </div>
        </div>
        )}
        <div className="select-btn">
          <button 
            type='button' 
            disabled={status || !isValid}
            style={{ opacity: (status || !isValid) ? 0.6 : 1 }}
            onClick={handleProceed}
          >
            { loading ? 'Loading...' : getButtonText() }
          </button>
        </div>
      </div> )}
    </>
  );
}

export default SelectInterestDetail;