import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { InAppNotifications } from "../Common/Notification";
import { resetUserDataAction } from "../../Redux/actions/userActions";
import { resetCognitoUserDataAction } from "../../Redux/actions/cognitoUserActions";
import { resetLoadersDataAction } from "../../Redux/actions/loadersAction";
import { resetHomeOpportunityDataAction } from "../../Redux/actions/homePageOpportunities";
import { chatClient } from "../../libs/axiosClient";
import { GetSingleChatHandler } from "../../Services/query/chatHandler";
import { API } from "aws-amplify";
import { onCreateAppNotification } from "../../graphql/subscriptions";
import { sendAppNotification } from "../../utils/helpers";
import { useLocalStorage } from "../../utils/storage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const user = useSelector((store) => store.user);
  const [notificationsList, setNotificationsList] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [routeHistory, setRouteHistory] = useLocalStorage("routeHistory", []);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const chat_id = search.get("id");
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let sockets = [];

  const addToRouteHistory = (route) => {
    setRouteHistory((prev) => {
      let newArray = prev;
      if (prev[prev.length - 1] !== route) {
        newArray = [...prev, route];
      }
      return newArray;
    });
  };

  const removeFromRouteHistory = (route) => {
    setRouteHistory((prev) => {
      let newArray = prev;
      if (prev.includes(route)) {
        newArray = prev.filter((elt) => elt !== route);
      }
      return newArray;
    });
  };

  const navigateToPreviousRoute = () => {
    const previousRoute = routeHistory[routeHistory.length - 2];
    removeFromRouteHistory(routeHistory[routeHistory.length - 1]);
    navigate(previousRoute);
  };

  const getPreviousRoute = () => {
    return routeHistory[routeHistory.length - 2];
  };

  const logout = () => {
    dispatch(resetUserDataAction());
    dispatch(resetCognitoUserDataAction());
    dispatch(resetLoadersDataAction());
    dispatch(resetHomeOpportunityDataAction());
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate("/login");

    // const signoutUri = awsmobile.oauth.redirectSignOut;
    // window.location.href = 'https://' + awsmobile.oauth.domain + '/logout?client_id=' + awsmobile.aws_user_pools_web_client_id + '&logout_uri=' + signoutUri;
  };

  const createPushMessage = (notification) => {
    return {
      body: notification?.text,
      timestamp: new Date(notification?.time),
      silent: false,
      icon: notification?.icon,
    };
  };

  const createPushNotification = (messageObject) => {
    if (document.visibilityState === "visible") {
      return;
    }
    let notification = null;
    if (Notification.permission === "granted") {
      notification = new Notification("Junity Youth", {
        ...messageObject,
      });
    }

    if (notification && messageObject?.linkUrl) {
      notification.onclick = () => {
        window.location.href = window.location.origin + messageObject?.linkUrl;
      };
    }
  };

  const addNotification = (notification) => {
    setNotificationsList((prev) => {
      let newArray = prev;
      if (!prev.includes(notification)) {
        newArray = [...prev, notification];
      }
      return newArray;
    });
    setAllNotifications((prev) => {
      let newArray = prev;
      if (!prev.includes(notification)) {
        newArray = [...prev, notification];
      }
      return newArray;
    });
    if (notification?.type === "push") {
      createPushNotification(createPushMessage(notification));
    }
  };

  const removeNotification = (index) => {
    let newNotifications = [...notificationsList];
    newNotifications.splice(index, 1);
    setNotificationsList(newNotifications);
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      if (!window.matchMedia("(display-mode: standalone)").matches) {
        const path = window.location.pathname;
        const excludes = ["/verify-email/redirect", "/reset-password"];
        if (!excludes.includes(path) && path !== "/install") {
          window.location.pathname = "/install";
        }
      } else {
        if (!user?.id) {
          return navigate("/login");
        }
      }
    } else {
      if (!user?.id) {
        return navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    addToRouteHistory(pathname + location?.search);

    const getNewMessages = async () => {
      try {
        const chats = await chatClient.get("chats/");
        const userChats = chats.data?.filter(
          (elt) => JSON.parse(elt?.custom_json)?.creatorID === user?.id
        );

        userChats.forEach(async (chat) => {
          const data = await GetSingleChatHandler(chat?.id);
          let socket = new WebSocket(
            `wss://api.chatengine.io/chat/?projectID=${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}&chatID=${chat?.id}&accessKey=${data?.access_key}`
          );

          socket.onmessage = async (message) => {
            message = JSON.parse(message?.data);
            if (
              message?.action === "new_message" &&
              (window.location.pathname !== "/chat-person" ||
                (chat_id && parseInt(chat_id) !== chat?.id))
            ) {
              const sender = JSON.parse(message?.data?.message?.custom_json);
              if (sender?.sender_id !== user?.id) {
                await sendAppNotification(
                  user?.id,
                  `You have an incoming message`,
                  "log",
                  `/chat-person?id=${chat?.id}`,
                  "Click here to view",
                  "youth",
                  "push",
                  "messageCircle"
                );
              }
            }
          };
          sockets.push(socket);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getNewMessages();

    const notifier = API.graphql({
      query: onCreateAppNotification,
      variables: { userId: user?.id, module: "youth" },
    }).subscribe({
      next: async (data) => {
        const noti = data?.value?.data?.onCreateAppNotification;
        addNotification({
          status: noti?.title,
          text: noti?.text,
          linkUrl: noti?.link,
          linkTitle: noti?.action,
          icon: noti?.icon || "messageCircle",
          type: noti?.type,
          time: new Date(noti?.createdAt).toISOString(),
          shown: noti?.read,
        });
      },
    });

    return () => {
      notifier.unsubscribe();
      sockets.forEach((socket) => socket.close());
    };
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{
        user,
        notificationsList,
        allNotifications,
        addNotification,
        removeNotification,
        navigateToPreviousRoute,
        getPreviousRoute,
        logout,
      }}
    >
      <InAppNotifications
        notifications={notificationsList}
        removeNotification={removeNotification}
      />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
