import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSingleStudent } from "./students";

export const GET_REVIEWS = gql`
  query ReviewsQuery {
    listReviews (limit: 1000) {
      items {
        createdAt
        description
        id
        opportunityID
        resourceID
        review
        stars
        studentID
        updatedAt
      }
      nextToken
    }
}
`

export const GET_REVIEWS_WITH_TOKEN = gql`
  query ReviewsQuery ($limit: Int, $nextToken: String) {
    listReviews (limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        description
        id
        opportunityID
        resourceID
        review
        stars
        studentID
        updatedAt
      }
      nextToken
    }
}
`

export async function AllReviews() {
  const resp = await API.graphql({ 
    query: GET_REVIEWS,
  });

  const data = resp?.data?.listReviews
  let nextToken = data?.nextToken
  let reviews = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_REVIEWS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listReviews

    const previousData = [...reviews, ...respData?.items]
    reviews = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return await reviews?.reduce(async (previousPromise, resourceReview) => {
    const student = await GetSingleStudent(resourceReview.studentID);
    let reviewsData = await previousPromise;
    const obj = { ...resourceReview, studentName: student?.name };
    reviewsData.push(obj);
    return reviewsData;
  }, Promise.resolve([]));
}

export async function GetReviewsByResourceID (resourceID) {
  const reviews = await AllReviews();
  const resourceReviews = reviews?.filter((elt) => elt.resourceID === resourceID);
  return resourceReviews || [];
}

export async function GetReviewsByResourceIDAndStudentID (studentID, resourceID) {
  const reviews = await AllReviews();
  const resourceReviews = reviews?.filter(
    (elt) => elt.studentID === studentID && elt?.resourceID === resourceID
  );

  return resourceReviews || [];
}

export async function GetReviewsByStudentID (studentID) {
  try {
    const reviews = await AllReviews();
    const studentReviews = reviews?.filter((elt) => elt.studentID === studentID);
    return studentReviews || [];
  } catch (err) {
    return [];
  }
}

