import { API } from "aws-amplify";
import React from "react";
import { Trash2 } from "react-feather";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useLongPress from "../../hooks/LongPressItem"
import { resetCognitoUserDataAction } from "../../Redux/actions/cognitoUserActions";
import { resetLoadersDataAction } from "../../Redux/actions/loadersAction";
import { resetUserDataAction } from "../../Redux/actions/userActions";
import { DELETE_PURCHASE } from "../../Services/mutation/purchase";
import { DELETE_REVIEW } from "../../Services/mutation/review";
import { UPDATE_SCHOOL } from "../../Services/mutation/school";
import { DELETE_STUDENT } from "../../Services/mutation/student";
import { DELETE_STUDENT_GUARDIAN } from "../../Services/mutation/studentGuardian";
import { DELETE_STUDENT_INTEREST } from "../../Services/mutation/studentInterest";
import { DELETE_STUDENT_OPPORTUNITY_APPLICATION } from "../../Services/mutation/studentOpportunityApplication";
import { DELETE_STUDENT_VOLUNTEER_WORK } from "../../Services/mutation/studentVolunteerWork";
import { GetPurchasesByStudentID } from "../../Services/query/purchases";
import { GetReviewsByStudentID } from "../../Services/query/review";
import { GetSingleSchool } from "../../Services/query/school";
import { GetStudentInterestByStudentID } from "../../Services/query/studentInterests";
import { GetStudentOpportunityApplicationByStudentID } from "../../Services/query/studentOpportunityApplications";
import { SingleStudentVolunteerWork } from "../../Services/query/studentVolunteerWork";
import { cognitoidentity, paramsWithUsername } from "../../userPools";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const HoldBinToDeleteAccount = ({ removeOverlay, shown, position }) => {
  const onLongPress = useLongPress(5000);
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteStudent = async (id) => {
    await API.graphql({
      query: DELETE_STUDENT,
      variables: { id },
    });
  };

  const deleteStudentGuardian = async (id) => {
    if (id) {
      await API.graphql({
        query: DELETE_STUDENT_GUARDIAN,
        variables: { id },
      });
    } else return;
  };

  const deleteStudentInterests = async (id) => {
    const studentInterest = await GetStudentInterestByStudentID(id);
    await API.graphql({
      query: DELETE_STUDENT_INTEREST,
      variables: { id: studentInterest.id },
    });
  };

  const deletePurchases = async (id) => {
    const studentPurchases = await GetPurchasesByStudentID(id);
    studentPurchases.forEach(async (elt) => {
      await API.graphql({
        query: DELETE_PURCHASE,
        variables: { id: elt.id },
      });
    });
  };

  const deleteReview = async (id) => {
    const studentReviews = await GetReviewsByStudentID(id);
    studentReviews.forEach(async (elt) => {
      await API.graphql({
        query: DELETE_REVIEW,
        variables: { id: elt.id },
      });
    });
  };

  const deleteStudentOpportunityApplication = async (id) => {
    const studentApplications = await GetStudentOpportunityApplicationByStudentID(id);
    studentApplications.forEach(async (elt) => {
      await API.graphql({
        query: DELETE_STUDENT_OPPORTUNITY_APPLICATION,
        variables: { id: elt.id },
      });
    });
  };

  const deleteStudentVolunteerWork = async (id) => {
    const volunteerWorks = await SingleStudentVolunteerWork(id);
    volunteerWorks.forEach(async (elt) => {
      await API.graphql({
        query: DELETE_STUDENT_VOLUNTEER_WORK,
        variables: { id: elt.id },
      });
    });
  };

  const deleteSchoolConnections = async () => {
    const { id, connections, connection_dates } = await GetSingleSchool(user.schoolID);
    const connectionsObj = connections?.filter((elt) => elt !== user?.id) || [];
    const connectionDates = connection_dates?.filter(
      (elt, index) => index !== connections.indexOf(user?.id)
    ) || [];
    
    if (id) {
      await API.graphql({
        query: UPDATE_SCHOOL,
        variables: {
          id,
          connections: [...connectionsObj],
          connectionDates: [...connectionDates],
        },
      });
    } else return;
  }

  const deleteAccount = async () => {
    const loadingToast = toast.loading("Deleting account...");
    let userDeleted = false;
    try {
      await cognitoidentity.adminUserGlobalSignOut(paramsWithUsername(user.email)).promise();
      // await deleteStudent(user.id);
      userDeleted = true;
      await cognitoidentity.adminDisableUser(paramsWithUsername(user.email)).promise();
      await cognitoidentity.adminDisableProviderForUser(paramsWithUsername(user.email)).promise();
      // await deleteStudentInterests(user.id);
      // await deleteStudentGuardian(user.guardianID);
      // await deletePurchases(user.id);
      // await deleteReview(user.id);
      // await deleteSchoolConnections();
      // await deleteStudentOpportunityApplication(user.id);
      // await deleteStudentVolunteerWork(user.id);
      toast.dismiss(loadingToast);
      dispatch(resetUserDataAction());
      dispatch(resetCognitoUserDataAction());
      dispatch(resetLoadersDataAction());
      navigate('/login')
    } catch (e) {
      console.error(e);
      toast.dismiss(loadingToast);
      if (userDeleted) navigate('/login');
    }
  }

  return (
    <>
      <div
        className={`overlay_background ${shown} delete-account-overlay`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="delete-popup">
              <div className="circle" {...onLongPress(deleteAccount)} >
                <Trash2 size="64" />
              </div>
              <div className="des text-center">
                Hold the bin icon for 5 seconds <br/> to delete your account 
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default HoldBinToDeleteAccount;
