import {useLocation, useNavigate} from "react-router-dom";
import { calculateTime } from "../../../utils/helpers";
import Heart from "../../../Assets/images/heart.svg";
import { ReactComponent as EmptyState } from '../../../Assets/images/no-opportunities.svg';
import {useDispatch} from "react-redux";
import {setRoutingStateDataAction} from "../../../Redux/actions/routingStateAction";


function  PopularInArea(props) {
  const navigate = useNavigate();
  const { 
    dependencies, 
    student, 
    user, 
    addLike, 
    state, 
    likes 
  } = { ...props }
  const dispatch = useDispatch();
  const location = useLocation();

  const routeToDetailedOpportunityPage = (elt) => {
    dispatch(setRoutingStateDataAction({
      previousRoute: location.pathname,
    }));
    navigate(`/resource-profile?id=${elt?.school?.id}`);
  }

  const routeToDetailedOpportunity = (elt) => {
    dispatch(setRoutingStateDataAction({
      singleOpportunityPreviousRoute: location.pathname + location.search,
    }));
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  }
  
  return ( 
    <>{dependencies.oppsInArea?.length ?
      dependencies.oppsInArea?.map((elt, index) => (
      <div className={`item`} key={index}>
        <div className="item-head">
          <div className="p-logo">
            <img
                src={elt?.school?.image}
                className="circle"
                alt="c-logo"
                onClick={() => routeToDetailedOpportunityPage(elt)}
            />
            <div
              className={`heart ${
                student?.verification_status === "verified"
                  ? ""
                  : "inactive"
              }`}
              onClick={() => addLike(elt)}
            >
              {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                <i className="fas fa-heart"></i>
              ) : (
                <img src={Heart} alt="icon" />
              )}
            </div>
          </div>
        </div>
        <div
          className="info"
          onClick={() => routeToDetailedOpportunity(elt)}
        >
          <div className="title">{elt?.name}</div>
          <div className="des break-overview">{elt?.description}</div>
          <div className="date-likes">
            <div className="date">{calculateTime(elt?.createdAt)}</div>
            <div className="dot"></div>
            <div className="likes">
              {Object.keys(likes)?.includes(elt?.id)
                ? likes[elt?.id]
                : elt?.likes || 0}{" "}
              { likes[elt?.id] === 1 ? 'like' : 'likes' }
            </div>
          </div>
        </div>
        <div className="p-type mr-1" type={elt?.category} key={index}>
          {elt?.category}
        </div>
      </div>
    ))
    : (
            <div className='d-flex justify-content-center align-items-center w-100 mt-4'>
              <div className="d-flex flex-column">
                <EmptyState />
                <p>No Opportunities</p>
              </div>
            </div>
        )
    }</>
  );
}

export default PopularInArea;