import React, { useState } from "react";
import MuteIcon from "../../../Assets/images/volume-off.svg";
import Person from "../../../Assets/images/counselor-img.jpg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GetSingleSchool } from "../../../Services/query/school";
import { formatMessageTime } from "../../../utils/helpers";
import { useSelector } from "react-redux";

const ResourcesChat = ({ item }) => {
  const user = useSelector((store) => store.user);
  const [school, setSchool] = useState({});
  const navigate = useNavigate();

  const isOnline = item?.people?.some(
    (elt) =>
      elt?.person?.username !== user?.id && elt?.person?.is_online === true
  );

  useEffect(() => {
    GetSingleSchool(JSON.parse(item?.custom_json || "{}")?.orgID).then(
      (school) => setSchool(school)
    );
  }, [item]);

  return (
    <div
      className="item"
      onClick={() => navigate(`/chat-person?id=${item?.id}`)}
    >
      <div className="user-img">
        {school?.image && <img src={school?.image} alt="user-img" />}
        <div className={isOnline && "active"}></div>
      </div>
      <div className="info">
        <div className="name">{school?.name}</div>
        <div className="s-msg">{item?.last_message?.text}</div>
      </div>
      <div className="action">
        <div className="time">{formatMessageTime(item?.created)}</div>
        {/* <div className="no">
          <div className="mute">
            <img src={MuteIcon} alt="icon" />
          </div>
          <div className="msg-no">2</div>
        </div> */}
      </div>
    </div>
  );
};

export default ResourcesChat;
