import { gql, useQuery } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_EXPERIENCE_SESSIONS = gql`
  query AllExperienceSessions {
    listExperienceSessions (limit: 1000) {
      items {
        date
        end_time
        id
        slots
        start_time
      }
      nextToken
    }
  }
`


export const GET_EXPERIENCE_SESSIONS_WITH_TOKEN = gql`
  query AllExperienceSessions ($limit: Int, $nextToken: String) {
    listExperienceSessions (limit: $limit, nextToken: $nextToken) {
      items {
        date
        end_time
        id
        slots
        start_time
      }
      nextToken
    }
}
`

export async function AllExperienceSessions() {
  const resp = await API.graphql({ 
    query: GET_EXPERIENCE_SESSIONS,
  });

  const data = resp?.data?.listExperienceSessions
  let nextToken = data?.nextToken
  let experienceSessions = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_EXPERIENCE_SESSIONS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listExperienceSessions

    const previousData = [...experienceSessions, ...respData?.items]
    experienceSessions = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return experienceSessions || [];
}


export const GET_EXPERIENCE_SESSION = gql`
  query SingleExperienceSession($id: ID!) {
    getExperienceSession(id: $id) {
      date
      end_time
      id
      slots
      start_time
    }
  }
`;

export async function GetSingleSession(id) {
  try {
    const respSession = await API.graphql({
      query: GET_EXPERIENCE_SESSION,
      variables: { id },
    });
    const session = respSession?.data?.getExperienceSession;
    return session;
  } catch (err) {
    console.error(err);
    return {};
  }
}


export function GetExperienceSession(id) {
  const resp = useQuery(GET_EXPERIENCE_SESSION, {
    variables: {
      id
    },
  });

  if (resp?.data) {
    return {
      data: resp?.data?.getExperienceSession,
      refetch: resp?.refetch
    }
  }

  return {};
}
