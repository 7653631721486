import React from "react";
import { Info, ShoppingBag, File } from "react-feather";
import { useNavigate } from "react-router-dom";
import JIcon from "../../Assets/images/jCoin.svg";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const StoreOption = ({ removeOverlay, shown, position }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="my-account">
              <div className="list">
                <a href="https://joinjunity.com" target="_blank">
                  <div className="item">
                    <Info />
                    <span>About the Junity Store</span>
                  </div>
                </a>
                <div
                  className="item"
                  onClick={() => navigate("/store/purchase-items")}
                >
                  <ShoppingBag />
                  <span>My Purchases</span>
                </div>
                <a href="https://joinjunity.com" target="_blank">
                  <div className="item">
                    <img src={JIcon} alt="icon" />
                    <span>About Junity Credits</span>
                  </div>
                </a>
                <a href="https://joinjunity.com" target="_blank">
                  <div className="item">
                    <File />
                    <span>Cancellation and Refund Policy</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreOption;
