import React, { useState, useEffect } from 'react'
import BottomNav from '../../Components/BottomNav'
import ShoppingBag from '../../Assets/images/shopping-bag-2.svg'
import JCoin from '../../Assets/images/jCoin.svg'
import { useNavigate } from 'react-router-dom'
import { resetUserDataAction } from '../../Redux/actions/userActions'
import { resetCognitoUserDataAction } from '../../Redux/actions/cognitoUserActions'
import { useSelector, useDispatch } from 'react-redux'
import { GetSingleStudent } from "../../Services/query/students";
import { resetLoadersDataAction } from '../../Redux/actions/loadersAction'

import './Tabs/style.scss'
import './style.scss'
import { resetHomeOpportunityDataAction } from '../../Redux/actions/homePageOpportunities'

const AccountScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const [student, setStudent] = useState({});

  const getInitial = (name) => {
    if (name) {
      let initials = ""
      const splittedName = name?.split(" ")
      splittedName.forEach(elt => {
        initials += elt[0]?.toUpperCase() || ""
      })
      return initials
    }

    return ""

  }

  const logout = async () => {
    dispatch(resetUserDataAction());
    dispatch(resetCognitoUserDataAction());
    dispatch(resetLoadersDataAction());
    dispatch(resetHomeOpportunityDataAction());
    navigate('/login');
    // const signoutUri = awsmobile.oauth.redirectSignOut;
    // window.location.href = 'https://' + awsmobile.oauth.domain + '/logout?client_id=' + awsmobile.aws_user_pools_web_client_id + '&logout_uri=' + signoutUri;
  }

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user.id);
      setStudent(student);
    }
    studentVerified();
  }, []);
  

  return (
    <>
      <div className="account">
        <div className="head">
          <div className="title">Account</div>
        </div>
        <div className="first-card">
          <div className="name">
            <div className="circle">{getInitial(user?.name)}</div>
            <div className="text">
              <div className="title">{user?.name}</div>
              <div className="label">Student</div>
            </div>
          </div>
          <div className="seperator"></div>
          <div 
            className={`bottom ${student?.verification_status === "verified" ? '' : 'inactive'}`} 
            onClick={() => navigate('/store')}
          >
            <div className="store">
              <img src={ShoppingBag} alt="icon" />
              <span>Junity Store</span>
            </div>
            <div className="time">
              <img src={JCoin} alt="icon" />
              <span>{(user?.balance || 0).toFixed(2)}</span>
            </div>
            <div className="arrow">{'>'}</div>
          </div>
        </div>
        <div className="list-card">
          <div className="item"
             onClick={() => navigate('/personal')}>
            <div className="title">Profile</div>
            <div className="arrow">{'>'}</div>
          </div>
          <div
            className={`item ${student?.verification_status === "verified" ? '' : 'inactive'}`}
            onClick={() => navigate('/submitted-applications')}
          >
            <div className="title">Submitted Applications</div>
            <div className="arrow">{'>'}</div>
          </div>
          <div
            className={`item ${student?.verification_status === "verified" ? '' : 'inactive'}`}
            onClick={() => navigate('/liked-opportunities')}
          >
            <div className="title">Liked Opportunities </div>
            <div className="arrow">{'>'}</div>
          </div>
          <div className={`item ${student?.verification_status === "verified" ? '' : 'inactive'}`} onClick={() => navigate('/recommendations')}>
            <div className="title">My Recommendations</div>
            <div className="arrow">{'>'}</div>
          </div>
        </div>
        <div className={`list-card ${student?.verification_status === "verified" ? '' : 'inactive'}`}>
          <div
            className="item change"
            onClick={() => navigate('/influencer-program')}
          >
            <div className="title">Become a Junity Influencer</div>
            <div className="arrow">{'>'}</div>
          </div>
          <div className="item" onClick={() => navigate('/invite-friends')}>
            <div className="title">Invite Friends</div>
            <div className="arrow">{'>'}</div>
          </div>
        </div>
        <div className="list-card bottom-margin">
          <div className="item" onClick={() => navigate('/account-settings')}>
            <div className="title">Account Settings</div>
            <div className="arrow">{'>'}</div>
          </div>
          <div
            className="item"
            onClick={() => navigate('/notification-settings')}
          >
            <div className="title">Notification Settings </div>
            <div className="arrow">{'>'}</div>
          </div>
          <div className="item">
            <a href="https://joinjunity.com" target="_blank">
              <div className="title">About Junity</div>
              <div className="arrow">{'>'}</div>
            </a>
          </div>
          <div className="item">
            <a href="https://joinjunity.com" target="_blank">
              <div className="title">FAQs</div>
              <div className="arrow">{'>'}</div>
            </a>
          </div>
          <div className="item">
            <a href="https://joinjunity.com" target="_blank">
              <div className="title">Privacy Policy</div>
              <div className="arrow">{'>'}</div>
            </a>
          </div>
          <div className="item">
            <a href="https://joinjunity.com" target="_blank">
              <div className="title">Terms & Conditions</div>
              <div className="arrow">{'>'}</div>
            </a>
          </div>
          <div className="item" onClick={logout}>
            <div className="title">Log out</div>
            <div className="arrow">{'>'}</div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  )
}

export default AccountScreen
