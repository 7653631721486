import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ImgFive from '../../Assets/images/scene-5.webp'
import WelcomePreloader from "../../Components/Common/WelcomePreloaders";
import './style.scss'

const GettingStart = () => {
  const [loading, setLoading] = useState(true);

  const user = useSelector((store) => store.user);

  return (
    <>
      {loading && <WelcomePreloader />}
      <div className="getting-start">
        <div className="img">
          <img
              src={ImgFive}
              alt="classroom img"
              className='img-fluid'
              onLoad={() => setLoading(false)}
          />
        </div>
        <div className="title">Good to have you here, {user.name}!</div>
        <div className="des">
          Your account has been created and you’re ready to start exploring
          opportunities on Junity based on your interests.
        </div>
        <div className="process-button">
          <Link to="/select-interest">Show Me!</Link>
        </div>
      </div>

    </>
  )
}

export default GettingStart
