/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { MapPin, Calendar, Briefcase, Search } from "react-feather";
import CalendarOutline from "../../Assets/images/calendar-outline.svg";
import CalendarFill from "../../Assets/images/calendar-fill.svg";
import {getCities, titleWords} from "../../utils/helpers";
import "./style.scss";

/** react-date-range */
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { AllSchools } from "../../Services/query/school";
/** */

const stopProp = (e) => {
  e.stopPropagation();
};

const setDate = (dateString) => {
  let date = new Date();
  let dateSplit = date.toDateString().split(" ");

  if (dateString === "today") {
    return dateSplit.splice(1, 2).join(" ");
  } else if (dateString === "week") {
    let first = date.getDate() - date.getDay();
    let last = first + 6;

    let firstDay = new Date(date.setDate(first)).getDate();
    let lastDay = new Date(date.setDate(last)).getDate();

    return `${dateSplit[1]} ${firstDay}-${lastDay}`;
  } else if (dateString === "month") {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
    let lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    let month = dateSplit[1];
    return `${month} ${firstDay}-${lastDay}`;
  }
};

const showFilterItem = (e) => {
  let _filter = e.currentTarget.getAttribute("data-filter");
  let items = document.getElementsByClassName(`${_filter}-filter`);
  if (items.length) {
    let filterElem = items[0];
    filterElem.classList.remove("d-none");
    filterElem.style.bottom = 0;
    filterElem.style.left = 0;
  }
};

const closeFilterItem = (filterItem) => {
  let items = document.getElementsByClassName(filterItem);
  if (items.length) {
    let filterElem = items[0];
    filterElem.classList.add("d-none");
  }
};

const CheckItem = ({ label, isSelected = false, onSelect = () => {} }) => {
  const [selected, setSelected] = useState(isSelected);

  const toggleSelected = () => {
    setSelected((prevState) => !prevState);
    if (!selected) {
      onSelect((prevState) => [...prevState, label]);
    } else {
      onSelect((prevState) => prevState.filter((f) => f !== label));
    }
  };

  return (
    <>
      <div
        className={`radio-item d-flex align-items-center ${selected ? 'activated': 'not-activated'}`}
        onClick={toggleSelected}
      >
        {selected ? (
          <i className="fas fa-check-circle fa-fw radio"></i>    
        ) : (
          <i className="far fa-circle fa-fw radio"></i>
        )}
        <span className={`ml-2 font-weight-bold ${selected ? "selected" : ""}`}>
          {label}
        </span>
      </div>
    </>
  );
};

const RadioItem = ({
  label,
  text = "",
  hasIcon = true,
  isSelected = false,
  onSelect = () => {},
}) => {
  const handleSelect = () => {
    let textSplit = label.toLowerCase().split(" ");
    onSelect(textSplit.length < 2 ? textSplit[0] : textSplit[1]);
  };

  return (
    <>
      <div
        className={`radio-item ${
          isSelected ? "selected" : ""
        } d-flex justify-content-between align-items-center`}
        onClick={handleSelect}
      >
        <label className="d-flex align-items-start">
          {hasIcon ? (
            <img
              src={isSelected ? CalendarFill : CalendarOutline}
              alt="calendar-icon"
            />
          ) : null}
          <div className="d-flex flex-column">
            <span className="font-weight-bold">{label}</span>
            {text ? <span className="small">{text}</span> : null}
          </div>
        </label>
        {isSelected ? (
          <i className="fas fa-check-circle fa-fw radio"></i>
        ) : (
          <i className="far fa-circle fa-fw radio"></i>
        )}
      </div>
    </>
  );
};

const FilterItems = ({ removeOverlay, shown, position }) => {
  const [dueDate, setDueDate] = useState("");
  const [customDate, setCustomDate] = useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);
  const [locations, setLocations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [orgSearch, setOrgSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [schools, setSchools] = useState([]);

  const modifiedStates = getCities({
    keys: ["name", "code"],
    requiresAll: true,
    returnObj: true,
    sort: true
  });

  const [searchedResources, setSearchedResources] = useState([]);
  const [searchedLocations, setSearchedLocations] = useState(modifiedStates);

  const handleLocationSelect = (e, code) => {
    if (locations.includes(code)) {
      const allSelectedLocations = [ ...locations ];
      setLocations(allSelectedLocations.filter((elt) => elt !== code));
    } else {
      setLocations([...locations, code]);
    }
  };

  const handleOrganizationChange = (e, id) => {
    if (organizations.includes(id)) {
      const allSelectedOrganizations = [ ...organizations ];
      setOrganizations(allSelectedOrganizations.filter((elt) => elt !== id));
    } else {
      setOrganizations([...organizations, id]);
    }
  };

  const filterByDueDate = (reset = false) => {
    if (reset) {
      removeOverlay("dueDate", dueDate, reset);
    } else {
      removeOverlay("dueDate", dueDate);
    }
    closeFilterItem("calendar-filter");
  };

  const filterByOrganizations = (reset = false) => {
    if (reset) {
      removeOverlay("organizations", organizations, reset);
    } else {
      removeOverlay("organizations", organizations);
    }
    closeFilterItem("organization-filter");
  };

  const filterByLocations = (reset = false) => {
    if (reset) {
      removeOverlay("locations", locations, reset);
    } else {
      removeOverlay("locations", locations);
    }
    closeFilterItem("location-filter");
  };

  const selectCustomDate = () => {
    removeOverlay("dueDate", {
      firstDay: customDate[0].startDate,
      lastDay: customDate[0].endDate,
    });
    closeFilterItem("custom-date-filter");
  };

  // useLayoutEffect(() => {
  //   const handleDependencies = async () => {
  //     const usStates = City.getCitiesOfState('US', 'CT');
  //     const modifiedStates = [];
  //     modifiedStates.push({ name: 'All', code: 'All' });
  //     usStates.forEach((elt) => {
  //       modifiedStates.push({ name: `${elt.name}, CT`, code: `${elt.name}, CT` });
  //     })
  //     setGeneratedLocations(modifiedStates);
  //     setSearchedLocations(modifiedStates);
  //   };
  //
  //   handleDependencies();
  // }, []);

  // useEffect(() => {
  //   const mainContainer = document.getElementsByClassName('listing locations')[0];
  //   const checkBox = mainContainer.getElementsByClassName('radio-item not-activated');
  //   if (locations.length === 5) {
  //     for (const element of checkBox) {
  //       element.style.pointerEvents = 'none';
  //     };
  //   } else {
  //     for (const element of checkBox) {
  //       element.style.pointerEvents = 'auto';
  //     };
  //   }
  // }, [locations]);

  useEffect(() => {
    if (orgSearch.trim() !== "") {
      const re = new RegExp(orgSearch.toLowerCase(), "g");
      const matches = schools.filter(
        (elt) => elt?.name?.toLowerCase().match(re) !== null
      );
      setSearchedResources(matches);
    } else {
      setSearchedResources(schools);
    }
  }, [orgSearch]);

  useEffect(() => {
    if (locationSearch.trim() !== "") {
      const re = new RegExp(locationSearch.toLowerCase(), "g");
      const matches = [];
      modifiedStates.forEach(
        (elt) => { if (elt.name.toLowerCase().match(re) !== null) matches.push(elt); }
      );
      setSearchedLocations(matches);
    } else {
      setSearchedLocations(modifiedStates);
    }
  }, [locationSearch]);

  useEffect(() => {
    AllSchools().then((schools) => setSchools(schools?.filter((elt) => elt?.type === "resource")));
  }, []);

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={() => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={() => removeOverlay()}></div>
            <div className="my-account">
              <div className="title">Filter</div>
              <div className="des">Select a category to apply filter</div>
              <div className="list">
                <div
                  className="item"
                  data-filter="location"
                  onClick={(e) => showFilterItem(e)}
                >
                  <MapPin />
                  <span>Location</span>
                </div>
                <div
                  className="item"
                  data-filter="calendar"
                  onClick={(e) => showFilterItem(e)}
                >
                  <Calendar />
                  <span>Due Date </span>
                </div>
                <div
                  className="item"
                  data-filter="organization"
                  onClick={(e) => showFilterItem(e)}
                >
                  <Briefcase />
                  <span>Organization</span>
                </div>
              </div>
            </div>
            <div className="filter-item location-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div
                  className="back"
                  onClick={() => closeFilterItem("location-filter")}
                >
                  {"<"}
                </div>
                <div className="action d-flex p-0">
                  <button
                    className="reset p-0"
                    onClick={() => filterByLocations(true)}
                  >
                    Reset
                  </button>
                  <button className="done" onClick={() => filterByLocations()}>
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Location</div>
              <div className="search">
                <Search />
                <input 
                  type="text"
                  name="locationSearch"
                  placeholder="Search" 
                  onChange={(e) => setLocationSearch(e.target.value)}
                />
              </div>
              <div className="label">
                You can select only 5 location at a time
              </div>
              <div className="listing locations">
                {searchedLocations.map((location) => 
                  <CheckItem
                    label={location.name}
                    onSelect={(e) => handleLocationSelect(e, location.code)}
                    key={`location-${location.code}`}
                  />
                )}
              </div>
            </div>
            <div className="filter-item calendar-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div
                  className="back"
                  onClick={() => closeFilterItem("calendar-filter")}
                >
                  {"<"}
                </div>
                <div className="action d-flex p-0">
                  <button
                    className="reset p-0"
                    onClick={() => filterByDueDate(true)}
                  >
                    Reset
                  </button>
                  <button className="done" onClick={() => filterByDueDate()}>
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Due Date</div>
              <div className="listing">
                <RadioItem label="All" hasIcon={false} />
                <RadioItem
                  label="Today"
                  text={setDate("today")}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === "today"}
                />
                <RadioItem
                  label="This Week"
                  text={setDate("week")}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === "week"}
                />
                <RadioItem
                  label="This Month"
                  text={setDate("month")}
                  hasIcon={true}
                  onSelect={setDueDate}
                  isSelected={dueDate === "month"}
                />

                <div
                  className="radio-item d-flex justify-content-between align-items-center"
                  data-filter="custom-date"
                  onClick={(e) => showFilterItem(e)}
                >
                  <label>
                    <img src={CalendarOutline} alt="calendar-alt" />
                    Custom Date
                  </label>
                </div>
              </div>
            </div>
            <div className="filter-item organization-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div
                  className="back"
                  onClick={() => closeFilterItem("organization-filter")}
                >
                  {"<"}
                </div>
                <div className="action d-flex p-0">
                  <button
                    className="reset p-0"
                    onClick={() => filterByOrganizations(true)}
                  >
                    Reset
                  </button>
                  <button
                    className="done"
                    onClick={() => filterByOrganizations()}
                  >
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Organization</div>
              <div className="search">
                <Search />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setOrgSearch(e.target.value)}
                />
              </div>
              <div className="listing mt-3">
                {searchedResources?.map((elt, index) => (
                  <CheckItem
                    label={titleWords(elt?.name)}
                    onSelect={(e) => handleOrganizationChange(e, elt.resourceID)}
                    key={index}
                  />
                ))}
              </div>
            </div>
            <div className="filter-item custom-date-filter bg-white p-3 position-absolute w-100 d-none">
              <div className="head d-flex justify-content-between">
                <div
                  className="back"
                  onClick={() => closeFilterItem("organization-filter")}
                >
                  {"<"}
                </div>
                <div className="action d-flex p-0">
                  <button
                    className="reset p-0"
                    onClick={() => selectCustomDate(true)}
                  >
                    Reset
                  </button>
                  <button className="done" onClick={() => selectCustomDate()}>
                    Done
                  </button>
                </div>
              </div>
              <div className="title">Custom Date</div>
              <div className="mt-3 d-flex justify-content-center">
                <DateRange
                  ranges={customDate}
                  showDateDisplay={false}
                  rangeColors={["#38609F", "#fff", "#red"]}
                  moveRangeOnFirstSelection={false}
                  onChange={(item) => setCustomDate([item.selection])}
                  className="date-range-picker"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterItems;
