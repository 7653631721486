import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Flag from "../../Assets/images/flag.svg";
import Upload from "../../Assets/images/upload.svg";
import Close from "../../Assets/images/x.svg";
import Logo from "../../Assets/images/logo.png";
import { MessageCircle, Phone, ExternalLink, Star } from "react-feather";
import ConnectCounselor from "../../Components/Popus/ConnectCounselor";
import ReviewOpportunity from "../../Components/Popus/ReviewOpportunity";
import { GetReviewsByResourceID } from "../../Services/query/review";
import { useDispatch, useSelector } from "react-redux";
import Verified from "../../Components/Popus/Verified";
import { GetSingleStudent } from "../../Services/query/students";
import { setUserDataAction } from "../../Redux/actions/userActions";
import { AllResources } from "../../Services/query/resources";

import "./style.scss";
import ReviewStarFill from "../../Widgets/ReviewStars/ReviewStarFill";
import {
  calculateResponseTimeRate,
  formatDateMonthYear,
  getInitial,
  responseTimeRates,
} from "../../utils/helpers";
import { chatClient } from "../../libs/axiosClient";
import { GetSingleSchool } from "../../Services/query/school";

const ResourceProfile = () => {
  const [overlay, setOverlay] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [reviewOverlay, setReviewOverlay] = useState(false);
  const [resource, setResource] = useState({});
  const [student, setStudent] = useState({});
  const [reviewLength, setReviewLength] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [expandDes, setExpandDes] = useState(true);
  const [resourceAdmin, setResourceAdmin] = useState({});
  const [orgResponseRate, setOrgResponseRate] = useState("");
  const [hasResponseTimes, setHasResponseTimes] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const oppId = search.get("oppId");

  const user = useSelector((store) => store.user);
  const previousRoute = useSelector(
    (store) => store.routingState.previousRoute
  );

  const handleSetReview = () => {
    if (student?.is_verified) {
      setReviewOverlay(true);
    }
  };

  const getResponseTimeRate = async (orgID) => {
    const chats = await chatClient.get("/chats");
    const youthChats = chats.data?.filter(
      (elt) =>
        JSON.parse(elt?.custom_json)?.orgID === orgID &&
        JSON.parse(elt?.custom_json)?.creatorType === "youth"
    );

    if (youthChats.length) {
      const responseTimes = await youthChats?.reduce(async (acc, chat) => {
        const prev = await acc;
        const messages = await chatClient.get(`chats/${chat.id}/messages/`);
        const responseTime = responseTimeRates(messages.data, user?.id);
        return [...prev, ...responseTime];
      }, Promise.resolve([]));

      if (!responseTimes.length) {
        return;
      }
      setHasResponseTimes(true);
      const avgResponseRate = calculateResponseTimeRate(responseTimes);
      setOrgResponseRate(avgResponseRate);
    }
  };

  const share = async () => {
    if (oppId) {
      const file = await fetch(Logo)
        .then((response) => response.blob())
        .then((blob) => new File([blob], "logo.png", { type: blob.type }));

      const data = {
        title: resource?.name,
        text: resource?.about || resource.name,
        url: `${process.env.REACT_APP_KYC_URL}/resource-profile?id=${resource?.id}`,
        files: [file],
      };
      if (navigator?.canShare(data)) {
        try {
          await navigator.share(data);
        } catch (err) {
          console.error(`Error: ${err}`);
        }
      }
    }
  };

  const calculateReview = () => {
    let sum = 0;
    // eslint-disable-next-line no-unused-expressions
    reviews?.forEach((elt) => {
      sum += parseInt(elt.stars);
    });
    const overallStars = parseFloat(sum / reviews.length);
    return Math.round(overallStars * 10) / 10;
  };

  const reviewSect = () => {
    if (!reviewLength && student?.is_verified) {
      return (
        <div className="leave-review">
          <div className="title">Leave a Review</div>
          <div className="des">
            You are seeing this because you recently applied to {resource?.name}
            .
          </div>
          <div className="label">Your review will remain anonymous.</div>
          <div className={`review`} onClick={handleSetReview}>
            <Star size={30} />
            <Star size={30} />
            <Star size={30} />
            <Star size={30} />
            <Star size={30} />
          </div>
        </div>
      );
    }

    return <></>;
  };

  const viewOpportunities = () => {
    navigate(`/resource-opportunities?id=${id}`);
  };

  useEffect(() => {
    const handleSetDependencies = async (org) => {
      const student = await GetSingleStudent(user?.id);
      dispatch(setUserDataAction(student));
      setStudent(student);
      const reviews = await GetReviewsByResourceID(org?.id);
      setReviews(reviews);
      const review = reviews?.filter((elt) => elt.studentID === user?.id);
      setReviewLength(review.length);
    };

    GetSingleSchool(id).then((org) => {
      setResource(org);
      handleSetDependencies(org);
    });

    if (reviewed) {
      setTimeout(() => {
        setReviewed(false);
      }, 3000);
    }
  }, [reviewed]);

  useEffect(() => {
    if (resource) getResponseTimeRate(resource?.id);
  }, [resource]);

  useEffect(() => {
    AllResources().then((resources) => {
      const resourceAdmin = resources.find(
        (elt) => elt.organizationID === id && elt?.isAdmin === true
      );
      setResourceAdmin(resourceAdmin);
    });
  }, []);

  return (
    <>
      <ConnectCounselor
        removeOverlay={() => setOverlay(false)}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <ReviewOpportunity
        removeOverlay={() => setReviewOverlay(false)}
        resourceName={resource?.name}
        resourceID={resource?.id}
        setReviewed={setReviewed}
        shown={reviewOverlay ? "shown" : "hidden"}
        position={reviewOverlay ? "slide-up" : "slide-down"}
      />
      <Verified
        removeOverlay={() => setReviewed(false)}
        shown={reviewed ? "shown" : "hidden"}
        position={reviewed ? "slide-up" : "slide-down"}
        header="Thank you!"
        text="Your review has been posted."
      />
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={previousRoute}>
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon mr-3">
          <Link to="">
            <img src={Upload} alt="icon" onClick={share} />
          </Link>
        </div>
        <div className="icon">
          <Link to="">
            <img src={Flag} alt="icon" />
          </Link>
        </div>
      </div>
      <div className="institute-couns mt-5">
        <div className="ins-head">
          <div className="bg">
            <img src={resource?.banner} alt="img" />
          </div>
          <div className="logo">
            <img src={resource?.image} alt="img" />
          </div>
          <div className="info">
            <div className="title">{resource?.name}</div>
            <div className="connections">
              <div className="text">{user.state}</div>
              <div className="dot"></div>
              <div className="text">
                <ReviewStarFill starNo={calculateReview() || 0} />
                <span className="review-star">{calculateReview() || 0}</span>/5
                <span className="review-total">
                  ({reviews.length} review{reviews.length === 1 ? "" : "s"})
                </span>
              </div>
            </div>
            <div className="connect-btn resource">
              <button type="button" onClick={viewOpportunities}>
                View Opportunities
              </button>
            </div>
            <div
              className={`more-actions ${
                student?.verification_status === "verified" ? "" : "inactive"
              }`}
            >
              <div className="item">
                <MessageCircle size={20} />
                <span>Chat</span>
              </div>
              <a
                href={`tel:${resourceAdmin?.contact_phone}`}
                style={{ textDecoration: "none" }}
              >
                <div className="item">
                  <Phone size={20} />
                  <span>Call</span>
                </div>
              </a>
              <div className="item">
                <ExternalLink size={20} />
                <span>
                  <a
                    href={`${resource?.website}`}
                    style={{ color: "#10408c" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Website
                  </a>
                </span>
              </div>
            </div>

            <div className="des text-center mt-2">
              {hasResponseTimes
                ? `Typically responds within ${orgResponseRate}`
                : "No response time available"}
            </div>
          </div>
        </div>
        <>{reviewSect()}</>
        <div className="information">
          <div className="title">About</div>
          <div className={`des ${expandDes ? "" : "break-detail"}`}>
            {resource?.about}
          </div>
          <div className="see-more" onClick={() => setExpandDes(!expandDes)}>
            {!expandDes ? "See more" : "See less"} <span>{">"}</span>
          </div>
          <div className="social-links">
            <label>Email</label>
            <div className="link">
              <a href={`mailto:${resource?.contact_email}`}>
                {resource?.contact_email}
              </a>
            </div>
          </div>
          <div className="social-links">
            <label>Website</label>
            <div className="link">
              <a href={resource?.website}>{resource?.website}</a>
            </div>
          </div>
        </div>
        <div className="reviews">
          <div className="title">Reviews</div>
          <div className="review-top">
            <div className="des">
              <Star size={25} className="active" />
              <div className="review-val">
                <span className="overall-stars">{calculateReview() || 0}</span>
                <span className="standard-stars">/5</span>
                <span className="reviews-total">
                  ({reviews?.length} review{reviews.length === 1 ? "" : "s"})
                </span>
              </div>
            </div>
            <div className="see-all">See all</div>
          </div>

          {reviews?.map((elt, index) => (
            <div className="review-card" key={index}>
              <div className="card-header-sect">
                <div className="circle">{getInitial(elt?.studentName)}</div>
                <div className="stars-createdAt">
                  <div className="review-date">
                    {formatDateMonthYear(elt?.createdAt)}
                  </div>
                  <div>
                    <ReviewStarFill starNo={elt.stars} />
                  </div>
                </div>
              </div>
              <p className="mt-3">{elt.review}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ResourceProfile;
