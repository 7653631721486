import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSingleSchool } from "./school";

export const GET_EDUCATORS = gql`
  query EducatorsQuery {
    listEducators (limit: 1000) {
      items {
        bio
        createdAt
        date_deactivated
        deactivated
        dob
        email
        gender
        id
        isAdmin
        image
        is_verified
        name
        personality
        phone
        role
        state
        schoolid
        schoolID
        updatedAt
        verification_date_started
        verification_status
        zipcode
        verification_progress_dates
      }

      nextToken
    }
}
`

export const GET_EDUCATORS_WITH_TOKEN = gql`
  query EducatorsQuery ($limit: Int, $nextToken: String) {
    listEducators (limit: $limit, nextToken: $nextToken) {
      items {
        bio
        createdAt
        date_deactivated
        deactivated
        image
        dob
        email
        gender
        id
        isAdmin
        is_verified
        name
        personality
        phone
        role
        state
        schoolid
        schoolID
        updatedAt
        verification_date_started
        verification_status
        zipcode
        verification_progress_dates
      }
      nextToken
    }
}
`

export async function AllEducators() {
  const resp = await API.graphql({ 
    query: GET_EDUCATORS,
  });

  const data = resp?.data?.listEducators
  let nextToken = data?.nextToken
  let educators = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_EDUCATORS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listEducators

    const previousData = [...educators, ...respData?.items]
    educators = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return educators || [];
}

export async function GetEducatorsBySchoolID (id) {
  const educators = await AllEducators();
  const filteredEducators = educators?.filter((elt) => elt.schoolID === id);
  return filteredEducators || [];
}

export const GET_EDUCATOR = gql`
  query SingleEducator($id: ID!) {
    getEducator(id: $id) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      image
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;

export async function GetEducator(id) {
  if (id){
    try {
      const resp = await API.graphql({
        query: GET_EDUCATOR,
        variables: { id },
      });
    
      const educator = resp?.data?.getEducator;
      const school = await GetSingleSchool(educator.schoolID);
      const obj = { ...educator, school };
      return obj || {};
    
    } catch (err) {
      console.error(err);
      return {};
    }
  }
  return {}

}
