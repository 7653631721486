import { gql, useQuery } from "@apollo/client";
import { API } from "aws-amplify";
import { axiosClient } from "../../libs/axiosClient";

const responseFields = [
  "closing_date",
  "description",
  "id",
  "is_archived",
  "is_featured",
  "liked_by",
  "likes",
  "location",
  "messageIDs",
  "partners",
  "price_per_person",
  "requirements",
  "sessionsIDs",
  "title",
  "type",
  "viewing",
  "image",
]

export const GET_EXPERIENCES = gql`
  query ExperiencesQuery {
    listExperiences (limit: 1000) {
      items {
        ${responseFields}
      }
      nextToken
    }
}
`

export const GET_EXPERIENCES_WITH_TOKEN = gql`
  query InterestsQuery ($limit: Int, $nextToken: String) {
    listExperiences (limit: $limit, nextToken: $nextToken) {
      items {
        ${responseFields}
      }
      nextToken
    }
}
`

export const GET_EXPERIENCES_LIKED_BY_STUDENT = gql`
  query ExperiencesQuery ($studentID: ID) {
    listExperiences (limit: 1000, filter: {liked_by: {contains: $studentID}}) {
      items {
        ${responseFields}
      }
      nextToken
    }
}
`

export const GET_FEATURED_EXPERIENCES = gql`
  query ExperiencesQuery {
    listExperiences (limit: 1000, filter: {is_featured: {eq: true}}) {
      items {
        ${responseFields}
      }
      nextToken
    }
}
`
export const GET_FEATURED_EXPERIENCES_WITH_TOKEN = gql`
  query InterestsQuery ($limit: Int, $nextToken: String) {
    listExperiences (limit: $limit, nextToken: $nextToken, filter: {is_featured: {eq: true}}) {
      items {
        ${responseFields}
      }
      nextToken
    }
}
`

export async function AllExperiences() {
  const resp = await API.graphql({ 
    query: GET_EXPERIENCES,
  });

  const data = resp?.data?.listExperiences
  let nextToken = data?.nextToken
  let experiences = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_EXPERIENCES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listExperiences

    const previousData = [...experiences, ...respData?.items]
    experiences = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return experiences || [];
}

export async function GetFeaturedExperiences() {
  try {
    const resp = await API.graphql({ 
      query: GET_FEATURED_EXPERIENCES,
    });
  
    const data = resp?.data?.listExperiences
    let nextToken = data?.nextToken
    let experiences = data?.items;

    while (nextToken) {
      const resp = await API.graphql({ 
        query: GET_EXPERIENCES_WITH_TOKEN,
        variables: { limit: 1000, nextToken }
      });
      const respData = resp?.data?.listExperiences

      const previousData = [...experiences, ...respData?.items]
      experiences = [...previousData];

      nextToken = respData?.nextToken
    }
  } catch(error) {
    console.log(error)
    return []
  }
  
}

export async function GetExperienceLikedByStudent (studentID) {
  try {
    const resp = await API.graphql({ 
      query: GET_EXPERIENCES_LIKED_BY_STUDENT,
      variables: {studentID}
    });

    const data = resp?.data?.listExperiences
    let experiences = data?.items;

    experiences = await Promise.all(
      experiences.map(async(experience) => {
          const resp = await axiosClient.get(`experience/${experience?.id}`);
          return resp?.data?.data
      })
    )
    
    return experiences || [];
  } catch (error) {
    return []
  }
} 

export async function GetExperienceByTypes () {
  const experiences = await AllExperiences();
  const featuredExperiences = experiences?.filter(
    (elt) => elt?.is_featured === true
  );
  const notFeaturedExperiences = experiences?.filter(
    (elt) => elt?.is_featured === false
  );
  return {
    featured: featuredExperiences,
    notFeatured: notFeaturedExperiences,
  };
} 


export const GET_EXPERIENCE = gql`
  query SingleExperience($id: ID!) {
    getExperience(id: $id) {
      ${responseFields}
    }
  }
`;


export function GetExperience(id) {
  const resp = useQuery(GET_EXPERIENCE, {
    variables: {
      id
    },
  });

  if (resp?.data) {
    return {
      data: resp?.data?.getExperience,
      refetch: resp?.refetch
    }
  }

  return {};
}

export async function GetSingleExperience(id) {
  try {
    const respExperience = await API.graphql({
      query: GET_EXPERIENCE,
      variables: { id },
    });
    return respExperience?.data?.getExperience;
  } catch (err) {
    console.error(err);
    return {};
  }
}

