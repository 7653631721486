/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import OrgImg from '../../Assets/images/org-img.jpg'
import Empty from '../../Assets/images/empty_todo_full.svg';
import './style.scss'
import { calculateTime, titleWords } from '../../utils/helpers'
import { AllStudentOpportunityApplicationsByStudentID } from '../../Services/query/studentOpportunityApplications'
import {useDispatch, useSelector} from 'react-redux'
import { GetReviewsByStudentID } from '../../Services/query/review';
import {setRoutingStateDataAction} from "../../Redux/actions/routingStateAction";


const EmptyState = () => {
  return (
    <div className='empty-state'>
      <div className='center-content'>
        <img src={Empty} alt=""/>
      </div>
    </div>
  )
}

const ThingsToDO = () => {
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const [appsOppsIncluded, setAppsOppsIncluded] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  const routeToResourceProfile = (elt) => {
    dispatch(setRoutingStateDataAction({
      singleOpportunityPreviousRoute: location.pathname + location.search,
    }))
    navigate(`/apply-for-opportunity?id=${elt?.opportunity?.id}&review=true`)
  }

  useEffect(() => {
    AllStudentOpportunityApplicationsByStudentID(user?.id).then(async (apps) => {
      const reviews = await GetReviewsByStudentID(user?.id);
      const requiringReviews = apps.filter((elt) => reviews?.some((review) => review?.resourceID === elt?.schoolID) === false);
      setAppsOppsIncluded(requiringReviews);
    });
  }, [user])
  
  
  return (
    <> 
    <div className="noti-head">
      <div className="icon">
        <Link to="/home">
          <span>{'<'}</span>
        </Link>
      </div>
      <div className="text">Things to Do</div>
      <div className="icon"></div>
    </div>
    {appsOppsIncluded.length > 0
    ?
      <div className="tasks-sec mt-5">
        <div className="head">
          <div className="title">Reviews</div>
          <div className="des">Review your recent applications</div>
        </div>
        {appsOppsIncluded.map((elt, index) =>
        <div 
          className="item" key={index} 
          onClick={() => routeToResourceProfile(elt)}
        >
          <div className="img-p">
            <img src={OrgImg} alt="img" />
          </div>
          <div className="info">
            <div className="title">{elt?.opportunity?.name}</div>
            <div className="status">
              <div className={`${elt?.status.toLowerCase()}`}>
                {titleWords(elt?.status)}
              </div>
              <div className="dot"></div>
              <div className="date">{calculateTime(elt?.createdAt)}</div>
            </div>
            <div className="seperator"></div>
            <div className="company">
              <img src={elt?.opportunity?.school?.image} alt="img" className='item_img' />
              <span>{elt?.opportunity?.school?.name}</span>
            </div>
          </div>
        </div>
        )}
        <div className="show-more">
          Show More <span>{'>'}</span>
        </div>
      </div>
    : <EmptyState/>
    } 
  </>
  )
}

export default ThingsToDO
