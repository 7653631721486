import { gql, useQuery } from "@apollo/client";
import { API } from 'aws-amplify';
import { GetSingleOpportunity } from "./opportunities";

export const GET_STUDENT_OPPORTUNITY_APPLICATION = gql`
  query StudentOpportunityApplicationsQuery {
    listStudentOpportunityApplications (limit: 1000) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        occupation
        studentID
        schoolID
        updatedAt
        opportunityID
        status
      }

      nextToken
    }
}
`

export const GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN = gql`
  query StudentOpportunityApplicationsQuery ($limit: Int, $nextToken: String) {
    listStudentOpportunityApplications (limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        occupation
        studentID
        updatedAt
        opportunityID
        schoolID
        status
      }
      nextToken
    }
}
`

export async function AllStudentOpportunityApplications() {
  const resp = await API.graphql({ 
    query: GET_STUDENT_OPPORTUNITY_APPLICATION,
    cache: true,
  });

  const data = resp?.data?.listStudentOpportunityApplications
  let nextToken = data?.nextToken
  let studentOpportunityApplications = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudentOpportunityApplications

    const previousData = [...studentOpportunityApplications, ...respData?.items]
    studentOpportunityApplications = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return await studentOpportunityApplications?.reduce(async (previousPromise, app) => {
    const opportunity = await GetSingleOpportunity(app.opportunityID);
    let opportunitiesData = await previousPromise;
    const obj = { ...app, opportunity };
    opportunitiesData.push(obj);
    return opportunitiesData;
  }, Promise.resolve([]));

}

export async function GetStudentOpportunityApplicationByStudentID(id) {
  const oppsApps = await AllStudentOpportunityApplications();
  const studentApps = oppsApps?.filter(apps => apps.studentID === id);
  return studentApps || [];
}

export async function GetStudentOpportunityApplicationBySchoolID(id) {
  const oppsApps = await AllStudentOpportunityApplications();
  const studentApps = oppsApps?.filter(apps => apps.schoolID === id);
  return studentApps || [];
}

export async function GetApplicationByOpportunityIDAndStudentID(id, studentID) {
  const oppsApps = await AllStudentOpportunityApplications();
  const studentApps = oppsApps?.find(
    (elt) => (elt?.opportunityID === id) && (elt.studentID === studentID)
  );
  return studentApps || {};
}

export function AllStudentOpportunityApplicationsObj() {
  const resp = useQuery(GET_STUDENT_OPPORTUNITY_APPLICATION);
  if (resp?.data) {
    return {
      data: resp?.data?.listStudentOpportunityApplications?.items,
      refetch: resp?.refetch
    }
  }
  return [];
}

export const GET_SINGLE_APPLICATION = gql`
  query SingleApplication($id: ID!) {
    getStudentOpportunityApplication(id: $id) {
      createdAt
      dont_show_again
      employer
      employment_status
      household_income
      id
      occupation
      studentID
      updatedAt
      opportunityID
      schoolID
      status
    }
  }
`;

export async function GetStudentOpportunityApplication(id) {
  try {
    const respStudentOpportunityApplication = await API.graphql({
      query: GET_SINGLE_APPLICATION,
      variables: { id },
    });
    const studentOpportunityApplication = respStudentOpportunityApplication?.data?.getStudentOpportunityApplication;
    const opportunity = await GetSingleOpportunity(studentOpportunityApplication.opportunityID)
    return {
      ...studentOpportunityApplication,
      opportunity
    };
  } catch (err) {
    return {};
  }
}

export const GET_STUDENT_OPPORTUNITY_APPLICATION_BY_STUDENT_ID = gql`
  query StudentOpportunityApplicationsQuery ($studentID: ID) {
    listStudentOpportunityApplications (limit: 1000, filter: { studentID: { eq: $studentID } }) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        occupation
        studentID
        schoolID
        updatedAt
        opportunityID
        status
      }

      nextToken
    }
}
`

export const GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN_BY_STUDENT_ID = gql`
  query StudentOpportunityApplicationsQuery ($limit: Int, $nextToken: String, $studentID: ID) {
    listStudentOpportunityApplications (limit: $limit, nextToken: $nextToken, filter: { studentID: { eq: $studentID } }) {
      items {
        createdAt
        dont_show_again
        employer
        employment_status
        household_income
        id
        occupation
        studentID
        updatedAt
        opportunityID
        schoolID
        status
      }
      nextToken
    }
}
`

export async function AllStudentOpportunityApplicationsByStudentID(studentID) {
  const resp = await API.graphql({ 
    query: GET_STUDENT_OPPORTUNITY_APPLICATION_BY_STUDENT_ID,
    variables: { studentID }
  });

  const data = resp?.data?.listStudentOpportunityApplications
  let nextToken = data?.nextToken
  let studentOpportunityApplications = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_STUDENT_OPPORTUNITY_APPLICATION_WITH_TOKEN_BY_STUDENT_ID,
      variables: { limit: 1000, nextToken, studentID }
    });
    const respData = resp?.data?.listStudentOpportunityApplications

    const previousData = [...studentOpportunityApplications, ...respData?.items]
    studentOpportunityApplications = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return await studentOpportunityApplications?.reduce(async (acc, app) => {
    const prev = await acc;
    const opportunity = await GetSingleOpportunity(app?.opportunityID);
    return [ ...prev, { ...app, opportunity } ]
  }, Promise.resolve([]));
}