import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function ApplyOpportunityLoader() {
  return ( 
    <>
      <div className="sub-app mt-5">
        <div className='d-flex justify-content-between my-2'>
          <Skeleton width={200} height={25}/>
          <div>
            <Skeleton width={35} height={35} className="rounded-circle mb-1"/>
            <Skeleton width={35} height={15}/>
          </div>
        </div>
        <div className='d-flex mb-2'>
          <div className='d-flex'>
            <Skeleton width={15} height={15} className="mr-1"/>
            <Skeleton width={80} height={15}/>
          </div>
          <div className='d-flex ml-2'>
            <Skeleton width={15} height={15} className="mr-1"/>
            <Skeleton width={60} height={15}/>
          </div>
        </div>
        <div className='d-flex justify-content-between mb-1 border-bottom pb-2'>
            <Skeleton height={25} width={90} style={{borderRadius: 32}}/>
            <Skeleton height={25} width={90} style={{borderRadius: 32}}/>
            <Skeleton height={25} width={90} style={{borderRadius: 32}}/>
        </div>
        <div className='d-flex justify-content-between mt-1 mb-2 py-1 pb-2 border-bottom'>
            <div className='d-flex'>
                <Skeleton width={40} height={40} className="rounded-circle mr-1"/>
                <div>
                    <Skeleton height={20} width={180}/>
                    <Skeleton height={15} width={120}/>
                </div>
            </div>
            <div className='d-flex flex-column align-items-end'>
                <Skeleton height={20} width={80}/>
                <Skeleton height={15} width={60}/>
            </div>
        </div>
        <div className='mt-1 mb-2 py-1 pb-2'>
            <Skeleton height={160}/>
            <Skeleton height={15} width={50}/>

            <Skeleton height={35} style={{borderRadius: 32}}/>
        </div>
        
      </div>
    </>
  );
}

export default ApplyOpportunityLoader;