import {
  SET_COGNITO_USER_DATA,
  RESET_COGNITO_USER_DATA,
} from "../constants/cognitoUserConstants";

const initialLoginState = {};

const cognitoUserReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_COGNITO_USER_DATA:
      return {...state, ...action.payload};
    case RESET_COGNITO_USER_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default cognitoUserReducer;
