import {
  SET_LOADERS,
  RESET_LOADERS,
} from "../constants/loadersConstants";

const initialLoginState = {};

const loadersReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_LOADERS:
      return {...state, ...action.payload};
    case RESET_LOADERS:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default loadersReducer;
