import { API } from "aws-amplify";
import { UPDATE_OPPORTUNITY } from '../Services/mutation/opportunities'

export const AddLike = (props) => {
  const {
    state,
    setState,
    oppsLikedBy,
    user,
    elt,
    likes,
    updateOpps,
    toastLoading,
    setToastLoading,
   } = { ...props };

  const { id, liked_by} = {...elt}
  const likedBy = oppsLikedBy.includes(user?.id) ? oppsLikedBy: (liked_by || [])

  state[id]
  ? setState({ ...state, [id]: !state[id] })
  : setState({ ...state, [id]: !(likedBy.includes(user?.id))});

  if (!toastLoading) {
    setToastLoading(true);
    let newLikes = 0
    const inputData = {
      id
    }

    const userLikes = likes[id] || elt?.likes
    
    if (likedBy?.includes(user?.id)) {
      inputData.likes = userLikes < 1 ? 0 : userLikes - 1
      const othersLiked = likedBy?.filter(
        (elt) => elt !== user?.id
      )
      inputData.liked_by = othersLiked;
    } else {
      inputData.likes = userLikes + 1
      if (likedBy.length > 0) {
        inputData.liked_by = [...likedBy, user?.id];
      } else {
        inputData.liked_by = [user?.id];
      } 
    }
    newLikes = inputData.likes
    API.graphql({
      query: UPDATE_OPPORTUNITY,
      variables: {...inputData}
    }).then(async () => {
      updateOpps(id, newLikes, likedBy);
      setToastLoading(false);
    }).catch(async (err) => {
      console.error(err);
      setToastLoading(false);
    })
  }
}

export const passwordIsValid = (password) => {
  const re = new RegExp(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/);
  return re.test(password);
}

export const sendVerificationCode = async (phone) => {
  const resp = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/send-sms`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phone }),
    }
  );

  return resp.json();
}

export const verifyCode = async (otp) => {
  const otpToken = localStorage.getItem('otpToken');
  return await fetch(
    `${process.env.REACT_APP_SERVER_URL}/verify-otp`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ otpToken, otp }),
    }
  );
}