import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FieldChecked from '../../../../Assets/images/field-checked.svg';
import "./style.scss";
import { setUserDataAction } from "../../../../Redux/actions/userActions";
import {isRequiredFieldsPassed, validatePhone} from "../../../../utils/helpers";

const CompleteAuthDetails = () => {
  useEffect(() => {
    document.body.style.background =
      "linear-gradient(0deg, #c1d6f41a, #c1d6f41a), #ffffff";
  });

  const user = useSelector((store) => store.user);

  const getName = (type) => {
    if (type === 'fname') return user?.name?.split(' ')[0];
    else return user?.name?.split(' ')?.slice(1);
  }

  const [state, setState] = useState({
    email: user?.email,
    fname: getName("fname"),
    lname: getName("lname")
  });
  const [valid, setValid] = useState(false);
  const [phoneCls, setPhoneCls] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value});
  };

  const updateDetails = () => {
    const name = (state?.fname || getName('fname')) + ' ' + (state?.lname || getName('lname'));
    dispatch(setUserDataAction({ name }));
    navigate('/social-second-step')
  }

  useEffect(() => {
    setValid(isRequiredFieldsPassed(state, 4, 'eq')
        && state?.phone?.length === 10
        && validatePhone(state?.phone)
    );
    if (state?.phone?.length === 10) {
      const validPhone = validatePhone(state?.phone);
      setPhoneCls(validPhone ? 'border-success' : 'border-danger');
    } else {
      if (phoneCls !== '') setPhoneCls('');
    }
  }, [state]);

  return (
    <>
        <div className="noti-head p-0 back-head border-0">
          <div className="icon">
            <Link to="/split-three">
              <i className="fas fa-angle-left"></i>
            </Link>
          </div>
          <div className='text progress-bars'>
            <div className='active'></div>
            <div className='next'></div>
          </div>
        </div>
      <div className="auth-sec mt-5">
        <div className="heading">Welcome to Junity</div>
        <div className="sub-heading">Let’s get started.</div>
        <form>
          <div className="form-auth">
            <label>First Name</label>
            <input
              type="text"
              placeholder="Bryce"
              name="fname"
              value={state?.fname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-auth">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="David"
              name="lname"
              value={state?.lname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-auth">
            <label>Email Address</label>
            <div className="checked-field-wrapper">
              <input
                type="email"
                placeholder="bryce.david@gmail.com"
                name="email"
                value={user?.email}
                onChange={handleChange}
                disabled
              />
              {user?.email_verified
                ? <img src={FieldChecked} alt=""/>
                : ''
              }
            </div>
          </div>
          <div className="form-auth">
            <label>Mobile (Phone) Number</label>
            <div className={`phone-input ${phoneCls}`}>
              <span>+1</span>
              <input
                  type="tel"
                  placeholder="Enter your mobile number"
                  name="phone"
                  onChange={handleChange}
              />
            </div>
            <p>
              We’ll use this to help you recover your account if you lose access
              to your email.
            </p>
          </div>
        </form>
      </div>
      <div className="center-btn">
        <div className="submit-btn point">
          <button
            className="cus-btn"
            style={{ opacity: valid ? 1 : 0.3 }}
            disabled={!valid}
            onClick={updateDetails}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default CompleteAuthDetails;
