import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setApplyOpportunityDataAction } from "../../../Redux/actions/applyOpportunityAction";
import { GetSingleSchool } from "../../../Services/query/school";
import EducationTab from "../../Account/Tabs/Education";
import CareerTab from "../../Account/Tabs/Career";
import { GetSingleOpportunity } from "../../../Services/query/opportunities";
import { resetApplyOpportunityDataAction } from "../../../Redux/actions/applyOpportunityAction";
import { CREATE_STUDENT_OPPORTUNITY_APPLICATION } from "../../../Services/mutation/studentOpportunityApplication";
import { useMutation } from "@apollo/client";
import { axiosClient } from "../../../libs/axiosClient";
import { useAuth } from "../../../Components/Auth";

const CompleteProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const [school, setSchool] = useState({});
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const [createStudentOpportunityApplication] = useMutation(
    CREATE_STUDENT_OPPORTUNITY_APPLICATION
  );

  const search = new URLSearchParams(useLocation().search);
  const [opp, setOpp] = useState({});
  const opportunityID = search.get("opportunityID");
  const schoolID = search.get("schoolID");
  const schoolName = search.get("schoolName");
  const type = search.get("type");

  const submitApplication = () => {
    const inputData = {
      schoolID,
      opportunityID,
      schoolName,
      studentID: user?.id,
      createdAt: new Date(),
      status: "pending",
      state: user?.state,
    };

    createStudentOpportunityApplication({
      variables: { ...inputData },
    })
      .then(async (data) => {
        dispatch(resetApplyOpportunityDataAction());
        const appId = data.data.createStudentOpportunityApplication.id;
        await axiosClient.post("/update-credit", {
          studentID: user?.id,
          condition: "Submissions",
          balance: user?.balance || 0,
          multiplier: user?.multiplier || 0,
          submissions_scan_1: user?.submissions_scan_1 || 0,
          submissions_scan_2: user?.submissions_scan_2 || 0,
          submissions_scan: user?.submissions_scan || 0,
          event_submissions_scan: user?.event_submissions_scan || 0,
          event_submissions_recommendation_scan:
            user?.event_submissions_recommendation_scan || 0,
        });
        navigate(
          `/app-submitted?id=${inputData?.opportunityID}&appId=${appId}`
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const proceed = () => {
    dispatch(
      setApplyOpportunityDataAction({
        schoolID,
        opportunityID,
        schoolName,
      })
    );

    const oppQuestions = JSON.parse(opp.questions);
    if (Object.keys(oppQuestions).length > 0) navigate("/additional-info");
    else submitApplication();
  };

  useEffect(() => {
    GetSingleSchool(schoolID).then((school) => setSchool(school));
    GetSingleOpportunity(opportunityID).then((opp) => setOpp(opp));
  }, []);

  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="send-profile mt-5">
        <div className="title">Complete Your Profile</div>
        <div className="des">
          This organization needs some information that is currently missing
          from your Junity profile.
        </div>
        {type === "education" ? (
          <EducationTab hideNav={true} proceed={proceed} />
        ) : (
          <CareerTab hideNav={true} proceed={proceed} />
        )}
      </div>
    </>
  );
};

export default CompleteProfile;
