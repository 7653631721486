import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './style.scss'

const NavTabs = () => {
  const navigate = useNavigate()
  return (
    <div className="account-profile">
      <div className="head">
        <div className="icon" onClick={() => navigate('/accounts')}>
          {'<'}
        </div>
        <div className="text">Profile</div>
        <div className="icon"></div>
      </div>
      <div className="tabs-nav">
        <div className="item">
          <NavLink to="/personal">Personal</NavLink>
        </div>
        <div className="item">
          <NavLink to="/add-interest">Interests</NavLink>
        </div>
        <div className="item">
          <NavLink to="/education">Education</NavLink>
        </div>
        <div className="item">
          <NavLink to="/career">Career</NavLink>
        </div>
        <div className="item">
          <NavLink to="/profile-sharing">Profile Sharing</NavLink>
        </div>
      </div>
    </div>
  )
}

export default NavTabs
