import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { isEmail } from "../utils";
import { toast, Toaster } from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT } from '../../../Services/mutation/student'
import { setUserDataAction } from '../../../Redux/actions/userActions';
import { setCognitoUserDataAction } from "../../../Redux/actions/cognitoUserActions";

const ChangeEmail = () => {
  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const sendVerificationLink = async (email) => {
    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_URL}/email-verification`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: user.name,
            email: email,
            studentId: user.id,
          }),
        }
      );
      navigate("/verify-email?status=new");
    } catch (e) {
      toast.error("An error occurred, try again later.");
    }
  };

  const onChangeEmail = async()=>{
    setLoading(true);
    try {
      const loggedInUser = await Auth.signIn(user.email, state.password);
      await Auth.updateUserAttributes(loggedInUser, {email: state.email});
      setCognitoUserDataAction(loggedInUser);
      const data = await updateStudent({
        variables: {
          id: user?.id, 
          email: state.email,
          email_verified: false
        }
      })
      dispatch(setUserDataAction(data?.data?.updateStudent));
      await sendVerificationLink(state.email)
      setLoading(false);
    } catch (error) {
      setLoading(false)
      toast.error('An error occurred. Try again later.')
    }
  }

  useEffect(() => {
    setValid(
      Object.keys(state).length === 2 &&
        Object.values(state).every((elt) => elt.trim() !== "")
    );
  }, [state]);

  const handleChange = (e) => {
    const newState = state;

    newState[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      if (!isEmail(e.target.value) || (e.target.value === user?.email))
        delete newState[e.target.name];
    } 

    setState({ ...newState });
  };
  
  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to="/account-settings">
            <span>{'<'}</span>
          </Link>
        </div>
      </div>
      <div className="auth-sec mt-3">
        <div className="heading">Change Email Address</div>
        <div className="sub-heading">
          Change to an email you have access to as a verification code will be
          sent to the new email.
        </div>
        <form>
          <div className="form-auth">
            <label>New Email Address </label>
            <input 
              type="email" 
              name='email'
              placeholder="New email address " 
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-auth">
            <label>Password</label>
            <input 
              type="password" 
              name='password'
              placeholder="Type your password"
              onChange={handleChange}
              required
            />
          </div>
          <div className="submit-login">
            <button 
              type="button"
              className={valid ? loading ? 'inactive' : '' : 'inactive'}
              onClick={onChangeEmail}
              disabled={!valid}
            >
                { loading ? "Loading..." : "Change Email Address" }
            </button>
          </div>
        </form>
      </div>
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
      />
    </>
  )
}

export default ChangeEmail
