import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Star } from "react-feather";
import { useSelector } from "react-redux";
import { CREATE_REVIEW, UPDATE_REVIEW } from "../../Services/mutation/review";
import { GetReviewsByResourceIDAndStudentID } from "../../Services/query/review";
import "./style.scss";
import { axiosClient } from "../../libs/axiosClient";
import AlertToast from "../Common/Alerts/ToastAlert";

const stopProp = (e) => {
  e.stopPropagation();
};

function ReviewOpportunity(props) {
  const {
    removeOverlay,
    resourceName,
    resourceID,
    setReviewed,
    shown,
    position,
  } = { ...props };

  const [stars, setStars] = useState({});
  const [detail, setDetail] = useState("");
  const [valid, setValid] = useState(false);
  const [toastStatus, setToastStatus] = useState("");
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState("");
  const [loading, setLoading] = useState(false);
  const [createReview] = useMutation(CREATE_REVIEW);
  const [updateReview] = useMutation(UPDATE_REVIEW);
  const user = useSelector((store) => store.user);

  const handleToast = (status, message) => {
    setToastStatus(status);
    setToast(message);
    setActiveToast(true);
  };

  const postReview = async () => {
    setLoading(true);
    let filledStars = 0;
    Object.entries(stars)?.forEach((elt) => {
      const [, value] = [...elt];
      filledStars += value ? 1 : 0;
    });


    const review = await GetReviewsByResourceIDAndStudentID(
      user.id,
      resourceID
    );

    if (review?.length === 0) {
      createReview({
        variables: {
          resourceID,
          review: detail,
          stars: filledStars,
          studentID: user?.id,
          createdAt: new Date(),
        },
      })
        .then(async () => {
          await axiosClient.post("/update-resource-view", {
            studentID: user?.id,
            balance: user?.balance || 0,
            multiplier: user?.multiplier || 0,
            scan: user?.resource_reviews_scan || 0,
          });
          setLoading(false);
          setReviewed(true);
          removeOverlay();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          handleToast("error", "Error occurred while posting review");
        });
    } else if (review?.length === 1) {
      updateReview({
        variables: {
          id: review.id,
          review: detail,
          stars: filledStars,
          updatedAt: new Date(),
        },
      })
        .then(() => {
          setLoading(false);
          setReviewed(true);
          removeOverlay();
        })
        .catch((err) => {
          setLoading(false);  
          handleToast("error", "Error occurred while updating review");
          console.error(err);
        });
    } else {
      setLoading(false);
      console.error("Error occurred");
    }
  };

  const handleReviewChange = (e) => {
    setDetail(e.target.value.slice(0, 200));
  };

  const fillStars = (id) => {
    if (id === 5) {
      setStars({ ...stars, s1: true, s2: true, s3: true, s4: true, s5: true });
    } else if (id > 0) {
      const selected = {};
      for (let i = 1; i <= id; i++) {
        selected[`s${i}`] = true;
      }
      setStars(selected);
    }
  };

  useEffect(() => {
    setValid(detail?.trim() !== "");
  }, [detail, stars]);

  return (
    <>
      <AlertToast
        variant={toastStatus}
        icon={toastStatus === "success" ? "check" : "delete"}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="review_container">
              <h5>Leave a Review</h5>
              <div className="review">
                <Star
                  size={30}
                  className={stars?.s1 ? "active" : ""}
                  onClick={() => fillStars(1)}
                />
                <Star
                  size={30}
                  className={stars?.s2 ? "active" : ""}
                  onClick={() => fillStars(2)}
                />
                <Star
                  size={30}
                  className={stars?.s3 ? "active" : ""}
                  onClick={() => fillStars(3)}
                />
                <Star
                  size={30}
                  className={stars?.s4 ? "active" : ""}
                  onClick={() => fillStars(4)}
                />
                <Star
                  size={30}
                  className={stars?.s5 ? "active" : ""}
                  onClick={() => fillStars(5)}
                />
              </div>
              <div className="review_detail">
                <textarea
                  cols={60}
                  rows={5}
                  maxLength={200}
                  className="form-control"
                  value={detail}
                  onChange={handleReviewChange}
                  placeholder={`How was your experience with ${resourceName}?`}
                ></textarea>
              </div>
              <p>Your review will be posted anonymously</p>
              <div className="select-btn">
                <button
                  type="button"
                  disabled={!valid}
                  style={{ opacity: valid ? 1 : 0.6 }}
                  onClick={postReview}
                >
                  {loading ? "Loading..." : "Post Review" }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewOpportunity;
