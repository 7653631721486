import React, { useState, useEffect } from 'react'
import NavTabs from '../Nav'
import EditPen from '../../../../Assets/images/pencil.svg'
import AddPlus from '../../../../Assets/images/plus-circle.svg'
import AddVolunteer from '../../../../Components/Popus/AddVolunteer'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT } from '../../../../Services/mutation/student'
import { setUserDataAction } from '../../../../Redux/actions/userActions'
import Verified from '../../../../Components/Popus/Verified'
import { 
  CREATE_STUDENT_VOLUNTEER_WORK, 
  UPDATE_STUDENT_VOLUNTEER_WORK 
} from '../../../../Services/mutation/studentVolunteerWork'
import { SingleStudentVolunteerWork } from '../../../../Services/query/studentVolunteerWork';
import { GetSingleStudent } from '../../../../Services/query/students'

const CareerTab = ({ hideNav, proceed }) => {
  const user = useSelector((store) => store.user);

  const [overlay, setOverlay] = useState(false);
  const [employed, setEmployed] = useState(user?.employed);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentEmployment, setCurrentEmployment] = useState({
    current_employer: user.current_employer,
    current_occupation: user.current_occupation
  });
  const [volunteerWork, setVolunteerWork] = useState([]);
  const [student, setStudent] = useState([]);

  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [createVolunteerWork] = useMutation(CREATE_STUDENT_VOLUNTEER_WORK);
  const [updateVolunteerWork] = useMutation(UPDATE_STUDENT_VOLUNTEER_WORK);
  
  const [filteredStudentVolunteerWork, setFilteredStudentVolunteerWork] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState({});

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setCurrentEmployment({
      ...currentEmployment,
      [e.target.name]: e.target.value
    });
  };

  const updateEmploymentStatus = () => {
    let inputData = {}
    if (!employed) {
      inputData = { employed }
    } else {
      inputData = { ...currentEmployment }
    }

    updateStudent({
      variables: { ...inputData, id: user?.id }
    }).then((data) => {
      setLoading(false);
      dispatch(setUserDataAction(data?.data?.updateStudent));
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }).catch(() => {
      setLoading(false);
    });
  };

  const createOrUpdateVolunteerWork = async (work) => {
    const { organization, hours_spent } = { ...work };
    try {
      if (work.id) {
        const work = volunteerWork[0];
        await updateVolunteerWork({
          variables: {
            id: work.id,
            updatedAt: new Date(),
            organization,
            hours_spent
          }
        })
      } else {
        await createVolunteerWork({
          variables: {
            organization,
            hours_spent,
            studentID: user.id,
            createdAt: new Date(),
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const save = async () => {
    setLoading(true);
    if (!employed || Object.keys(currentEmployment || {}).length) {
      updateEmploymentStatus();
    }
    
    if (volunteerWork.length > 0) {
      volunteerWork.forEach(async (work) => {
        await createOrUpdateVolunteerWork(work);
      });
    }

    if (hideNav) proceed();
  }

  const editVolunteerWork = (work) => {
    setSelectedVolunteer(work);
    const filteredVolunteerWork = volunteerWork?.filter(
      (elt) => elt.organization !== work.organization
    );
    setVolunteerWork(filteredVolunteerWork);
    setOverlay(true);
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const volunteerWork = await SingleStudentVolunteerWork(user?.id);
      setFilteredStudentVolunteerWork(volunteerWork);

      const student = await GetSingleStudent(user.id);
      setStudent(student);
    }
    handleSetDependencies();
  }, [])
  

  return (
    <>
      <AddVolunteer 
        removeOverlay={() => setOverlay(false)} 
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
        data={volunteerWork}
        setData={setVolunteerWork}
        selectedItem={selectedVolunteer}
      />
      <Verified
        removeOverlay={() => setSuccess(false)}
        shown={success ? 'shown': 'hidden'}
        position={success ? 'slide-up' : 'slide-down'}
        header="Success!"
        text="Your profile has been updated."
      />
      {!hideNav && <NavTabs />}
      <div className={`auth-sec ${hideNav ? "auth-sec-hide px-0 pb-0" : "pb-6"}`}>
        <div className="des">
          Details about your career help resources understand your suitability
          for opportunities.
        </div>
        <form>
          <div className="edu-check">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customControlAutosizing1"
                checked={!employed}
                onChange={() => setEmployed(!employed)}
              />
              <label
                className="custom-control-label"
                htmlFor="customControlAutosizing1"
              >
                I am not currently employed
              </label>
            </div>
          </div>
          <>
            <div className="form-auth">
              <label>Current Employer Name</label>
              <input
                type="text"
                defaultValue=""
                value={currentEmployment?.current_employer}
                name="current_employer"
                placeholder="Current Employer Name"
                onChange={handleChange}
                disabled={!employed}
                className={!employed && "grayed"}
              />
            </div>
            <div className="form-auth">
              <label>Current Occupation</label>
              <input
                type="text"
                defaultValue=""
                value={currentEmployment?.current_occupation}
                name="current_occupation"
                onChange={handleChange}
                placeholder="Current Occupation"
                disabled={!employed}
                className={!employed && "grayed"}
              />
            </div>
          </>
          <div className="volunteer">
            <div className="label">VOLUNTEER WORK</div>
            {(volunteerWork.length > 0 ? volunteerWork : filteredStudentVolunteerWork)?.map((work, index) => 
            <div className="added-item mb-2" key={index}>
              <div className="info">
                <div className="title">{work?.organization}</div>
                <div className="hours">{work?.hours_spent} Hours</div>
              </div>
              <div className="edit" onClick={() => editVolunteerWork(work)}>
                <img src={EditPen} alt="icon" />
              </div>
            </div>
            )}
            {(!volunteerWork || volunteerWork.length < 5)
            ?
            <div className="add-item" onClick={() => setOverlay(true)}>
              <img src={AddPlus} alt="" />
              <span>Add volunteer work</span>
            </div>
            : null
            }
          </div>
        </form>
        <div className={`select-btn ${student.is_verified ? '' : 'inactive'}`}>
          {hideNav && (<p>
            By applying to this opportunity, you are allowing this organization
            view your profile for use in processing your application. Learn more
            about this in our <strong>Privacy Policy</strong>.
          </p>)}
          <button onClick={save}>
            {loading ? "Loading..." : `${hideNav ? "Submit Application" : "Save"}`}
          </button>
        </div>
      </div>
    </>
  )
}

export default CareerTab
