import {
  SET_REFERRED_BY_DATA,
  RESET_REFERRED_BY_DATA,
} from "../constants/referredByConstants";

export const setReferredByDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_REFERRED_BY_DATA,
    payload: data,
  });
};

export const resetReferredByDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_REFERRED_BY_DATA,
  });
};
