import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckSquare } from "react-feather";
import { AllStudentOpportunityApplicationsByStudentID } from "../../../Services/query/studentOpportunityApplications";
import { GetReviewsByStudentID } from "../../../Services/query/review";
import { useLocalStorage } from "../../../utils/storage";
import { daysPassed } from "../../../utils/helpers";
// import {ReactComponent as MessageCircleGreen} from "../../../Assets/images/message-circle-green.svg";


function CompleteTasks({ studentId, addNotification }) {
  const navigate = useNavigate();
  const [notified, setNotified] = useLocalStorage('tasks_notified', false)
  const [value, setValue] = useLocalStorage('tasks_notifier', new Date().toISOString())
  const [appsRequiringReview, setAppsRequiringReview] = useState([]);

  useEffect(() => {
    AllStudentOpportunityApplicationsByStudentID(studentId).then(async (apps) => {
      const reviews = await GetReviewsByStudentID(studentId);
      const requiringReviews = apps.filter((elt) => reviews?.some((review) => review?.resourceID === elt?.schoolID) === false);
      setAppsRequiringReview(requiringReviews);
      
      if (requiringReviews?.length){
        if ((!daysPassed(value) && !notified) || daysPassed(value)){
          setNotified(true)
          // await sendAppNotification(
          //   studentId,
          //   `Continue setting up your Junity account.`,
          //   'success',
          //   `/things-to-do`,
          //   'Click here to view pending tasks!',
          //   'youth',
          //   'push',
          //   'messageCircleSuccess'
          // )
          // await sendAppNotification(
          //   studentId,
          //   `Congrats on utilizing a Junity resource.`,
          //   'success',
          //   `/things-to-do`,
          //   'Click here to view pending tasks!',
          //   'youth',
          //   'push',
          //   'messageCircleSuccess'
          // )
          if (daysPassed(value)) setValue(new Date().toISOString())
        }
      }
    });
  }, [studentId])
  
  
  return ( 
    <div
      className="complete-tasks"
      onClick={() => navigate("/things-to-do")}
    >
      <div className="circle">
        <CheckSquare size="18" />
      </div>
      <div className="info">
        <div className="title">
          Complete these tasks{" "}
          <span>{appsRequiringReview?.length || 0}</span>
        </div>
        <div className="des">Stay active and earn more Jcoins</div>
      </div>
      <div className="action">{">"}</div>
    </div>
  );
}

export default CompleteTasks;