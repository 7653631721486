import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImgSix from '../../Assets/images/scene-6.webp'
import ImgSeven from '../../Assets/images/scene-7.webp'
import ImgEight from '../../Assets/images/scene-8.webp'
import ImgNine from '../../Assets/images/scene-9.webp'

import './style.scss';

function LaunchingApp() {
  const navigate = useNavigate();

  const deactivateAllItems = ()=>{
    document.querySelectorAll('.carousel-item')
		.forEach((e, i)=>{
		  e.classList.remove('active')
		})
  }

  const activateItem = (index)=>{
	  document.getElementsByClassName('carousel-item')[index].classList.add('active')
  }

  const startCarousel = ()=>{
    setTimeout(() => {
      deactivateAllItems()
      activateItem(1)
      setTimeout(() => {
        deactivateAllItems()
      	activateItem(2)
        setTimeout(() => {
			deactivateAllItems()
			activateItem(3)
			setTimeout(() => {
				navigate('/home')
			}, 4000)
        }, 4000)
      }, 5000)
    }, [5000])
  }

  return ( 
    <div className='setting_up_loader'>
      {/* <img src={Loader} id="loader_img" alt="loader boxes"/> */}
      <div id="setupCarousel" className="carousel slide" data-wrap="false" data-touch="false">
        <div className="carousel-inner">
          <div className="carousel-item active">
              <img src={ImgSix} onLoad={startCarousel} className="d-block img-fluid" alt="..."/>
              <div className="carousel-caption text-dark position-relative">
                <p>Building your profile ...</p>
              </div>
          </div>
          <div className="carousel-item">
              <img src={ImgSeven} className="d-block img-fluid" alt="..."/>
              <div className="carousel-caption text-dark position-relative">
                <p>Finding opportunities for you ...</p>
              </div>
          </div>
          <div className="carousel-item">
              <img src={ImgEight} className="d-block img-fluid" alt="..."/>
              <div className="carousel-caption text-dark position-relative">
                <p>Finding institutions near you ...</p>
              </div>
          </div>
          <div className="carousel-item">
              <img src={ImgNine} className="d-block img-fluid" alt="..."/>
              <div className="carousel-caption text-dark position-relative">
                <p>Wrapping up ...</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchingApp;