import React from "react";
import { Trash2 } from "react-feather";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const DeleteAccount = ({ removeOverlay, shown, position, setDeleteAcc }) => {

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="delete-popup">
              <div className="circle">
                <Trash2 size="64" />
              </div>
              <div className="title text-center">Delete Account</div>
              <div className="des text-center">
                This action can not be reversed. Once you delete your account,
                it is gone forever.
              </div>
              <div className="act">
                <div className="label text-center">Are you sure you want to continue?</div>
                <button className="primary" onClick={removeOverlay}>No, Go back to account </button>
                <button className="danger" onClick={() => setDeleteAcc(true)}>Yes, Delete Account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
