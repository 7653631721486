/* eslint-disable no-unused-expressions */
import { Auth } from "aws-amplify";
import { 
  cognitoidentity, 
  paramsWithUsername, 
  paramsUpdateAttr 
} from "../../userPools";

export const deleteUser = (oldUsername, phone, password, data, updateStudent) => {
  cognitoidentity.adminDeleteUser(paramsWithUsername(oldUsername), (err, dataParams) => {
    if (err) { console.error(err); }
    else { 
      createUser(phone, password, data, updateStudent) 
    }
  })
}

export const createUser = (username, password, data, updateStudent) => {
  Auth.signUp(username, password).then(() => {
    cognitoidentity.adminUpdateUserAttributes(
      paramsUpdateAttr(username, [
        { Name: "address", Value: data?.address },
        { Name: "birthdate", Value: data?.birthdate },
        { Name: "gender", Value: data?.gender },
        { Name: "name", Value: data?.name },
        { Name: "phone_number", Value: data?.phone_number },
      ]),
      (err, data) => { 
        if (err) { console.error(err); }
        else { updateStudent(username) }
      })
    }).catch((err) => {
      console.error(err);
    });
}

export const cleanResp = (attrs) => {
  const data = {};
  attrs?.forEach((elt) => {
    data[elt.Name] = elt.Value;
  });
  return data;
};

export const isEmail = (email) =>
  email.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );