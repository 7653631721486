import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/images/logo.png";
import Flag from "../../Assets/images/flag.svg";
import Upload from "../../Assets/images/upload.svg";
import Close from "../../Assets/images/x.svg";
import NoImg from "../../Assets/images/no-img.jpg";
import Heart from "../../Assets/images/heart.svg";
import ConnectCounselor from "../../Components/Popus/ConnectCounselor";
import RemoveConnection from "../../Components/Popus/RemoveConnection";
import { GetSingleSchool } from "../../Services/query/school";
import { useDispatch, useSelector } from "react-redux";
import { GetEducatorsBySchoolID } from "../../Services/query/educators";
import { GetSingleStudent } from "../../Services/query/students";
import { useMutation } from "@apollo/client";
import { UPDATE_SCHOOL } from "../../Services/mutation/school";
import { UPDATE_STUDENT } from "../../Services/mutation/student";
import CounselorInstituteLoader from "../../Components/Common/FindCouncelorLoader/CounselorInstituteLoader";
import { calculateTime, getSchoolState } from "../../utils/helpers";
import AlertToast from "../../Components/Common/Alerts/ToastAlert";
import { AddLike } from "../../utils/common";
import { MoreHorizontal } from "react-feather";
import ChatCounselor from "../../Components/Popus/ChatCounselor";
import { chatClient } from "../../libs/axiosClient";
import { CREATE_CHAT_HANDLER } from "../../Services/mutation/chatHandler";
import { ReportIssueModal } from "../../Components/Popus/ReportIssue";

import "./style.scss";
import { setRoutingStateDataAction } from "../../Redux/actions/routingStateAction";
import { useAuth } from "../../Components/Auth";

const CounselorIns = () => {
  const [overlay, setOverlay] = useState(false);
  const [chatOverlay, setChatOverlay] = useState(false);
  const [reportOverlay, setReportOverlay] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [removeConnectionOverlay, setRemoveConnectionOverlay] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState("");
  const search = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = search.get("id");

  const [expandDes, setExpandDes] = useState(true);
  const [school, setSchool] = useState({});
  const [student, setStudent] = useState({});
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [selectedCounselor, setSelectedCounselor] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);
  const [filteredCouncelors, setFilteredCouncelors] = useState([]);
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [createChatHandler] = useMutation(CREATE_CHAT_HANDLER);

  const user = useSelector((store) => store.user);
  const homeOpps = useSelector((store) => store.homeOpps);
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const handleSetSchool = async () => {
    const school = await GetSingleSchool(id);
    setSchool(school);
  };

  const share = async () => {
    if (school?.id) {
      const file = await fetch(Logo)
        .then((response) => response.blob())
        .then((blob) => new File([blob], "logo.png", { type: blob.type }));

      const data = {
        title: school?.name,
        text: school?.about,
        url: `${process.env.REACT_APP_KYC_URL}/mentor-profile?id=${id}`,
        files: [file],
      };

      if (navigator.canShare(data)) {
        try {
          await navigator.share(data);
        } catch (err) {
          console.error(`Error: ${err}`);
        }
      }
    }
  };

  useEffect(() => {
    const handleSetSchool = async () => {
      const school = await GetSingleSchool(id);
      setSchool(school);
    };

    handleSetSchool();
  }, [id]);

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user.id);
      setStudent(student);
      const educators = await GetEducatorsBySchoolID(id);
      setFilteredCouncelors(educators);
      setLoaded(true);
    };
    studentVerified();
  }, [id, user?.id]);

  const connect = (dontShowAgain = true) => {
    const { connections, connection_dates, id } = { ...school };

    const connectionsObj = connections?.length > 0 ? connections : [];
    const connectionDates =
      connection_dates?.length > 0 ? connection_dates : [];
    const profileSharing =
      student?.profile_sharing?.length > 0 ? student?.profile_sharing : [];
    const inputData = {
      id,
      connections: [...connectionsObj, user?.id],
      connection_dates: [...connectionDates, new Date()],
    };

    if (dontShowAgain) {
      if (school.counselors_dont_show_again !== null) {
        inputData.counselors_dont_show_again = [
          ...school.counselors_dont_show_again,
          user?.id,
        ];
      } else {
        inputData.counselors_dont_show_again = [user?.id];
      }
    }

    updateSchool({
      variables: { ...inputData },
    })
      .then(() => {
        updateStudent({
          variables: {
            id: student.id,
            profile_sharing: [...profileSharing, id],
          },
        })
          .then(() => {
            navigate(`/connecting-counselor?id=${id}`);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleConnect = () => {
    if (school.accepting_connection) {
      if (student?.is_verified) {
        if (
          school.counselors_dont_show_again !== null &&
          school.counselors_dont_show_again.includes(user.id)
        ) {
          connect();
          navigate(`/connecting-counselor?id=${id}`);
        } else {
          setOverlay(true);
        }
      }
    } else {
      setActiveToast(true);
      setToast("Institution is not accepting any connection");
    }
  };

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };

  const createChat = async (chatStarted, chat) => {
    try {
      const users = await chatClient.get("/users");
      const usernames = [selectedCounselor, user];

      await Promise.all(
        usernames.map(async (elt) => {
          const isCreated = users.data.findIndex(
            (item) => item.username === elt?.id
          );
          if (isCreated === -1) {
            await chatClient.post("/users/", {
              username: elt?.id,
              first_name: elt?.name?.split(" ")[0],
              last_name: elt?.name?.split(" ").splice(1).join(""),
              secret: elt?.id,
            });
          }
        })
      );

      if (chatStarted === -1) {
        const data = {
          usernames: [selectedCounselor?.id, user?.id],
          title: selectedCounselor?.name,
          is_direct_chat: true,
          custom_json: JSON.stringify({
            orgID: selectedCounselor?.schoolID,
            creatorID: student?.id,
            creatorType: "youth",
            destinationType: "educator",
            destinationID: selectedCounselor?.id,
            senderName: student?.name,
            destinationName: selectedCounselor?.name,
            destinationImg: selectedCounselor?.image,
            type: "sameOrgDirectChat",
          }),
        };

        const chat = await chatClient.put("/chats/", data);
        createChatHandler({
          variables: {
            chat_id: chat.data.id,
            access_key: chat.data.access_key,
            createdAt: new Date(),
          },
        });

        navigate(`/chat-person?id=${chat.data.id}&type=educator`);
      } else {
        navigate(`/chat-person?id=${chat.id}&type=educator`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const routeToApplyOpportunity = (elt) => {
    dispatch(
      setRoutingStateDataAction({
        singleOpportunityPreviousRoute: location.pathname + location.search,
      })
    );
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  };

  const proceedToChat = (counselor) => {
    if (student?.is_verified) {
      setSelectedCounselor(counselor);
      setChatOverlay(true);
    }
  };

  return (
    <>
      <AlertToast
        variant={"error"}
        icon={"delete"}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <ConnectCounselor
        removeOverlay={() => setOverlay(false)}
        school={school}
        profile_sharing={student?.profile_sharing}
        student={student}
        connect={connect}
        refetch={handleSetSchool}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <RemoveConnection
        removeOverlay={() => setRemoveConnectionOverlay(false)}
        school={school}
        student={student}
        refetch={handleSetSchool}
        shown={removeConnectionOverlay ? "shown" : "hidden"}
        position={removeConnectionOverlay ? "slide-up" : "slide-down"}
      />
      <ChatCounselor
        counselor={selectedCounselor}
        proceed={createChat}
        user={user}
        removeOverlay={() => setChatOverlay(false)}
        shown={chatOverlay ? "shown" : "hidden"}
        position={chatOverlay ? "slide-up" : "slide-down"}
      />
      <ReportIssueModal
        removeOverlay={() => setReportOverlay(false)}
        shown={reportOverlay ? "shown" : "hidden"}
        position={reportOverlay ? "slide-up" : "slide-down"}
        title={`Report ${school?.name}`}
        module={school}
        type={"Counselor"}
      />
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon mr-3">
          <img src={Upload} alt="icon" onClick={share} />
        </div>
        <div className="icon" onClick={() => setReportOverlay(true)}>
          <img src={Flag} alt="icon" />
        </div>
      </div>
      {loaded ? (
        <div className="institute-couns mt-5 bg-white">
          <div className="ins-head">
            <div className="bg">
              {school?.banner ? <img src={school?.banner} alt="img" /> : ""}
            </div>
            <div className="logo">
              <img src={school?.image} alt="img" />
            </div>
            <div className="info">
              <div className="title">{school?.name}</div>
              <div className="connections">
                <div className="text">
                  {getSchoolState(school?.full_address)}
                </div>
                <div className="dot"></div>
                <div className="text">
                  {school?.connections?.length || 0} connection
                  {school?.connections?.length === 1 ? "" : "s"}
                </div>
              </div>
              <div className="connect-btn">
                {school?.connections?.includes(user?.id) ? (
                  <button
                    onClick={() => setRemoveConnectionOverlay(true)}
                    className="active"
                  >
                    Connected
                  </button>
                ) : (
                  <button
                    onClick={handleConnect}
                    style={{ opacity: student?.is_verified ? "1" : "0.6" }}
                  >
                    Connect
                  </button>
                )}
                {/* <p>Counselors typically respond within 1 hour</p> */}
              </div>
            </div>
          </div>
          {school?.connections?.includes(user?.id) ? (
            <React.Fragment>
              <div className="counselor-tabs">
                <ul className="nav nav-pills nav-justified">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="counselors-tab"
                      data-toggle="tab"
                      data-target="#counselors"
                      type="button"
                      role="tab"
                      aria-controls="recommendations"
                      aria-selected="false"
                    >
                      Counselors
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="recommendations-tab"
                      data-toggle="tab"
                      data-target="#recommendations"
                      type="button"
                      role="tab"
                      aria-controls="recommendations"
                      aria-selected="false"
                    >
                      Recommendations
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="counselors"
                  role="tabpanel"
                  aria-labelledby="counselors-tab"
                >
                  <div className="counselors">
                    <div className="title">
                      Counselors ({filteredCouncelors?.length || 0})
                    </div>
                    {filteredCouncelors?.map((elt, index) => (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        onClick={() => proceedToChat(elt)}
                        key={index}
                      >
                        <div className="item">
                          <div className="img">
                            <img src={elt?.image || NoImg} alt="img" />
                          </div>
                          <div className="name">{elt?.name}</div>
                        </div>
                        <MoreHorizontal />
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="recommendations"
                  role="tabpanel"
                  aria-labelledby="recommendations-tab"
                >
                  <div className="popular-items">
                    {homeOpps.recommendations.map((elt, index) => (
                      <div className={`item`} key={index}>
                        <div className="item-head">
                          <div className="p-logo">
                            <img
                              src={elt?.school?.image}
                              className="circle"
                              alt="c-logo"
                            />
                            <div
                              className={`heart ${
                                user?.verification_status === "verified"
                                  ? ""
                                  : "inactive"
                              }`}
                              onClick={() => addLike(elt)}
                            >
                              {state[elt?.id] ||
                              elt?.liked_by?.includes(user?.id) ? (
                                <i className="fas fa-heart"></i>
                              ) : (
                                <img src={Heart} alt="icon" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="info"
                          onClick={() => routeToApplyOpportunity(elt)}
                        >
                          <div className="title">{elt?.name}</div>
                          <div className="des break-overview">
                            {elt?.description}
                          </div>
                          <div className="date-likes">
                            <div className="date">
                              {calculateTime(elt?.createdAt)}
                            </div>
                            <div className="dot"></div>
                            <div className="likes">
                              {Object.keys(likes)?.includes(elt?.id)
                                ? likes[elt?.id]
                                : elt?.likes || 0}{" "}
                              {likes[elt?.id] === 1 ? " like" : " likes"}
                            </div>
                          </div>
                        </div>
                        <div
                          className="p-type mr-1"
                          type={elt?.category}
                          key={index}
                        >
                          {elt?.category}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="counselors">
              <div className="title">
                Counselors ({filteredCouncelors?.length || 0})
              </div>
              {filteredCouncelors?.map((elt) => (
                <div className="item">
                  <div className="img">
                    <img src={elt?.image || NoImg} alt="img" />
                  </div>
                  <div className="name">{elt?.name}</div>
                </div>
              ))}
            </div>
          )}

          <div className="information">
            <div className="title">About</div>
            <div className={`des ${expandDes ? "" : "break-detail"}`}>
              {school?.about}
            </div>
            <div
              className="see-more counselor"
              onClick={() => setExpandDes(!expandDes)}
            >
              {!expandDes ? "See more" : "See less"} <span>{">"}</span>
            </div>
            <div className="social-links">
              <label>Email</label>
              <div className="link">
                <a href={`mailto:${school?.contact_email}`}>
                  {school?.contact_email}
                </a>
              </div>
            </div>
            <div className="social-links">
              <label>Website</label>
              <div className="link">
                <a href={school?.website}>{school?.website}</a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CounselorInstituteLoader />
      )}
    </>
  );
};

export default CounselorIns;
