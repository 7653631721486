import React from "react";
import { Info, ShoppingBag, File, Flag } from "react-feather";
import JIcon from "../../Assets/images/jCoin.svg";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const PurchasesOptions = ({ removeOverlay, shown, position }) => {
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="my-account">
              <div className="list">
                <div className="item">
                  <Flag />
                  <span>Report an Issue</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasesOptions;
