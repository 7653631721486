import React from "react";
import CustomApp from "./CustomApp";

import "./Chatmain.css";


function ChatScreen() {

  return (
    <div className="chat-screen col-sm-12  pl-0 pr-0">
      <CustomApp />
    </div>
  );
}

export default ChatScreen;
