import {
  SET_HOME_OPPORTUNITY_DATA,
  RESET_HOME_OPPORTUNITY_DATA,
} from "../constants/homeOpportunityConstants";

export const setHomeOpportunityDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_HOME_OPPORTUNITY_DATA,
    payload: data,
  });
};

export const resetHomeOpportunityDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_HOME_OPPORTUNITY_DATA,
  });
};
