import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { XCircle } from 'react-feather'
import { useMutation } from '@apollo/client'
import { UPDATE_SCHOOL } from '../../Services/mutation/school'
import { formatDate } from '../../utils/helpers'
import { UPDATE_STUDENT } from '../../Services/mutation/student'
import './style.scss'


const stopProp = (e) => {
  e.stopPropagation()
}

const RemoveConnection = (props) => {
  const {
    student,
    removeOverlay, 
    school, 
    refetch,
    shown,
    position,
  } = { ...props }

  const navigate = useNavigate();
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const user = useSelector((store) => store.user);
  const {
    connections,
    connection_dates,
    id
  } = { ...school }

  const connectionDates = connection_dates?.filter(
    (elt, index) => index !== connections.indexOf(user?.id)
  );

  const connectionDate = connection_dates?.length > 0 
  ? connection_dates[connections?.indexOf(user?.id)] 
  : '';

  const removeConnection = () => {
    const connectionsObj = connections?.filter(
      (elt) => elt !== user?.id
    );

    const profileSharing = student?.profile_sharing?.filter(
      (elt) => elt !== id
    );
  
    updateSchool({
      variables: {
        id,
        connections: [...connectionsObj],
        connection_dates: [...connectionDates],
      }
    }).then(() => {
      updateStudent({
        variables: { 
          id: student.id, 
          profile_sharing: [...profileSharing]
        }
      }).then(() => {
        refetch();
        navigate(`/counselor-institute?id=${id}`);
        removeOverlay();
      }).catch((err) => {
        console.error(err);
      });
    })
    .catch(() => console.error('Error connecting to school'));
  }

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="remove-c">
              <div className="item" onClick={removeConnection}>
                <XCircle size="16" />
                <span>Remove Connection</span>
              </div>
              <div className="des">
                You’ve been connected to this institution since {formatDate(connectionDate)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RemoveConnection
