/* eslint-disable no-unused-expressions */
import { gql } from "@apollo/client";
import { API } from 'aws-amplify'

export const GET_ALL_STUDENTS = gql`
  query AllStudents {
    listStudents (limit: 1000) {
      items {
        email
        name
        state
        id
        educatorID
        guardianID
        chat_username
        chat_secret
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        username
        multiplier_level
        multiplier
        interestIDs
        interests
        address
        balance
        apartment
        hubspotID
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        referral_code
        current_employer
        current_occupation
        email_verified
        dont_show_app_pop
        event_submissions_recommendation_scan
        event_submissions_scan
        consecutive_counselor_chat_scan
        consecutive_resource_chat_scan
        invited_by
        invites_scan
        opportunity_acceptance_scane
        opportunity_share_scan
        resource_reviews_scan
        submissions_scan
        unique_views_scan
        shared_opportunities
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN = gql`
  query StudentsQuery ($limit: Int, $nextToken: String) {
    listStudents (limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        state
        id
        educatorID
        guardianID
        chat_username
        chat_secret
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        multiplier
        multiplier_level
        address
        balance
        referral_code
        apartment
        interestIDs
        interests
        hubspotID
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        username
        dont_show_app_pop
        event_submissions_recommendation_scan
        event_submissions_scan
        consecutive_counselor_chat_scan
        consecutive_resource_chat_scan
        invited_by
        invites_scan
        opportunity_acceptance_scane
        opportunity_share_scan
        resource_reviews_scan
        submissions_scan
        unique_views_scan
        shared_opportunities
      }
      nextToken
    }
}
`

export async function AllStudents() {
  const resp = await API.graphql({ 
    query: GET_ALL_STUDENTS,
  });

  const data = resp?.data?.listStudents
  let nextToken = data?.nextToken
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_ALL_STUDENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudents

    const previousData = [...students, ...respData?.items]
    students = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return students || [];
}

export const GET_STUDENT = gql`
  query SingleStudent($id: ID!) {
    getStudent(id: $id) {
      email
      name
      state
      id
      educatorID
      guardianID
      chat_username
      chat_secret
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      createdAt
      referral_code
      multiplier
      multiplier_level
      address
      balance
      apartment
      hubspotID
      graduation_year
      graduation_term
      is_verified
      verification_date_started
      verification_progress
      verification_progress_dates
      verification_status
      interestIDs
      interests
      schooling_mode
      school
      profile_sharing
      current_employer
      current_occupation
      employed
      email_verified
      dont_show_app_pop
      username
      event_submissions_recommendation_scan
      event_submissions_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invited_by
      invites_scan
      opportunity_acceptance_scane
      opportunity_share_scan
      resource_reviews_scan
      submissions_scan
      unique_views_scan
      shared_opportunities
    }
  }
`;

export async function GetSingleStudent(id) {
  try {
    const respStudent = await API.graphql({
      query: GET_STUDENT,
      variables: { id },
      cache: true,
    });
    const student = respStudent?.data?.getStudent;
    return student;
  } catch (err) {
    console.error(err);
    return {};
  }
}


export async function GetStudentByEmail(email) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.email === email);
  return student || {};
}

export async function GetStudentByPhone(phone) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.phone === phone);
  return student || {};
}

export const GET_ALL_STUDENTS_BY_INVITED_BY = gql`
  query AllStudents ($userID: ID) {
    listStudents (limit: 1000, filter: { invited_by: { eq: $userID }}) {
      items {
        name
        createdAt
        invited_by
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN_BY_INVITED_BY = gql`
  query StudentsQuery ($limit: Int, $nextToken: String, $userID: ID) {
    listStudents (limit: $limit, nextToken: $nextToken, filter: { invited_by: { eq: $userID }}) {
      items {
        name
        createdAt
        invited_by
      }
      nextToken
    }
}
`

export async function AllStudentsByInvitedBy(userID) {
  const resp = await API.graphql({ 
    query: GET_ALL_STUDENTS_BY_INVITED_BY,
    variables: { userID },
  });

  const data = resp?.data?.listStudents
  let nextToken = data?.nextToken
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_ALL_STUDENTS_WITH_TOKEN_BY_INVITED_BY,
      variables: { limit: 1000, nextToken, userID }
    });
    const respData = resp?.data?.listStudents

    const previousData = [...students, ...respData?.items]
    students = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return students || [];
}