import {
  SET_APPLY_OPPORTUNITY_DATA,
  RESET_APPLY_OPPORTUNITY_DATA,
} from "../constants/applyOpportunityConstants";

const initialLoginState = {};

const applyOpportunityReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_APPLY_OPPORTUNITY_DATA:
      return {...state, ...action.payload};
    case RESET_APPLY_OPPORTUNITY_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default applyOpportunityReducer;
