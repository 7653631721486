import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  resetApplyOpportunityDataAction,
  setApplyOpportunityDataAction,
} from "../../../Redux/actions/applyOpportunityAction";
import { UPDATE_SCHOOL } from "../../../Services/mutation/school";
import { CREATE_STUDENT_OPPORTUNITY_APPLICATION } from "../../../Services/mutation/studentOpportunityApplication";
import { GetSingleOpportunity } from "../../../Services/query/opportunities";
import { GetSingleSchool } from "../../../Services/query/school";

import "../style.scss";
import { axiosClient } from "../../../libs/axiosClient";
import { useAuth } from "../../../Components/Auth";

const SendProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const schoolID = search.get("schoolID");

  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [school, setSchool] = useState({});
  const [opp, setOpp] = useState({});
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [createStudentOpportunityApplication] = useMutation(
    CREATE_STUDENT_OPPORTUNITY_APPLICATION
  );
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const submitApplication = () => {
    const inputData = {
      schoolID,
      opportunityID: id,
      schoolName: school.name,
      studentID: user?.id,
      createdAt: new Date(),
      status: "pending",
      state: user?.state,
    };

    createStudentOpportunityApplication({
      variables: { ...inputData },
    })
      .then(async (data) => {
        dispatch(resetApplyOpportunityDataAction());
        const appId = data.data.data.createStudentOpportunityApplication.id;
        await axiosClient.post("/update-credit", {
          studentID: user?.id,
          condition: "Submissions",
          balance: user?.balance || 0,
          multiplier: user?.multiplier || 0,
          submissions_scan: user?.submissions_scan || 0,
          submissions_scan_1: user?.submissions_scan_1 || 0,
          submissions_scan_2: user?.submissions_scan_2 || 0,
          event_submissions_scan: user?.event_submissions_scan || 0,
          event_submissions_recommendation_scan:
            user?.event_submissions_recommendation_scan || 0,
        });
        navigate(
          `/app-submitted?id=${inputData?.opportunityID}&appId=${appId}`
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const proceed = () => {
    const dont_show_again = school?.dont_show_again || [];
    if (dontShowAgain) {
      updateSchool({
        variables: {
          id: schoolID,
          dont_show_again: dont_show_again
            ? [...dont_show_again, user?.id]
            : [user?.id],
        },
      })
        .then(() => console.log("success"))
        .catch((err) => console.error(err));
    }

    if (user.schooling_mode === null) {
      navigate(
        `/complete-profile?opportunityID=${id}&schoolID=${schoolID}&schoolName=${school.name}&type=education`
      );
    } else if (user.employed === null) {
      navigate(
        `/complete-profile?opportunityID=${id}&schoolID=${schoolID}&schoolName=${school.name}&type=career`
      );
    } else {
      dispatch(
        setApplyOpportunityDataAction({
          schoolID,
          opportunityID: id,
          schoolName: school.name,
        })
      );

      const oppQuestions = JSON.parse(opp?.questions || {});
      if (Object.keys(oppQuestions).length > 0) navigate("/additional-info");
      else submitApplication();
    }
  };

  useEffect(() => {
    const handleSetDependencies = async () => {
      const school = await GetSingleSchool(schoolID);
      setSchool(school);

      const opp = await GetSingleOpportunity(id);
      setOpp(opp);
    };

    handleSetDependencies();
  }, [schoolID]);

  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="send-profile mt-5">
        <div className="title">Your Profile</div>
        <div className="des">
          We need to send this organization your profile information along with
          your application.
        </div>
        <div className="des">
          By applying to this opportunity, you are allowing this organization
          view your profile for use in processing your application. Learn more
          about this in our Privacy Policy.
        </div>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customControlValidation1"
            value={dontShowAgain}
            onChange={() => setDontShowAgain(!dontShowAgain)}
          />
          <label
            className="custom-control-label"
            htmlFor="customControlValidation1"
          >
            Don’t show this again for this organization
          </label>
        </div>
        <div className="coun-btn">
          <button onClick={proceed}>Continue</button>
        </div>
      </div>
    </>
  );
};

export default SendProfile;
