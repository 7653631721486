import { gql } from "@apollo/client";

export const CREATE_STUDENT_INTEREST = gql`
  mutation CreateStudentInterestMutation(
    $createdAt: AWSDateTime
    $interestIDs: [ID!]
    $studentID: ID
  ) {
    createStudentInterest(
      input: {
        createdAt: $createdAt
        interestIDs: $interestIDs
        studentID: $studentID
      }
    ) {
      id
      interestIDs
    }
  }
`;


export const UPDATE_STUDENT_INTEREST = gql`
  mutation UpdateStudentInterestMutation(
    $interestIDs: [ID!]
    $studentID: ID
    $id: ID!
  ) {
    updateStudentInterest(
      input: {
        id: $id
        interestIDs: $interestIDs
        studentID: $studentID
      }
    ) {
      id
      interestIDs
    }
  }
`;

export const DELETE_STUDENT_INTEREST = gql`
  mutation DeleteStudentInterestMutation(
    $id: ID!
  ) {
    deleteStudentInterest(
      input: {
        id: $id
      }
    ) {
      id
      interestIDs
    }
  }
`;



