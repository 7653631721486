import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { X, Heart } from 'react-feather'
import clockImg from '../../../Assets/images/clock-img.png'
import JICon from '../../../Assets/images/jCoin.svg'
import { useSelector } from 'react-redux'
import { AddExperienceLike } from '../util'
import {axiosClient} from "../../../libs/axiosClient";
import './style.scss'



const ItemPurchased = () => {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const user = useSelector((store) => store.user);
  const id = search.get("id");

  const [experiences, setExperiences] = useState([]);
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = []
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    } 
    
    setOppsLikedBy(updatedLikedBy);
  }

  const addLike = async (experience) => {
    const addExperienceLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      experience,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    await AddExperienceLike(addExperienceLikeProps);
  };

  const getOtherExperiences = async () => {
    try {
      const resp = await axiosClient.post('/experiences/filter', {
        filter: {id: {ne: id}},
        limit: 3,
        fields: ["id", "title", "likes", "liked_by", "image", "is_featured", "price_per_person"]
      });

      setExperiences(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getOtherExperiences();
  }, [state])
  

  return (
    <>
      <div className="noti-head border-bottom-0" onClick={() => navigate('/store')}>
        <div className="icon">
          <X />
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="item-purchase mt-5">
        <img src={clockImg} alt="clock-img" />
        <div className="title">Item Purchased</div>
        <div className="des">
          Awesome! Your purchase is successful. Keep an eye out for a
          confirmation SMS or email.
        </div>
        <div className="act">
          <button onClick={() => navigate('/store')}>Back to Store</button>
        </div>
      </div>
      <div className="store-sec">
        <div className="sec-title">You Might Also Like ...</div>
        <div className="horizontal-items">
          {experiences?.map((elt, index) =>
          <div className="item" key={index}>
            <div className="item-img">
              <img src={elt?.image} alt="store-img" />
            </div>
            <div className="item-info">
              <div className="name">{elt?.title}</div>
              <div className="heart" onClick={() => addLike(elt)}>
                {(state[elt?.id] || elt?.liked_by?.includes(user?.id)) ? (
                  <img
                    src={require("../../../Assets/images/heart-2.png")}
                    alt=""
                  />
                ) : (
                  <Heart />
                )}
              </div>
            </div>
            <div
              className="des"
              onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
            >
              {Object.keys(likes)?.includes(elt?.id) 
              ? (elt?.liked_by?.length || 0) 
              : (elt?.likes || 0)
              } {elt?.liked_by?.length === 1 ? 'person likes' : 'people like'} this
            </div>
            <div
              className="j-icon"
              onClick={() => navigate(`/store/single-item?id=${elt?.id}`)}
            >
              <img src={JICon} alt="icon" />
              {elt?.price_per_person}
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ItemPurchased
