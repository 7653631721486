import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImgTwo from '../../../Assets/images/scene-2.webp'

import './style.scss'
import WelcomePreloader from '../../../Components/Common/WelcomePreloaders'

const SplitTwo = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.background= "linear-gradient(0deg, #fff4e133, #fff4e133), #ffffff"
  })

  return (
    <>
      {loading && <WelcomePreloader />}
      <div 
        className="onbording-screen bg-two"
        style={{ display: loading ? "none" : "block" }}
      >
        <div className="img my-4">
          <img 
            src={ImgTwo} 
            alt="classroom img" 
            onLoad={() => setLoading(false)}
          />
        </div>
        <div className="text">
          <div className="heading">Empowering your future</div>
          <div className="sub-heading">
            Enabling you to prepare to for a better life.
          </div>
        </div>
        <div className="indicators">
          <div className="item"></div>
          <div className="item active"></div>
          <div className="item"></div>
        </div>
        <div className="skip-next">
          <div className="row">
            <div className="col-6">
              <div className="skip">
                <Link to="/split-three">Skip</Link>
              </div>
            </div>
            <div className="col-6">
              <div className="next">
                <Link to="/split-three">Next &gt; </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SplitTwo
