import { useNavigate } from "react-router-dom";
import JCoin from "../../../Assets/images/jCoin.svg";
import CheckTick from "../../../Assets/images/check-circle.svg";
import { Clock } from "react-feather";
import Search from "../../../Assets/images/search.svg";



function VerificationBanner({ student, setOverlay1 }) {
  const navigate = useNavigate();

  const Unverified = () => {
    return (
      <button className="dash-btn" onClick={() => setOverlay1(true)}>
        <img src={CheckTick} alt="icon" />
        <span>Verify your account</span>
      </button>
    );
  };

  const Inprogress = () => {
    return (
      <button className="ongoing-btn">
        <Clock size="18" />
        <span>Verification Ongoing</span>
      </button>
    );
  };

  const Verified = () => {
    <button className="j-btn">
      <img src={JCoin} alt="icon" />
      <span>{student?.balance}</span>
    </button>;
  };

  return ( 
    <div className="verify-btn">
      {student && (
        student.is_verified ? (
          <Verified/>
        ) : student.verification_status === "inprogress" ? (
          <Inprogress/>
        ) : (
          <Unverified/>
        )
      )}

      {student?.is_verified ? (
        <button 
          className="j-btn"
          onClick={() => navigate("/store")}>
          <img src={JCoin} alt="icon" />
          <span>{(student?.balance || 0).toFixed(2)}</span>
        </button>
      ) : (
        ""
      )}
      <button
        className={`simp-btn`}
        onClick={() => navigate("/find-counselor")}
      >
        <img src={Search} alt="icon" />
        <span>Find a counselor</span>
      </button>
    </div>
  );
}

export default VerificationBanner;