import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { GetSingleStudent } from "../../Services/query/students";

import { NavLink, useNavigate } from 'react-router-dom'
import HomeS from '../../Assets/images/menu-icon/Home-1.svg'
import HomeO from '../../Assets/images/menu-icon/Home-2.svg'
import OpperS from '../../Assets/images/menu-icon/opper-1.svg'
import OpperO from '../../Assets/images/menu-icon/opper-2.svg'
import ChatS from '../../Assets/images/menu-icon/chat-1.svg'
import ChatO from '../../Assets/images/menu-icon/chat-2.svg'
import AccountS from '../../Assets/images/menu-icon/Person-1.svg'
import AccountO from '../../Assets/images/menu-icon/Person-2.svg'
import './style.scss'

const BottomNav = ({ className }) => {
  const user = useSelector((store) => store.user);
  const [student, setStudent] = useState({});
  const navigate = useNavigate()

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user.id);
      setStudent(student);
    }
    studentVerified();
  }, []);

  const onNavigate = (e)=>{
    e.preventDefault()
    const path = e.currentTarget.getAttribute('href')
    setTimeout(goTo.bind(this, path), 200)
  }

  const goTo = (path)=>{
    navigate(path)
  }

  
  
  return (
    <>
      <div className={`bottom-nav ${className}`}>
        <div className="item">
          <NavLink to="/home" onClick={onNavigate}>
            <div className="icon">
              <div className="solid">
                <img src={HomeS} alt="icon" />
              </div>
              <div className="outline">
                <img src={HomeO} alt="icon" />
              </div>
            </div>
            <div className="title">Home</div>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/opportunities" onClick={onNavigate}>
            <div className="icon">
              <div className="solid">
                <img src={OpperS} alt="icon" />
              </div>
              <div className="outline">
                <img src={OpperO} alt="icon" />
              </div>
            </div>
            <div className="title">Opportunities</div>
          </NavLink>
        </div>
        <div className={`item ${student?.verification_status === "verified" ? '' : 'inactive'}`}>
          <NavLink to="/chats" onClick={onNavigate}>
            <div className="icon">
              <div className="solid">
                <img src={ChatS} alt="icon" />
              </div>
              <div className="outline">
                <img src={ChatO} alt="icon" />
              </div>
            </div>
            <div className="title">Chat</div>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/accounts" onClick={onNavigate}>
            <div className="icon">
              <div className="solid">
                <img src={AccountS} alt="icon" />
              </div>
              <div className="outline">
                <img src={AccountO} alt="icon" />
              </div>
            </div>
            <div className="title">Account</div>
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default BottomNav
