import { gql } from "@apollo/client";

export const CREATE_STUDENT = gql`
  mutation CreateNewStudent(
    $email: AWSEmail
    $email_verified: Boolean
    $username: String
    $name: String
    $state: String
    $dob: String
    $gender: String
    $phone: AWSPhone
    $createdAt: AWSDateTime
    $deactivated: Boolean
    $multiplier: Float
    $balance: Float
    $interests: [String]
    $interestIDs: [ID]
  	$is_verified: Boolean
    $referral_code: String
    $dont_show_app_pop: [String!]
  	$verification_progress: Int
  	$verification_status: String
  	$verification_date_started: AWSDateTime
    $invited_by: ID
    $hubspotID: String
  ) {
    createStudent(
      input: {
        email: $email
        email_verified: $email_verified
        username: $username
        name: $name
        state: $state
        interests: $interests
        interestIDs: $interestIDs
        dob: $dob
        gender: $gender
        phone: $phone
        createdAt: $createdAt
        deactivated: $deactivated
        dont_show_app_pop: $dont_show_app_pop
        balance: $balance
        invited_by: $invited_by
        referral_code: $referral_code
        multiplier: $multiplier
        is_verified: $is_verified
        hubspotID: $hubspotID
        verification_progress: $verification_progress
        verification_status: $verification_status
        verification_date_started: $verification_date_started
      }
    ) {
      email
      name
      state
      id
      educatorID
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      multiplier
      createdAt
      interests
      interestIDs
      address
      is_verified
      hubspotID
      referral_code
      verification_progress
      verification_status
      verification_date_started
      dont_show_app_pop
      email_verified
      username
      invited_by
      event_submissions_recommendation_scan
      event_submissions_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invited_by
      invites_scan
      opportunity_acceptance_scane
      opportunity_share_scan
      resource_reviews_scan
      submissions_scan
      unique_views_scan
      shared_opportunities
    }
  }
`;


export const UPDATE_STUDENT = gql`
  mutation UpdateStudent(
    $id: ID!
    $email: AWSEmail
    $username: String
    $state: String
    $name: String
    $address: String
    $apartment: String
    $points: String
    $school: String
    $multiplier: Float
    $balance: Float
    $schooling_mode: String
    $graduation_year: String
    $graduation_term: String
    $is_verified: Boolean
    $verification_status: String
    $guardianID: ID
    $phone: AWSPhone
    $dont_show_app_pop: [String!]
    $interests: [String]
    $interestIDs: [ID]
    $profile_sharing: [ID]
    $hubspotID: String
    $current_employer: String
    $current_occupation: String
    $employed: Boolean
    $dob: String
    $referral_code: String
    $shared_opportunities: Int
    $email_verified: Boolean
    $submissions_scan_1: Int
    $submissions_scan_2: Int
    $chat_username: String
  ) {
    updateStudent(
      input: {
        email: $email
        state: $state
        name: $name
        username: $username
        address: $address
        apartment: $apartment
        schooling_mode: $schooling_mode
        graduation_year: $graduation_year
        graduation_term: $graduation_term
        interests: $interests
        interestIDs: $interestIDs
        is_verified: $is_verified
        multiplier: $multiplier
        balance: $balance
        points: $points
        school: $school
        hubspotID: $hubspotID
        referral_code: $referral_code
        dont_show_app_pop: $dont_show_app_pop
        verification_status: $verification_status
        guardianID: $guardianID
        profile_sharing: $profile_sharing
        phone: $phone
        dob: $dob
        id: $id
        chat_username: $chat_username
        current_employer: $current_employer
        current_occupation: $current_occupation
        employed: $employed
        email_verified: $email_verified
        submissions_scan_1: $submissions_scan_1
        submissions_scan_2: $submissions_scan_2
        shared_opportunities: $shared_opportunities
      }
    ) {
      email
      name
      state
      id
      educatorID
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      multiplier
      createdAt
      username
      interestIDs
      interests
      address
      balance
      apartment
      hubspotID
      graduation_year
      graduation_term
      verification_date_started
      verification_progress
      verification_progress_dates
      verification_status
      is_verified
      school
      schooling_mode
      referral_code
      profile_sharing
      employed
      current_employer
      current_occupation
      email_verified
      dont_show_app_pop
      event_submissions_recommendation_scan
      event_submissions_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invited_by
      invites_scan
      chat_username
      chat_secret
      opportunity_acceptance_scane
      opportunity_share_scan
      resource_reviews_scan
      submissions_scan
      unique_views_scan
      submissions_scan_1
      submissions_scan_2
      shared_opportunities
      invited_by
    }
  }
`;

export const DELETE_STUDENT = gql`
  mutation DeleteInterest($id: ID!) {
    deleteStudent(input: { id: $id }) {
      name
    }
  }
`;