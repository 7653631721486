import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Link, useNavigate } from 'react-router-dom'
import { setUserDataAction } from '../../../../Redux/actions/userActions'
// import { DAYS, MONTHSHORTS } from '../../../../utils/data'

import './style.scss'
import {getCities} from "../../../../utils/helpers";

const animatedComponents = makeAnimated();

const SecondStep = () => {
  const modifiedStates = getCities({
    keys: ["label", "value"],
    requiresAll: false,
    returnObj: true,
    sort: true
  });

  useEffect(() => {
    document.body.style.background =
      'linear-gradient(0deg, rgba(255, 244, 225, 0.2), rgba(255, 244, 225, 0.2)), #FFFFFF'
  }, []);

  const name = useSelector(state => state.user.name);
  const nameArray = name?.split(/(\s+)/);
  const firstName = nameArray[0];

  const [state, setState] = useState({})
  const [valid, setValid] = useState(false);
  const [location, setLocation] = useState({});
  // const daysJsx = []
  // const monthJsx = []
  // const yearsJsx = []
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const maxYear = new Date().getFullYear()-13
  // const minYear = new Date().getFullYear()-20

  // let dateRange = []

  // for(let i = minYear; i <= maxYear; i++){
  //   dateRange.push(i)
  // }

  useEffect(() => {
    if (Object.keys(state).length === 2) {
      setValid(Object.values(state).every(elt => elt !== ""))
    }
  }, [state])

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const setupInfo = () => {
    // const dob = `${state.year}-${state.month}-${state.day}`
    const obj = {...state}
    // delete obj.year
    // delete obj.month
    // delete obj.day
    //
    // obj.dob = dob;
    dispatch(setUserDataAction(obj))
    navigate("/last-step")
  }


  useEffect(() => {
    if (location?.label) {
      setState({
        ...state,
        state: location?.label
      })
    }
  }, [location]);

  return (
    <>
      <div className="noti-head p-0 back-head border-0">
        <div className='icon'>
          <Link to="/sign-up">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className='text progress-bars'>
          <div className='previous'></div>
          <div className='active'></div>
          <div className='next'></div>
        </div>
      </div>
      <div className="auth-sec second-step mt-5">
        <div className="heading">Hi, {firstName}!</div>
        <div className="sub-heading">Let’s get to know you.</div>
        <form>
          <div className="form-auth">
            <label>How do you identify yourself?</label>
            <div className="custom-text-radio">
              <input 
                type="radio" 
                id="Male" 
                name="gender" 
                value="male"
                onChange={handleChange}
              />
              <label htmlFor="Male">Male</label>

              <input 
                type="radio" 
                id="Female" 
                name="gender"
                value="female" 
                onChange={handleChange}
              />

              <label htmlFor="Female">Female</label>

              <input 
                type="radio" 
                id="Prefer" 
                name="gender" 
                value="Prefer not to say"
                onChange={handleChange}
              />

              <label htmlFor="Prefer">Prefer not to say</label>
            </div>
          </div>
          {/*<div className="form-auth">*/}
          {/*  <label>When were you born?</label>*/}
          {/*  <div className="custom-select-options">*/}
          {/*    <select */}
          {/*      name="month"*/}
          {/*      className={!state["month"] && "selectNonActive"}*/}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Month</option> */}
          {/*      {MONTHSHORTS.forEach((elt, index) => {*/}
          {/*        monthJsx.push(<option value={DAYS[index]} key={`month-${elt}`}>{elt}</option> )*/}
          {/*      })}       */}
          {/*      {monthJsx}*/}
          {/*    </select>*/}
          {/*    <select */}
          {/*      name="day"*/}
          {/*      className={!state["day"] && "selectNonActive"}*/}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Day</option>*/}
          {/*      {DAYS.forEach((elt) => {*/}
          {/*        daysJsx.push(<option value={elt} key={`day-${elt}`}>{elt}</option>)*/}
          {/*      })}*/}
          {/*      {daysJsx}*/}
          {/*    </select>*/}
          {/*    <select */}
          {/*      name="year" */}
          {/*      onChange={handleChange}*/}
          {/*      className={!state["year"] && "selectNonActive"}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Year</option>*/}
          {/*      {dateRange.forEach((elt) => {*/}
          {/*        yearsJsx.push(<option value={elt} key={`year-${elt}`}>{elt}</option>)*/}
          {/*      })}*/}
          {/*      {yearsJsx}*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="form-auth">
            <label>Where do you live?</label>
            <Select
              closeMenuOnSelect={false}
              className={`grayed ${!state?.state && "stateSelectNotActive"}`}
              components={animatedComponents}
              options={modifiedStates}
              isClearable={true}
              placeholder="Start typing..."
              name="state"
              onChange={setLocation}
            />
          </div>
          <div className="center-btn">
            <div className="submit-btn">
              <button 
                type="button"
                className="cus-btn"
                disabled={!valid}
                style={{opacity : valid ? 1 : 0.3}}
                onClick={setupInfo}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SecondStep
