import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleIcon from "../../../Assets/images/google.png";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setUserDataAction } from "../../../Redux/actions/userActions";
import { axiosClient } from "../../../libs/axiosClient";
import AlertToast from "../../../Components/Common/Alerts/ToastAlert";

import "./style.scss";
import {isRequiredFieldsPassed, validatePhone} from "../../../utils/helpers";


const SignUp = () => {
  useEffect(() => {
    document.body.style.background =
      "linear-gradient(0deg, #c1d6f41a, #c1d6f41a), #ffffff";
  });

  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneCls, setPhoneCls] = useState("");
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value});
  };

  const createAccount = async () => {
    setLoading(true);
    if (!valid) return;
    try {
      const resp = await axiosClient.post('/get-student-by-filter/', {
        field: 'phone',
        value: `+1${state.phone}`
      });
      if (resp.data.data.length === 0) {
        const name = state?.fname?.trim() + " " + state?.lname?.trim();
        const obj = { ...state };
        delete obj?.fname;
        delete obj?.lname;
    
        obj.name = name;
        obj.phone = '+1' + state.phone;
    
        dispatch(setUserDataAction(obj));
        setLoading(false);
        navigate("/second-step");
      } else {
        setLoading(false);
        setActiveToast(true);
        setToast('An account with this phone number already exists');
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setValid(isRequiredFieldsPassed(state, 3, 'eq')
        && state?.phone?.length === 10
        && validatePhone(state?.phone)
    );
    if (state?.phone?.length === 10) {
      const validPhone = validatePhone(state?.phone);
      setPhoneCls(validPhone ? 'border-success' : 'border-danger');
    } else {
      if (phoneCls !== '') setPhoneCls('');
    }
  }, [state]);


  return (
    <>
      <AlertToast 
        variant={'error'} 
        icon={'delete'} 
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
        <div className="noti-head back-head border-0">
          <div>
            <Link to="/split-three">
              <i className="fas fa-angle-left"></i>
            </Link>
          </div>
          <div className='text progress-bars'>
            <div className='active'></div>
            <div className='next'></div>
            <div className='next'></div>
          </div>
        </div>
      <div className="auth-sec mt-5">
        <div className="heading">Welcome to Junity</div>
        <div className="sub-heading">Let’s get started.</div>
        <form>
          <div
            className="google-login"
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          >
            <Link to="">
              <img src={GoogleIcon} alt="icon" />
              <span>Sign up with Google</span>
            </Link>
          </div>
          <div className="option-text">or sign up with your email address</div>
          <div className="form-auth">
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-auth">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-auth">
            <label>Mobile (Phone) Number</label>
            <div className={`phone-input ${phoneCls}`}>
              <span>+1</span>    
              <input
                type="tel"
                placeholder="Enter your mobile number"
                name="phone"
                onChange={handleChange}
              /> 
            </div>      
            <p>
              We’ll use this to help you recover your account if you lose access
              to your email.
            </p>
          </div>
        </form>
      </div>
      <div className="center-btn">
        <div className="submit-btn point">
          <button
            className="cus-btn"
            onClick={createAccount}
            style={{ opacity: valid ? 1 : 0.3 }}
            disabled={!valid}
          >
            {loading ? "Loading..." : "Get Started"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SignUp;
