/* eslint-disable jsx-a11y/anchor-is-valid */
import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLength } from "validator";
import { setCognitoUserDataAction } from "../../../../Redux/actions/cognitoUserActions";
import { GetStudentByPhone } from "../../../../Services/query/students";
import { setUserDataAction } from "../../../../Redux/actions/userActions";

const Login = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [valid, setValid] = useState(false);

  const search = new URLSearchParams(useLocation().search);
  const number = search.get("number");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setValid(isPasswordValid);
  }, [state]);

  const handleChange = (e) => {
    const newState = state;

    newState[e.target.name] = e.target.value;

    if (e.target.name === "password") {
      const validPassword = isLength(e.target.value, { min: 8 });
      setIsPasswordValid(validPassword);
    }

    setState({ ...newState });
  };

  const handleLogin = () => {
    setLoading(true);
    const phone = state.phone || "+" + number?.trim();
    Auth.signIn(phone, state.password)
      .then(async (data) => {
        dispatch(setCognitoUserDataAction(data));
        const student = await GetStudentByPhone(phone);
        const studentObj = { ...student };
        dispatch(setUserDataAction(studentObj));
        setLoading(false);
        return navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <>
      <div className="auth-sec">
        <div className="back-head">
          <Link to="/split-three">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className="heading">Welcome to Junity</div>
        <div className="sub-heading">Let’s get started.</div>
        <form>
          <div className="form-auth">
            <label>Mobile Number</label>
            <input
              type="text"
              placeholder="Enter your mobile number"
              value={`+${number?.trim()}`}
              name="phone"
              className="border-success"
              onChange={handleChange}
            />
          </div>
          <div className="form-auth">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              name="password"
              onChange={handleChange}
            />
          </div>
          <div className="having-issue mt-2">
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
          <div className="submit-login">
            <div onClick={handleLogin} style={{ opacity: valid ? 1 : 0.3 }}>
              {loading ? "Loading..." : "Log In"}
            </div>
          </div>
          <div className="submit-btn-text">
            <Link to="/sign-up" className="cus-btn">
              Don’t have an account? Sign Up
            </Link>
          </div>
        </form>
      </div>
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
      />
    </>
  );
};

export default Login;
