import React from "react";
import { Flag, Briefcase, Trash } from "react-feather";
import {useLocation, useNavigate} from "react-router-dom";
import "./style.scss";
import { chatClient } from "../../libs/axiosClient";
import { useMutation } from "@apollo/client";
import { DELETE_CHAT_HANDLER } from "../../Services/mutation/chatHandler";
import {useDispatch} from "react-redux";
import {setRoutingStateDataAction} from "../../Redux/actions/routingStateAction";

const stopProp = (e) => {
  e.stopPropagation();
};

const ChatMenu = ({ removeOverlay, shown, position, chatId, chatHandlerId, url }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteChatHandler] = useMutation(DELETE_CHAT_HANDLER);

  const handleChatDelete = async () => {
    try {
      await chatClient.delete(`chats/${chatId}/`);
      await deleteChatHandler({ variables: { chat_id: chatHandlerId } })
      removeOverlay();
      navigate('/chats');
    } catch (e) {
      console.error(e);
    }
  }

  const handleProfileRouting = () => {
    dispatch(setRoutingStateDataAction({
      previousRoute: location.pathname + location.search,
    }));
    navigate(url);
  }


  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={() => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={() => removeOverlay()}></div>
            <div className="my-account">
              <div className="list">
                <div className="item" onClick={handleProfileRouting}>
                  <Briefcase />
                  <span>View Institution Profile</span>
                </div>
                <div className="item" onClick={() => navigate("/report-issue")}>
                  <Flag />
                  <span>Report an Issue</span>
                </div>
                <div className="item change-c" onClick={handleChatDelete}>
                  <Trash />
                  <span>Delete Chat</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMenu;
