/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { calculateTime, formatDate } from "../../utils/helpers";
import { GetApplicationByOpportunityIDAndStudentID } from "../../Services/query/studentOpportunityApplications";
import { GetSingleStudent } from "../../Services/query/students";
import { GetReviewsByResourceID } from "../../Services/query/review";
import { setUserDataAction } from "../../Redux/actions/userActions";
import { AddLike } from "../../utils/common";
import { useMutation } from "@apollo/client";
import { UPDATE_OPPORTUNITY } from "../../Services/mutation/opportunities";
import { UPDATE_STUDENT } from "../../Services/mutation/student";
import { axiosClient, chatClient } from "../../libs/axiosClient";
import { Heart, Calendar, Clock, MessageCircle } from "react-feather";
import { API } from "aws-amplify";
import { AllCreditEventsByCondition } from "../../Services/query/creditEvents";

import Logo from "../../Assets/images/logo.png";
import Flag from "../../Assets/images/flag.svg";
import Upload from "../../Assets/images/upload.svg";
import Close from "../../Assets/images/x.svg";
import CheckCircle from "../../Assets/images/check-circle.svg";
import HeartC from "../../Assets/images/heart.svg";
import ApplyOpper from "../../Components/Popus/ApplyOpper";
import ApplyOppReview from "../../Components/Popus/ApplyOppReview";
import ReviewStarFill from "../../Widgets/ReviewStars/ReviewStarFill";
import VerifyAccount from "../../Components/Popus/VerifyAccount";
import ApplyOpportunityLoader from "../../Components/Common/OpportunitiesLoader/ApplyOpportuntiyLoader";
import WithdrawOpportunityApplication from "../../Components/Popus/WithdrawOpportunityApplication";
import ApplicationWithdrawn from "../../Components/Popus/ApplicationWithdrawn";
import { ReportIssueModal } from "../../Components/Popus/ReportIssue";
import "./style.scss";
import { setRoutingStateDataAction } from "../../Redux/actions/routingStateAction";
import { GetSingleOpportunity } from "../../Services/query/opportunities";
import { useAuth } from "../../Components/Auth";
import { CREATE_CHAT_HANDLER } from "../../Services/mutation/chatHandler";

const ApplyOnOpportunity = () => {
  const user = useSelector((store) => store.user);
  const homeOpps = useSelector((store) => store.homeOpps);
  const previousRoute = useSelector(
    (store) => store.routingState.singleOpportunityPreviousRoute
  );
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [loading, setLoading] = useState(true);
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [withdrawAppOverlay, setWithdrawAppOverlay] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [toastLoading, setToastLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [overlay1, setOverlay1] = useState(false);
  const [reviewOverlay, setReviewOverlay] = useState(false);
  const [reportOverlay, setReportOverlay] = useState(false);
  const [expandDes, setExpandDes] = useState(false);
  const [student, setStudent] = useState({});
  const [opp, setOpp] = useState([]);
  const [app, setApp] = useState([]);
  const [recommendedOpp, setRecommendedOpp] = useState(false);
  const [otherOpps, setOtherOpps] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [successfulWithdrawn, setSuccessfulWithdrawn] = useState(false);
  const [createChatHandler] = useMutation(CREATE_CHAT_HANDLER);
  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);

  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const review = search.get("review");
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const getStartedChat = () => {
    return chats.findIndex(
      (elt) =>
        (JSON.parse(elt.custom_json)["creatorID"] === user?.id &&
          JSON.parse(elt.custom_json)["orgID"] === opp?.school?.id) ||
        (JSON.parse(elt.custom_json)["creatorID"] === opp?.school?.id &&
          JSON.parse(elt.custom_json)["orgID"] === user?.id)
    );
  };

  const createChat = async (resource, users) => {
    if (opp) {
      setLoading(true);
      const usernames = [resource, user];

      const usernamesCreated = usernames?.reduce(async (acc, resource) => {
        if (users.findIndex((elt) => elt.username === resource?.id) === -1) {
          await chatClient.post("/users/", {
            username: resource?.id,
            first_name: resource?.name?.split(" ")[0],
            last_name: resource?.name?.split(" ").splice(1).join(""),
            secret: resource?.id,
          });
        }

        const resp = await acc;
        return [...resp, resource?.id];
      }, Promise.resolve([]));

      const chatIndex = getStartedChat();

      if (chatIndex === -1) {
        const data = {
          usernames: usernamesCreated,
          title: opp?.school?.name,
          is_direct_chat: false,
          custom_json: JSON.stringify({
            orgID: opp?.school?.id,
            creatorID: user?.id,
            creatorType: "youth",
            destinationType: "resource",
          }),
        };

        const chat = await chatClient.put("/chats/", data);
        createChatHandler({
          variables: {
            chat_id: chat.data.id,
            access_key: chat.data.access_key,
            createdAt: new Date(),
          },
        });

        navigate(`/chat-person?id=${chat.data.id}&type=resource`);
      } else {
        const chat = chats[chatIndex];
        navigate(`/chat-person?id=${chat.id}&type=resource`);
      }
    }
  };

  const share = async () => {
    if (opp?.id) {
      const file = await fetch(Logo)
        .then((response) => response.blob())
        .then((blob) => new File([blob], "logo.png", { type: blob.type }));

      const data = {
        title: opp?.name,
        text: opp?.description,
        url: `${process.env.REACT_APP_KYC_URL}/opportunities?id=${opp?.id}`,
        files: [file],
      };
      if (navigator?.canShare(data)) {
        try {
          await navigator.share(data);
          const sharedOpportunities = user?.shared_opportunities || 0;
          const eventCondition = await AllCreditEventsByCondition(
            "Shares of opportunities"
          );
          if (sharedOpportunities === eventCondition[0].value - 1) {
            await API.graphql({
              query: UPDATE_STUDENT,
              variables: { id: user?.id, shared_opportunities: 0 },
            });
            await axiosClient.post("/update-by-shares", {
              studentID: user?.id,
              shared_opportunities: user?.shared_opportunities || 0,
              scan: user?.opportunity_share_scan || 0,
              multiplier: user?.multiplier || 0,
              balance: user?.balance || 0,
            });
          } else {
            await API.graphql({
              query: UPDATE_STUDENT,
              variables: {
                id: user?.id,
                shared_opportunities: (user?.shared_opportunities || 0) + 1,
              },
            });
          }
        } catch (err) {
          console.error(`Error: ${err}`);
        }
      }
    }
  };

  const calculateReview = () => {
    let sum = 0;
    // eslint-disable-next-line no-unused-expressions
    reviews?.forEach((elt) => {
      sum += parseInt(elt.stars);
    });
    const overallStars = parseFloat(sum / reviews.length);
    return Math.round(overallStars * 10) / 10;
  };

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };

  const proceedToApply = () => {
    if (student?.verification_status === "verified") {
      setOverlay(true);
      dispatch(setUserDataAction(student));
    } else {
      setOverlay1(true);
    }
  };

  const pendingOppStatus = (status) => {
    return (
      <div className="app-status" type={status?.toLowerCase()}>
        <div className="title">
          Your application is {status?.toLowerCase()}
          <span
            className="withdraw"
            onClick={() => setWithdrawAppOverlay(true)}
          >
            Withdraw
          </span>
        </div>
        <div className="des">Submitted {calculateTime(app?.createdAt)}</div>
      </div>
    );
  };

  const confirmedOppStatus = () => {
    return (
      <div className="app-status" type="accepted">
        <div className="title">Great! Your application was accepted!</div>
        <span>You should be contacted soon with more details.</span>
      </div>
    );
  };

  const alertOppStatus = (status) => {
    if (status === "pending") {
      return pendingOppStatus(status);
    } else if (status === "accepted") {
      return confirmedOppStatus();
    }
  };

  const handleOpportunityView = async () => {
    if (Object.keys(opp || {}).length > 0) {
      const views = opp?.views || [];
      if (!views.includes(user?.id)) {
        updateOpportunity({
          variables: {
            id,
            views: [...views, user?.id],
          },
        });

        await axiosClient.post("/update-opportunity-view", {
          studentID: user?.id,
          balance: user?.balance || 0,
          multiplier: user?.multiplier || 0,
          scan: user?.unique_views_scan || 0,
        });
      }
    }
  };

  const applyButtonClick = (status) => {
    if (status === "accepted" || status === "pending") {
      createChat(opp?.school, users);
    } else {
      proceedToApply();
    }
  };

  useEffect(() => {
    const studentVerified = async () => {
      const student = await GetSingleStudent(user?.id);
      if (
        student !== undefined &&
        Object.keys(student || {}).length &&
        student
      ) {
        dispatch(setUserDataAction(student));
        setStudent(student);
        const app = await GetApplicationByOpportunityIDAndStudentID(
          id,
          user?.id
        );

        const otherOpps = homeOpps.mainOpps?.filter((elt) => elt.id !== id);
        let opp = homeOpps?.mainOpps?.find((elt) => elt.id === id);

        if (!opp) {
          opp = await GetSingleOpportunity(id);
        }

        const reviews = await GetReviewsByResourceID(opp?.resourceID);
        const recommendedOpp = homeOpps?.recommendations?.find(
          (elt) => elt?.id === opp?.id
        );
        const userOpps = homeOpps.oppsInArea?.filter((elt) =>
          otherOpps?.some((innerElt) => innerElt.id === elt.id)
        );
        setRecommendedOpp(Object.keys(recommendedOpp || {}).length > 0);
        setOpp(opp);
        setOtherOpps(userOpps);
        setReviews(reviews);
        setApp(app);
        setLoading(false);
      }
    };

    studentVerified();
  }, [
    Object.keys(student).length > 1,
    successfulWithdrawn === true,
    state,
    id,
    reviewed,
  ]);

  const routeToResourceProfile = () => {
    dispatch(
      setRoutingStateDataAction({
        previousRoute: `/apply-for-opportunity?id=${id}`,
      })
    );
    navigate(
      `/resource-profile?id=${opp?.school?.id}&state=${
        opp?.state[0] || ""
      }&oppId=${opp?.id}`
    );
  };

  const checkOppStatus = () => {
    return new Date() > new Date(opp?.expiry_date)
      ? "Closed"
      : `Closes ${formatDate(opp?.expiry_date)}`;
  };

  useEffect(() => {
    setReviewOverlay(review || false);
  }, [review]);

  useEffect(() => {
    if (!id) {
      navigate("/opportunities");
    }
  }, [id, navigate]);

  useEffect(() => {
    handleOpportunityView();
  }, [opp]);

  useEffect(() => {
    chatClient
      .get("/chats")
      .then((chats) => {
        setChats(chats.data);
      })
      .catch((error) => console.error("chat error", error));

    chatClient
      .get("/users")
      .then((users) => {
        setUsers(users.data);
      })
      .catch((error) => console.error("users error", error));
  }, []);

  return (
    <>
      <ApplyOpper
        removeOverlay={() => setOverlay(false)}
        opportunityID={id}
        schoolID={opp?.resource?.organizationID}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <VerifyAccount
        removeOverlay={() => setOverlay1(false)}
        shown={overlay1 ? "shown" : "hidden"}
        position={overlay1 ? "slide-up" : "slide-down"}
      />
      <ReportIssueModal
        removeOverlay={() => setReportOverlay(false)}
        shown={reportOverlay ? "shown" : "hidden"}
        position={reportOverlay ? "slide-up" : "slide-down"}
        title="Report this opportunity"
        module={opp}
        type={"Opportunity"}
      />
      <ApplicationWithdrawn
        removeOverlay={() => setSuccessfulWithdrawn(false)}
        shown={successfulWithdrawn ? "shown" : "hidden"}
        position={successfulWithdrawn ? "slide-up" : "slide-down"}
        header="Your Application has been withdrawn."
        text={`${opp?.resource?.name} will no longer see your 
        application or have access to your profile.`}
      />
      <WithdrawOpportunityApplication
        shown={withdrawAppOverlay ? "shown" : "hidden"}
        position={withdrawAppOverlay ? "slide-up" : "slide-down"}
        removeOverlay={() => setWithdrawAppOverlay(false)}
        resourceName={opp?.resource?.name}
        opp={opp}
        studentID={user.id}
        setSuccessfulWithdrawn={setSuccessfulWithdrawn}
      />
      <ApplyOppReview
        shown={reviewOverlay ? "shown" : "hidden"}
        position={reviewOverlay ? "slide-up" : "slide-down"}
        removeOverlay={() => setReviewOverlay(false)}
        resourceID={opp?.school?.id}
        opportunityID={opp?.id}
        resourceName={opp?.school?.name}
        setReviewed={setReviewed}
      />
      <div className="noti-head">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon mr-3">
          <img src={Upload} alt="icon" onClick={share} />
        </div>
        <div className="icon" onClick={() => setReportOverlay(true)}>
          <img src={Flag} alt="icon" />
        </div>
      </div>
      {loading ? (
        <ApplyOpportunityLoader />
      ) : (
        <div className="sub-app mt-5 apply-opp">
          {recommendedOpp && (
            <div className="recommended-banner">
              <div className="text">
                <img src={CheckCircle} alt="" width={16} height={16} />
                <span>This opportunity was recommended for you.</span>
              </div>
              <div className="arrow">{">"}</div>
            </div>
          )}
          <div className="head">
            <div className="title">{opp?.name}</div>
            <div
              className={`like ${
                student?.verification_status === "verified" ? "" : "inactive"
              }`}
              onClick={() => addLike(opp)}
            >
              <div className="circle">
                {state[opp?.id] || opp?.liked_by?.includes(user?.id) ? (
                  <i className="fas fa-heart"></i>
                ) : (
                  <Heart size={20} />
                )}
              </div>
              <div className="likes">
                <span>
                  {Object.keys(likes)?.includes(opp?.id)
                    ? likes[opp?.id]
                    : opp?.likes || 0}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="value">
            <div className="day">
              <Calendar size={16} />
              <span>{calculateTime(opp?.createdAt)}</span>
            </div>
            <div className="status">
              <Clock
                size={16}
                stroke={
                  checkOppStatus(opp?.expiry_date) !== "Closed"
                    ? "#AA8545"
                    : "currentColor"
                }
              />
              <span
                className={
                  checkOppStatus(opp?.expiry_date) !== "Closed"
                    ? "opp-active"
                    : "opp-closed"
                }
              >
                {checkOppStatus(opp?.expiry_date)}
              </span>
            </div>
          </div>
          <div className="interest-tag pb-0 flex-wrap">
            <div className="p-type mr-1" type={opp?.category}>
              {opp?.category}
            </div>
            {opp?.interests?.map((elt, index) => (
              <div className="p-type mr-1" key={index}>
                {elt}
              </div>
            ))}
          </div>
          <div className="agence" onClick={routeToResourceProfile}>
            <div className="info">
              <div className="logo">
                <img src={opp?.school?.image} alt="icon" />
              </div>
              <div className="label">
                <div className="name">{opp?.school?.name}</div>
                <div className="des">{user?.state}</div>
              </div>
            </div>
            <div className="rating">
              <div className="stars">
                <ReviewStarFill starNo={calculateReview() || 0} />
              </div>
              <div className="reviews">
                {calculateReview() || 0}
                <span>({reviews?.length} reviews)</span>
              </div>
            </div>
          </div>
          <div className="info">
            <div className={`des ${expandDes ? "" : "break-detail"}`}>
              {opp?.description}
            </div>
            <div className="view-more" onClick={() => setExpandDes(!expandDes)}>
              {expandDes ? "View less" : "View more"}
              <span>{">"}</span>
            </div>
          </div>
          <>{alertOppStatus(app?.status)}</>
          <div
            className={`send-msg ${
              student?.verification_status === "verified" ? "" : "inactive"
            }`}
          >
            <button
              id="apply-btn"
              onClick={() => applyButtonClick(app?.status)}
            >
              {app?.status === "pending" || app?.status === "accepted" ? (
                <span>
                  <MessageCircle />
                  Send a message
                </span>
              ) : (
                "Apply Now"
              )}
            </button>
          </div>
        </div>
      )}
      <div className="sug-opp">
        <div className="main">
          <div className="title">You Might Also Be Interested In...</div>
          <div className="popular-items pl-0">
            {otherOpps?.map((elt, index) => (
              <div className="item" key={index}>
                <div className="item-head">
                  <div className="p-logo">
                    <img
                      src={elt?.school?.image}
                      className="circle"
                      alt="c-logo"
                    />
                    <div
                      className={`heart ${
                        student?.verification_status === "verified"
                          ? ""
                          : "inactive"
                      }`}
                      onClick={() => addLike(elt)}
                    >
                      {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                        <i className="fas fa-heart"></i>
                      ) : (
                        <img src={HeartC} alt="icon" />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="info"
                  onClick={() =>
                    navigate(`/apply-for-opportunity?id=${elt.id}`)
                  }
                >
                  <div className="title">{elt?.name}</div>
                  <div className="des break-overview">{elt?.description}</div>
                  <div className="date-likes">
                    <div className="date">{calculateTime(elt?.createdAt)}</div>
                    <div className="dot"></div>
                    <div className="likes">
                      {Object.keys(likes)?.includes(elt?.id)
                        ? likes[elt?.id]
                        : elt?.likes || 0}{" "}
                      {likes[elt?.id] === 1 ? " like" : " likes"}
                    </div>
                  </div>
                </div>
                <div className="p-type mr-1" type={elt?.category} key={index}>
                  {elt?.category}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyOnOpportunity;
