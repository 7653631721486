import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserImg from "../../Assets/images/counselor-img.jpg";
import NoImg from "../../Assets/images/no-img.jpg";
import { chatClient } from "../../libs/axiosClient";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const ChatCounselor = ({ removeOverlay, shown, position, counselor, proceed, user }) => {
  const [chatStarted, setChatStarted] = useState(-1);
  const [chats, setChats] = useState([]);

  const handleChatCreation = () => {
    proceed(chatStarted, chatStarted !== -1 ? chats.data[chatStarted]: {})
  }

  useEffect(() => {
    chatClient.get('/chats').then((chats) => {
      setChats(chats);
      const singleChat = chats.data.findIndex(
        (elt) => (JSON.parse(elt.custom_json)["creatorID"] === user?.id 
        && JSON.parse(elt.custom_json)["destinationID"] === counselor?.id)
        || (JSON.parse(elt.custom_json)["creatorID"] === counselor?.id
        && JSON.parse(elt.custom_json)["destinationID"] === user?.id)
      );
      setChatStarted(singleChat);
    }).catch((error) => console.error('hy', error));
  }, [counselor, user]);
  
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="chat-co">
              <img src={counselor?.image || NoImg} alt="user-img" />
              <div className="name">{counselor?.name}</div>
              <div className="label">Counselor</div>
              <div className="des">
                {counselor?.personality}
              </div>
              <button onClick={handleChatCreation}>
                {chatStarted !== -1 ? "Go to Chat" : "Start a Chat" }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatCounselor;
