import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AlertToast from "../Common/Alerts/ToastAlert";
import { isRequiredFieldsPassed } from "../../utils/helpers";
import { createHubspotTicket } from "../../utils/Hubspot";

const stopProp = (e) => {
  e.stopPropagation();
};

export const ReportIssueModal = ({
  removeOverlay,
  type,
  module,
  shown,
  position,
  title = "Report Issue",
  description = null,
}) => {
  const user = useSelector((store) => store.user);
  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastVariant, setToastVariant] = useState("success");
  const [toastIcon, setToastIcon] = useState("check");
  const [toastInfo, setToastInfo] = useState("");

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const showToast = (variant, message) => {
    setToastVariant(variant);
    setToastIcon(variant === "success" ? "check" : "delete");
    setToastInfo(message);
    setToastActive(true);
  };

  useEffect(() => {
    setValid(isRequiredFieldsPassed(state, 2, "eq"));
  }, [state]);

  const reportIssue = async () => {
    const subject = `${type} Issue Report - ${state?.title}`;
    let content = `Title: \n${state?.title}`;
    content += `\n\n${type} ID: \n${module?.id}`;
    content += `\n\n${type} Name: \n${module?.name}`;
    content += `\n\nContent: \n${state?.description}`;

    setLoading(true);
    try {
      await createHubspotTicket(subject, content, "feedback", user?.hubspotID);

      setLoading(false);
      removeOverlay();
      showToast("success", "Issue reported successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <>
        <div
          className={`overlay_background ${shown}`}
          onClick={() => removeOverlay()}
        >
          <div className="overlay_card" onClick={(e) => stopProp(e)}>
            <div className={`popup_inner ${position} text-left`}>
              <div
                className="close-line"
                onClick={(e) => removeOverlay()}
              ></div>
              <div className="report-issue mt-3">
                <h4>{title}</h4>
                <h6 className="info-description mb-4">
                  {description ? (
                    <>{description}</>
                  ) : (
                    <>
                      {`Don't hesitate to reach out and let us know about 
                any issues you encounter - we're here to help`}
                    </>
                  )}
                </h6>
                <div className="form-auth">
                  <label className="institution-form-label">Title</label>
                  <input
                    type="text"
                    placeholder="Report Title"
                    name="title"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-auth">
                  <label className="institution-form-label">Description</label>
                  <textarea
                    rows={3}
                    name="description"
                    className="form-control"
                    placeholder="Report description"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="select-btn mt-3">
                  <button
                    type="button"
                    className={!valid ? "inactive" : ""}
                    disabled={!valid || loading}
                    onClick={reportIssue}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <AlertToast
        variant={toastVariant}
        icon={toastIcon}
        active={toastActive}
        setActive={setToastActive}
        info={toastInfo}
      />
    </>
  );
};
