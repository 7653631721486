import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Heart from "../../../Assets/images/heart.svg";
import { useSelector, useDispatch } from "react-redux";
import { GetSingleStudent } from "../../../Services/query/students";
import { calculateTime } from "../../../utils/helpers";
import { AddLike } from "../../../utils/common";
import "./style.scss";
import { setUserDataAction } from "../../../Redux/actions/userActions";

const ResourceOpportunities = () => {
  const user = useSelector((store) => store.user);
  const homeOpps = useSelector((store) => store.homeOpps);
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);
  const [activeFilteredOpps, setActiveFilteredOpps] = useState([]);
  const [closedFilteredOpps, setClosedFilteredOpps] = useState([]);
  const [student, setStudent] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };


  useEffect(() => {
    const handleSetDependencies = async () => {
      const student = await GetSingleStudent(user?.id);
      dispatch(setUserDataAction(student));
      setStudent(student);
      const activeFilteredOpps = homeOpps?.mainOpps?.filter(
        (elt) =>
          elt?.resource?.organizationID === id &&
          new Date(elt?.expiry_date) > new Date()
      );
      const closedFilteredOpps = homeOpps?.expiredOpps?.filter(
        (elt) =>
          elt?.resource?.organizationID === id &&
          new Date() > new Date(elt?.expiry_date)
      );
      setActiveFilteredOpps(activeFilteredOpps);
      setClosedFilteredOpps(closedFilteredOpps.slice(0, 10));
    };

    handleSetDependencies();
  }, []);

  return (
    <>
      {/* <div className={overlay ? 'overlay_shown' : 'overlay_hidden'}>
        <WithdrawApplication removeOverlay={() => setOverlay(false)} />
      </div> */}
      <div className="noti-head">
        <div className="icon">
          <Link to={`/resource-profile?id=${id}`}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Opportunities</div>
        <div className="icon"></div>
      </div>
      <div className="item_container" style={{marginTop: 70}}>
        {activeFilteredOpps?.length && <h5>Active</h5>}
        {activeFilteredOpps?.map((elt, index) => (
          <div className="item" key={index}>
            <div className="item-head">
              <div className="p-logo">
                <img src={elt?.school?.image} className="circle" alt="c-logo" />
                <div
                  className={`heart ${
                    student?.verification_status === "verified"
                      ? ""
                      : "inactive"
                  }`}
                  onClick={() => addLike(elt)}
                >
                  {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                    <i className="fas fa-heart"></i>
                  ) : (
                    <img src={Heart} alt="icon" />
                  )}
                </div>
              </div>
            </div>
            <div
              className="info"
              onClick={() => navigate(`/apply-for-opportunity?id=${elt?.id}`)}
            >
              <div className="title">{elt?.name}</div>
              <div className="des break-overview">{elt?.description}</div>
              <div className="date-likes">
                <div className="date">{calculateTime(elt?.createdAt)}</div>
                <div className="dot"></div>
                <div className="likes">
                  {Object.keys(likes)?.includes(elt?.id)
                    ? likes[elt?.id]
                    : elt?.likes || 0}{" "}
                  {likes[elt?.id] === 1 ? " like" : " likes"}
                </div>
              </div>
            </div>
            <div className="p-type mr-1" type={elt?.category} key={index}>
              {elt?.category}
            </div>
          </div>
        ))}
      </div>
      <div className="item_container">
        {closedFilteredOpps && <h5>Recently Closed</h5>}
        {closedFilteredOpps?.map((elt, index) => (
          <div className="item inactive" key={index}>
            <div className="item-head">
              <div className="p-logo">
                <img src={elt?.school?.image} className="circle" alt="c-logo" />

                <div
                  className={`heart ${
                    student?.verification_status === "verified"
                      ? ""
                      : "inactive"
                  }`}
                  onClick={() => addLike(elt)}
                >
                  {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                    <i className="fas fa-heart"></i>
                  ) : (
                    <img src={Heart} alt="icon" />
                  )}
                </div>
              </div>
            </div>
            <div className="info">
              <div className="title">{elt?.name}</div>
              <div className="des break-overview">{elt?.description}</div>
              <div className="date-likes">
                <div className="date">{calculateTime(elt?.createdAt)}</div>
                <div className="dot"></div>
                <div className="likes">
                  {Object.keys(likes)?.includes(elt?.id)
                    ? likes[elt?.id]
                    : elt?.likes || 0}{" "}
                  {likes[elt?.id] === 1 ? " like" : " likes"}
                </div>
              </div>
            </div>
            <div className="p-type mr-1" type={elt?.category} key={index}>
              {elt?.category}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ResourceOpportunities;
