import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { axiosClient } from "../../libs/axiosClient";

export const GET_PURCHASES = gql`
  query PurchasesQuery {
    listPurchases (limit: 1000) {
      items {
        id
        purchased_by
        purchaser_role
        sessionID
        slots
        status
        updatedAt
        experienceID
        createdAt
        amount
      }
      nextToken
    }
}
`

export const GET_PURCHASES_WITH_TOKEN = gql`
  query PurchasesQuery ($limit: Int, $nextToken: String) {
    listPurchases (limit: $limit, nextToken: $nextToken) {
      items {
        id
        purchased_by
        purchaser_role
        sessionID
        slots
        status
        updatedAt
        experienceID
        createdAt
        amount
      }
      nextToken
    }
}
`

export const GET_PURCHASES_BY_STUDENT = gql`
  query PurchasesQuery($studentID: ID) {
    listPurchases (limit: 1000, filter: {purchased_by: {eq: $studentID}}) {
      items {
        id
        purchased_by
        purchaser_role
        sessionID
        slots
        status
        updatedAt
        experienceID
        createdAt
        amount
      }
      nextToken
    }
}
`

export async function AllPurchases() {
  const resp = await API.graphql({ 
    query: GET_PURCHASES,
  });

  const data = resp?.data?.listPurchases
  let nextToken = data?.nextToken
  let purchases = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_PURCHASES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listPurchases

    const previousData = [...purchases, ...respData?.items]
    purchases = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return purchases || [];
}

export const GET_PURCHASE = gql`
  query PurchasesQuery ($id: ID!) {
    getPurchases (id: $id) {
      id
      purchased_by
      purchaser_role
      sessionID
      slots
      status
      updatedAt
      experienceID
      createdAt
      amount
    }
}
`

export async function GetSinglePurchase(id) {
  try {
    const respPurchase = await API.graphql({
      query: GET_PURCHASE,
      variables: { id },
    });
    const purchase = respPurchase?.data?.getPurchases;
    return purchase;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function GetPurchasesByStudentID (studentID) {
  try {
    const resp = await API.graphql({ 
      query: GET_PURCHASES_BY_STUDENT,
      variables: {studentID}
    });
  
    const data = resp?.data?.listPurchases
    let purchases = data?.items;

    purchases = await Promise.all(
      purchases.map(async(purchase, index) => {
          const resp = await axiosClient.get(`experience/${purchase?.experienceID}`);
          purchase['experience'] = resp?.data?.data
          return purchase
      })
    )

    return purchases || [];
  } catch (err) {
    console.error(err);
    return [];
  }
}