import React from 'react'
import Check from '../../Assets/images/check-circle.svg'
import './style.scss'
import { useState } from 'react'

const stopProp = (e) => {
  e.stopPropagation()
}

const ConnectCounselor = (props) => {
  const {
    removeOverlay,
    shown,
    position,
    connect
  } = { ...props }

  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="connect-counselor">
              <div className="title">Connect to Institution</div>
              <div className="des">
                Starting a connection with a counselling institution means:
              </div>
              <div className="list">
                <div className="item">
                  <div className="icon">
                    <img src={Check} alt="icon" />
                  </div>
                  <div className="text">
                    You’re sharing access to your profile
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={Check} alt="icon" />
                  </div>
                  <div className="text">
                    You can communicate with any of the counselors from this
                    institution
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <img src={Check} alt="icon" />
                  </div>
                  <div className="text">
                    Counselors can recommend opportunities on Junity for you
                  </div>
                </div>
              </div>
              <div className="cus-check">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    value={dontShowAgain}
                    onClick={() => setDontShowAgain(!dontShowAgain)}
                    id="customControlAutosizing"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customControlAutosizing"
                  >
                    Got it. Don’t show this again
                  </label>
                </div>
              </div>
              <div className="connect">
                <button 
                  type='button'
                  onClick={(e) => connect(dontShowAgain)}
                >
                  I want this! Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConnectCounselor
