import { gql } from "@apollo/client";

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience(
    $id: ID!
    $likes: Int
    $liked_by: [ID!]
  ) {
    updateExperience(
      input: {
        id: $id
        likes: $likes
        liked_by: $liked_by
      }
    ) {
      id
      title
    }
  }
`;



