import { combineReducers } from "redux";
import userReducer from "./userReducers";
import institutionReducer from "./institutionReducers";
import cognitoUserReducer from "./cognitoUserReducer";
import applyOpportunityReducer from "./applyOpportunityReducers"
import loadersReducer from "./loadersReducers";
import homeOpportunitiesReducer from "./homeOpportunitiesReducer";
import referredByReducer from "./referredByReducers";
import routingStateReducer from "./routingStateReducer";

const rootReducer = combineReducers({
  user: userReducer,
  cognitoUser: cognitoUserReducer,
  institution: institutionReducer,
  applyOpportunity: applyOpportunityReducer,
  loaders: loadersReducer,
  referredBy: referredByReducer,
  homeOpps: homeOpportunitiesReducer,
  routingState: routingStateReducer
});

export default rootReducer;
