/* eslint-disable no-unused-expressions */
import React from 'react'
import './style.scss'

const stopProp = (e) => {
  e.stopPropagation()
}

const InfoHelp = ({ removeOverlay, category, shown, position }) => {

  return (
    <>
    {category !== {}
    &&
    <div
      className={`overlay_background ${shown}`}
      onClick={(e) => removeOverlay()}
    >
      <div className="overlay_card modified" onClick={(e) => stopProp(e)}>
        <div className={`popup_inner modified ${position} text-left`}>
          <div className="close-line" onClick={(e) => removeOverlay()}></div>
          <div className='category-main'>
            <h3>About {category?.name}</h3>
            <p>{category?.description}</p>
            <div className='interest-section'>
              {category?.interests?.map((interest) =>
              <div className='item'>
                <div className='icon interests'>
                  <img src={require(`../../Assets/images/${interest?.name}.${category?.name === "Business" ? "svg" : "png"}`)} alt="Marketing" />
                </div>
                <div>
                  <h6>{interest?.name}</h6>
                  <p>
                    {interest?.description}
                  </p>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  )
}

export default InfoHelp
