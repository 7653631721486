/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import NotiBell from "../../Assets/images/bell.svg";
import ImgTen from "../../Assets/images/scene-10.webp";
import BottomNav from "../../Components/BottomNav";
import MyAccount from "../../Components/Popus/MyAccount";
import VerifyAccount from "../../Components/Popus/VerifyAccount";
import { getInitial, PopularInYourArea } from "../../utils/helpers";
import { AllOpportunitiesAPI } from "../../Services/query/opportunities";
import { setLoadersDataAction } from "../../Redux/actions/loadersAction";
import {
  onCreateOpportunity,
  onUpdateOpportunity,
  onUpdateStudent,
} from "../../graphql/subscriptions";
import HomeSkeletonLoader from "../../Components/Common/HomeLoader/HomeLoader";
import OpportunitiesLoader from "../../Components/Common/OpportunitiesLoader";
import { AddLike } from "../../utils/common";
import ConfirmEmailAddress from "./ConfirmEmailAddress";
import VerificationBanner from "./VerificationBanner";
import CompleteTasks from "./CompleteTasks";
import PopularInArea from "./PopularInArea";
import Recommendations from "./Recommendations";
import Verified from "../../Components/Popus/Verified";
import { setHomeOpportunityDataAction } from "../../Redux/actions/homePageOpportunities";

import "./style.scss";
import { useAuth } from "../../Components/Auth";
import { getUserNotifications } from "../../Services/query/notifications";
import moment from "moment";

const Home = () => {
  const user = useSelector((store) => store.user);
  const loaders = useSelector((store) => store.loaders);
  const homeOpps = useSelector((store) => store.homeOpps);
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLoaded, setOppsLoaded] = useState(false);
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [overlay1, setOverlay1] = useState(false);
  const [verified, setVerified] = useState(false);
  const [student, setStudent] = useState(user);
  const [studentRefetch, setStudentRefetch] = useState(false);
  const [dependencies, setDependencies] = useState(homeOpps);
  const [refetch, setRefetch] = useState(false);
  const [toastLoading, setToastLoading] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const { addNotification } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy?.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };

  // const handleEmailVerified = (student) => {
  //   if (student?.email_verified && !(student?.dont_show_app_pop?.includes('email_verified'))) {
  //     updateStudent({
  //       variables: { id: student.id, dont_show_app_pop: [...student.dont_show_app_pop || [], 'email_verified'] }
  //     }).then(() => {
  //       setVerified(true);
  //       setTimeout(() => setVerified(false), 3000);
  //     }).catch((err) => console.error(err));
  //   };
  // }

  // console.log("homeOpps", homeOpps);
  const h = homeOpps?.recommendations?.filter((rec) => {
    const diffDays = moment(rec.expiry_date).diff(moment(), "days");
    return diffDays >= 0;
  });

  const studentVerified = useCallback(async () => {
    // handleEmailVerified(user);
    if (user?.id) {
      const opps = await AllOpportunitiesAPI();
      const nonExpiredOpps = opps?.filter(
        (elt) => new Date(elt?.expiry_date) > new Date()
      );
      const oppsInArea = await PopularInYourArea(user, nonExpiredOpps, "likes");
      const dependenciesObj = { apps: [], oppsInArea };
      dispatch(setLoadersDataAction({ home: true }));
      dispatch(setHomeOpportunityDataAction(dependenciesObj));
      setDependencies(dependenciesObj);
      setOppsLoaded(true);
    }
  }, [user, dispatch]);

  useEffect(() => {
    studentVerified();
  }, [studentVerified]);

  const retrieveNotifications = async () => {
    const results = await getUserNotifications(user?.id);
    setUserNotifications(
      results?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    );
  };

  useEffect(() => {
    retrieveNotifications();
  }, []);

  useEffect(() => {
    if (user?.id) {
      if (Object.keys(homeOpps?.oppsInArea || {})?.length === 0)
        studentVerified();
      else {
        setTimeout(() => setOppsLoaded(true), 2000);
        dispatch(setLoadersDataAction({ home: true }));
      }
      const studentUpdate = API.graphql({
        query: onUpdateStudent,
        variables: { id: user?.id },
      }).subscribe({
        next: (data) => {
          const oppData = data.value.data.onUpdateStudent;
          setStudent(oppData);
          setStudentRefetch(oppData);
        },
      });

      const opportunityCreation = API.graphql({
        query: onCreateOpportunity,
      }).subscribe({
        next: () => {
          setRefetch(true);
        },
      });

      const opportunityUpdate = API.graphql({
        query: onUpdateOpportunity,
      }).subscribe({
        next: () => {
          setRefetch(!refetch);
        },
      });

      return () => {
        studentUpdate.unsubscribe();
        opportunityCreation.unsubscribe();
        opportunityUpdate.unsubscribe();
      };
    } else {
      navigate("/sign-up");
    }
  }, []);

  return (
    <>
      {loaders?.home !== true ? (
        <HomeSkeletonLoader />
      ) : (
        <>
          <VerifyAccount
            removeOverlay={() => setOverlay1(false)}
            shown={overlay1 ? "shown" : "hidden"}
            position={overlay1 ? "slide-up" : "slide-down"}
          />
          <MyAccount
            removeOverlay={() => setOverlay(false)}
            shown={overlay ? "shown" : "hidden"}
            position={overlay ? "slide-up" : "slide-down"}
          />
          <Verified
            removeOverlay={() => setVerified(false)}
            shown={verified ? "shown" : "hidden"}
            position={verified ? "slide-up" : "slide-down"}
            header="Successful!"
            text="Your email address has been confirmed"
          />
          <div className="main">
            <div className="header-h">
              <div className="title">Hi, {user?.name?.split(" ")[0]}</div>
              <div className="menu">
                <div
                  className={`noti`}
                  onClick={() => navigate("/notifications")}
                >
                  <img src={NotiBell} alt="icon" />
                  {!!userNotifications?.length && (
                    <div className="red-dot"></div>
                  )}
                </div>
                <div className="user-p" onClick={() => setOverlay(true)}>
                  <div className="name-p">{getInitial(user?.name)}</div>
                  <span>{">"}</span>
                </div>
              </div>
            </div>
            <VerificationBanner student={student} setOverlay1={setOverlay1} />
            {!student?.email_verified && <ConfirmEmailAddress user={user} />}
            {student?.is_verified && (
              <CompleteTasks
                studentId={user?.id}
                addNotification={addNotification}
              />
            )}
            <div className="popular-sec">
              <div className="sec-head">
                <div className="title">Popular in your area</div>
                <div className={`view`}>
                  <Link to="/opportunities">
                    See more <span>{">"}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="popular-items">
            {oppsLoaded ? (
              <PopularInArea
                dependencies={dependencies}
                addLike={addLike}
                student={student}
                user={user}
                likes={likes}
                state={state}
              />
            ) : (
              <OpportunitiesLoader />
            )}
          </div>
          <div
            className={`join-junity-sec`}
            onClick={() => navigate("/influencer-program")}
          >
            <div className="bg position-relative py-4">
              <div className="info">
                <div className="title">Become a Junity Influencer</div>
                <div className="des">Earn rewards as an influencer</div>
              </div>
              <div
                className="icon position-absolute"
                style={{
                  width: "120px",
                  height: "120px",
                  right: 0,
                  top: "-10px",
                }}
              >
                <img src={ImgTen} alt="img" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="recommended-sec">
            <div className="sec-head">
              <div className="title">Recommended for you</div>
              <div className={`view`}>
                <Link to="/recommendations">
                  See more <span>{">"}</span>
                </Link>
              </div>
            </div>
            <Recommendations
              addLike={addLike}
              student={student}
              state={state}
              user={user}
              recommendations={h}
            />
          </div>
        </>
      )}
      <BottomNav className="home" />
    </>
  );
};

export default Home;
