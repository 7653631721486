import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { setUserDataAction } from "../../Redux/actions/userActions";
import { UPDATE_STUDENT } from "../../Services/mutation/student";
import { GetSingleStudent } from "../../Services/query/students";


function EmailVerificationRedirect() {
  const [page, setPage] = useState('Redirecting...');
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const token = search.get('id');

  useEffect(() => {
    const handleSetDependencies = async () => {
      const resp = await fetch(`${process.env.REACT_APP_SERVER_URL}/decode-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
      }) 
      if (resp.status === 400) {
        setPage('Email Verification is expired!');
      } else {
        const respData = await resp.json()
        const student = await GetSingleStudent(respData.studentId);
        dispatch(setUserDataAction(student));
        updateStudent({
          variables: { id: student.id, email_verified: true },
        }).then(() => navigate('/install')).catch(err => console.error(err));
      }
    }

    handleSetDependencies();
  }, [token])
  
  return ( 
    <div className="empty-state-full">
      <div className="center-content">
        <h4>{page}</h4>
      </div>
    </div>
  );
}

export default EmailVerificationRedirect;