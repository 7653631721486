import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Close from "../../Assets/images/x.svg";
import NoImg from "../../Assets/images/no-img.jpg";
import ChatCounselor from "../../Components/Popus/ChatCounselor";
import { GetEducatorsBySchoolID } from "../../Services/query/educators";

import "./style.scss";
import { chatClient } from "../../libs/axiosClient";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { CREATE_CHAT_HANDLER } from "../../Services/mutation/chatHandler";

const ChooseCounselor = () => {
  const student = useSelector((store) => store.user);
  const [overlay, setOverlay] = useState(false);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const navigate = useNavigate();

  const [counselors, setCounselors] = useState([]);
  const [createChatHandler] = useMutation(CREATE_CHAT_HANDLER);

  const createUser = async (data) => {
    await chatClient.post('/users/',{
      username: data?.id,
      first_name: data?.name?.split(' ')[0],
      last_name: data?.name?.split(' ').splice(1).join(''),
      secret: data?.id,
    });
  }

  const createChat = async (selectedCounselor, usernames) => {
    const chats = await chatClient.get('/chats');
    const singleChat = chats.data.findIndex(
      (elt) => (JSON.parse(elt.custom_json)["creatorID"] === student?.id 
      && JSON.parse(elt.custom_json)["destinationID"] === selectedCounselor?.id)
      || (JSON.parse(elt.custom_json)["creatorID"] === selectedCounselor?.id
      && JSON.parse(elt.custom_json)["destinationID"] === student?.id)
    );

    if (singleChat === -1) {
      const data = {
        usernames,
        title: student?.name,
        is_direct_chat: true,
        custom_json: JSON.stringify({
          orgID: selectedCounselor?.schoolID,
          creatorID: student?.id,
          creatorType: 'youth',
          destinationType: 'educator',
          destinationID: selectedCounselor?.id,
          senderName: student?.name,
          destinationName: selectedCounselor?.name,
          destinationImg: selectedCounselor?.image,
          type: 'sameOrgDirectChat'
        })
      }

      const chat = await chatClient.put('/chats/', data);
      createChatHandler({
        variables: {
          chat_id: chat.data.id,
          access_key: chat.data.access_key,
          createdAt: new Date()
        }
      });

      navigate(`/chat-person?id=${chat.data.id}&type=educator`);
    } else {
      const chat = chats.data[singleChat];
      navigate(`/chat-person?id=${chat.id}&type=educator`);
    }

  }

  const handleCounselorChat = async (selectedCounselor) => {
    try {
      const users = await chatClient.get("/users");
      const usersData = [selectedCounselor, student];
      usersData.forEach(async (elt) => {
        if (!users.data.some((user) => user.username === elt?.id)) {
          await createUser(elt);
        }
      });

      const usernames = [selectedCounselor?.id, student?.id];
      await createChat(selectedCounselor, usernames);      
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    GetEducatorsBySchoolID(id).then((data) => setCounselors(data));
  }, [id]);

  return (
    <>
      <ChatCounselor
        removeOverlay={() => setOverlay(false)}
        shown={overlay ? "shown" : "hidden"}
        position={overlay ? "slide-up" : "slide-down"}
      />
      <div className="noti-head">
        <div className="icon">
          <Link to="/find-counselor">
            <img src={Close} alt="icon" />
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="choose-con mt-5">
        <div className="title">Choose a Counselor.</div>
        <div className="des">
          You can chat with a different counselor later, if you change your
          mind.
        </div>
        <div className="row">
          {counselors?.map((elt) => (
            <div className="col-6" key={elt?.id}>
              <div
                className="item"
                onClick={() => handleCounselorChat(elt)}
              >
                <div className="user-img">
                  <img src={elt?.image || NoImg} alt="u-img" />
                </div>
                <div className="name">{elt?.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChooseCounselor;
