import React from 'react'
import { Link } from 'react-router-dom'
import { Mail } from 'react-feather'
import './style.scss'


const ResendRecoverEmail = () => {
  return (
    <>
      <div className="auth-sec">
        <div className="back-head">
          <Link to="/split-three">
            <i className="fas fa-times"></i>
          </Link>
        </div>
        <form>
          <div className="verify-email">
            <div className="circle">
              <Mail size="75" />
            </div>
            <div className="title">Check your Email</div>
            <div className="des">
              We just sent you an email with a instructions to change your
              mobile number and recover your account.
            </div>
          </div>
          <div className="submit-btn-text">
            <span>Did not receive the email?</span>
            <p>
              Check your spam folder, or{' '}
              <Link to="/sign-up" className="cus-btn">
                request a new link.
              </Link>
            </p>
          </div>
        </form>
      </div>
    </>
  )
}

export default ResendRecoverEmail
