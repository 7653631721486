import React from "react";
import SuccessImg from "../../Assets/images/success-img.svg";
import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const ApplicationSubmitted = ({ removeOverlay, shown, position }) => {
  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="connect-counselor">
              <div className="gif-img">
                <img src={SuccessImg} alt="gif" />
              </div>
              <div className="title">Your application has been submitted.</div>
              <div className="des mt-3">
                Updates on your application would be communicated soon.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationSubmitted;
