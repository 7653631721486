import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Verified from '../../../Components/Popus/Verified';
import { paramsWithPassword } from '../../../userPools';
import { cognitoidentity } from '../../../userPools';
import { passwordIsValid } from '../../../utils/common';

const ResetPassword = () => {
  const [overlay, setOverlay] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [passwords, setPasswords] = useState({});
  const [expired, setExpired] = useState(false);
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
  };

  const LinkExpired = () => {
    return (
      <div className='empty-state-full'>
        <div className='center-content'>
          <p>Link Expired!</p>
        </div>
      </div>
    )
  }

  const handleResetPassword = async () => {
    setLoading(true);
    cognitoidentity.adminSetUserPassword(
      paramsWithPassword(passwords.password1, userData.email),
      async (err, data) => {
        if (err) { 
          setLoading(false);
          throw err; 
        }
        else {
          setLoading(false);
          setOverlay(true);
          await new Promise(r => setTimeout(r, 2000));
          setTimeout(() => setOverlay(false), 2000);
          navigate('/install');
        }
      }
    )
  }

  useEffect(() => {
    if (Object.keys(passwords || {})?.length === 2) {
      const { password1, password2 } = { ...passwords };
      setValid((password1 === password2) && passwordIsValid(password1));
    } else {
      setValid(false);
    }
  }, [passwords]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const resp = await fetch(`${process.env.REACT_APP_SERVER_URL}/decode-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: id })
      }) 
      if (resp.status === 400) {
        setExpired(true);
      } else {
        const respData = await resp.json();
        setUserData(respData);
        setExpired(false);
      }
    }

    handleSetDependencies();
    
  }, []);
  
  return (
    <>
    {expired
    ? <LinkExpired />
    : <>
        <Verified 
          removeOverlay={() => setOverlay(false)} 
          shown={overlay ? 'shown': 'hidden'}
          header="Password Reset!"
          text="Password reset successful"
          position={overlay ? 'slide-up' : 'slide-down'}
        />
        <div className="auth-sec">
          <div className="back-head">
            <Link to="/split-three">
              <i className="fas fa-angle-left"></i>
            </Link>
          </div>
          <div className="heading">Reset Password</div>
          <div className="sub-heading">
            Choose a strong password that is different from any password you’ve
            used before.
          </div>
          <form>
            <div className="form-auth">
              <label>New Password</label>
              <input 
                type="password"
                placeholder="Choose a strong password"
                name="password1"
                onChange={handleChange}
              />
              <p>Password must be alpha-numeric with both lowercase and uppercase
                and at least 8 characters.</p>
            </div>
            <div className="form-auth">
              <label>Confirm Password</label>
              <input 
                type="password" 
                placeholder="Type password again" 
                name="password2"
                onChange={handleChange}
              />
              <p>Both passwords must match.</p>
            </div>
            <div className="submit-login">
              <button 
                type='button'
                disabled={!valid || loading}
                style={{ opacity: valid ? 1 : 0.6 }}
                onClick={handleResetPassword}
              >
                {loading ? "Loading..." : "Reset" }
              </button>
            </div>
          </form>
        </div>
      </>
    }
    </>
  )
}

export default ResetPassword
