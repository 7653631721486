import React, { useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetSingleSchool } from "../../Services/query/school";
import "./style.scss";
import { GetSingleOpportunity } from "../../Services/query/opportunities";
import { resetApplyOpportunityDataAction, setApplyOpportunityDataAction } from "../../Redux/actions/applyOpportunityAction";
import { CREATE_STUDENT_OPPORTUNITY_APPLICATION } from "../../Services/mutation/studentOpportunityApplication";
import { useMutation } from "@apollo/client";
import { axiosClient } from "../../libs/axiosClient";

const stopProp = (e) => {
  e.stopPropagation();
};

const ApplyOpper = (props) => {
  const {
    removeOverlay, 
    opportunityID, 
    schoolID,
    shown,
    position,
  } = { ...props };
  
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [school, setSchool] = useState({});
  const [opp, setOpp] = useState({});

  const [createStudentOpportunityApplication] = useMutation(
    CREATE_STUDENT_OPPORTUNITY_APPLICATION
  );

  const submitApplication = () => {
    const inputData = {
      schoolID,
      opportunityID,
      schoolName: school.name,
      studentID: user?.id,
      createdAt: new Date(),
      status: "pending",
      state: user?.state,
    };
    
    createStudentOpportunityApplication({
      variables: { ...inputData },
    }).then(async (data) => {
        const appId = data.data.createStudentOpportunityApplication.id;
        dispatch(resetApplyOpportunityDataAction());
        await axiosClient.post('/update-credit', {
          studentID: user?.id,
          condition: "Submissions",
          balance: user?.balance || 0,
          multiplier: user?.multiplier || 0,
          submissions_scan_1: user?.submissions_scan_1 || 0,
          submissions_scan_2: user?.submissions_scan_2 || 0,
          submissions_scan: user?.submissions_scan || 0,
          event_submissions_scan: user?.event_submissions_scan || 0,
          event_submissions_recommendation_scan: user?.event_submissions_recommendation_scan || 0,
        });
        navigate(`/app-submitted?id=${inputData?.opportunityID}&appId=${appId}`);
    }).catch((err) => {
      console.error(err);
    });
  };

  const handleProceed = () => {
    let profileType = ''
    if (user.schooling_mode === null) profileType = 'education';
    else if (user.employed === null) profileType = 'career';


    if (!(school?.dont_show_again?.includes(user?.id))) {
      navigate(`/send-profile?id=${opportunityID}&schoolID=${schoolID}&schoolName=${school.name}$type=${profileType}`);
    } else {
      if (profileType !== '') {
        navigate(
          `/complete-profile?opportunityID=${opportunityID}&schoolName=${school.name}&schoolID=${schoolID}&type=${profileType}`
        );
      } else {
        dispatch(setApplyOpportunityDataAction({
          schoolID,
          opportunityID,
          schoolName: school.name
        }));

        const oppQuestions = JSON.parse(opp?.questions || {});
        if (Object.keys(oppQuestions || {}).length > 0) navigate('/additional-info');
        else submitApplication();
      }
    }
  };

  useEffect(() => {
    const handleSetDependencies = async () => {
      const school = await GetSingleSchool(schoolID);
      setSchool(school);
      const opp = await GetSingleOpportunity(opportunityID);
      setOpp(opp);
    };
    handleSetDependencies();
  }, [schoolID]);

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={(e) => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={(e) => removeOverlay()}></div>
            <div className="apply-opper-popup text-left">
              <div className="title">Submit your Application</div>
              <div className="des">
                To submit an application to this resource, you’ll need to:
              </div>
              <div className="list">
                <div className="item">
                  <CheckCircle />
                  <span>Complete your Junity profile</span>
                </div>
                <div className="item">
                  <CheckCircle />
                  <span>Provide additional information</span>
                </div>
                <div className="item">
                  <CheckCircle />
                  <span>Follow up in Chat</span>
                </div>
              </div>
              <div className="lets-btn">
                <button onClick={handleProceed}>Let’s Go!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyOpper;
