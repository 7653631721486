import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client'
import { CREATE_STUDENT_GUARDIAN } from '../../../Services/mutation/studentGuardian';
import { UPDATE_STUDENT } from '../../../Services/mutation/student';
import { Link, useNavigate } from 'react-router-dom';
import { isEmail } from '../../AccountSettings/utils';
import InfoS from '../../../Assets/images/info-s.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDataAction } from '../../../Redux/actions/userActions';
import './style.scss'
import {isRequiredFieldsPassed, validatePhone} from "../../../utils/helpers";

const SendVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.user);
  const [state, setState] = useState({});
  const [agreed, setAgreed] = useState(false);
  const [phoneCls, setPhoneCls] = useState("");
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);

  const [createStudentGuardian] = useMutation(CREATE_STUDENT_GUARDIAN);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const handleChange = (e) => {
    const newState = state;

    newState[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      setIsEmailValid(true)
      setShowEmailError(false)
      if (!isEmail(e.target.value) || e.target.value === user.email){
        delete newState[e.target.name];
        setIsEmailValid(false)

        if (e.target.value === user.email){
          setShowEmailError(true)
        }
        else {
          setShowEmailError(false)
        }
      }
      else {
        setIsEmailValid(true)
      }
    }

    if (e.target.name === "phone") {
      if (e.target.value === user.phone){
        setShowPhoneError(true)
      }
      else {
        setShowPhoneError(false)
      }
    }

    setState({ ...newState });
  }

  const sendMail = async () => {
    await fetch(`${process.env.REACT_APP_SERVER_URL}/account-verification-request`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        name: user.name,
        email: state.email,
        parent: state.name,
        studentId: user.id,
      })
    });
  }

  const proceed = async() => {
    setLoading(true);
    createStudentGuardian({
      variables: { ...state, createdAt: new Date() }
    }).then(async (data) => {
      const guardian = data?.data?.createStudentGuardian;
      updateStudent({ 
        variables: { 
          id: user?.id, 
          guardianID: guardian?.id,
          verification_status: "inprogress",
        }}).then((student) => {
        setLoading(false);
        dispatch(setUserDataAction(student?.data?.updateStudent));
        sendMail();
        navigate('/verification-inprogress');
      }).catch(() => {
        setLoading(false);
      });
    }).catch(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setValid(isRequiredFieldsPassed(state, 3, 'eq')
        && state?.phone?.length === 10
        && validatePhone(state?.phone)
        && agreed
    );
    if (state?.phone?.length === 10) {
      const validPhone = validatePhone(state?.phone);
      setPhoneCls(validPhone ? 'border-success' : 'border-danger');
    } else {
      if (phoneCls !== '') setPhoneCls('');
    }

  }, [state, agreed]);
  

  return (
    <>
      <div className="auth-sec req-v">
        <div className="back-head">
          <Link to="/home">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className="heading">Request Verification</div>
        <div className="sub-heading">
          Please provide the contact information of a parent or legal guardian.
        </div>
        <div className="alert-c">
          <a href="https://joinjunity.com">
            <img src={InfoS} alt="icon" />
            <span>Learn more about proxy verification on Junity.</span>
          </a>
        </div>
        <div className="seperator"></div>
        <form>
          <div className="main-label">Parent or Guardian</div>
          <div className="form-auth">
            <label>Full Name</label>
            <input type="text" placeholder="Full Name" name='name' onChange={handleChange}/>
          </div>
          <div className="form-auth">
            <label>Phone Number</label>
            <div className={`phone-input ${phoneCls}`}>
              <span>+1</span>
              <input 
                type="text" 
                placeholder="xxxxxxxxxx"
                name='phone' 
                onChange={handleChange}
              />
            </div>
            <small 
              className={`text-danger font-weight-bold ${showPhoneError ? '' : 'd-none'}`}
              style={{fontSize: 11}}>
                Guardian's phone number cannot be
              the same ward's phone number!
            </small>
          </div>
          <div className="form-auth">
            <label>Email Address</label>
            <input 
              type="text" 
              placeholder="Email Address"  
              name='email' 
              className={`${isEmailValid ? '' : 'border border-danger'}`}
              onChange={handleChange}/>
            <small 
              className={`text-danger font-weight-bold ${showEmailError ? '' : 'd-none'}`} 
              style={{fontSize: 11}}>
                Guardian's email address cannot be
              the same as ward's email address!
            </small>
          </div>
          <div className="cus-check">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customControlAutosizing"
                value={agreed}
                onChange={() => setAgreed(!agreed)}
              />
              <label className="custom-control-label" htmlFor="customControlAutosizing">
                I agree that these are my actual parents or legal guardians and
                that if this information is found to be inaccurate, my account
                may be restricted.
              </label>
            </div>
          </div>
          <div className='center-btn'>
            <div className="submit-btn">
              <button 
                type='button'
                className={`cus-btn bg-green ${valid ? loading ? 'inactive' : '' : 'inactive'}`}
                onClick={proceed}
              >
                { loading ? "Requesting..." : "Request Verification"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SendVerification
