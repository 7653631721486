import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import GoogleIcon from "../../../../Assets/images/google.png";
import { GetStudentByEmail } from "../../../../Services/query/students";
import { useDispatch } from "react-redux";
import { setUserDataAction } from "../../../../Redux/actions/userActions";
import { setCognitoUserDataAction } from "../../../../Redux/actions/cognitoUserActions";
import AlertToast from "../../../../Components/Common/Alerts/ToastAlert";

import "./style.scss";

const NoLogin = () => {
  const [state, setState] = useState("");
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [toast, setToast] = useState("");
  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      const student = await GetStudentByEmail(state.email);
      console.log(student);
      if (Object.keys(student).length > 0) {
        const cognitoUser = await Auth.signIn(student.email, state.password);
        dispatch(setUserDataAction(student));
        dispatch(setCognitoUserDataAction(cognitoUser));
        setLoading(false);
        navigate(!student?.interestIDs ? "/select-interest" : "/home");
      } else {
        setActiveToast(true);
        setToast("User does not exist");
        setLoading(false);
      }
    } catch (err) {
      setActiveToast(true);
      setToast("Incorrect email address or password");
      setLoading(false);
    }
  };

  useEffect(() => {
    setValid(Object.keys(state).length > 0);
  }, [state]);

  useEffect(() => {
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        console.log("");
      });
    }
  }, []);

  useEffect(() => {
    if (passwordRef.current) {
      if (showPassword) {
        passwordRef.current.type = "text";
      } else {
        passwordRef.current.type = "password";
      }
    }
  }, [showPassword]);

  return (
    <>
      <AlertToast
        variant={"error"}
        icon={"delete"}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
      />
      <div className="auth-sec">
        <div className="back-head">
          <Link to="/split-three">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className="heading">Welcome to Junity</div>
        <div className="sub-heading">Let’s get started.</div>
        <form>
          <div
            className="google-login"
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          >
            <Link to="">
              <img src={GoogleIcon} alt="icon" />
              <span>Log In with Google</span>
            </Link>
          </div>
          <div className="option-text">or sign in with your email address</div>
          <div className="form-auth">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter your email address"
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="form-auth position-relative">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              name="password"
              onChange={handleChange}
              ref={passwordRef}
            />
            <i
              className={`fas ${
                showPassword ? "fa-eye-slash" : "fa-eye"
              } fa-fw position-absolute text-secondary`}
              style={{ top: 48, right: 18, cursor: "pointer" }}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
          </div>
          <div className="having-issue mt-2">
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
          <div className="submit-login">
            <button
              className="cus-btn"
              type="button"
              disabled={!valid}
              style={{ opacity: valid ? 1 : 0.3 }}
              onClick={handleNext}
            >
              {loading ? "Loading..." : "Log In"}
            </button>
          </div>
          <div className="submit-btn-text">
            <Link to="/sign-up">Don’t have an account? Sign Up</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default NoLogin;
