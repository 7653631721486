import {
  SET_USER_DATA,
  RESET_USER_DATA,
} from "../constants/userConstants";

const initialLoginState = {
  name: "",
  email: "",
  gender: "",
  dob: "",
  state: "",
  phone: ""
};

const userReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {...state, ...action.payload};
    case RESET_USER_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default userReducer;
