import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

const SocialSignUp = () => {
  useEffect(() => {
    document.body.style.background =
      'linear-gradient(0deg, #c1d6f41a, #c1d6f41a), #ffffff'
  })
  return (
    <>
      <div className="noti-head p-0 back-head border-0">
        <div>
          <Link to="/sign-up">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className='text progress-bars'>
          <div className='active'></div>
          <div className='next'></div>
          <div className='next'></div>
        </div>
      </div>
      <div className="auth-sec mt-5">
        <div className="heading">Welcome to Junity</div>
        <div className="sub-heading">Let’s get started.</div>
        <form>
          <div className="form-auth">
            <label>First Name</label>
            <input type="text" defaultValue="Bryce" />
          </div>
          <div className="form-auth">
            <label>Last Name</label>
            <input type="text" defaultValue="David" />
          </div>
          <div className="form-auth">
            <label>Email Address</label>
            <input type="text" defaultValue="bryce.david@gmail.com" />
          </div>
          <div className="submit-btn">
            <Link to="/second-step" className="cus-btn">
              Get Started
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default SocialSignUp
