/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import NavTabs from "../Nav";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { UPDATE_STUDENT_INTEREST } from "../../../../Services/mutation/studentInterest";
import Verified from "../../../../Components/Popus/Verified";
import SelectInterestDetail from "../../../../Components/Common/SelectInterests"
import { GetStudentInterestByStudentID } from "../../../../Services/query/studentInterests";
import { UPDATE_STUDENT } from "../../../../Services/mutation/student";

const InterestTab = () => {
  const [state, setState] = useState({});
  const [verified, setVerified] = useState(false);
  const [updateStudentInterest] = useMutation(UPDATE_STUDENT_INTEREST);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [loading, setLoading] = useState(false);

  const user = useSelector((store) => store.user);

  const proceed = async (interests, categories) => {
    setLoading(true);
    updateStudentInterest({
      variables: {
        id: state.id,
        interestIDs: interests,
      },
    }).then(() => {
      updateStudent({ variables: { id: user.id, interestIDs: interests, interests: categories }});
      setLoading(false);
      setVerified(true);
      getStudentInterestData();
      setTimeout(() => {
        setVerified(false);
      }, 3000);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
  };

  const getStudentInterestData = async () => {
    const studentInterest = await GetStudentInterestByStudentID(user?.id);
    setState(studentInterest);
  }
  

  useEffect(() => {
    getStudentInterestData();
  }, []);
  

  return (
    <>
    <NavTabs />
    <div className={verified ? "overlay_shown" : "overlay_hidden"}>
      <Verified
        removeOverlay={() => setVerified(false)} 
        shown={verified ? 'shown': 'hidden'}
        position={verified ? 'slide-up' : 'slide-down'}
        header="Success!"
        text="Updated Successfully!"
      />
    </div>
    <SelectInterestDetail 
      proceed={proceed}
      status={!user.is_verified}
      loading={loading}
      userInterests={state.interestIDs}
    />
  </>
  );
};

export default InterestTab;