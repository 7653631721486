import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { CREATE_REVOKE_PROFILE_SHARING } from "../../Services/mutation/revokeSharingProfile";
import { UPDATE_SCHOOL } from "../../Services/mutation/school";
import { UPDATE_STUDENT } from "../../Services/mutation/student";
import { DELETE_STUDENT_OPPORTUNITY_APPLICATION } from "../../Services/mutation/studentOpportunityApplication";

import "./style.scss";

const stopProp = (e) => {
  e.stopPropagation();
};

const RevokePop = (props) => {
  const { 
    removeOverlay, 
    school, 
    user, 
    setFilteredSchools,
    shown,
    position
  } = { ...props };
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [other, setOther] = useState("");
  const [deleteOppApp] = useMutation(DELETE_STUDENT_OPPORTUNITY_APPLICATION);
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [createRevokeAccess] = useMutation(CREATE_REVOKE_PROFILE_SHARING);
  const [updateSchool] = useMutation(UPDATE_SCHOOL);

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const accessRevoke = () => {
    createRevokeAccess({
      variables: {
        studentID: user?.id,
        school: school.name,
        createdAt: new Date(),
        reason: reason === "other" ? other : reason,
      },
    })
      .then(() => {
        setLoading(false);
        setFilteredSchools([]);
        removeOverlay();
      })
      .catch((err) => console.error(err));
  };

  const schoolUpdate = () => {
    const connectionsObj = school?.connections?.filter(
      (elt) => elt !== user?.id
    );
    const connectionDates = school?.connection_dates?.filter(
      (elt, index) => index !== school?.connections.indexOf(user?.id)
    );

    updateSchool({
      variables: {
        id: school.id,
        connections: [...connectionsObj],
        connectionDates: [...connectionDates],
      },
    })
      .then(() => {
        accessRevoke();
      })
      .catch((err) => console.error(err));
  };

  const revokeAccess = () => {
    setLoading(true);
    updateStudent({
      variables: {
        id: user?.id,
        profile_sharing: user.profile_sharing?.filter((id) => id !== school.id),
      },
    })
      .then(() => {
        school?.studentApps?.forEach((app) => {
          deleteOppApp({
            variables: { id: app.id },
          });
        });
        schoolUpdate();
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <>
      <div
        className={`overlay_background ${shown}`}
        onClick={() => removeOverlay()}
      >
        <div className="overlay_card" onClick={(e) => stopProp(e)}>
          <div className={`popup_inner ${position} text-left`}>
            <div className="close-line" onClick={() => removeOverlay()}></div>
            <div className="revole-pop">
              <div className="title">
                Are you sure you want to revoke access to your profile?
              </div>
              <div className="des-alert">
                You currently have ({school?.studentApps?.length}) pending
                application{school?.studentApps?.length > 1 ? "s" : ""} to{" "}
                {school?.name}.. If you stop sharing your profile now, your
                application will automatically be withdrawn.
              </div>
              <div className="des">
                Why do you want to stop sharing your profile with this resource?
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="first"
                  name="revoke"
                  value="haveConcernsAboutPrivacy"
                  onChange={handleChange}
                />
                <label htmlFor="first">I have concerns about my privacy</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="second"
                  name="revoke"
                  value="other"
                  onChange={handleChange}
                />
                <label htmlFor="second">Some other reason</label>
              </div>
              {reason === "other" && (
                <div className="reason">
                  <textarea
                    rows="2"
                    placeholder="Please describe the issue"
                    onChange={(e) => setOther(e.target.value)}
                  ></textarea>
                </div>
              )}
              <div className="buttons">
                <button className="con" onClick={removeOverlay}>
                  Continue Sharing
                </button>
                <button className="rev" onClick={revokeAccess}>
                  {loading ? "Revoking..." : "Revoke Access"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevokePop;
