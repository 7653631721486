import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllStudents } from '../../../Services/query/students';
import { setReferredByDataAction } from '../../../Redux/actions/referredByActions';
import LaunchLogo from '../../../Assets/images/launch-logo.svg'

import './style.css'

const LaunchScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const { id } = useParams();

  useEffect(() => {
    if (user?.id && !user?.interestIDs){
      return navigate('/select-interest');
    } else if (user?.id) {
      const timer = setTimeout(() => navigate('/home'), 2000);
      return () => clearTimeout(timer);
    } else {
      if (id) {
        AllStudents().then((students) => {
          const referrer = students.find((student) => student.referral_code === id);
          dispatch(setReferredByDataAction(referrer.id))
        });
      }
      const timer = setTimeout(() => navigate('/split-one'), 2000);
      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <>
      <div className="launch-screen">
        <img src={LaunchLogo} alt="logo" />
      </div>
    </>
  )
}

export default LaunchScreen
