/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import makeAnimated from 'react-select/animated'
import { setUserDataAction } from '../../../../Redux/actions/userActions'
import { DAYS, MONTHSHORTS } from '../../../../utils/data'
import { useMutation } from '@apollo/client'
import { CREATE_STUDENT } from '../../../../Services/mutation/student'

import './style.scss'
import {getCities} from "../../../../utils/helpers";


const animatedComponents = makeAnimated();


const SocialSecondStep = () => {
  const modifiedStates = getCities({
    keys: ["label", "value"],
    requiresAll: false,
    returnObj: true,
    sort: true
  });
  
  useEffect(() => {
    document.body.style.background =
      'linear-gradient(0deg, rgba(255, 244, 225, 0.2), rgba(255, 244, 225, 0.2)), #FFFFFF'
  }, []);

  const name = useSelector(state => state.user.name);
  const user = useSelector((store) => store.user);
  const nameArray = name?.split(/(\s+)/);
  const firstName = nameArray[0];

  const [state, setState] = useState({});
  const [valid, setValid] = useState(false);
  const [location, setLocation] = useState({});
  const daysJsx = [];
  const monthJsx = [];
  const yearsJsx = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createStudent] = useMutation(CREATE_STUDENT);
  const maxYear = new Date().getFullYear()-13
  const minYear = new Date().getFullYear()-20

  let dateRange = []

  for(let i = minYear; i <= maxYear; i++){
    dateRange.push(i)
  }

  useEffect(() => {
    if (Object.keys(state).length === 5) {
      setValid(Object.values(state).every(elt => elt !== ""))
    }
  }, [state])

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const setupInfo = () => {
    const dob = `${state.year}-${state.month}-${state.day}`
    const obj = {...user, ...state}
    delete obj.year
    delete obj.month
    delete obj.day
    delete obj.phone

    obj.dob = dob

    const deactivated = false;
    const balance = 0;
    const createdAt = new Date();
    const verification_progress = 0
    const verification_status = "unverified";
    const verification_date_started = new Date();
    const is_verified = false;
    const email_verified = true;

    createStudent({
      variables: { 
        ...obj,
        createdAt, 
        deactivated, 
        balance,
        verification_date_started,
        verification_progress,
        verification_status,
        is_verified,
        email_verified,
      }
    }).then((data) => {
      const dataReceived = { ...data?.data?.createStudent }
      dispatch(setUserDataAction(dataReceived));
      navigate('/get-start')
    }).catch((err) => {
      console.error(err);
    });
  }
  
  useEffect(() => {
    if (location?.label) {
      setState({
        ...state,
        state: location?.label
      })
    }
  }, [location])

  return (
    <>
      <div className="noti-head p-0 back-head border-0">
        <div className='icon'>
          <Link to="/sign-up">
            <i className="fas fa-angle-left"></i>
          </Link>
        </div>
        <div className='text progress-bars'>
          <div className='previous'></div>
          <div className='active'></div>
        </div>
      </div>
      <div className="auth-sec mt-5">
        <div className="heading">Hi, {firstName}!</div>
        <div className="sub-heading">Let’s get to know you.</div>
        <form>
          <div className="form-auth">
            <label>How do you identify yourself?</label>
            <div className="custom-text-radio">
              <input 
                type="radio" 
                id="Male" 
                name="gender" 
                value="male"
                onChange={handleChange}
              />
              <label htmlFor="Male">Male</label>

              <input 
                type="radio" 
                id="Female" 
                name="gender"
                value="female" 
                onChange={handleChange}
              />

              <label htmlFor="Female">Female</label>

              <input 
                type="radio" 
                id="Prefer" 
                name="gender" 
                value="Prefer not to say"
                onChange={handleChange}
              />

              <label htmlFor="Prefer">Prefer not to say</label>
            </div>
          </div>
          {/*<div className="form-auth">*/}
          {/*  <label>When were you born?</label>*/}
          {/*  <div className="custom-select-options">*/}
          {/*    <select */}
          {/*      name="month" */}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Month</option> */}
          {/*      {MONTHSHORTS.forEach((elt, index) => {*/}
          {/*        monthJsx.push(<option value={DAYS[index]}>{elt}</option> )*/}
          {/*      })}       */}
          {/*      {monthJsx}*/}
          {/*    </select>*/}
          {/*    <select */}
          {/*      name="day" */}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Day</option>*/}
          {/*      {DAYS.forEach((elt) => {*/}
          {/*        daysJsx.push(<option value={elt}>{elt}</option>)*/}
          {/*      })}*/}
          {/*      {daysJsx}*/}
          {/*    </select>*/}
          {/*    <select */}
          {/*      name="year" */}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option>Year</option>*/}
          {/*      {dateRange.forEach((elt) => {*/}
          {/*        yearsJsx.push(<option value={elt} key={`year-${elt}`}>{elt}</option>)*/}
          {/*      })}*/}
          {/*      {yearsJsx}*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="form-auth google-map">
            <label>Where do you live?</label>
            <Select
              closeMenuOnSelect={false}
              className="grayed"
              components={animatedComponents}
              options={modifiedStates}
              isClearable={true}
              placeholder="Start typing..."
              name="state"
              onChange={setLocation}
            />
          </div>
          <div className="center-btn">
            <div className="submit-btn">
              <button 
                type='button'
                className="cus-btn"
                disabled={!valid}
                style={{opacity : valid ? 1 : 0.3}}
                onClick={setupInfo}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SocialSecondStep
