import React from "react";
import {formatMessageTime, getInitial, getRandomColor} from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CounselorsChat = ({ item }) => {
  const user = useSelector((store) => store.user);

  const navigate = useNavigate();

  const isOnline = item?.people?.some(
    (elt) =>
      elt?.person?.username !== user?.id && elt?.person?.is_online === true
  );

  return (
    <div
      className="item"
      onClick={() => navigate(`/chat-person?id=${item?.id}&type=educator`)}
    >
      <div className="user-img">
        {JSON.parse(item?.custom_json || "{}")?.destinationImg
            ? <img
          src={JSON.parse(item?.custom_json || "{}")?.destinationImg}
          width={48}
          height={48}
          style={{ borderRadius: "50%" }}
          alt="user-img"
        /> :
        <div className="user-p">
          <div className="name-p" style={{ backgroundColor: getRandomColor()}}>{getInitial(JSON.parse(item?.custom_json || "{}")?.destinationName)}</div>
        </div>
      }
        <div className={isOnline && "active"}></div>
      </div>
      <div className="info">
        <div className="name">
          {JSON.parse(item?.custom_json || "{}")?.destinationName}
        </div>
        <div className="s-msg">{item?.last_message?.text}</div>
      </div>
      <div className="action">
        <div className="time">{formatMessageTime(item?.last_message?.created)}</div>
        {/* <div className="no">
          <div className="mute">
            <img src={MuteIcon} alt="icon" />
          </div>
          <div className="msg-no">2</div>
        </div> */}
      </div>
    </div>
  );
};

export default CounselorsChat;
