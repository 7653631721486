import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetGroup } from "./groups";

export const GET_RECOMMENDED_OPPORTUNITIES = gql`
  query RecommendedOpportunitiesQuery {
    listRecommendations (limit: 1000) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }

      nextToken
    }
}
`

export const GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN = gql`
  query RecommendedOpportunitiesQuery ($limit: Int, $nextToken: String) {
    listRecommendations (limit: $limit, nextToken: $nextToken) {
      items {
        studentID
        opportunityID
        updatedAt
        id
        groupID
        educatorID
        createdAt
        categoryID
      }
      nextToken
    }
}
`

export async function AllRecommendedOpportunities() {
  const resp = await API.graphql({ 
    query: GET_RECOMMENDED_OPPORTUNITIES,
  });

  const data = resp?.data?.listRecommendations
  let nextToken = data?.nextToken
  let recommendedOpps = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_RECOMMENDED_OPPORTUNITIES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listRecommendations

    const previousData = [...recommendedOpps, ...respData?.items]
    recommendedOpps = [...previousData];

    nextToken = respData?.nextToken
  }
  return await recommendedOpps?.reduce(async (previousPromise, recommendation) => {
    const group = await GetGroup(recommendation.groupID);
    let opportunitiesData = await previousPromise;
    const obj = { ...recommendation, group };
    opportunitiesData.push(obj);
    return opportunitiesData;
  }, Promise.resolve([]));
}


export async function GetRecommendationsByStudentIDAndOppID (oppID, studentID) {
  const recommendations = await AllRecommendedOpportunities();
  const recommendedOpp = recommendations.find((elt) => (elt.opportunityID === oppID) && (elt?.group?.studentIDs?.includes(studentID)));
  return recommendedOpp || {};
}



