import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdClose, MdInfo } from 'react-icons/md';
import { Compass, User } from 'react-feather';
import { ReactComponent as MessageCircle } from '../../../Assets/images/message-circle.svg';
import { ReactComponent as MessageCircleWarning } from '../../../Assets/images/message-circle-yellow.svg';
import { ReactComponent as MessageCircleSuccess } from '../../../Assets/images/message-circle-green.svg';
import { ReactComponent as FileCheck } from '../../../Assets/images/file-check.svg';
import { ReactComponent as CheckmarkCircle } from '../../../Assets/images/check-circle.svg';
import { ReactComponent as Cube } from '../../../Assets/images/cube.svg';
import {ReactComponent as CubeBlack} from '../../../Assets/images/cube-black.svg';
import './style.css';


const icons = {
  compass: <Compass size={20} color="#D41811"/>,
  compassSucces: <Compass size={20} color="#2D9371"/>,
  messageCircle: <MessageCircle/>,
  messageCircleWarning: <MessageCircleWarning/>,
  messageCircleSuccess: <MessageCircleSuccess/>,
  checkmarkCircle: <CheckmarkCircle/>,
  fileCheck: <FileCheck/>,
  cube: <Cube/>,
  cubeBlack: <CubeBlack/>,
  user: <User size={20} color="#262F3F"/>
}

export const PushNotificationItem = ({notification, onClose})=>{
  const [closed, setClosed] = useState(false);

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      onClose();
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 4000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={`notification ${closed ? 'slide-out' : ''} mb-2`}>
      <div className={`notification-icon ${notification?.status}`}>
        {notification?.icon 
          ? <>{icons[notification?.icon]}</>
          : <MdInfo />
        }
      </div>
      <div className="notification-content">
        <p>{notification?.text}</p>
        {notification?.linkUrl &&
          <Link 
            to={notification?.linkUrl} 
            className='notification-link'
            onClick={handleClose}
            >{notification?.linkTitle}</Link>
        }
      </div>
      <MdClose
        className="close-icon"
        size={24}
        color='silver'
        onClick={handleClose}
      /> 
    </div>
  )
}

export const InAppNotificationItem = ({notification, onClose=()=>{}})=>{
  const [show, setShow] = useState(true);
  const [closed, setClosed] = useState(false);

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setShow(false);
      onClose();
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 4000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])


  return (
    <>
    {show && 
      <div className={`notification inapp ${closed ? 'slide-out' : ''} mb-2`}>
        <div className="notification-content">
          <p>{notification?.text}</p>
          {notification?.linkUrl &&
            <Link 
              to={notification?.linkUrl} 
              className='notification-link'
              onClick={handleClose}
              >{notification?.linkTitle}</Link>
          }
        </div>
      </div>
    }
    </>
  )
}

export const InAppNotifications = ({notifications, removeNotification}) => {

  return (
    <div className="notifications-container">
        {notifications.map((notification, index) => (
          <>
            {(notification?.type === 'inapp') ?
              <InAppNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            : 
              <PushNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            }
          </>
        ))}
      </div>
  );
};

