/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Heart from "../../Assets/images/heart.svg";
import { Info } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { calculateTime } from "../../utils/helpers";
import { AddLike } from "../../utils/common";
import { setRoutingStateDataAction } from "../../Redux/actions/routingStateAction";
import { useAuth } from "../../Components/Auth";

const RecommendedItems = () => {
  const user = useSelector((store) => store.user);
  const homeOpps = useSelector((store) => store.homeOpps);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [state, setState] = useState({});
  const [likes, setLikes] = useState({});
  const [oppsLikedBy, setOppsLikedBy] = useState([]);
  const [toastLoading, setToastLoading] = useState(false);
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const updateOpps = (id, newLikes, oldLikedBy) => {
    setLikes({
      ...likes,
      [id]: newLikes,
    });

    let updatedLikedBy = [];
    if (oldLikedBy.includes(user?.id)) {
      updatedLikedBy = oldLikedBy.filter((elt) => elt !== user?.id);
    } else {
      updatedLikedBy = [...oldLikedBy, user?.id];
    }

    setOppsLikedBy(updatedLikedBy);
  };

  const addLike = (elt) => {
    const addLikeProps = {
      state,
      setState,
      oppsLikedBy,
      user,
      elt,
      likes,
      updateOpps,
      toastLoading,
      setToastLoading,
    };

    AddLike(addLikeProps);
  };

  const routeToDetailedOpportunity = (elt) => {
    dispatch(
      setRoutingStateDataAction({
        singleOpportunityPreviousRoute: location.pathname + location.search,
      })
    );
    navigate(`/apply-for-opportunity?id=${elt?.id}`);
  };

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Recommended for you </div>
        <div className="icon">
          <Link to="/">
            <span>
              <Info />
            </span>
          </Link>
        </div>
      </div>
      {/* <div className="liked-items">
        <div className="title">Today, Nov 18, 2021</div>
      </div> */}
      <div className="oppertunities" style={{ marginTop: 80 }}>
        <div className="opper-list">
          {homeOpps?.recommendations?.map((elt, index) => (
            <div className="item" key={index}>
              <div className="item-head">
                <div className="p-logo">
                  <img
                    src={elt?.school?.image}
                    className="circle"
                    alt="c-logo"
                    onClick={() => routeToDetailedOpportunity(elt)}
                  />

                  <div className="heart" onClick={() => addLike(elt)}>
                    {state[elt?.id] || elt?.liked_by?.includes(user?.id) ? (
                      <i className="fas fa-heart"></i>
                    ) : (
                      <img src={Heart} alt="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="info"
                onClick={() => routeToDetailedOpportunity(elt)}
              >
                <div className="title">{elt?.name}</div>
                <div className="des break-overview">{elt?.description}</div>
                <div className="date-likes">
                  <div className="date">{calculateTime(elt?.createdAt)}</div>
                  <div className="dot"></div>
                  <div className="likes">
                    {Object.keys(likes)?.includes(elt?.id)
                      ? likes[elt?.id]
                      : elt?.likes || 0}{" "}
                    {likes[elt?.id] === 1 ? " like" : " likes"}
                  </div>
                </div>
              </div>
              <div className="p-type mr-1" type={elt?.category} key={index}>
                {elt?.category}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecommendedItems;
