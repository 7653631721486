import React, {useState, useEffect, useRef} from 'react'
import { User, ChevronLeft, MoreHorizontal, Calendar } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import JICon from '../../../Assets/images/jCoin.svg'
import PurchasesOptions from '../../../Components/Popus/PurchasesOptions'
import { GetSingleExperience } from '../../../Services/query/experiences'
import { GetSingleSession } from '../../../Services/query/experienceSessions'
import { GetSinglePurchase } from '../../../Services/query/purchases'
import {calculateTimeWithPmOrAm, formatDate, formatDateForSessions, formatTimestamp} from '../../../utils/helpers'
import { useMutation } from '@apollo/client'
import { DELETE_PURCHASE } from '../../../Services/mutation/purchase'
import { UPDATE_STUDENT } from '../../../Services/mutation/student'
import './style.scss'
import AlertToast from "../../../Components/Common/Alerts/ToastAlert";

const PurchasesDetail = () => {
  const navigate = useNavigate();

  const user = useSelector((store) => store.user);
  const [activeToast, setActiveToast] = useState(false);
  const [toast, setToast] = useState("");
  const [loading, setLoading] = useState(false);
  
  const search = new URLSearchParams(useLocation().search);
  const id = search.get("id");
  const number = search.get("number");
  
  const [overlay, setOverlay] = useState(false)
  const [purchase, setPurchase] = useState({});
  const [experience, setExperience] = useState({});
  const [session, setSession] = useState({});

  const [deletePurchase] = useMutation(DELETE_PURCHASE);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const addToGoogleRef = useRef(null);

  const experienceType = {
    'online': 'Online Experience',
    'inperson': 'In Person Experience',
  }

  const getExperienceType = (type) => {
    if (type && Object.keys(experience)?.includes(type)) {
      return experienceType[type];
    }

    return ""
  }

  const getSessionDuration = () => {
    if (session) {
      const dateTimeStart = new Date(`${session?.date}T${session?.start_time}:00`);
      const dateTimeEnd = new Date(`${session?.date}T${session?.end_time}:00`);
      const hourDiff = dateTimeEnd.getHours() - dateTimeStart.getHours();
      if (hourDiff === 0) {
        return `${dateTimeEnd.getMinutes() - dateTimeStart.getMinutes()} mins`;
      }
      return `${hourDiff} hr`;
    }
    return 0
  }

  const cancelPurchase = async () => {
    setLoading(true);
    try {
      await deletePurchase({ variables: { id: id }});
      await updateStudent({ 
        variables: { 
          id: user?.id, 
          balance: (user?.balance || 0) + purchase?.amount,
          multiplier: (user?.multiplier || 0) - 0.1
        }
      });
      setLoading(false);
      setActiveToast(true);
      setToast(`You have successfully canceled your purchase of ${experience?.title}`);
      await new Promise((r) => setTimeout(r, 3000));
      navigate('/store/purchase-items');
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }

  const getEventUrl = (session) => {
    const timezone = "US/Eastern";
    const googleDomainUrl = "https://calendar.google.com/calendar/u/0/r/eventedit"
    const startTimeStamp = formatTimestamp(session?.date, session?.start_time);
    const endTimeStamp = formatTimestamp(session?.date, session?.end_time);
    const { location, title } = experience;
    return `${googleDomainUrl}?dates=${startTimeStamp}/${endTimeStamp}&ctz=${timezone}&text=${title}&location=${location}`
  }
  
  useEffect(() => {
    const handleSetDependencies = async () => {
      const purchase = await GetSinglePurchase(id);
      setPurchase(purchase);
      const experience = await GetSingleExperience(purchase.experienceID);
      setExperience(experience);
      const session = await GetSingleSession(purchase.sessionID);
      setSession(session);
    }

    handleSetDependencies();
  }, []);

  console.log(experience);
  

  return (
    <>
      <PurchasesOptions 
        removeOverlay={() => setOverlay(false)} 
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
      />
      <AlertToast
          variant={'error'}
          icon={'box'}
          active={activeToast}
          setActive={setActiveToast}
          info={toast}
      />
      <div className="noti-head border-bottom-0">
        <div className="icon"  onClick={() => navigate('/store/purchase-items')}>
          <ChevronLeft />
        </div>
        <div className="text"></div>
        <div className="icon" onClick={() => setOverlay(true)}>
          <MoreHorizontal />
        </div>
      </div>
      <div className="checkout-sec mt-5">
        <div className="purchases-detail">
          <div className="label">
            <div className="name">Purchase #{number}</div>
            <div className="type" type="Confirmed">
              {purchase?.status}
            </div>
          </div>
          <div className="date">
            <strong>Last updated: </strong>
            {formatDate(purchase?.updatedAt) || formatDate(purchase?.createdAt)}
          </div>
          <div className="date">
            <strong>Purchase date: </strong>{formatDate(purchase?.createdAt)}
          </div>
        </div>
        <div className="purchase-item">
          <div className="item">
            <div className="item-img">
              <img src={experience?.image} alt="item-img" />
            </div>
            <div className="info">
              <div className="item-title">{experience?.title}</div>
              <div className="des">{getExperienceType(experience?.type)}</div>
              <div className="time">{getSessionDuration()}</div>
            </div>
          </div>
        </div>
        <div className="change-session">
          <div className="title">Session</div>
          <div className="item">
            <div className="sess">
              {formatDateForSessions(session?.date)} <br/>
              {calculateTimeWithPmOrAm(session?.start_time)} - 
              {calculateTimeWithPmOrAm(session?.end_time)}
              (ET)            
            </div>
          </div>
          <button onClick={() => addToGoogleRef.current.click()}>
            <Calendar />
            Add to Calendar
          </button>
          <a ref={addToGoogleRef} href={getEventUrl(session)} rel="noreferrer" target="_blank" hidden>Google</a>
        </div>
        <div className="slots">
          <div className="title">Slots</div>
          <div className="item">
            <div className="left">
              <User />
              <span>{purchase?.slots} Person</span>
            </div>
          </div>
        </div>
        <div className="price">
          <div className="title">Price</div>
          <div className="item">
            <div className="left">
              <strong>Total: </strong>
              <img src={JICon} alt="icon" />
              <span>{purchase?.amount}</span>
            </div>
          </div>
        </div>
        <div className="change-session">
          <div className="title">Your Contact Information</div>
          <div className="item">
            <div className="sess">
              <div className="label">Mobile Phone Number</div>
              {user?.phone}
            </div>
          </div>
        </div>
        <div className="acti">
          <button className="light-btn">Get Help with this Purchase</button>
          <button className="light-btn" disabled={loading} onClick={cancelPurchase}>
            {loading ? "Loading..." : "Cancel Purchase" }
          </button>
        </div>
      </div>
    </>
  )
}

export default PurchasesDetail
