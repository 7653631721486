import { gql } from "@apollo/client";

export const CREATE_STUDENT_OPPORTUNITY_APPLICATION = gql`
  mutation CreateStudentOpportunityApplication(
    $dont_show_again: Boolean
    $opportunityID: ID!
    $studentID: ID!
    $schoolID: ID
    $state: String
    $question_reply: String
    $status: String
    $createdAt: AWSDateTime!
    $updatedAt: AWSDateTime
  ) {
    createStudentOpportunityApplication(
      input: {
        dont_show_again: $dont_show_again
        opportunityID: $opportunityID
        question_reply: $question_reply
        status: $status
        state: $state
        schoolID: $schoolID
        studentID: $studentID
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      id
      createdAt
    }
  }
`;

export const DELETE_STUDENT_OPPORTUNITY_APPLICATION = gql`
  mutation DeleteStudentOpportunityApplication(
    $id: ID!
  ) {
    deleteStudentOpportunityApplication(
      input: {
        id: $id
      }
    ) {
      id
      opportunityID
    }
  }
`;


