import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { toast, Toaster } from 'react-hot-toast';
import { cognitoidentity, paramsWithPassword } from '../../../userPools';


const ChangePassword = () => {
  const [state, setState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);

  const handleChange = (e) => {
   setState({
     ...state,
     [e.target.name]: e.target.value
   });
  };

  const handlePasswordChangeCallback = async (err, data) => {
    if (err) { 
      setLoading(false);
      toast.error('An error occurred!');
    }
    else {
      setLoading(false);
      setState({});
      toast.success('Password changed successfully!', 2);
      await new Promise(r => setTimeout(r, 2000));
      navigate('/account-settings')
    }
  }

  const handleChangePassword = () => {
    setLoading(true);
    Auth.signIn(user.email, state.oldPassword).then(() => {
      cognitoidentity.adminSetUserPassword(
        paramsWithPassword(state.newPassword, user.email),
        handlePasswordChangeCallback
      )
      }).catch((err) => {
        if (err.code === "NotAuthorizedException") {
          setLoading(false);
          toast.error('Password incorrect!');
        } else {
          setLoading(false);
          toast.error('An error occurred, try again later!');
        }
      });
  }

  useEffect(() => {
    if (state?.newPassword?.trim()) {
      if (state?.newPassword?.trim() === state?.confirmNewPassword?.trim()) {
        const validPassword = state?.newPassword?.match(
          /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/
        );
        if (validPassword !== null) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false)
    }
  }, [state])
  

  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to="/account-settings">
            <span>{'<'}</span>
          </Link>
        </div>
      </div>
      <div className="auth-sec mt-3">
        <div className="heading">Change Password</div>
        <div className="sub-heading">
          Choose a strong password that is different from any password you’ve
          used before.
        </div>
        <form>
          <div className="form-auth">
            <label>Old Password</label>
            <input 
              type="password" 
              placeholder="Old Password" 
              name="oldPassword" 
              value={state?.oldPassword}
              onChange={handleChange}
            />
          </div>
          <div className="form-auth">
            <label>New Password</label>
            <input 
              type="password" 
              placeholder="Choose a strong password"
              name="newPassword" 
              value={state?.newPassword}
              onChange={handleChange}
            />
            <p>Password must be alpha-numeric with both lowercase and uppercase
              and at least 8 characters.</p>
          </div>
          <div className="form-auth">
            <label>Confirm New Password</label>
            <input 
              type="password" 
              placeholder="Type password again" 
              name="confirmNewPassword" 
              value={state?.confirmNewPassword}
              onChange={handleChange}
            />
            <p>Both passwords must match.</p>
          </div>
          <div className="submit-login">
            <button 
              type='button' 
              disabled={!isValid}
              style={{ opacity: isValid ? 1 : 0.6 }}
              onClick={handleChangePassword}
            >
              {loading ? "Loading..." : "Change Password" }
            </button>
          </div>
        </form>
      </div>
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
      />
    </>
  )
}

export default ChangePassword
