import {
    SET_ROUTING_STATE_DATA,
    RESET_ROUTING_STATE_DATA,
} from "../constants/routingStateConstant";

export const setRoutingStateDataAction = (data) => async (dispatch) => {
    dispatch({
        type: SET_ROUTING_STATE_DATA,
        payload: data,
    });
};

export const resetRoutingStateDataAction = () => async (dispatch) => {
    dispatch({
        type: RESET_ROUTING_STATE_DATA,
    });
};
