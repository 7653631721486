import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Jcoin from "../../../Assets/images/jCoin.svg";
import { ChevronLeft } from "react-feather";
import { AllPurchases, GetPurchasesByStudentID } from "../../../Services/query/purchases";
import { formatDate } from "../../../utils/helpers";

import "./style.scss";

const PurchaseItems = () => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const [myPurchases, setMyPurchases] = useState([]);

  useEffect(() => {
    const handleSetDependencies = async () => {
      const purchases = await GetPurchasesByStudentID(user?.id);
      setMyPurchases(purchases);
    };

    handleSetDependencies();
  }, []);

  return (
    <>
      <div className="noti-head">
        <div className="icon" onClick={() => navigate("/store")}>
          <ChevronLeft />
        </div>
        <div className="text">My Purchases</div>
        <div className="icon"></div>
      </div>
      <div className="listing-items mt-5">
        {myPurchases?.map((elt, index) => (
          <div
            key={index}
            className="item"
            onClick={() =>
              navigate(
                `/store/purchase-items/detail?id=${elt?.id}&number=${index + 1}`
              )
            }
          >
            <div className="label">
              Purchase #{index + 1}
              <div className="jCoin">
                <img src={Jcoin} alt="icon" />
                {elt?.amount}
              </div>
            </div>
            <div className="count">1 item purchased</div>
            <div className="status">
              <div className="date">{formatDate(elt?.createdAt)}</div>
              <div className="type" type="Confirmed">
                {elt?.status}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PurchaseItems;
