import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Verified from "../../../../Components/Popus/Verified";
import { setUserDataAction } from "../../../../Redux/actions/userActions";
import { UPDATE_STUDENT } from "../../../../Services/mutation/student";
import { GetSchool } from "../../../../Services/query/school";
import NavTabs from "../Nav";
import { GetSingleStudent } from "../../../../Services/query/students";


const EducationTab = ({ hideNav, proceed }) => {
  const [state, setState] = useState({});
  const [success, setSuccess] = useState(false);
  const [userSchool, setUserSchool] = useState({});
  const dispatch = useDispatch();
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [loading, setLoading] = useState(false);
  const [student, setStudent] = useState([]);
  const user = useSelector((store) => store.user);


  const schools = GetSchool();
  const school = [{label: user?.school, value: user?.schoolID}]
  const options = [];
  schools?.forEach((school) => {
    const obj = {
      label: school?.name,
      value: school?.id,
    };
    options.push(obj);
  });

  const [hide, setHide] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const save = () => {
    setLoading(true);
    const inputData = {
      id: user?.id,
      schooling_mode: state.schoolingMode || user?.schooling_mode || "",
      points: state.points ||  user?.points || 0,
      schoolID: state?.schoolID || user?.schoolID || '',
      school: state?.school || user?.school || '',
      graduation_term: state.graduation_term ||  user?.graduation_term || "",
      graduation_year: state.graduation_year ||  user?.graduation_year || "",
    };
    updateStudent({
      variables: { ...inputData },
    })
      .then((data) => {
        setLoading(false);
        if (hideNav) { proceed() }
        else {
          dispatch(setUserDataAction(data?.data?.updateStudent));
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            
          }, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (state.schoolingMode) {
      setHide(state.schoolingMode !== "");
    }
  }, [state]);

  useEffect(() => {
    setState({
      ...state,
      schoolID: userSchool?.id,
      school: userSchool?.name,
    })
  }, [userSchool]);

  useEffect(() => {
    GetSingleStudent(user.id).then((student) => setStudent(student));
  }, []);

  return (
    <>
      {!hideNav && <NavTabs />}
      <div className={success ? "overlay_shown" : "overlay_hidden"}>
        <Verified
          removeOverlay={() => setSuccess(false)} 
          shown={success ? 'shown': 'hidden'}
          position={success ? 'slide-up' : 'slide-down'}
          header="Success!"
          text="Your profile has been updated."
        />
      </div>
      <div className={`auth-sec ${hideNav ? "auth-sec-hide px-0 pb-0" : "pb-6"}`}>
        <div className="des">
          Details about your school and education are used to find you
          counselors, mentors and opportunities close to you.
        </div>
        <form>
          <div className="edu-check">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                id="customControlAutosizing1"
                name="schoolingMode"
                value="notenrolled"
                defaultChecked={user?.schooling_mode === "notenrolled"}
                onChange={handleChange}
              />
              <label
                className="custom-control-label"
                htmlFor="customControlAutosizing1"
              >
                I’m not enrolled in school
              </label>
            </div>
          </div>
          <div className="edu-check">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                id="customControlAutosizing2"
                name="schoolingMode"
                value="schooling"
                defaultChecked={user?.schooling_mode === "schooling"}
                onChange={handleChange}
              />
              <label
                className="custom-control-label"
                htmlFor="customControlAutosizing2"
              >
                I’m schooling
              </label>
            </div>
          </div>
          {/* {!hide ? (
            <>
              <div className="form-auth">
                <label>School</label>
                <input
                  type="text"
                  placeholder="St Joseph High School "
                  value={state?.school || school?.name}
                  onChange={handleChange}
                  name="school"
                />
                <SelectAutoComplete 
                  data={options} 
                  setData={setUserSchool} 
                  currentData={state?.school}
                  defaultValue={school}
                />
              </div>
              <div className="form-auth">
                <label>Grade</label>
                <div className="custom-select-options">
                  <select
                    name="points"
                    onChange={handleChange}
                    defaultValue={user?.points}
                  >
                    <option>Select Grade</option>
                    <option value="12">Grade 12</option>
                    <option value="11">Grade 11</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-auth">
                    <label>Graduation Term</label>
                    <div className="custom-select-options">
                      <select
                        name="graduation_term"
                        onChange={handleChange}
                        defaultValue={user?.graduation_term}
                      >
                        <option>Select Grade</option>
                        <option value="Summer">Summer</option>
                        <option value="Winter">Winter</option>
                        <option value="Fall">Fall</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-auth">
                    <label>Graduation Year</label>
                    <div className="custom-select-options">
                      <select
                        name="graduation_year"
                        onChange={handleChange}
                        defaultValue={user?.graduation_year}
                      >
                        <option>Select Grade</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )} */}
        </form>
        <div className={`select-btn ${student.is_verified ? '' : 'inactive'}`}>
          {hideNav && (<p>
            By applying to this opportunity, you are allowing this organization
            view your profile for use in processing your application. Learn more
            about this in our <strong>Privacy Policy</strong>.
          </p>)}
          <button onClick={save}>
            {loading ? "Loading..." : `${hideNav ? "Submit Application" : "Save"}`}
          </button>
        </div>
      </div>
    </>
  );
};

export default EducationTab;
