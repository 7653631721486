/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { axiosClient } from "../../../libs/axiosClient";
import { CREATE_STUDENT_OPPORTUNITY_APPLICATION } from "../../../Services/mutation/studentOpportunityApplication";
import { resetApplyOpportunityDataAction } from "../../../Redux/actions/applyOpportunityAction";
import { GetSingleOpportunity } from "../../../Services/query/opportunities";
import { isRequiredFieldsPassed } from "../../../utils/helpers";
import { useAuth } from "../../../Components/Auth";

const AdditionalInfo = () => {
  const applyOpportunity = useSelector((store) => store.applyOpportunity);
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [oppQuestions, setOppQuestions] = useState({});
  const [state, setState] = useState("");

  const [createStudentOpportunityApplication] = useMutation(
    CREATE_STUDENT_OPPORTUNITY_APPLICATION
  );

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const submitApplication = () => {
    setLoading(true);
    const inputData = {
      ...applyOpportunity,
      studentID: user?.id,
      createdAt: new Date(),
      status: "pending",
      question_reply: JSON.stringify(state),
      state: user?.state,
    };
    createStudentOpportunityApplication({
      variables: { ...inputData },
    })
      .then(async (data) => {
        setLoading(false);
        dispatch(resetApplyOpportunityDataAction());
        const appId = data.data.createStudentOpportunityApplication.id;
        await axiosClient.post("/update-credit", {
          studentID: user?.id,
          condition: "Submissions",
          balance: user?.balance || 0,
          multiplier: user?.multiplier || 0,
          submissions_scan_1: user?.submissions_scan_1 || 0,
          submissions_scan_2: user?.submissions_scan_2 || 0,
          submissions_scan: user?.submissions_scan || 0,
          event_submissions_scan: user?.event_submissions_scan || 0,
          event_submissions_recommendation_scan:
            user?.event_submissions_recommendation_scan || 0,
        });
        navigate(
          `/app-submitted?id=${inputData?.opportunityID}&appId=${appId}`
        );
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValid(
      isRequiredFieldsPassed(state, Object.keys(oppQuestions).length, "eq")
    );
  }, [state]);

  useEffect(() => {
    GetSingleOpportunity(applyOpportunity.opportunityID).then((opp) => {
      const questionsObj = JSON.parse(opp.questions) || {};
      setOppQuestions(questionsObj);
    });
  }, []);

  return (
    <>
      <div className="noti-head border-bottom-0">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text"></div>
        <div className="icon"></div>
      </div>
      <div className="send-profile mt-5">
        <div className="title">Additional Information</div>
        <div className="des">
          {applyOpportunity?.schoolName} would like to get some more information
          about you.
        </div>
        <div className="auth-sec p-0 mb-3">
          <form>
            {Object.keys(oppQuestions).map((elt) =>
              oppQuestions[elt]?.type === "shortAns" ? (
                <div className="form-auth">
                  <label>{oppQuestions[elt]?.question}</label>
                  <input
                    type="text"
                    name={elt}
                    onChange={handleChange}
                    placeholder="Enter your answer here..."
                  />
                </div>
              ) : (
                <div className="form-auth">
                  <label>{oppQuestions[elt]?.question}</label>
                  <select name={elt} onChange={handleChange}>
                    <option value="">Select answer</option>
                    {Object.keys(oppQuestions[elt]?.options)?.map((opt) => (
                      <option value={oppQuestions[elt]?.options[opt]} key={opt}>
                        {oppQuestions[elt]?.options[opt]}
                      </option>
                    ))}
                  </select>
                </div>
              )
            )}
          </form>
        </div>
        <div className="coun-btn">
          <p>
            By applying to this opportunity, you confirm that this information
            is accurate. If this information is found to be inaccurate, your
            application will be rejected and you will not be allowed to apply to
            opportunities from this organization in future.
          </p>
          <button
            style={{ opacity: valid ? 1 : 0.6 }}
            onClick={submitApplication}
            disabled={!valid}
          >
            {loading ? "Submitting..." : "Submit Application"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AdditionalInfo;
