/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CheckSquare, Clock } from 'react-feather'
import './style.scss'
import ApplicationSubmitted from '../../Components/Popus/ApplicationSubmitted'
import ApplyApplication from '../../Components/Popus/ApplyApplication'
import TermsConditions from '../../Components/Popus/TermsConditions'
import WithdrawApplication from '../../Components/Popus/WithdrawApplication'
import JICon from '../../Assets/images/jCoin.svg'
import Gift from '../../Assets/images/gift.svg';
import CheckFill from '../../Assets/images/check-fill.svg';
import Star from '../../Assets/images/star.svg';
import InfluencerBanner from '../../Assets/images/scene-12.webp';
import { useMutation } from '@apollo/client'
import { CREATE_JUNITY_INFLUENCER } from '../../Services/mutation/JunityInfluencer'
import { useDispatch, useSelector } from 'react-redux'
import { GetJunityInfluencer } from '../../Services/query/junityInfluencer'
import { setUserDataAction } from '../../Redux/actions/userActions'
import { calculateTime } from '../../utils/helpers'
import { GetSingleStudent } from '../../Services/query/students'
import { toast, Toaster } from 'react-hot-toast'

const InfluencerProgram = () => {
  const [overlay, setOverlay] = useState(false);
  const [overlay1, setOverlay1] = useState(false);
  const [overlay2, setOverlay2] = useState(false);
  const [overlay3, setOverlay3] = useState(false);
  const [innerOverlay, setInnerOverlay] = useState(false);
  const [createJunityInfluencer] = useMutation(CREATE_JUNITY_INFLUENCER);
  const [applied, setApplied] = useState(false);
  const [influencerId, setInfluencerId] = useState('');
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const influencer = GetJunityInfluencer(user?.influencer_ID);

  const student = GetSingleStudent(user?.id);

  const handleApply = () => {
    if (student?.is_verified) {
      setOverlay(true);
    } else {
      toast.error('Account not verified')
    }
  }

  useEffect(() => {
    if (student !== undefined && student !== {}) {
      dispatch(setUserDataAction(student));
    }
  }, [student]);
  

  useEffect(() => {
    if (innerOverlay) {
      setTimeout(() => setInnerOverlay(false), 3000);
      createJunityInfluencer({
        variables: { 
          role_ID: user?.id, 
          role: 'student',
          createdAt: new Date(),
          is_active: true,
        }
      }).then((data) => {
        dispatch(setUserDataAction({
          influencer_ID: data?.data?.createJunityInfluencer?.id
        }));
        setApplied(true);
        setInfluencerId(data?.data?.createJunityInfluencer?.id)
        influencer?.refetch();
      }).catch((err) => console.error(err))
    }
  }, [innerOverlay]);
  

  return (
    <>
      <ApplyApplication 
        removeOverlay={() => setOverlay(false)} 
        setInnerOverlay={setInnerOverlay}
        shown={overlay ? 'shown': 'hidden'}
        position={overlay ? 'slide-up' : 'slide-down'}
      />
      <TermsConditions 
        removeOverlay={() => setOverlay1(false)} 
        shown={overlay1 ? 'shown': 'hidden'}
        position={overlay1 ? 'slide-up' : 'slide-down'}
      />
      <WithdrawApplication 
        removeOverlay={() => setOverlay2(false)} 
        id={influencerId || user.influencer_ID} 
        setApplied={setApplied}
        shown={overlay2 ? 'shown': 'hidden'}
        position={overlay2 ? 'slide-up' : 'slide-down'}
      />
      <ApplicationSubmitted 
        removeOverlay={() => setOverlay3(false)} 
        shown={overlay3 ? 'shown': 'hidden'}
        position={overlay3 ? 'slide-up' : 'slide-down'}
      />

      <div className="influen-head">
        <div className="back">
          <Link to="/home">{'<'}</Link>
        </div>
        <div className="">
          <img src={InfluencerBanner} className="banner" alt="junity-influen"/>
        </div>
      </div>
      <div className="influen-body">
        <div className="head">
          <div className="title">Become a Junity Influencer</div>
          <div className="des">
            Are you a natural leader who enjoys working with others? Are you
            active in your school or community and social media responsible?
            {' '}
            Does Junity’s mission resonate with you?{' '}
          </div>
          <div className="se-des">
            Yes? <strong>Then you should become a Junity Influencer.</strong>{' '}
          </div>
        </div>
        <div className="make-impact">
          <div className="title">Make Impact</div>
          <div className="des">
            As a Junity Influencer, you’ll help your peers discover
            opportunities on Junity that will contribute to their growth as they
            explore their passions.
          </div>
          <div className="listing">
            <div className="item">
              <div className="icon">
                <CheckSquare />
              </div>
              <div className="text">
                Invite others with your unique invite link
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <CheckSquare />
              </div>
              <div className="text">
                Plan and host Junity events with other Junity Inflluencers
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <CheckSquare />
              </div>
              <div className="text">Stay active in the JI community</div>
            </div>
          </div>
        </div>
        <div className="earn-re">
          <div className="title">Earn Rewards</div>
          <div className="des">
            We’ve made sure your Junity Influencer status comes with the perks
            you deserve.
          </div>
          <div className="listing">
            <div className="item">
              <div className="icon">
                <img src={JICon} alt="icon" width={22} />
              </div>
              <div className="text">
                <div className="t">More Junity Credits</div>
                <div className="d">
                  Earn more credits for using Junity and inviting peers.
                </div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src={Gift} alt="icon" />
              </div>
              <div className="text">
                <div className="t">Cool Junity Swags</div>
                <div className="d">
                  Get a free laptop, t-shirts and suppliers from Junity.
                </div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src={Star} alt="icon" />
              </div>
              <div className="text">
                <div className="t">Get Trained</div>
                <div className="d">
                  Leadership and influencer trainings from experts.
                </div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src={CheckFill} alt="icon" />
              </div>
              <div className="text">
                <div className="t">Junity Influencer Badge</div>
                <div className="d">
                  Stand out with a Junity Influencer Badge.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="apply-now">
          <div className="title">Apply to be a JI</div>
          <div className="des">
            Believe you’d make a great Junity Influencer? Let us know.
            <strong>Applications are open till June 18, 2021.</strong>
          </div>
          {(applied || influencer?.data?.is_active)
          ? (
            <div className="pending-app">
              <div className="icon">
                <Clock />
              </div>
              <div className="info">
                <div className="label">Your application is Pending</div>
                <div className="label-des">
                  Submitted {calculateTime(influencer?.data?.createdAt || new Date())}
                </div>
              </div>
              <div className="action" onClick={() => setOverlay2(true)}>
                Withdraw
              </div>
            </div>
          ) : (
            <button onClick={handleApply} className="inactive">
              Coming soon...
            </button>
          )
          }
          <div className="se-des">
            By applying to be a JI, you agree to the{' '}
            <strong onClick={() => setOverlay1(true)}>
              Terms and Conditions{' '}
            </strong>{' '}
            of the Junity Influencer Program (JIP).
          </div>
        </div>
      </div>
      <Toaster />
    </>
  )
}

export default InfluencerProgram
