import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './style.scss';

function FindCouncellorLoader() {
  return ( 
    <>
      <div className="notifications">
        <div className="item border-bottom">
          <div className='d-flex justify-content-between'>
            <Skeleton width={25} height={25}/>
            <Skeleton width={250} height={40}/>
            <Skeleton width={20} height={15}/>
          </div>
        </div>
        <div className="item border-bottom">
          <div className='d-flex justify-content-between'>
            <Skeleton width={25} height={25}/>
            <div className='d-flex flex-column'>
              <Skeleton width={250} height={40}/>
              <Skeleton width={250} height={50}/>
            </div>
            <Skeleton width={20} height={15}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindCouncellorLoader;