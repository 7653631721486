/* eslint-disable no-unused-expressions */
import { getSpecificStringDate } from "../../../utils/helpers";

export const HandleFilter = (props) => {
  const {
    setFilteredSearch,
    setHeaderTextState,
    reset,
    filterBy,
    filterParams,
    setOverlay,
    sameInterestOpps,
  } = { ...props }

  const matchStates = (state) => {
    return filterParams.includes(state);
  }

  const getStates = (state) => {
    return state.some((r) => matchStates(r) === true);
  }

  if (reset) {
    setFilteredSearch(sameInterestOpps);
    setHeaderTextState("");
  } else {
    let foundOpps = [];

    if (filterBy) {
      if (filterBy === "locations") {
        if (!filterParams?.includes("All")) {
          foundOpps = sameInterestOpps?.filter((elt) => getStates(elt.state) === true );
        } else {
          foundOpps = sameInterestOpps;
        }
      } else if (filterBy === "organizations") {
        foundOpps = sameInterestOpps?.filter(
          (elt) => filterParams.includes(elt.resourceID)
        );
      } else if (filterBy === "dueDate") {
        const times = ["today", "month", "week"];
        let getDate = times?.includes(filterParams)
          ? getSpecificStringDate(filterParams)
          : filterParams;

        foundOpps = sameInterestOpps?.filter(
          (elt) =>
            new Date(elt?.createdAt) >= getDate.firstDay &&
            new Date(elt?.createdAt) <= getDate.lastDay
        );
      }
  
      foundOpps?.length > 1
        ? setHeaderTextState("opportunities")
        : setHeaderTextState("opportunity");
      
      setFilteredSearch(foundOpps);
    } else {
      setFilteredSearch(sameInterestOpps);
    }
  }
  setOverlay(false);
}