import { gql } from "@apollo/client";

export const CREATE_STUDENT_VOLUNTEER_WORK = gql`
  mutation CreateNewVolunteer(
    $organization: String!
    $hours_spent: String!
    $studentID: ID!
    $createdAt: AWSDateTime
  ) {
    createStudentVolunteerWork(
      input: {
        organization: $organization
        hours_spent: $hours_spent
        studentID: $studentID
        createdAt: $createdAt
      }
    ) {
      id
      organization
      hours_spent
    }
  }
`;


export const UPDATE_STUDENT_VOLUNTEER_WORK = gql`
  mutation UpdateStudentVolunteer(
    $id: ID!
    $organization: String!
    $hours_spent: String!
    $updatedAt: AWSDateTime
  ) {
    updateStudentVolunteerWork(
      input: {
        organization: $organization
        hours_spent: $hours_spent
        updatedAt: $updatedAt
        id: $id
      }
    ) {
      id
      organization
      hours_spent
    }
  }
`;

export const DELETE_STUDENT_VOLUNTEER_WORK = gql`
  mutation DeleteStudentVolunteer(
    $id: ID!
  ) {
    deleteStudentVolunteerWork(
      input: {
        id: $id
      }
    ) {
      id
      organization
      hours_spent
    }
  }
`;
