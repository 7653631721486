import Email from "../../../Assets/images/mail.svg";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

function ConfirmEmailAddress({ user }) {
  const navigate = useNavigate();

  const sendVerificationLink = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_URL}/email-verification`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: user.name,
            email: user.email,
            studentId: user.id,
          }),
        }
      );
      navigate("/verify-email");
    } catch (e) {
      toast.error("An error occurred, try again later.");
    }
  };

  return (
    <div className="confirm-email">
      <div className="icon">
        <img src={Email} alt="icon" />
      </div>
      <div className="info">
        <div className="title">Confirm your email address</div>
        <div className="des">
          We’ll send a verification code to confirm your email address.
        </div>
        <div className="link" onClick={sendVerificationLink}>
          <a>
            Send verification link <span>{">"}</span>
          </a>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default ConfirmEmailAddress;
