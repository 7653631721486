/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import ImgNineteen from '../../Assets/images/scene-19.webp';
import LaunchLogo from '../../Assets/images/logo.png'

import './style.css';

const LogoutPage = () => {

  return (
    <>
      <div className="p-3 logout">
        <Link to='/login' className="logo-tm d-flex align-items-center">
          <img src={LaunchLogo} width={60} height={60} alt="launch logo"/>
          <div className="font-weight-bolder junitytm junity-text-primary">Junity <sup>TM</sup></div>
        </Link>
        <h4 className="heading font-weight-bolder mt-3">See you next time.</h4>
        <div>You have logged out of your Junity account.</div>
        <div className="img my-3">
          <img src={ImgNineteen} className="img-fluid" alt="logout"/>
        </div>
        <div className="login-link">
          <p>Forgot Something? <Link to="/login" className="junity-text-primary font-weight-bolder">Log In</Link></p>
        </div>
      </div>
    </>
  );
};

export default LogoutPage;
