import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ImgOne from "../../../Assets/images/scene-1.webp";
import WelcomePreloader from "../../../Components/Common/WelcomePreloaders";
import "./style.scss";

const SplitOne = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(0deg, #c1d6f41a, #c1d6f41a), #ffffff";
  });

  return (
    <>
      {loading && <WelcomePreloader />}
      <div
        className="onbording-screen bg-one"
        style={{ display: loading ? "none" : "block" }}
      >
        <div className="img my-4">
          <img
            src={ImgOne}
            alt="classroom img"
            onLoad={() => setLoading(false)}
          />
        </div>
        <div className="text">
          <div className="heading">Connecting you to opportunities</div>
          <div className="sub-heading">
            Preparing you for a future of success.
          </div>
        </div>
        <div className="indicators">
          <div className="item active"></div>
          <div className="item"></div>
          <div className="item"></div>
        </div>
        <div className="skip-next">
          <div className="row">
            <div className="col-6">
              <div className="skip">
                <Link to="/split-three">Skip</Link>
              </div>
            </div>
            <div className="col-6">
              <div className="next">
                <Link to="/split-two">Next &gt; </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplitOne;
