import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_RESOURCES = gql`
  query AllResources {
    listResources (limit: 1000) {
      items {
        id
        name
        description
        contact_email
        contact_phone
        createdAt
        State
        organizationID
        updatedAt
        zipcode
        field
        isAdmin
        website
        connections
      }
      nextToken
    }
  }
`;

export const GET_RESOURCES_WITH_TOKEN = gql`
  query AllResources ($limit: Int, $nextToken: String) {
    listResources (limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        contact_email
        contact_phone
        organizationID
        createdAt
        State
        updatedAt
        zipcode
        field
        isAdmin
        website
        connections
      }
      nextToken
    }
}
`

export async function AllResources() {
  const resp = await API.graphql({ 
    query: GET_RESOURCES,
  });

  const data = resp?.data?.listResources
  let nextToken = data?.nextToken
  let resources = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_RESOURCES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listResources

    const previousData = [...resources, ...respData?.items]
    resources = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return resources || [];
}

export const GET_RESOURCE = gql`
  query SingleResource($id: ID!) {
    getResource(id: $id) {
      id
      name
      description
      contact_email
      contact_phone
      createdAt
      State
      updatedAt
      zipcode
      field
      isAdmin
      website
      organizationID
      connections
    }
  }
`;


export async function GetResource(id) {
  if (!id) return {};
  try {
    const respResource = await API.graphql({
      query: GET_RESOURCE,
      variables: { id },
    });
    const resource = respResource?.data?.getResource;
    return { ...resource };
  } catch (err) {
    console.error(err);
    return {};
  }
}