import { gql } from "@apollo/client";

export const CREATE_SCHOOL = gql`
  mutation CreateNewSchool(
    $contact_email: AWSEmail!
    $state: String!
    $zipcode: String!
    $name: String!
    $type: String
    $contact_phone: AWSPhone!
  ) {
    createSchool(
      input: {
        contact_email: $contact_email
        contact_phone: $contact_phone
        type: $type
        state: $state
        zipcode: $zipcode
        name: $name
      }
    ) {
      id
      name
      type
    }
  }
`;


export const UPDATE_SCHOOL = gql`
  mutation UpdateSchool(
    $state: String
    $name: String
    $id: ID!
    $connections: [ID!]
    $connection_dates: [AWSDateTime]
    $counselors_dont_show_again: [ID!]
    $dont_show_again: [ID!]
  ) {
    updateSchool(
      input: {
        state: $state
        name: $name
        connections: $connections
        dont_show_again: $dont_show_again
        connection_dates: $connection_dates
        counselors_dont_show_again: $counselors_dont_show_again
        id: $id
      }
    ) {
      id
      name
    }
  }
`;
