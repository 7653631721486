/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Counselors from "../../Assets/images/users.svg";
import { GetSchoolsByState } from "../../Services/query/school";
import { GetSingleStudent } from "../../Services/query/students";
import { setUserDataAction } from "../../Redux/actions/userActions";
import FindCouncellorLoader from "../../Components/Common/FindCouncelorLoader";
import "./style.scss";
import { setLoadersDataAction } from "../../Redux/actions/loadersAction";
import { getSchoolState } from "../../utils/helpers";
import { useAuth } from "../../Components/Auth";

const FindCounselor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  const [loaded, setLoaded] = useState(false);
  const [student, setStudent] = useState({});
  const [searchKey, setSearchKey] = useState("");

  const [filteredSchools, setFilteredSchools] = useState([]);
  const [schools, setSchools] = useState([]);
  const { getPreviousRoute, navigateToPreviousRoute } = useAuth();

  const counselors = (educators) => {
    return educators?.length || 0;
  };

  const proceedToConnect = (id) => {
    navigate(`/counselor-institute?id=${id}`);
    dispatch(setUserDataAction(student));
  };

  useEffect(() => {
    const setSameSchoolData = async () => {
      setLoaded(false);
      const sameStateSchools = await GetSchoolsByState(user.state);
      const sameSchoolsAcceptingConnections = sameStateSchools.filter(
        (elt) => elt.accepting_connection === true
      );
      setFilteredSchools(sameSchoolsAcceptingConnections);
      setSchools(sameSchoolsAcceptingConnections);

      const student = await GetSingleStudent(user.id);
      setStudent(student);

      setTimeout(() => {
        setLoaded(true);
      }, 100);

      dispatch(setLoadersDataAction({ find_counselor: true }));
      setLoaded(true);
    };
    setSameSchoolData();
  }, []);

  useEffect(() => {
    if (searchKey.trim() !== "") {
      const re = new RegExp(searchKey.toLowerCase(), "g");
      const matches = filteredSchools?.filter(
        (elt) => elt.name.toLowerCase().match(re) !== null
      );
      setFilteredSchools(matches);
    } else {
      setFilteredSchools(schools);
    }
  }, [searchKey]);

  return (
    <>
      <div className="noti-head">
        <div className="icon">
          <Link to={getPreviousRoute()} onClick={navigateToPreviousRoute}>
            <span>{"<"}</span>
          </Link>
        </div>
        <div className="text">Find a Counselor</div>
      </div>
      <div className="find-counselor" style={{ marginTop: 80 }}>
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search for an institution"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <i className="fas fa-search"></i>
        </div>
        <div className="institute">
          <div className="title">
            We found {filteredSchools?.length} institutions near you
          </div>
          {loaded ? (
            <div className="items">
              <div className="row">
                {filteredSchools?.map((elt, index) => (
                  <div className="col-6" key={index}>
                    <div
                      className="item"
                      onClick={() => proceedToConnect(elt?.id)}
                    >
                      <div className="ins-logo">
                        <img src={elt?.image} alt="institute logo" />
                      </div>
                      <div className="title">{elt.name}</div>
                      <div className="label">
                        {getSchoolState(elt.full_address)}
                      </div>
                      <div className="no-cons">
                        <img src={Counselors} alt="" />
                        {counselors(elt.educators)} Counselor
                        {counselors(elt.educators) > 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <FindCouncellorLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default FindCounselor;
