import { BsStarFill } from "react-icons/bs";
import React, { useState, useEffect } from 'react';


function ReviewStarFill({starNo}) {
  const [filled, setFilled] = useState([])
  const [unfilled, setUnFilled] = useState([])

  const starFilled = (index) => {
    return (
      <BsStarFill 
        fill="#FFC768" 
        className="mr-1"
        key={`star-filled-${index}`}
      />
    )
  }

  const starUnFilled = (index) => {
    return (
      <BsStarFill 
        fill="#DDDFE2" 
        className="mr-1"
        key={`star-unfilled-${index}`}
      />
    )
  }

  useEffect(() => {
    let collectFills = []
    let collectUnFills = []

    for (let i = 0; i < parseInt(starNo); i++){
      collectFills.push(starFilled(i))
    }

    for (let i = 0; i < 5 - parseInt(starNo); i++){
      collectUnFills.push(starUnFilled(i))
    }


    setFilled(collectFills)
    setUnFilled(collectUnFills)

  }, [starNo])
  
  return ( 
    <>
      <>{filled}</>
      <>{unfilled}</>
        {/* <BsStarFill 
          fill="#DDDFE2" 
          className="mr-1"
        /> */}
    </>
  );
}

export default ReviewStarFill;